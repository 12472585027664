const FilterModalIconTwo = () => {
  return (
    <svg
      width="300"
      height="172"
      viewBox="0 0 300 172"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4179_11968)">
        <path
          d="M201.386 35.8551H193.167C193.986 35.3132 194.605 34.5177 194.929 33.5905C195.417 32.1019 195.766 30.5718 195.974 29.0194C196.146 27.8073 196.236 26.5852 196.246 25.3611C196.233 23.7114 195.999 22.0707 195.549 20.4835H198.948C198.745 21.2159 198.589 21.9607 198.481 22.7133C198.345 23.5962 198.277 24.4886 198.279 25.3821C198.308 28.1776 198.751 30.9534 199.596 33.6183C199.935 34.5378 200.563 35.3226 201.386 35.8551Z"
          fill="#01A76A"
        />
        <path
          opacity="0.2"
          d="M198.976 20.4347C198.837 21.2361 198.676 21.9747 198.509 22.6645C198.009 24.8982 197.153 27.0368 195.974 28.9985C196.146 27.7865 196.236 26.5643 196.246 25.3403C196.233 23.6905 195.999 22.0498 195.549 20.4626L198.976 20.4347Z"
          fill="black"
        />
        <path
          d="M191.496 10.8675L190.451 12.1636L189.901 11.5225C189.156 10.7797 188.165 10.3359 187.115 10.2752C187.036 10.268 186.957 10.268 186.878 10.2752C186.649 10.3093 186.429 10.3884 186.23 10.508C186.032 10.6276 185.859 10.7853 185.722 10.972C185.5 11.2644 185.339 11.5985 185.248 11.9545C185.221 12.0482 185.2 12.1437 185.186 12.2402C185.074 12.9893 185.155 13.7545 185.423 14.4631C185.938 15.8567 186.99 16.7765 188.09 16.7765H188.856V18.6021H188.09C186.377 18.6021 184.782 17.2642 184.016 15.1947C183.967 15.0623 183.918 14.9229 183.876 14.7836C183.589 13.947 183.502 13.0544 183.623 12.178C183.744 11.3015 184.069 10.4659 184.573 9.73869C184.86 9.34656 185.232 9.02486 185.662 8.7979C186.091 8.57095 186.567 8.4447 187.052 8.42869C187.719 8.42257 188.38 8.56043 188.988 8.83284C189.734 9.15739 190.405 9.63174 190.96 10.2265L191.496 10.8675Z"
          fill="#01A76A"
        />
        <path
          opacity="0.2"
          d="M188.975 8.86768C188.934 8.96078 188.88 9.04759 188.814 9.1255C188.584 9.32915 188.317 9.48753 188.027 9.59236C187.604 9.77247 187.206 10.0067 186.843 10.2892C186.614 10.3232 186.394 10.4023 186.196 10.5219C185.997 10.6415 185.824 10.7992 185.687 10.986C185.407 11.3582 185.223 11.7938 185.151 12.2542C184.891 12.695 184.678 13.1621 184.517 13.6478C184.417 14.1029 184.173 14.5136 183.821 14.8184C183.364 13.4149 183.449 11.8914 184.057 10.547C184.188 10.2717 184.349 10.0122 184.538 9.77353C184.825 9.3814 185.197 9.05969 185.627 8.83274C186.056 8.60579 186.532 8.47954 187.017 8.46353H187.275C187.862 8.49019 188.438 8.6273 188.975 8.86768Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M187.296 8.46354C187.296 8.46354 187.296 8.50535 187.296 8.52625C187.15 9.05525 186.803 9.50574 186.328 9.78051C185.757 10.155 185.114 10.4065 184.441 10.5191C184.322 10.5266 184.204 10.5266 184.085 10.5191C184.216 10.2438 184.377 9.9843 184.566 9.74567C184.853 9.35354 185.225 9.03183 185.655 8.80488C186.084 8.57792 186.56 8.45167 187.045 8.43567L187.296 8.46354Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M188.208 16.8113C188.077 16.986 187.891 17.1108 187.679 17.1653C187.468 17.2197 187.244 17.2005 187.045 17.1109C186.55 16.9245 186.105 16.6234 185.748 16.2322C185.391 15.841 185.131 15.3709 184.991 14.8602C184.744 13.9051 184.822 12.895 185.214 11.9894C185.186 12.083 185.165 12.1785 185.151 12.2751C185.039 13.0241 185.12 13.7893 185.388 14.4979C185.903 15.8915 186.955 16.8113 188.055 16.8113H188.208Z"
          fill="black"
        />
        <path
          d="M203.252 10.8675L204.325 12.1636L204.875 11.5225C205.62 10.7797 206.611 10.3359 207.661 10.2752C207.737 10.268 207.814 10.268 207.891 10.2752C208.12 10.3085 208.341 10.3873 208.539 10.507C208.738 10.6266 208.91 10.7847 209.047 10.972C209.269 11.2643 209.43 11.5985 209.521 11.9545C209.521 12.0521 209.569 12.1427 209.59 12.2402C209.7 12.9898 209.616 13.7551 209.346 14.463C208.831 15.8567 207.779 16.7765 206.679 16.7765H205.913V18.6021H206.679C208.392 18.6021 209.994 17.2642 210.753 15.1947C210.807 15.0602 210.854 14.923 210.893 14.7836C211.181 13.9471 211.268 13.0544 211.147 12.1778C211.026 11.3013 210.7 10.4657 210.196 9.73868C209.908 9.34767 209.536 9.02678 209.106 8.79995C208.677 8.57312 208.202 8.44621 207.717 8.42868C207.047 8.42178 206.385 8.55964 205.774 8.83283C205.028 9.15738 204.357 9.63173 203.803 10.2265L203.252 10.8675Z"
          fill="#01A76A"
        />
        <path
          opacity="0.2"
          d="M205.774 8.86768C205.814 8.96245 205.871 9.04963 205.941 9.1255C206.168 9.33034 206.433 9.48892 206.721 9.59236C207.144 9.77247 207.542 10.0067 207.905 10.2892C208.134 10.3225 208.355 10.4013 208.553 10.5209C208.752 10.6406 208.924 10.7987 209.061 10.986C209.343 11.3578 209.53 11.7933 209.604 12.2542C209.859 12.6967 210.069 13.1635 210.231 13.6478C210.336 14.1008 210.58 14.5099 210.927 14.8184C211.384 13.4149 211.299 11.8914 210.691 10.547C210.56 10.2733 210.402 10.0141 210.217 9.77353C209.929 9.38252 209.556 9.06162 209.127 8.8348C208.698 8.60797 208.223 8.48106 207.738 8.46353H207.473C206.886 8.49019 206.31 8.6273 205.774 8.86768Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M207.452 8.46354C207.452 8.46354 207.452 8.50535 207.452 8.52625C207.598 9.05525 207.945 9.50574 208.42 9.78051C208.991 10.155 209.634 10.4065 210.308 10.5191C210.426 10.5263 210.544 10.5263 210.663 10.5191C210.532 10.2454 210.374 9.9862 210.189 9.74567C209.901 9.35465 209.529 9.03376 209.099 8.80693C208.67 8.5801 208.195 8.4532 207.71 8.43567L207.452 8.46354Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M206.54 16.8113C206.671 16.986 206.857 17.1108 207.069 17.1653C207.28 17.2197 207.504 17.2005 207.703 17.1109C208.204 16.9276 208.655 16.628 209.018 16.2368C209.381 15.8456 209.646 15.3738 209.792 14.8602C210.033 13.904 209.952 12.8951 209.562 11.9894C209.562 12.0869 209.611 12.1775 209.632 12.2751C209.742 13.0247 209.658 13.7899 209.388 14.4979C208.873 15.8915 207.821 16.8113 206.721 16.8113H206.54Z"
          fill="black"
        />
        <path
          d="M206.902 16.5186C206.876 16.7738 206.836 17.0273 206.783 17.2782C206.53 18.7112 205.734 19.9915 204.562 20.8528C201.678 22.7899 197.088 23.3474 193.341 22.2464L193.007 22.1489C192.068 21.8548 191.18 21.4177 190.374 20.8528C189.877 20.4855 189.445 20.0353 189.1 19.5219C188.499 18.626 188.132 17.5933 188.034 16.5186C187.939 15.513 188.07 14.4987 188.417 13.5502C188.452 13.4457 188.494 13.3412 188.536 13.2367C188.741 12.7607 188.995 12.3076 189.295 11.8849C189.671 11.3553 190.11 10.8954 190.465 10.3728C190.828 9.88782 191.013 9.29207 190.987 8.68652L197.465 9.25791L203.935 8.68652C203.908 9.2933 204.096 9.89011 204.464 10.3728C204.812 10.8954 205.251 11.3553 205.627 11.8849C206.01 12.4195 206.315 13.0061 206.533 13.6269C206.864 14.5527 206.99 15.5394 206.902 16.5186Z"
          fill="#01A76A"
        />
        <path
          opacity="0.1"
          d="M198.732 14.0868C194.999 15.6825 190.904 14.5397 189.211 13.8986C188.942 13.7946 188.679 13.6735 188.424 13.5363C188.44 13.4864 188.458 13.4375 188.48 13.39C188.689 12.8509 188.965 12.3404 189.302 11.8709C189.678 11.3414 190.117 10.8815 190.472 10.3589C190.835 9.87388 191.02 9.27813 190.994 8.67258L197.472 9.24396L202.437 8.91646C202.57 9.56449 202.674 12.4075 198.732 14.0868Z"
          fill="white"
        />
        <path
          opacity="0.1"
          d="M206.902 16.5186C206.848 17.3673 206.611 18.1942 206.207 18.9423C205.803 19.6905 205.241 20.3423 204.562 20.8528C200.759 23.4101 194.115 23.5494 190.374 20.8528C189.705 20.333 189.15 19.679 188.747 18.9327C188.344 18.1864 188.102 17.3642 188.034 16.5186C188.034 16.2678 188.034 16.0169 188.034 15.7731C190.535 18.6997 195.382 19.6404 199.241 17.6963C201.312 16.6596 202.888 14.8426 203.621 12.6444C204.056 11.3688 204.116 9.99539 203.796 8.68652H203.97C203.943 9.2933 204.131 9.89011 204.499 10.3728C204.847 10.8954 205.286 11.3553 205.662 11.8849C206.617 13.2308 207.057 14.8753 206.902 16.5186Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M206.783 17.2781C206.53 18.7112 205.734 19.9914 204.562 20.8528C201.588 22.8526 196.81 23.3822 193.007 22.1488C192.527 21.9607 192.053 21.7447 191.614 21.5147C190.655 21.029 189.806 20.3505 189.121 19.5219C188.52 18.6259 188.153 17.5932 188.055 16.5186C187.962 15.5079 188.095 14.4889 188.445 13.5363C189.558 14.1374 190.749 14.5807 191.983 14.8533C192.917 15.1136 193.873 15.2885 194.839 15.3759C197.416 15.6128 202.5 15.6894 206.561 13.6129C206.887 14.8062 206.963 16.0541 206.783 17.2781Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M206.902 16.5186C206.848 17.3673 206.611 18.1941 206.207 18.9423C205.803 19.6905 205.241 20.3423 204.562 20.8528C200.759 23.4101 194.115 23.5494 190.374 20.8528C189.705 20.333 189.15 19.679 188.747 18.9327C188.344 18.1864 188.102 17.3642 188.034 16.5186C188.034 16.2678 189.26 19.0341 189.26 18.7554C192.046 22.1001 197.318 22.2812 201.428 20.3999C206.393 18.1283 205.537 12.8674 204.478 10.3798C204.826 10.8954 205.265 11.3483 205.634 11.8709C206.602 13.2169 207.053 14.8672 206.902 16.5186Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M204.151 9.79443C195.87 12.1496 190.57 8.83284 190.521 8.40082L197.486 9.21608L203.956 8.6447C203.91 9.03869 203.978 9.43746 204.151 9.79443Z"
          fill="black"
        />
        <path
          d="M204.659 7.7319C204.67 8.122 204.573 8.50754 204.381 8.8468C200.898 10.108 193.857 10.0314 190.555 8.8468C190.363 8.50754 190.266 8.122 190.277 7.7319C191.398 8.48446 195.055 8.66563 197.471 8.72834C199.881 8.67956 203.538 8.48446 204.659 7.7319Z"
          fill="#01A76A"
        />
        <path
          opacity="0.1"
          d="M202.465 9.37635H202.403C199.031 9.92872 195.591 9.90516 192.227 9.30667C191.66 9.19934 191.101 9.05028 190.555 8.86071C190.363 8.52146 190.266 8.13592 190.277 7.74582C190.973 8.21965 192.715 8.44262 194.511 8.60289C195.542 8.67954 196.601 8.71438 197.471 8.74225C199.064 8.71306 200.654 8.60376 202.235 8.41475H202.291C202.382 8.67954 202.444 9.37635 202.465 9.37635Z"
          fill="#01A76A"
        />
        <path
          d="M204.659 7.73189C204.715 8.28237 201.497 8.74226 197.472 8.74226C193.446 8.74226 190.221 8.28237 190.277 7.73189C190.333 7.18141 193.55 6.74939 197.472 6.74939C201.393 6.74939 204.603 7.18838 204.659 7.73189Z"
          fill="#01A76A"
        />
        <path
          opacity="0.2"
          d="M203.949 7.63434C203.949 8.04545 201.114 8.33115 197.555 8.33115C193.996 8.33115 191.134 8.01061 191.161 7.63434C191.189 7.25806 194.045 6.89572 197.555 6.89572C201.065 6.89572 203.921 7.22322 203.949 7.63434Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M193.885 11.7664C193.806 11.9776 193.662 12.1586 193.475 12.2837C193.287 12.4088 193.065 12.4716 192.84 12.4632C192.672 12.4771 192.502 12.4544 192.344 12.3967C192.185 12.339 192.041 12.2476 191.921 12.1287C191.816 12.0242 191.503 11.5086 191.802 11.2229L191.837 11.1881C192.089 11.0529 192.371 10.9851 192.656 10.9912C192.942 10.9973 193.221 11.0771 193.467 11.2229C193.933 11.4668 193.933 11.6061 193.885 11.7664Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M192.924 11.0278C193.447 11.1045 193.321 11.7943 192.771 11.8013C192.701 11.8074 192.631 11.8074 192.562 11.8013C192.018 11.7176 192.164 11.0348 192.722 11.0208C192.789 11.0133 192.857 11.0157 192.924 11.0278Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M194.435 8.67956C194.068 8.94532 193.647 9.12836 193.202 9.2161C192.882 9.28598 192.555 9.32334 192.227 9.32759C191.66 9.22026 191.101 9.0712 190.556 8.88163C190.415 8.63241 190.325 8.35765 190.291 8.07333C190.458 8.13366 190.635 8.16432 190.813 8.16392H191.078C191.078 8.20573 191.12 8.24057 191.203 8.26844C191.285 8.29408 191.369 8.31272 191.454 8.32419L192.269 8.47051L192.965 8.55413L193.564 8.61685C193.843 8.60291 194.135 8.63078 194.435 8.67956Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M198.461 8.74226C197.883 9.34058 197.098 9.69466 196.267 9.73173C195.849 9.77122 195.428 9.75952 195.013 9.69689L194.651 9.63418C194.151 9.54455 193.664 9.39238 193.202 9.18125C192.822 9.03487 192.496 8.77447 192.269 8.43567C192.889 8.51929 193.62 8.59593 194.435 8.64471C195.25 8.69348 196.385 8.74226 197.472 8.74226H198.447H198.461Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M193.571 8.58197C193.558 8.64524 193.523 8.70173 193.472 8.74132C193.421 8.78091 193.357 8.801 193.293 8.79798C193.293 8.79798 192.896 8.79798 192.972 8.51926L193.571 8.58197Z"
          fill="white"
        />
        <path
          d="M206.964 36.7191H187.777C189.593 35.6988 191.641 35.1614 193.725 35.1583H201.024C203.104 35.1583 205.15 35.6934 206.964 36.7121V36.7191Z"
          fill="#01A76A"
        />
        <path
          opacity="0.3"
          d="M206.964 36.7191H187.777C189.593 35.6988 191.641 35.1614 193.725 35.1583H201.024C203.104 35.1583 205.15 35.6934 206.964 36.7121V36.7191Z"
          fill="white"
        />
        <g opacity="0.8">
          <path
            d="M197.924 12.3239V18.1701H196.838V13.2297H195.605V12.3239H197.924Z"
            fill="white"
          />
        </g>
        <path
          d="M211.018 16.7765C211.018 16.7765 210.691 19.1248 210.175 21.7796C209.876 23.3335 207.605 24.2115 206.087 24.6435C208.176 24.7132 209.653 26.2949 210.482 27.9464C211.311 29.5978 211.568 33.3536 211.568 33.3536C211.568 33.3536 211.965 28.7756 213.24 26.936C214.027 25.8002 215.831 24.9083 217.091 24.3648C212.961 24.2045 211.492 20.2954 211.018 16.7765Z"
          fill="#01A76A"
        />
        <path
          opacity="0.7"
          d="M211.018 16.7765C211.018 16.7765 210.691 19.1248 210.175 21.7796C209.876 23.3335 207.605 24.2115 206.087 24.6435C208.176 24.7132 209.653 26.2949 210.482 27.9464C211.311 29.5978 211.568 33.3536 211.568 33.3536C211.568 33.3536 211.965 28.7756 213.24 26.936C214.027 25.8002 215.831 24.9083 217.091 24.3648C212.961 24.2045 211.492 20.2954 211.018 16.7765Z"
          fill="white"
        />
        <path
          d="M214.271 1.31433C214.271 1.31433 214.027 3.07725 213.637 5.06316C213.414 6.22683 211.708 6.8888 210.572 7.2163C212.139 7.26508 213.247 8.44965 213.867 9.68997C214.487 10.9303 214.682 13.7454 214.682 13.7454C214.682 13.7454 214.981 10.3101 215.935 8.93045C216.527 8.08034 217.878 7.4114 218.826 7.00726C215.726 6.88183 214.626 3.95524 214.271 1.31433Z"
          fill="#01A76A"
        />
        <path
          opacity="0.7"
          d="M214.271 1.31433C214.271 1.31433 214.027 3.07725 213.637 5.06316C213.414 6.22683 211.708 6.8888 210.572 7.2163C212.139 7.26508 213.247 8.44965 213.867 9.68997C214.487 10.9303 214.682 13.7454 214.682 13.7454C214.682 13.7454 214.981 10.3101 215.935 8.93045C216.527 8.08034 217.878 7.4114 218.826 7.00726C215.726 6.88183 214.626 3.95524 214.271 1.31433Z"
          fill="white"
        />
        <path
          d="M178.862 10.5401C178.862 10.5401 178.569 12.6305 178.117 14.923C177.852 16.3166 175.867 17.0483 174.537 17.4315C176.368 17.4942 177.657 18.8809 178.388 20.3302C179.12 21.7796 179.342 25.0685 179.342 25.0685C179.342 25.0685 179.691 21.0479 180.805 19.4453C181.501 18.4488 183.082 17.6684 184.183 17.1876C180.561 17.0413 179.308 13.62 178.862 10.5401Z"
          fill="#01A76A"
        />
        <path
          opacity="0.7"
          d="M178.862 10.5401C178.862 10.5401 178.569 12.6305 178.117 14.923C177.852 16.3166 175.867 17.0483 174.537 17.4315C176.368 17.4942 177.657 18.8809 178.388 20.3302C179.12 21.7796 179.342 25.0685 179.342 25.0685C179.342 25.0685 179.691 21.0479 180.805 19.4453C181.501 18.4488 183.082 17.6684 184.183 17.1876C180.561 17.0413 179.308 13.62 178.862 10.5401Z"
          fill="white"
        />
        <path
          d="M168.86 4.88894C168.804 4.88837 168.749 4.87401 168.7 4.84713L160.865 0.666282C160.821 0.64672 160.782 0.618409 160.75 0.583104C160.718 0.547799 160.693 0.506255 160.678 0.461054C160.662 0.415853 160.656 0.367959 160.66 0.320349C160.664 0.272739 160.678 0.226429 160.7 0.184296C160.723 0.142164 160.754 0.105107 160.791 0.0754308C160.828 0.0457543 160.872 0.024095 160.918 0.0117902C160.964 -0.000514484 161.012 -0.00319888 161.059 0.00390628C161.106 0.0110114 161.152 0.0277539 161.192 0.053092L169.027 4.23394C169.096 4.27085 169.15 4.32955 169.181 4.40072C169.212 4.47189 169.219 4.55145 169.2 4.62673C169.18 4.70202 169.136 4.76872 169.075 4.81623C169.014 4.86374 168.938 4.88933 168.86 4.88894Z"
          fill="#01A76A"
        />
        <path
          d="M174.175 7.69707C174.118 7.69742 174.063 7.683 174.014 7.65527L171.375 6.26165C171.329 6.24417 171.287 6.21713 171.252 6.18232C171.217 6.1475 171.19 6.10571 171.173 6.05968C171.155 6.01366 171.148 5.96445 171.151 5.9153C171.154 5.86616 171.167 5.81819 171.19 5.77456C171.213 5.73093 171.244 5.69263 171.283 5.66218C171.322 5.63173 171.366 5.60982 171.414 5.59791C171.462 5.58599 171.512 5.58434 171.56 5.59306C171.608 5.60177 171.654 5.62065 171.695 5.64846L174.342 7.04208C174.41 7.07898 174.464 7.13769 174.495 7.20886C174.526 7.28003 174.533 7.35958 174.514 7.43487C174.494 7.51016 174.451 7.57685 174.389 7.62436C174.328 7.67188 174.252 7.69747 174.175 7.69707Z"
          fill="#01A76A"
        />
        <path
          d="M168.86 15.2435H156.825C156.733 15.2435 156.644 15.2068 156.579 15.1415C156.514 15.0761 156.477 14.9875 156.477 14.8951C156.477 14.8027 156.514 14.7141 156.579 14.6488C156.644 14.5834 156.733 14.5467 156.825 14.5467H168.86C168.953 14.5467 169.041 14.5834 169.107 14.6488C169.172 14.7141 169.209 14.8027 169.209 14.8951C169.209 14.9875 169.172 15.0761 169.107 15.1415C169.041 15.2068 168.953 15.2435 168.86 15.2435Z"
          fill="#01A76A"
        />
        <path
          d="M161.06 29.765C160.995 29.765 160.932 29.7475 160.877 29.7144C160.822 29.6812 160.777 29.6337 160.746 29.5769C160.705 29.4954 160.696 29.4008 160.724 29.3134C160.751 29.2261 160.812 29.153 160.893 29.11L173.784 22.2604C173.825 22.235 173.87 22.2183 173.917 22.2112C173.965 22.2041 174.013 22.2068 174.059 22.2191C174.105 22.2314 174.148 22.253 174.185 22.2827C174.223 22.3124 174.254 22.3494 174.276 22.3916C174.299 22.4337 174.312 22.48 174.316 22.5276C174.32 22.5752 174.314 22.6231 174.299 22.6683C174.283 22.7135 174.259 22.7551 174.227 22.7904C174.194 22.8257 174.155 22.854 174.112 22.8736L161.199 29.7232C161.156 29.7462 161.109 29.7604 161.06 29.765Z"
          fill="#01A76A"
        />
        <path
          d="M221.841 7.69708C221.779 7.69725 221.717 7.68051 221.663 7.64863C221.609 7.61674 221.565 7.57089 221.535 7.51591C221.493 7.43344 221.484 7.33776 221.512 7.24925C221.539 7.16075 221.6 7.08641 221.681 7.04208L234.803 0.0739966C234.843 0.0486584 234.888 0.031916 234.936 0.0248108C234.983 0.0177057 235.031 0.0203901 235.077 0.0326948C235.123 0.0449995 235.166 0.0666589 235.204 0.0963353C235.241 0.126012 235.272 0.163068 235.295 0.205201C235.317 0.247334 235.331 0.293644 235.334 0.341254C235.338 0.388864 235.332 0.436757 235.317 0.481959C235.301 0.52716 235.277 0.568703 235.245 0.604008C235.213 0.639314 235.174 0.667625 235.13 0.687186L222.008 7.65527C221.957 7.6822 221.899 7.69653 221.841 7.69708Z"
          fill="#01A76A"
        />
        <path
          d="M239.204 15.2435H227.128C227.035 15.2435 226.947 15.2068 226.881 15.1415C226.816 15.0761 226.779 14.9875 226.779 14.8951C226.779 14.8027 226.816 14.7141 226.881 14.6488C226.947 14.5834 227.035 14.5467 227.128 14.5467H239.204C239.297 14.5467 239.385 14.5834 239.451 14.6488C239.516 14.7141 239.553 14.8027 239.553 14.8951C239.553 14.9875 239.516 15.0761 239.451 15.1415C239.385 15.2068 239.297 15.2435 239.204 15.2435Z"
          fill="#01A76A"
        />
        <path
          d="M229.586 26.9011C229.528 26.903 229.471 26.891 229.419 26.8663L221.911 22.8736C221.867 22.854 221.828 22.8257 221.796 22.7904C221.764 22.7551 221.739 22.7135 221.724 22.6683C221.709 22.6231 221.703 22.5752 221.706 22.5276C221.71 22.48 221.724 22.4337 221.746 22.3916C221.769 22.3494 221.8 22.3124 221.837 22.2827C221.875 22.253 221.918 22.2314 221.964 22.2191C222.01 22.2068 222.058 22.2041 222.105 22.2112C222.153 22.2183 222.198 22.235 222.238 22.2604L229.746 26.2461C229.787 26.2674 229.823 26.2966 229.853 26.332C229.883 26.3674 229.905 26.4084 229.918 26.4524C229.932 26.4965 229.937 26.5429 229.932 26.5888C229.928 26.6347 229.914 26.6793 229.893 26.7199C229.863 26.7754 229.819 26.8217 229.765 26.8537C229.711 26.8856 229.649 26.9021 229.586 26.9011Z"
          fill="#01A76A"
        />
        <path
          d="M234.963 29.765C234.907 29.7653 234.851 29.7509 234.803 29.7232L232.386 28.4411C232.346 28.4192 232.31 28.3895 232.281 28.3539C232.252 28.3182 232.231 28.2771 232.218 28.2331C232.205 28.1891 232.201 28.1429 232.206 28.0973C232.211 28.0517 232.224 28.0075 232.247 27.9672C232.29 27.8864 232.363 27.8257 232.45 27.7983C232.537 27.7709 232.632 27.7791 232.713 27.8209L235.13 29.11C235.211 29.153 235.271 29.2261 235.299 29.3134C235.326 29.4008 235.318 29.4954 235.276 29.5769C235.246 29.6337 235.201 29.6812 235.146 29.7144C235.09 29.7475 235.027 29.765 234.963 29.765Z"
          fill="#01A76A"
        />
        <path
          d="M261.979 88.7011H127.176L145.104 35.8481H261.979V88.7011Z"
          fill="#01A76A"
        />
        <path
          opacity="0.3"
          d="M261.979 88.7011H127.176L145.104 35.8481H261.979V88.7011Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M247.82 35.1165H153.67V46.9692H247.82V35.1165Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M242.171 48.9412H148.022V60.7939H242.171V48.9412Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M236.523 62.7659H142.373V74.6186H236.523V62.7659Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M230.868 76.5975H136.718V88.4502H230.868V76.5975Z"
          fill="white"
        />
        <path
          d="M262 169.601H65.6915L102.417 88.29H262V169.601Z"
          fill="#01A76A"
        />
        <g opacity="0.1">
          <path
            d="M262 169.601H65.6915L102.417 88.29H262V169.601Z"
            fill="white"
          />
        </g>
        <path
          opacity="0.2"
          d="M226.368 89.9902H114.835V101.843H226.368V89.9902Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M219.919 103.599H108.385V115.452H219.919V103.599Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M213.477 117.208H101.943V129.06H213.477V117.208Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M207.027 130.816H95.4938V142.669H207.027V130.816Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M200.585 144.425H89.0513V156.278H200.585V144.425Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M194.142 158.034H82.6089V169.886H194.142V158.034Z"
          fill="white"
        />
        <path
          d="M261.868 238.167H0L43.0422 158.556L261.868 156.856V238.167Z"
          fill="#01A76A"
        />
        <path
          opacity="0.2"
          d="M192.269 158.138H54.9031V169.991H192.269V158.138Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M184.329 171.747H46.9633V183.599H184.329V171.747Z"
          fill="white"
        />
        <path
          d="M87.8044 161.527L89.3785 150.489L94.8667 152.398L91.6908 162.53L87.8044 161.527Z"
          fill="#CB7A63"
        />
        <path
          d="M91.5167 162.056L87.8114 160.467C87.733 160.434 87.6454 160.428 87.5631 160.451C87.4808 160.474 87.4088 160.524 87.3587 160.593L85.0952 163.742C85.0454 163.808 85.0093 163.883 84.989 163.962C84.9687 164.042 84.9646 164.125 84.9769 164.206C84.9893 164.287 85.0178 164.365 85.0609 164.435C85.1039 164.505 85.1606 164.566 85.2275 164.613C85.2615 164.64 85.299 164.661 85.339 164.676C86.8921 165.31 88.299 165.819 90.2143 166.641C92.4778 167.617 91.7883 167.638 94.4349 168.822C96.0298 169.519 97.1024 167.833 96.4617 167.429C93.5156 165.7 93.028 164.363 92.2828 162.732C92.1136 162.425 91.8427 162.186 91.5167 162.056Z"
          fill="#263238"
        />
        <path
          d="M115.587 67.8685C115.587 67.8685 110.962 106.52 104.443 124.408C97.9243 142.295 94.6021 153.499 94.6021 153.499L89.1208 151.911C89.1208 151.911 91.071 129.613 95.1523 117.767C95.3404 117.23 95.5284 116.722 95.7234 116.234C96.886 113.322 97.4498 110.206 97.381 107.071V106.973C97.2487 101.399 97.1303 93.1418 97.4298 88.5498C96.9728 81.5724 98.3765 74.5989 101.497 68.3423L115.587 67.8685Z"
          fill="#263238"
        />
        <path
          opacity="0.1"
          d="M111.478 82.2925L112.752 87.6648C111.965 92.5425 111.025 97.8592 109.966 103.106C109.692 100.175 108.712 97.3538 107.111 94.8838C103.891 90.1516 102.661 84.3429 103.684 78.7109L111.478 82.2925Z"
          fill="black"
        />
        <path
          d="M95.6468 152.168L94.6508 154.503L88.5149 152.83L88.7517 150.287L95.6468 152.168Z"
          fill="#263238"
        />
        <path
          opacity="0.6"
          d="M95.6468 152.168L94.6508 154.503L88.5149 152.83L88.7517 150.287L95.6468 152.168Z"
          fill="white"
        />
        <path
          d="M137.171 139.556L139.058 152.942L135.339 153.464L131.209 140.211L137.171 139.556Z"
          fill="#CB7A63"
        />
        <path
          d="M138.849 151.625L134.747 152.322C134.66 152.337 134.58 152.381 134.521 152.447C134.462 152.512 134.426 152.596 134.42 152.684L134.211 156.684C134.203 156.769 134.213 156.855 134.239 156.937C134.265 157.018 134.307 157.094 134.363 157.159C134.419 157.224 134.487 157.277 134.564 157.315C134.641 157.353 134.724 157.375 134.81 157.381C134.856 157.388 134.903 157.388 134.949 157.381C136.648 157.067 138.153 156.719 140.284 156.37C142.791 155.952 142.206 156.37 145.159 155.91C146.942 155.639 146.928 153.562 146.162 153.583C142.638 153.722 141.461 152.837 139.929 151.82C139.606 151.623 139.221 151.553 138.849 151.625Z"
          fill="#263238"
        />
        <path
          d="M121.27 68.9486C121.27 68.9486 134.573 94.9605 135.896 103.092C137.219 111.224 138.236 144.517 138.236 144.517L132.086 145.981C129.23 135.221 127.473 124.199 126.842 113.084C126.786 111.879 126.487 110.729 126.361 109.6C125.839 104.995 109.876 91.0583 109.876 91.0583C109.876 91.0583 98.4048 88.6265 102.18 69.1367L121.27 68.9486Z"
          fill="#263238"
        />
        <path
          d="M138.585 143.166L138.71 145.786L132.442 146.984L131.327 144.552L138.585 143.166Z"
          fill="#263238"
        />
        <path
          opacity="0.6"
          d="M138.585 143.166L138.71 145.786L132.442 146.984L131.327 144.552L138.585 143.166Z"
          fill="white"
        />
        <path
          d="M101.588 46.4765C101.588 46.4765 105.829 55.8346 107.334 58.0435C108.838 60.2524 124.404 65.7851 124.404 65.7851L127.141 67.9661C127.289 68.084 127.458 68.1707 127.64 68.221C127.822 68.2713 128.012 68.2841 128.199 68.2587C128.386 68.2333 128.566 68.1702 128.728 68.0731C128.889 67.9761 129.03 67.8472 129.14 67.6943L130.533 65.7572C130.659 65.5846 130.742 65.3855 130.779 65.1753C130.815 64.9651 130.802 64.7494 130.742 64.5447L130.589 64.0291C130.506 63.7488 130.337 63.5017 130.105 63.3229C129.874 63.1441 129.593 63.0425 129.301 63.0327L125.588 62.9072C125.588 62.9072 112.509 57.291 111.234 55.096C109.959 52.9011 106.958 45.1526 106.958 45.1526L101.588 46.4765Z"
          fill="#CB7A63"
        />
        <path
          d="M99.0108 37.7455C99.0108 37.7455 96.3085 39.2715 102.312 50.9082C102.312 50.9082 104.095 51.7444 109.346 48.6436C109.319 48.6436 107.431 39.2227 99.0108 37.7455Z"
          fill="#01A76A"
        />
        <path
          opacity="0.4"
          d="M99.0108 37.7455C99.0108 37.7455 96.3085 39.2715 102.312 50.9082C102.312 50.9082 104.095 51.7444 109.346 48.6436C109.319 48.6436 107.431 39.2227 99.0108 37.7455Z"
          fill="white"
        />
        <path
          d="M114.723 20.0326C114.933 20.0418 115.143 20.0033 115.336 19.92C115.529 19.8367 115.701 19.7109 115.838 19.5518C115.773 19.7784 115.633 19.976 115.44 20.1116C115.248 20.2473 115.014 20.3128 114.779 20.2974L114.723 20.0326Z"
          fill="#263238"
        />
        <path
          d="M115.929 20.5273C116.012 20.8339 115.929 21.1196 115.719 21.1754C115.51 21.2311 115.281 21.0151 115.211 20.7224C115.141 20.4298 115.211 20.1302 115.42 20.0814C115.629 20.0326 115.845 20.2277 115.929 20.5273Z"
          fill="#263238"
        />
        <path
          d="M114.96 21.6631L116.959 23.7536C116.959 23.7536 116.875 24.527 115.691 24.3528L114.96 21.6631Z"
          fill="#D4827D"
        />
        <path
          d="M113.95 19.7121C113.977 19.7147 114.004 19.7104 114.028 19.6993C114.053 19.6883 114.074 19.6711 114.089 19.6494C114.21 19.4834 114.368 19.3483 114.551 19.255C114.734 19.1618 114.936 19.1131 115.141 19.1128C115.183 19.1149 115.225 19.101 115.257 19.0739C115.289 19.0467 115.31 19.0084 115.315 18.9665C115.317 18.9225 115.303 18.8794 115.274 18.8458C115.246 18.8122 115.206 18.7906 115.162 18.7853C114.9 18.7851 114.643 18.8484 114.411 18.9698C114.179 19.0911 113.981 19.267 113.832 19.4821C113.802 19.5132 113.786 19.5544 113.786 19.5971C113.786 19.6399 113.802 19.681 113.832 19.7121C113.85 19.7204 113.871 19.7246 113.891 19.7246C113.911 19.7246 113.932 19.7204 113.95 19.7121Z"
          fill="#263238"
        />
        <path
          d="M103.155 22.2624C103.851 26.6941 104.081 28.5894 106.56 30.6241C110.231 32.61 115.615 31.8784 116.2 27.3143C116.757 23.217 115.503 16.674 110.948 15.3082C109.948 15.009 108.887 14.9705 107.868 15.1964C106.848 15.4223 105.903 15.9051 105.123 16.5989C104.342 17.2927 103.751 18.1746 103.407 19.1609C103.062 20.1472 102.976 21.2052 103.155 22.2345V22.2624Z"
          fill="#CB7A63"
        />
        <path
          d="M105.39 28.1086C105.39 28.1086 106.783 33.8503 104.645 36.0313L112.306 36.4076C112.306 36.4076 109.611 34.5471 110.732 29.2305C109.15 28.2131 107.248 27.8137 105.39 28.1086Z"
          fill="#CB7A63"
        />
        <path
          d="M121.437 68.8859C121.437 68.8859 121.549 60.7471 121.368 54.3992C121.298 51.9325 122.189 51.2078 122.064 48.3509C121.988 46.1838 120.671 43.968 120.4 42.644C119.661 38.9579 119.912 34.1987 114.382 35.0906L100.843 36.1219C100.409 36.1549 99.9878 36.2834 99.6095 36.4981C99.2311 36.7128 98.9049 37.0084 98.654 37.3639C98.4032 37.7194 98.2339 38.1259 98.1583 38.5545C98.0827 38.983 98.1027 39.423 98.2168 39.8429C99.8117 45.6543 102.744 57.8554 100.919 69.5269L121.437 68.8859Z"
          fill="#89D3B8"
        />
        <path
          d="M108.135 14.3118C108.964 14.0581 109.836 13.9735 110.699 14.063C111.561 14.1524 112.397 14.414 113.157 14.8325C113.917 15.2509 114.585 15.8175 115.122 16.4989C115.659 17.1803 116.054 17.9625 116.283 18.7993C116.931 17.1966 115.977 15.148 114.702 13.9704C114.046 13.4047 113.274 12.9909 112.439 12.7584C111.605 12.526 110.73 12.4806 109.876 12.6255C109.376 12.6683 108.895 12.8413 108.483 13.1273C108.311 13.2654 108.185 13.453 108.123 13.6648C108.061 13.8766 108.065 14.1024 108.135 14.3118Z"
          fill="#263238"
        />
        <path
          d="M113.498 15.0783C111.269 12.528 107.013 11.7615 105.307 13.9982C103.914 13.0994 99.2685 14.3954 98.377 16.4928C96.0787 21.9001 98.1124 27.593 105.774 31.1258C105.774 31.1258 110.726 31.6763 110.691 28.032C110.628 21.0639 112.975 26.708 114.528 19.4403C114.679 18.6818 114.665 17.8997 114.487 17.1471C114.309 16.3945 113.972 15.6889 113.498 15.0783Z"
          fill="#263238"
        />
        <path
          d="M112.836 22.9522C113.116 23.8485 113.087 24.8132 112.752 25.6907C112.958 25.5257 113.12 25.3118 113.222 25.0683C113.324 24.8249 113.364 24.5598 113.338 24.2971C113.369 24.0491 113.339 23.7972 113.252 23.5629C113.165 23.3287 113.022 23.1192 112.836 22.9522Z"
          fill="#263238"
        />
        <path
          d="M112.585 22.7432C113.296 23.3443 113.787 24.1659 113.978 25.0775C114.059 24.8301 114.078 24.5665 114.033 24.31C113.988 24.0536 113.881 23.8121 113.72 23.6072C113.435 23.1473 113.135 22.7641 112.585 22.7432Z"
          fill="#263238"
        />
        <path
          d="M112.453 22.3809C112.69 22.7291 112.855 23.1208 112.94 23.5334C113.025 23.946 113.027 24.3713 112.947 24.7849C112.724 25.8928 111.603 25.9416 110.823 25.2099C110.043 24.4783 109.388 23.1892 110.022 22.2763C110.162 22.0789 110.349 21.9197 110.566 21.8133C110.784 21.7068 111.024 21.6565 111.266 21.6669C111.507 21.6773 111.743 21.7481 111.95 21.8728C112.157 21.9975 112.33 22.1722 112.453 22.3809Z"
          fill="#CB7A63"
        />
        <path
          d="M115.371 47.0967L111.143 55.8346C110.649 60.0155 109.959 72.7531 109.959 72.7531L111.673 76.9758C111.776 77.2295 111.794 77.5103 111.722 77.7747C111.65 78.0392 111.493 78.2727 111.276 78.4391L110.837 78.7736C110.702 78.8746 110.548 78.9477 110.384 78.9884C110.22 79.029 110.05 79.0366 109.883 79.0105L107.097 78.5576C106.93 78.5311 106.77 78.4708 106.628 78.3803C106.485 78.2899 106.362 78.1712 106.267 78.0315C106.172 77.8919 106.106 77.7342 106.074 77.5683C106.042 77.4023 106.044 77.2315 106.08 77.0664L107.097 72.2375C107.097 72.2375 105.753 57.7578 106.226 54.9427C106.7 52.1276 109.472 45.5288 109.472 45.5288L115.371 47.0967Z"
          fill="#CB7A63"
        />
        <path
          d="M119.487 38.2402C119.257 40.5885 118.408 44.5115 115.761 50.2881C115.761 50.2881 110.886 50.3647 107.55 47.9398C107.55 47.9398 109.702 36.2892 116.395 35.244C116.806 35.1879 117.224 35.226 117.618 35.3552C118.012 35.4845 118.371 35.7016 118.669 35.9902C118.967 36.2787 119.195 36.6312 119.337 37.021C119.479 37.4108 119.53 37.8277 119.487 38.2402Z"
          fill="#77B69F"
        />
        <path
          opacity="0.1"
          d="M110.433 31.6833C110.433 31.6833 110.273 30.1503 110.287 29.2305C110.287 29.2305 111.408 31.2652 113.073 31.1258L113.825 30.9446C112.776 31.4783 111.608 31.7327 110.433 31.6833Z"
          fill="black"
        />
        <path
          d="M138.849 151.625L134.747 152.322C134.66 152.337 134.58 152.381 134.521 152.447C134.462 152.512 134.426 152.596 134.42 152.684L134.211 156.684C134.203 156.769 134.213 156.855 134.239 156.937C134.265 157.018 134.307 157.094 134.363 157.159C134.419 157.224 134.487 157.277 134.564 157.315C134.641 157.353 134.724 157.375 134.81 157.381C134.856 157.388 134.903 157.388 134.949 157.381C136.648 157.067 138.153 156.719 140.284 156.37C142.791 155.952 142.206 156.37 145.159 155.91C146.942 155.639 146.928 153.562 146.162 153.583C142.638 153.722 141.461 152.837 139.929 151.82C139.606 151.623 139.221 151.553 138.849 151.625Z"
          fill="#263238"
        />
      </g>
      <defs>
        <clipPath id="clip0_4179_11968">
          <rect width="300" height="172" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default FilterModalIconTwo
