export const LOCAL_STORAGE_VALUES = {
  token: 'token',
  user_info: 'user-info',
  persist_company_more_information: 'persist-company-more-information',
  invitationCode: 'invitationCode',
  complete_account: 'complete-account',
  chat_recruiter_id: 'chat-recruiter-id',
  idiom: 'idiom',
  skip_linkedin_modal: 'skip-linkedin-modal',
  invitationCodeTimeExpired: 'invitation-code-time-expired',
  currentInvitationCodeExpired: 'current-invitation-code-time-expired',

  invitationCodeCompany: 'invitationCodeCompany',
  currentInvitationCodeExpiredCompany: 'currentInvitationCodeExpiredCompany',
  invitationCodeTimeExpiredCompany: 'invitationCodeTimeExpiredCompany',
}

export const DEFAULT_VALUES = {
  idiom: 'es',
}

export const userInfo =
  JSON.parse(localStorage.getItem(LOCAL_STORAGE_VALUES.user_info)) || {}

const { payload = {} } = userInfo

export const { typeUser = 0 } = payload

export const token =
  JSON.parse(localStorage.getItem(LOCAL_STORAGE_VALUES.token)) || undefined

export const invitationCode =
  JSON.parse(localStorage.getItem(LOCAL_STORAGE_VALUES.invitationCode)) || ''

export const chatRecruiterId =
  JSON.parse(localStorage.getItem(LOCAL_STORAGE_VALUES.chat_recruiter_id)) || ''

export const idiom =
  JSON.parse(localStorage.getItem(LOCAL_STORAGE_VALUES.idiom)) ||
  DEFAULT_VALUES.idiom

export const skipLinkedinModal =
  JSON.parse(localStorage.getItem(LOCAL_STORAGE_VALUES.skip_linkedin_modal)) ||
  false
