import { useTranslation } from 'react-i18next'
import UserTwoIcon from '../../../icons/UserTwoIcon'
import ArrowLeftIcon from '../../../icons/chat/ArrowLeftIcon'
import XIcon from '../../../icons/chat/XIcon'
import { useChatStore } from '../../../store'
import { IS_RECRUITER, TYPE_OF_CHAT_USER } from '../../../utils/constants'
import { useNavigate } from 'react-router-dom'

const Header = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { setOpen, id, setId, setCurrentMessageHistoryData, detailData } =
    useChatStore()
  const { user = {} } = detailData?.chat_users?.[0] || []
  const { names = '', typeProfile = '' } = user || {}

  const handleGoToTalentDetail = () => {
    if (IS_RECRUITER) {
      navigate(`/recruiter-talent-detail/${user?.user_table_id}`)
      return
    }
  }

  return (
    <div className="chat-header bg-bg-CTA-primary">
      <div
        className={`${
          id ? 'justify-content-center gap-5' : 'justify-content-end'
        } d-flex align-items-center h-100`}
      >
        {id && (
          <div
            className="cursor-pointer"
            onClick={() => {
              setId(null)
              setCurrentMessageHistoryData([])
            }}
          >
            <ArrowLeftIcon />
          </div>
        )}
        {id ? (
          <div
            className="d-flex align-items-center gap-2 inline-text cursor-pointer"
            onClick={handleGoToTalentDetail}
          >
            <UserTwoIcon width="32" height="32" />
            <div className="d-flex flex-column">
              <span className="font-small">{names}</span>
              <span className="font-extra-small">
                {TYPE_OF_CHAT_USER[typeProfile]}
              </span>
            </div>
          </div>
        ) : (
          <span
            className={`${
              id ? '' : 'chat-messenger-service-space'
            } fw-bolder text-on-bg-primary`}
          >
            {t('messenger_service')}
          </span>
        )}
        <div
          className="cursor-pointer"
          onClick={() => {
            setId(null)
            setOpen(false)
          }}
        >
          <XIcon />
        </div>
      </div>
    </div>
  )
}

export default Header
