const Dollar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M8.49992 14.6668C12.1818 14.6668 15.1666 11.6821 15.1666 8.00016C15.1666 4.31826 12.1818 1.3335 8.49992 1.3335C4.81802 1.3335 1.83325 4.31826 1.83325 8.00016C1.83325 11.6821 4.81802 14.6668 8.49992 14.6668Z"
        fill="#F0F0F0"
      />
      <path
        d="M8.21021 7.99988H15.1667C15.1667 7.39816 15.0865 6.81525 14.9371 6.26074H8.21021V7.99988Z"
        fill="#D80027"
      />
      <path
        d="M8.21021 4.52186H14.1882C13.7801 3.85592 13.2583 3.2673 12.6499 2.78271H8.21021V4.52186Z"
        fill="#D80027"
      />
      <path
        d="M8.5 14.6666C10.069 14.6666 11.5111 14.1243 12.6499 13.2173H4.3501C5.4889 14.1243 6.93101 14.6666 8.5 14.6666Z"
        fill="#D80027"
      />
      <path
        d="M2.81182 11.4784H14.1882C14.5158 10.9438 14.7699 10.3594 14.937 9.73926H2.06299C2.2301 10.3594 2.48419 10.9438 2.81182 11.4784Z"
        fill="#D80027"
      />
      <path
        d="M4.92138 2.37459H5.5289L4.9638 2.78514L5.17966 3.44943L4.61458 3.03889L4.0495 3.44943L4.23596 2.87555C3.73841 3.29001 3.30231 3.77558 2.94294 4.31662H3.1376L2.77789 4.57795C2.72185 4.67144 2.6681 4.76641 2.61659 4.86279L2.78836 5.39146L2.46789 5.15863C2.38823 5.3274 2.31536 5.49998 2.24987 5.67615L2.43911 6.25865H3.1376L2.5725 6.6692L2.78836 7.3335L2.22328 6.92295L1.88479 7.16889C1.85091 7.44123 1.83325 7.71863 1.83325 8.00016H8.49992C8.49992 4.31829 8.49992 3.88423 8.49992 1.3335C7.18294 1.3335 5.95526 1.71553 4.92138 2.37459ZM5.17966 7.3335L4.61458 6.92295L4.0495 7.3335L4.26536 6.6692L3.70026 6.25865H4.39875L4.61458 5.59436L4.83041 6.25865H5.5289L4.9638 6.6692L5.17966 7.3335ZM4.9638 4.72717L5.17966 5.39146L4.61458 4.98092L4.0495 5.39146L4.26536 4.72717L3.70026 4.31662H4.39875L4.61458 3.65232L4.83041 4.31662H5.5289L4.9638 4.72717ZM7.57096 7.3335L7.00588 6.92295L6.4408 7.3335L6.65666 6.6692L6.09156 6.25865H6.79005L7.00588 5.59436L7.22172 6.25865H7.92021L7.3551 6.6692L7.57096 7.3335ZM7.3551 4.72717L7.57096 5.39146L7.00588 4.98092L6.4408 5.39146L6.65666 4.72717L6.09156 4.31662H6.79005L7.00588 3.65232L7.22172 4.31662H7.92021L7.3551 4.72717ZM7.3551 2.78514L7.57096 3.44943L7.00588 3.03889L6.4408 3.44943L6.65666 2.78514L6.09156 2.37459H6.79005L7.00588 1.71029L7.22172 2.37459H7.92021L7.3551 2.78514Z"
        fill="#0052B4"
      />
    </svg>
  )
}

export default Dollar
