const HomeIcon = ({ className, width = '25', height = '25' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <mask
        id="mask0_59_1839"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="25"
      >
        <rect x="0.1604" y="0.886505" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_59_1839)">
        <path
          d="M6.1604 19.8865H9.1604V13.8865H15.1604V19.8865H18.1604V10.8865L12.1604 6.38651L6.1604 10.8865V19.8865ZM4.1604 21.8865V9.88651L12.1604 3.88651L20.1604 9.88651V21.8865H13.1604V15.8865H11.1604V21.8865H4.1604Z"
          fill="#E8FFFF"
        />
      </g>
    </svg>
  )
}

export default HomeIcon
