import { useQuery } from '@tanstack/react-query'
import Sidebar from '../../../components/Sidebar/Sidebar'
import {
  LOCAL_STORAGE_VALUES,
  skipLinkedinModal,
  userInfo,
} from '../../../localStorage'
import Profile from './Profile'
import { MY_RESUMEN_TAGS } from '../../../services/Tags/myResumen'
import { getProfileUser } from '../../../services/MyProfile'
import { useEffect, useState } from 'react'
import ChatMessageIcon from '../../../icons/ChatMessageIcon'
import { useChatStore, useLostConectionStore } from '../../../store'
import Chat from '../../Chat/Chat'
import {
  IS_COMPANY,
  IS_RECRUITER,
  IS_TALENT,
  OUT_OF_THE_APP,
  VIEWPORT_LG,
} from '../../../utils/constants'
import CustomModal from '../../../components/Modals/CustomModal'
import Swal from 'sweetalert2'
import LinkedinInformationIcon from '../../../icons/dashboard/LinkedinInformationIcon'
import { useTranslation } from 'react-i18next'
import { reloadPage } from '../../../utils'
import { useNavigate } from 'react-router-dom'
import { TAGS } from '../../../services/Tags'
import { listOfChats } from '../../../services/Chat'
import ProfileSkeleton from '../../../skeleton/Global/ProfileSkeleton'
import useResize from '../../../hooks/useResize'

// import Support from '../../Support/Support'

const DashboardWrapper = ({
  title = '',
  children,
  activeWrapper = true,
  dashboardWrapperClassName = '',
  hideDashboardWrapper = false,
  showTitle = false,
  isPositionRelative = true,
}) => {
  const { payload = {} } = userInfo
  const { typeUser = 0 } = payload
  const { t } = useTranslation()
  const navigate = useNavigate()
  const viewportSize = useResize()
  const { setIsLostConection } = useLostConectionStore()

  const { open, setOpen } = useChatStore()

  const [openDrawer, setOpenDrawer] = useState(false)

  const { data: userData = {}, isLoading: isLoadingUserData } = useQuery(
    [MY_RESUMEN_TAGS.user_resumen_data],
    getProfileUser,
    {
      enabled: typeUser !== 0,
    }
  )

  const { data: listOfChatsData } = useQuery(
    [TAGS.list_of_chats],
    listOfChats,
    {
      enabled: !OUT_OF_THE_APP,
    }
  )

  const unseenMessages = listOfChatsData?.some(
    (props) => props?.unseen_messages !== '0'
  )

  useEffect(() => {
    if (
      !userData?.completedProfile &&
      userData?.completedProfile !== undefined &&
      !skipLinkedinModal
    ) {
      if (!userData?.userInfo?.render_modal_profile_example) {
        CustomModal({
          Image: () => <LinkedinInformationIcon />,
          title: t('now_you_can_complete_your_profile_with_linkedIn'),
          description: t(
            'boost_your_profile_in_one_click_by_uploading_the_pdf_of_your_profile_on_linkedI'
          ),
          textPrimaryButton: t('complete_profile_with_linkedIn'),
          textSecondaryButton: t('skip'),
          handlePrimaryButton: () => {
            Swal.close()
            localStorage.setItem(LOCAL_STORAGE_VALUES.skip_linkedin_modal, true)
            navigate('/talent-information')
          },
          handleSecondaryButton: () => {
            Swal.close()
            localStorage.setItem(LOCAL_STORAGE_VALUES.skip_linkedin_modal, true)
            reloadPage()
          },
          viewportSize: viewportSize[0],
          classNameSecondaryButton: `${
            viewportSize[0] <= VIEWPORT_LG ? 'w-100' : ''
          }`,
          classNamePrimaryButton: `${
            viewportSize[0] <= VIEWPORT_LG ? 'w-100' : ''
          }`,
        })
      }
    }
  }, [userData?.completedProfile])

  useEffect(() => {
    setInterval(function () {
      if (!navigator.onLine) {
        navigate('/internet-connection-lost')
        setIsLostConection(false)
      }
    }, 2500)
  }, [navigator.onLine])

  return activeWrapper ? (
    <section className="d-flex dashboard-container bg-bg-primary">
      {isLoadingUserData ? (
        <ProfileSkeleton />
      ) : (
        <Profile
          typeUser={typeUser}
          username={userData?.userInfo?.user?.username}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      <div className="d-none d-lg-flex">
        <Sidebar userData={userData} />
      </div>
      {openDrawer && (
        <div className="drawer w-100" onClick={() => setOpenDrawer(false)}>
          <Sidebar isAnimation userData={userData} />
        </div>
      )}
      <div
        className={`${dashboardWrapperClassName} ${
          hideDashboardWrapper ? '' : 'dashboard-wrapper'
        } ${isPositionRelative ? 'position-relative' : ''}`}
      >
        {showTitle && (
          <h1 className="pt-0 mt-0 pt-lg-5 mt-lg-5 fw-bolder text-on-bg-primary">
            {title}
          </h1>
        )}
        {children}
        {(IS_COMPANY || IS_TALENT || IS_RECRUITER) &&
          (open ? (
            <Chat />
          ) : (
            <div
              className="position-fixed chat-message-icon cursor-pointer "
              onClick={() => setOpen(true)}
            >
              <div className="position-relative">
                {unseenMessages && (
                  <span className="chat-badge position-absolute top-0 p-2 bg-danger border border-light rounded-circle"></span>
                )}
                <ChatMessageIcon />
              </div>
            </div>
          ))}
      </div>

      {/* {IS_TALENT && <Support username={userData?.userInfo?.user?.username}/>} */}
    </section>
  ) : (
    <div>{children}</div>
  )
}

export default DashboardWrapper
