const EmptyStateOne = () => {
  return (
    <svg
      width="283"
      height="137"
      viewBox="0 0 283 137"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M200.221 117.188H193.896V117.37H200.221V117.188Z"
        fill="#EBEBEB"
      />
      <path
        d="M141.11 136.366C219.043 136.366 282.22 132.677 282.22 128.127C282.22 123.577 219.043 119.889 141.11 119.889C63.1771 119.889 0 123.577 0 128.127C0 132.677 63.1771 136.366 141.11 136.366Z"
        fill="#CFF2DF"
      />
      <path
        d="M182.652 26.4341C192.804 27.8169 198.241 41.5065 201.647 60.5526C205.053 79.5987 202.957 94.9695 202.957 105.122C202.957 115.275 195.097 122.814 195.097 122.814C157.434 132.428 101.941 129.183 89.7503 124.139C77.5599 119.095 71.6212 106.73 71.6212 106.73C71.6212 106.73 80.1072 84.6349 89.1317 79.5987C98.1562 74.5624 132.42 69.1477 132.42 69.1477C135.331 57.8452 149.923 43.2313 169.224 33.8429C170.289 31.2673 172.199 29.1306 174.639 27.7842C177.079 26.4377 179.905 25.9616 182.652 26.4341Z"
        fill="#00CF83"
      />
      <path
        opacity="0.1"
        d="M182.652 26.4341C192.804 27.8169 198.241 41.5065 201.647 60.5526C205.053 79.5987 202.957 94.9695 202.957 105.122C202.957 115.275 195.097 122.814 195.097 122.814C157.434 132.428 101.941 129.183 89.7503 124.139C77.5599 119.095 71.6212 106.73 71.6212 106.73C71.6212 106.73 80.1072 84.6349 89.1317 79.5987C98.1562 74.5624 132.42 69.1477 132.42 69.1477C135.331 57.8452 149.923 43.2313 169.224 33.8429C170.289 31.2673 172.199 29.1306 174.639 27.7842C177.079 26.4377 179.905 25.9616 182.652 26.4341Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M90.2452 96.039L138.235 88.5647C143.49 86.6651 148.941 84.7511 154.246 82.8516L170.818 41.4771C171.073 43.5221 175.039 43.5804 176.64 43.0782C174.035 51.7752 167.63 61.7022 167.39 69.2784C168.234 67.2624 171.852 64.3149 173.999 64.0966C168.904 67.9393 165.469 81.7381 161.626 93.0696C162.681 92.2472 164.348 91.9925 166.59 92.2618C162.994 92.1963 157.012 96.1045 151.852 98.9938C145.651 99.3431 140.353 98.7318 137.646 100.559L135.855 98.9938C127.51 98.621 119.148 99.1456 110.914 100.559C112.524 99.4862 114.199 98.5139 115.929 97.6474C107.974 97.2398 101.016 97.6474 95.296 98.9647C80.3983 108.724 78.8117 111.068 76.41 113.862L72.6037 108.768L90.2452 96.039Z"
        fill="black"
      />
      <path
        d="M94.9394 65.363C97.6686 64.1549 109.786 65.2029 117.624 66.8186C118.687 67.0296 119.728 67.2407 120.725 67.4517L121.416 67.6046C124.393 68.2377 126.984 68.8418 128.694 69.3076L132.158 70.2392L133.694 67.5973L136.525 62.7139L160.542 64.5552C160.542 64.5552 167.092 97.7348 146.983 97.7348C146.401 97.7348 145.811 97.7348 145.2 97.6621C140.724 97.3273 135.637 95.7771 130.477 93.6884C126.11 91.9271 121.664 89.7729 117.493 87.6405L116.7 87.2402L114.379 86.0466C106.111 81.7527 99.3134 78.041 96.7807 78.4922C95.9729 84.4164 81.6064 95.5733 70.5805 105.42L65.5297 103.353C65.5297 103.353 92.2102 66.6002 94.9394 65.363Z"
        fill="#E4897B"
      />
      <path
        d="M120.958 66.2657L116.293 88.958C116.293 88.958 132.304 101.774 146.132 96.7744C159.96 91.7745 155.593 68.6892 155.593 68.6892L137.398 63.209L132.42 69.0313L120.958 66.2657Z"
        fill="#00CF83"
      />
      <path
        opacity="0.6"
        d="M116.293 88.9579C116.562 89.1027 116.82 89.2682 117.064 89.4528C120.157 91.5514 123.425 93.38 126.831 94.9184C132.195 97.0654 138.905 98.9213 145.222 96.9853C145.528 96.8907 145.826 96.8398 146.124 96.7306C159.952 91.738 155.586 68.6673 155.586 68.6673L137.391 63.1943L133.723 67.5465L132.42 69.0748L121.627 66.4621L120.95 66.302L120.725 67.4082L118.687 77.3352L116.729 86.8546L116.293 88.9579Z"
        fill="white"
      />
      <path
        d="M121.751 65.829L116.897 90.3917L113.985 88.1283L117.865 65.356L121.751 65.829Z"
        fill="#00CF83"
      />
      <path
        opacity="0.4"
        d="M113.985 88.1283L116.897 90.3917L117.064 89.5402L117.523 87.2259L119.495 77.2334L121.416 67.532L121.627 66.4331L121.751 65.829L117.865 65.356L117.624 66.7533L115.805 77.4445L114.408 85.6539L113.985 88.1283Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M96.5697 78.332C96.6424 78.4557 96.7225 78.5066 96.8098 78.4921C99.3425 78.0336 106.14 81.7526 114.408 86.0465L113.986 88.5646L116.897 90.8644L117.064 89.911C120.154 92.0419 123.421 93.9022 126.831 95.4713L130.47 93.6009L135.783 90.8207L133.599 70.9668L130.448 75.3335C130.448 75.3335 123.097 77.1166 119.458 77.4659L118.651 77.5459C117.654 77.626 116.678 77.6842 115.739 77.6915C115.099 77.6915 114.495 77.6915 113.92 77.6406C107.727 77.2039 100.958 77.0074 96.5697 78.332Z"
        fill="black"
      />
      <path
        d="M95.7109 83.2227C100.347 79.1763 136.576 77.6406 136.576 77.6406L147.587 96.9269L101.679 92.2327C99.8228 97.924 77.0287 108.411 65.9736 114.43L62.08 112.421C62.08 112.421 92.3485 86.1703 95.7109 83.2227Z"
        fill="#E4897B"
      />
      <path
        d="M122.995 78.0045L127.479 98.0113L138.614 97.5892C138.614 97.5892 166.269 110.726 161.175 67.0805L139.123 61.0908L131.969 75.7993L122.995 78.0045Z"
        fill="#00CF83"
      />
      <path
        opacity="0.6"
        d="M122.995 78.0045L127.479 98.0113L138.614 97.5892C138.614 97.5892 166.269 110.726 161.175 67.0805L139.123 61.0908L131.969 75.7993L122.995 78.0045Z"
        fill="white"
      />
      <path
        d="M124.269 77.0078L129.4 98.4338L125.645 98.2154L121.241 77.903L124.269 77.0078Z"
        fill="#00CF83"
      />
      <path
        opacity="0.4"
        d="M124.269 77.0078L129.4 98.4338L125.645 98.2154L121.241 77.903L124.269 77.0078Z"
        fill="white"
      />
      <path
        d="M69.3287 113.346C69.5033 112.829 63.1134 110.893 63.1134 110.893C63.1134 110.893 57.8079 113.397 53.7759 112.844C49.744 112.29 50.9594 115.347 55.1296 116.905L65.4205 120.769C68.3826 121.875 67.4801 118.659 69.3287 113.346Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M92.9161 84.0815L97.7777 82.1528C97.4357 87.5748 82.3778 97.6692 71.512 105.697L72.2398 106.65C72.2398 106.65 68.055 113.571 66.7232 113.855C65.3913 114.139 63.3827 111.097 63.3827 111.097L92.9161 84.0815Z"
        fill="black"
      />
      <path
        d="M96.8098 78.4634L88.5131 72.6411L65.5443 103.332L69.3287 106.126C81.1843 96.9636 97.5595 86.0178 96.8098 78.4634Z"
        fill="#E4897B"
      />
      <path
        d="M56.4396 104.744L60.3842 108.048C62.029 109.416 63.4918 110.646 64.8892 111.818C67.32 113.848 67.5238 110.508 71.0389 106.105C71.1263 106.003 70.8279 105.661 70.3476 105.209C69.3287 104.249 67.4364 102.808 66.5121 102.124L65.9954 101.738C65.0609 101.81 64.1232 101.835 63.1862 101.811C60.8917 101.828 58.6188 101.367 56.5124 100.457C52.8516 98.6229 53.0481 101.883 56.4396 104.744Z"
        fill="#263238"
      />
      <path
        d="M102.195 63.5657C103.069 62.7069 107.334 60.4144 108.061 59.7521C108.221 59.6066 128.439 53.2021 131.227 53.2021L133.141 41.6958L139.152 42.8311C139.152 42.8311 136.612 57.7216 134.924 59.1117C133.235 60.5017 111.191 62.7506 111.191 62.7506C111.191 62.7506 109.175 65.1304 108.061 65.4506C106.948 65.7709 101.329 64.4172 102.195 63.5657Z"
        fill="#E4897B"
      />
      <path
        opacity="0.2"
        d="M132.762 43.9952L138.65 45.6036L138.948 43.9952L132.959 42.7798L132.762 43.9952Z"
        fill="black"
      />
      <path
        d="M132.224 43.1074L139.152 44.8978C139.28 39.4695 138.844 34.0426 137.85 28.7046C134.727 29.5561 133.388 36.0625 132.224 43.1074Z"
        fill="#00CF83"
      />
      <path
        opacity="0.2"
        d="M132.224 43.1074L139.152 44.8978C139.28 39.4695 138.844 34.0426 137.85 28.7046C134.727 29.5561 133.388 36.0625 132.224 43.1074Z"
        fill="black"
      />
      <path
        d="M148.388 0.648359L140.979 3.55949L138.796 15.9318C141.707 32.205 144.574 48.0344 147.718 50.4506C150.236 53.3617 155.877 60.3412 167.929 62.1679C165.746 57.6484 169.486 47.0082 169.486 39.17C169.486 31.3317 160.753 28.0276 161.539 21.7031C164.734 -3.5655 148.388 0.648359 148.388 0.648359Z"
        fill="#263238"
      />
      <path
        d="M141.001 3.57428C140.009 4.27365 139.208 5.20997 138.67 6.29803C138.132 7.3861 137.875 8.59135 137.922 9.8041C138.075 13.9961 138.235 18.0208 138.359 20.5971C138.949 32.8384 155.185 26.0555 157.805 23.8794C160.425 21.7034 154.945 4.12739 151.918 3.09394C148.89 2.06048 141.001 3.57428 141.001 3.57428Z"
        fill="#263238"
      />
      <path
        d="M136.838 60.196L133.519 67.714C145.724 68.2016 161.743 72.8885 161.743 72.8885C161.743 72.8885 161.655 68.5946 161.444 65.8509C160.855 58.3183 160.076 45.4729 163.468 35.2475C163.686 34.578 163.919 33.8793 164.195 33.1661C164.275 32.9312 164.305 32.6826 164.286 32.4355C164.266 32.1884 164.196 31.9479 164.08 31.7286C163.964 31.5094 163.805 31.316 163.612 31.1602C163.419 31.0045 163.197 30.8896 162.958 30.8226C160.702 30.1754 158.41 29.6579 156.095 29.2725C152.969 28.7177 149.807 28.3845 146.634 28.2754C142.551 28.2754 137.857 28.7048 137.857 28.7048C137.857 28.7048 137.799 28.974 136.765 33.1952C134.473 43.3405 134.793 45.4001 136.838 60.196Z"
        fill="#00CF83"
      />
      <path
        d="M154.996 29.2359C154.996 29.2359 153.439 31.4193 148.264 32.1107C148.264 32.1107 145.695 30.4222 146.808 28.3772C146.808 28.3772 147.929 28.0278 148.169 26.2448C148.284 24.9873 148.12 23.72 147.689 22.5331C147.631 22.3438 147.565 22.14 147.493 21.9363L154.043 17.8389C154.043 17.8389 151.714 26.1356 154.996 29.2359Z"
        fill="#E4897B"
      />
      <path
        opacity="0.2"
        d="M152.543 20.2407C152.543 20.2407 152.194 24.9131 148.177 26.2668C148.291 25.0093 148.127 23.742 147.696 22.5551C150.054 21.2742 152.543 20.2407 152.543 20.2407Z"
        fill="black"
      />
      <path
        d="M145.957 4.4259C140.608 5.3429 139.407 8.50148 139.924 14.0763C140.608 21.0485 142.973 25.6772 149.385 23.0644C158.147 19.5129 154.836 2.91211 145.957 4.4259Z"
        fill="#E4897B"
      />
      <path
        d="M143.33 13.1885C143.038 14.0602 142.642 14.8934 142.151 15.6702C142.345 15.8644 142.589 16.0011 142.856 16.0653C143.124 16.1295 143.403 16.1187 143.665 16.0341L143.33 13.1885Z"
        fill="#DE5753"
      />
      <path
        d="M146.532 12.191C146.59 12.635 146.881 12.9552 147.18 12.9188C147.478 12.8824 147.66 12.4821 147.595 12.0455C147.529 11.6088 147.238 11.2813 146.947 11.3177C146.656 11.3541 146.467 11.7471 146.532 12.191Z"
        fill="#263238"
      />
      <path
        d="M147.07 11.3179L146.059 11.085C146.059 11.085 146.648 11.82 147.07 11.3179Z"
        fill="#263238"
      />
      <path
        d="M141.03 12.9046C141.095 13.3412 141.387 13.6687 141.685 13.6323C141.983 13.596 142.165 13.1957 142.1 12.7517C142.034 12.3078 141.743 12.0239 141.445 12.0239C141.146 12.0239 140.972 12.4606 141.03 12.9046Z"
        fill="#263238"
      />
      <path
        d="M141.576 12.0312L140.557 11.7983C140.557 11.7983 141.154 12.5334 141.576 12.0312Z"
        fill="#263238"
      />
      <path
        d="M148.388 9.13454C148.421 9.13439 148.454 9.12717 148.484 9.11337C148.514 9.09956 148.541 9.07949 148.562 9.05449C148.604 9.01003 148.627 8.95151 148.627 8.89073C148.627 8.82996 148.604 8.77144 148.562 8.72698C148.183 8.44665 147.743 8.25916 147.278 8.17954C146.813 8.09992 146.335 8.13038 145.884 8.26848C145.825 8.28691 145.776 8.3279 145.748 8.38246C145.719 8.43703 145.713 8.50072 145.731 8.55959C145.751 8.61731 145.793 8.66525 145.847 8.69364C145.901 8.72203 145.964 8.72875 146.023 8.71243C146.398 8.59842 146.795 8.57194 147.182 8.63501C147.569 8.69809 147.937 8.84906 148.257 9.07632C148.294 9.10823 148.339 9.12849 148.388 9.13454Z"
        fill="#263238"
      />
      <path
        d="M139.967 9.96443C139.997 9.96459 140.026 9.95887 140.054 9.94763C140.081 9.93639 140.106 9.91984 140.127 9.89892C140.361 9.64487 140.65 9.44734 140.972 9.32126C141.293 9.19518 141.639 9.14384 141.983 9.17114C142.044 9.18272 142.107 9.16968 142.158 9.13487C142.209 9.10007 142.245 9.04636 142.256 8.98556C142.268 8.92476 142.255 8.86185 142.22 8.81067C142.185 8.75949 142.131 8.72423 142.071 8.71264C141.653 8.6752 141.233 8.73379 140.842 8.88391C140.451 9.03403 140.1 9.2717 139.815 9.57871C139.771 9.62231 139.747 9.68114 139.747 9.74245C139.747 9.80377 139.771 9.86261 139.815 9.90621C139.858 9.94099 139.912 9.96134 139.967 9.96443Z"
        fill="#263238"
      />
      <path
        d="M153.337 12.4458C153.337 12.4458 150.091 8.47934 149.887 5.02237C149.887 5.02237 138.555 2.49696 139.865 13.3482C139.865 13.3482 137.202 3.97436 146.357 2.19129C155.513 0.408222 155.353 14.9275 153.337 12.4458Z"
        fill="#263238"
      />
      <path
        d="M152.66 13.6905C152.632 13.378 152.667 13.063 152.762 12.764C152.857 12.465 153.01 12.1878 153.214 11.9487C153.417 11.7096 153.665 11.5134 153.945 11.3713C154.225 11.2293 154.53 11.1444 154.843 11.1215C156.532 11.0196 157.907 14.2801 153.926 15.8084C153.322 16.0558 152.893 15.5318 152.66 13.6905Z"
        fill="#E4897B"
      />
      <path
        d="M147.769 16.7036C147.769 16.7036 147.573 18.1592 144.603 18.2974"
        stroke="#263238"
        strokeWidth="0.363892"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M151.015 3.81443C149.638 5.28364 147.999 6.48316 146.183 7.35146C143.786 8.17965 141.335 8.8387 138.847 9.32376C139.844 14.2217 140.171 19.5491 135.63 21.3031C136.962 17.2129 135.411 12.9627 136.358 9.13454C138.395 0.808696 145.426 -1.14177 148.781 0.568524C149.654 1.01975 151.015 3.81443 151.015 3.81443Z"
        fill="#263238"
      />
      <path
        d="M153.73 5.00049V11.2885C154.018 12.2403 154.11 13.2407 154 14.229C153.889 15.2173 153.58 16.173 153.089 17.038C153.264 15.8081 153.002 13.1007 152.543 9.7602L150.44 5.61182L153.73 5.00049Z"
        fill="#263238"
      />
      <path
        d="M119.007 63.9074C119.881 63.0486 123.869 60.8434 124.873 60.0938C125.419 59.6862 155.564 56.4549 156.488 57.532L158.169 45.6182L164.719 46.8336C164.719 46.8336 163.846 63.1214 161.452 64.7662C159.057 66.4109 127.973 63.1214 127.973 63.1214C127.973 63.1214 125.957 65.5012 124.844 65.8214C123.73 66.1417 118.141 64.7443 119.007 63.9074Z"
        fill="#E4897B"
      />
      <path
        opacity="0.2"
        d="M164.559 49.4249L157.834 47.9766L158.169 45.604L164.734 46.8194L164.559 49.4249Z"
        fill="black"
      />
      <path
        d="M166.75 48.7844L157.427 47.0742C158.497 38.7192 160.054 32.2347 162.936 30.8228C164.996 31.8344 165.935 35.4223 166.531 39.8036C166.772 42.7908 166.845 45.7891 166.75 48.7844Z"
        fill="#00CF83"
      />
      <path
        opacity="0.2"
        d="M166.75 48.7844L157.427 47.0742C158.497 38.7192 160.054 32.2347 162.936 30.8228C164.996 31.8344 165.935 35.4223 166.531 39.8036C166.772 42.7908 166.845 45.7891 166.75 48.7844Z"
        fill="black"
      />
      <path
        d="M92.7924 65.2319L92.7124 67.1606C92.7124 67.6554 93.2728 67.8883 93.7604 67.8883L132.267 69.4458C132.762 69.4458 133.366 69.2857 133.388 68.7908L133.468 66.8622L92.7924 65.2319Z"
        fill="#263238"
      />
      <path
        opacity="0.4"
        d="M92.7924 65.2319L92.7124 67.1606C92.7124 67.6554 93.2728 67.8883 93.7604 67.8883L132.267 69.4458C132.762 69.4458 133.366 69.2857 133.388 68.7908L133.468 66.8622L92.7924 65.2319Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M92.7924 65.2319L92.7124 67.1606C92.7124 67.6554 93.2728 67.8883 93.7604 67.8883L119.16 68.9145C119.655 68.9145 120.252 68.7544 120.266 68.2595L120.346 66.3309L92.7924 65.2319Z"
        fill="black"
      />
      <path
        d="M87.1594 44.9853L90.8929 65.9455C90.9646 66.2843 91.1461 66.5899 91.4093 66.8149C91.6725 67.04 92.0027 67.1718 92.3485 67.19L117.392 68.2016C117.548 68.2032 117.703 68.1688 117.844 68.1011C117.984 68.0333 118.108 67.934 118.204 67.8108C118.301 67.6877 118.368 67.544 118.399 67.3909C118.431 67.2378 118.428 67.0794 118.389 66.928L114.641 46.1061C114.555 45.7885 114.365 45.5092 114.101 45.3136C113.836 45.1181 113.514 45.0177 113.185 45.029L88.1346 44.0174C87.4723 43.9955 87.0357 44.2575 87.1594 44.9853Z"
        fill="#263238"
      />
      <path
        opacity="0.4"
        d="M87.1594 44.9853L90.8929 65.9455C90.9646 66.2843 91.1461 66.5899 91.4093 66.815C91.6725 67.04 92.0027 67.1718 92.3485 67.19L116.54 68.1652C116.697 68.1692 116.852 68.1366 116.994 68.0702C117.136 68.0038 117.261 67.9053 117.358 67.7825C117.455 67.6597 117.523 67.516 117.555 67.3627C117.588 67.2093 117.584 67.0505 117.544 66.8989L113.789 46.077C113.711 45.7553 113.523 45.4712 113.257 45.2743C112.991 45.0775 112.664 44.9803 112.333 44.9999L88.1346 44.0174C87.4723 43.9955 87.0357 44.2575 87.1594 44.9853Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M99.0805 56.0327C99.0077 57.8158 100.856 59.3368 102.254 59.3951C103.651 59.4533 104.546 58.0341 104.219 56.2365C103.891 54.4389 102.552 52.9978 101.184 52.8887C101.511 54.6863 100.602 56.0909 99.2042 56.04L99.0805 56.0327Z"
        fill="black"
      />
    </svg>
  )
}

export default EmptyStateOne
