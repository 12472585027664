const HeartIcon = ({ className, fill = '#E8FFFF' }) => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <mask
        id="mask0_69_865"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="33"
        height="32"
      >
        <rect x="0.1604" width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_69_865)">
        <path
          d="M16.1605 28L14.2271 26.2667C11.9827 24.2444 10.1271 22.5 8.66048 21.0333C7.19381 19.5667 6.02715 18.25 5.16048 17.0833C4.29382 15.9167 3.68826 14.8444 3.34382 13.8667C2.99937 12.8889 2.82715 11.8889 2.82715 10.8667C2.82715 8.77777 3.52715 7.03333 4.92715 5.63333C6.32715 4.23333 8.07159 3.53333 10.1605 3.53333C11.316 3.53333 12.416 3.77777 13.4605 4.26666C14.5049 4.75555 15.4049 5.44444 16.1605 6.33333C16.916 5.44444 17.816 4.75555 18.8605 4.26666C19.9049 3.77777 21.0049 3.53333 22.1605 3.53333C24.2494 3.53333 25.9938 4.23333 27.3938 5.63333C28.7938 7.03333 29.4938 8.77777 29.4938 10.8667C29.4938 11.8889 29.3216 12.8889 28.9771 13.8667C28.6327 14.8444 28.0271 15.9167 27.1605 17.0833C26.2938 18.25 25.1271 19.5667 23.6605 21.0333C22.1938 22.5 20.3383 24.2444 18.0938 26.2667L16.1605 28ZM16.1605 24.4C18.2938 22.4889 20.0494 20.85 21.4271 19.4833C22.8049 18.1167 23.8938 16.9278 24.6938 15.9167C25.4938 14.9055 26.0494 14.0055 26.3605 13.2167C26.6716 12.4278 26.8271 11.6444 26.8271 10.8667C26.8271 9.53333 26.3827 8.42221 25.4938 7.53333C24.6049 6.64444 23.4938 6.19999 22.1605 6.19999C21.116 6.19999 20.1494 6.49444 19.2605 7.08333C18.3716 7.67221 17.7605 8.42221 17.4271 9.33333H14.8938C14.5605 8.42221 13.9494 7.67221 13.0605 7.08333C12.1716 6.49444 11.2049 6.19999 10.1605 6.19999C8.82715 6.19999 7.71604 6.64444 6.82715 7.53333C5.93826 8.42221 5.49381 9.53333 5.49381 10.8667C5.49381 11.6444 5.64937 12.4278 5.96048 13.2167C6.27159 14.0055 6.82715 14.9055 7.62715 15.9167C8.42715 16.9278 9.51604 18.1167 10.8938 19.4833C12.2716 20.85 14.0271 22.4889 16.1605 24.4Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default HeartIcon

export const HeartThreeIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_889_14002"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_889_14002)">
        <path
          d="M12 21L10.55 19.7C8.86667 18.1834 7.475 16.875 6.375 15.775C5.275 14.675 4.4 13.6875 3.75 12.8125C3.1 11.9375 2.64583 11.1334 2.3875 10.4C2.12917 9.66669 2 8.91669 2 8.15002C2 6.58336 2.525 5.27502 3.575 4.22502C4.625 3.17502 5.93333 2.65002 7.5 2.65002C8.36667 2.65002 9.19167 2.83336 9.975 3.20002C10.7583 3.56669 11.4333 4.08336 12 4.75002C12.5667 4.08336 13.2417 3.56669 14.025 3.20002C14.8083 2.83336 15.6333 2.65002 16.5 2.65002C18.0667 2.65002 19.375 3.17502 20.425 4.22502C21.475 5.27502 22 6.58336 22 8.15002C22 8.91669 21.8708 9.66669 21.6125 10.4C21.3542 11.1334 20.9 11.9375 20.25 12.8125C19.6 13.6875 18.725 14.675 17.625 15.775C16.525 16.875 15.1333 18.1834 13.45 19.7L12 21ZM12 18.3C13.6 16.8667 14.9167 15.6375 15.95 14.6125C16.9833 13.5875 17.8 12.6959 18.4 11.9375C19 11.1792 19.4167 10.5042 19.65 9.91252C19.8833 9.32086 20 8.73336 20 8.15002C20 7.15002 19.6667 6.31669 19 5.65002C18.3333 4.98336 17.5 4.65002 16.5 4.65002C15.7167 4.65002 14.9917 4.87086 14.325 5.31252C13.6583 5.75419 13.2 6.31669 12.95 7.00002H11.05C10.8 6.31669 10.3417 5.75419 9.675 5.31252C9.00833 4.87086 8.28333 4.65002 7.5 4.65002C6.5 4.65002 5.66667 4.98336 5 5.65002C4.33333 6.31669 4 7.15002 4 8.15002C4 8.73336 4.11667 9.32086 4.35 9.91252C4.58333 10.5042 5 11.1792 5.6 11.9375C6.2 12.6959 7.01667 13.5875 8.05 14.6125C9.08333 15.6375 10.4 16.8667 12 18.3Z"
          fill="#004949"
        />
      </g>
    </svg>
  )
}
