import { Text, View } from '@react-pdf/renderer'
import React from 'react'
import PdfCalendarIcon from '../icons/PdfCalendarIcon'
import PdfHutritHeaderIcon from '../icons/PdfHutritHeaderIcon'

const PdfHeader = ({
  styles = {},
  category_job,
  username,
  range_year,
  pdf_palet_of_colors = {},
}) => {
  const { black, on_bg_primary } = pdf_palet_of_colors
  return (
    <View id="pdf-header" style={styles.header}>
      <View id="pdf-header-container" style={styles.header_container}>
        <View>
          <PdfHutritHeaderIcon />
        </View>
        <Text style={styles.title}>{category_job?.name}</Text>
        <View
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'start',
            flexDirection: 'row',
            marginBottom: '8px',
            gap: '8px',
          }}
        >
          <Text
            style={{
              color: black,
              fontSize: '12px',
              marginBottom: '2px',
              marginTop: '4px',
            }}
          >
            {username}
          </Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            <PdfCalendarIcon />
            <Text style={{ color: on_bg_primary, fontSize: '12px' }}>
              {range_year?.years} de experiencia
            </Text>
          </View>
        </View>
      </View>
    </View>
  )
}

export default PdfHeader

{
  /* <View
style={{
  display: 'flex',
  flexDirection: 'column',
  gap: '2',
}}
>
{languaje_lang_levels?.map(({ id, lang_level, languaje }) => {
  return (
    <View
      key={id}
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '4px',
      }}
    >
      <Text style={{ color: on_bg_primary, fontSize: '12px' }}>
        {languaje?.name}
      </Text>{' '}
      -{' '}
      <Text
        style={{
          color: on_bg_primary,
          fontWeight: 'bold',
          fontSize: '12px',
        }}
      >
        {lang_level?.name}
      </Text>
    </View>
  )
})}
</View> */
}
