import { Link } from 'react-router-dom'
import { LOCAL_STORAGE_VALUES, idiom } from '../../../localStorage'
import { reloadPage } from '../../../utils'
import { LIST_OF_IDIOMS, TRANSLATE_LABELS } from '../../../utils/constants'
import { useTranslation } from 'react-i18next'
import ArrowDownSmallIcon from '../../../icons/ArrowDownSmallIcon'
import { useState } from 'react'
import ArrowUpIcon from '../../../icons/ArrowUpIcon'
import SpainIcon from '../../../icons/idioms/SpainIcon'
import EnglishIcon from '../../../icons/idioms/EnglishIcon'

const Translate = ({ isQuickOffer }) => {
  const { t } = useTranslation()
  const renderLabel = {
    es: TRANSLATE_LABELS.es,
    en: TRANSLATE_LABELS.en,
  }

  const [open, setOpen] = useState(false)

  const handleChangeIdiom = ({ idiom = 'es' }) => {
    localStorage.setItem(LOCAL_STORAGE_VALUES.idiom, JSON.stringify(idiom))
    reloadPage()
  }

  return (
    <div id="translate-landing">
      <div className="dropdown">
        <a
          className="dropdown-toggle text-bg-tertiary text-decoration-none d-flex justify-content-center align-items-center gap-1"
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          onClick={() => setOpen((prevState) => !prevState)}
        >
          <span
            className={`font-small ${isQuickOffer ? 'text-on-bg-primary' : ''}`}
          >
            {renderLabel[idiom]}
          </span>
          {open ? (
            <ArrowUpIcon fill={isQuickOffer ? '#044846' : '#FAFAFA'} />
          ) : (
            <ArrowDownSmallIcon fill={isQuickOffer ? '#044846' : '#FAFAFA'} />
          )}
        </a>
        <ul
          className="dropdown-menu font-extra-small"
          aria-labelledby="dropdownMenuLink"
        >
          <li>
            <Link
              className="dropdown-item"
              to="#"
              onClick={() =>
                handleChangeIdiom({ idiom: LIST_OF_IDIOMS.spanish })
              }
            >
              <div className="d-flex justify-content-start align-items-center gap-1">
                <SpainIcon />
                <span>{t('spanish')}</span>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className="dropdown-item"
              to="#"
              onClick={() =>
                handleChangeIdiom({ idiom: LIST_OF_IDIOMS.english })
              }
            >
              <div className="d-flex justify-content-start align-items-center gap-1">
                <EnglishIcon />
                <span>{t('english')}</span>
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Translate
