import QuickChart from 'quickchart-js'
import { Image, Text, View } from '@react-pdf/renderer'
import { PDF_PALET_OF_COLORS } from '../constants/pdfConstants'

const { white, on_bg_primary } = PDF_PALET_OF_COLORS

const PdfChart = ({ renderSkills = [] }) => {
  const myChart = new QuickChart()

  const transformSkills = renderSkills
    ?.slice(0, 8)
    ?.map(({ skills_categori }) => skills_categori?.skill?.name)

  const transformPercentage = renderSkills
    ?.slice(0, 8)
    ?.map(({ percentage }) => percentage?.value)

  myChart
    .setConfig({
      type: 'radar',
      data: {
        labels: transformSkills,
        datasets: [
          {
            lineTension: 0.5,
            label: '',
            backgroundColor: 'rgba(0, 207, 131, 0.10)',
            borderColor: '#00CF83',
            pointBackgroundColor: '#00CF83',
            data: transformPercentage,
          },
        ],
      },
      options: {
        scale: {
          gridLines: {
            circular: true,
          },
          ticks: {
            display: false,
            beginAtZero: true,
            max: 100,
          },
        },
        legend: {
          display: false,
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
      },
    })
    .setWidth(350)
    .setHeight(200)

  return (
    <View>
      <Text
        style={{
          color: on_bg_primary,
          fontWeight: 'bold',
          fontSize: '14px',
          textAlign: 'left',
          marginBottom: '8px'
        }}
      >
        Habilidades técnicas
      </Text>
      <View
        style={{
          backgroundColor: white,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '4',
          width: '100%',
          borderRadius: '8px',
        }}
      >
        <Image
          src={myChart.getUrl()}
          style={{
            width: '250px',
            height: '150px',
            padding: '8px',
          }}
        ></Image>
      </View>
    </View>
  )
}

export default PdfChart
