import SendMessageIcon from '../../../icons/chat/SendMessageIcon'

const SendMessage = ({ register, onSubmit, msg, waitResponse }) => {
  const handleOnKeyUp = (e) => {
    if (e.code === 'Enter' && msg.trim() !== '') {
      if (waitResponse) {
        return
      }
      onSubmit()
    }
  }
  return (
    <div className="d-flex justify-content-end align-items-end position-relative mb-5 pb-3">
      <textarea
        {...register('msg')}
        cols="40"
        rows="3"
        className="w-100 rounded border text-on-bg-primary"
        onKeyUp={handleOnKeyUp}
        disabled={waitResponse}
      />{' '}
      <button
        type="submit"
        disabled={msg.trim() === '' || waitResponse}
        className="position-absolute pe-3 pb-2 cursor-pointer border-none disable-button-styles"
      >
        <SendMessageIcon />
      </button>
    </div>
  )
}

export default SendMessage
