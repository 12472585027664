import { Link } from 'react-router-dom'
import { PUBLIC_ROUTES } from '../Routes/routes'
import i18next from 'i18next'

const InnerBanner = ({
  before = i18next?.t('start'),
  beforeRoute = PUBLIC_ROUTES.home,
  className = '',
  classNameText = 'text-on-bg-primary',
  text = '',
  isFunction = false,
  onClick = () => {},
}) => {
  return (
    <div className={`${className}`}>
      <ol className="breadcrumb mb-0 p-0">
        <li className="breadcrumb-item">
          <Link
            className="text-on-bg-secondary text-decoration-none"
            to={isFunction ? '' : beforeRoute}
            onClick={() => {
              if (isFunction) {
                onClick()
              }
            }}
          >
            {' '}
            {before}{' '}
          </Link>
        </li>
        <li className="breadcrumb-item active">
          {' '}
          <span className={classNameText}> {text} </span>
        </li>
      </ol>
    </div>
  )
}

export default InnerBanner
