import HutritIcon from '../../../icons/HutritIcon'
import Divider from '../../Divider'

const Header = () => {
  return (
    <div
      id="sibebar-header"
      className="p-0 d-flex flex-column justify-content-center align-items-center gap-4 mt-5"
    >
      <HutritIcon />
      <Divider className='bg-on-bg-brand'/>
    </div>
  )
}

export default Header
