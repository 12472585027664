const LocationIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="lists/pin_drop">
        <mask
          id="mask0_3038_1263"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="17"
          height="17"
        >
          <rect
            id="Bounding box"
            x="0.216797"
            y="0.0527344"
            width="16"
            height="16"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask0_3038_1263)">
          <path
            id="icon"
            d="M8.21672 11.0361C9.31672 10.1472 10.1473 9.29166 10.7084 8.46944C11.2695 7.64722 11.55 6.875 11.55 6.15278C11.55 5.53055 11.4362 5 11.2084 4.56111C10.9806 4.12222 10.7 3.76666 10.3667 3.49444C10.0334 3.22222 9.67227 3.025 9.28338 2.90278C8.89449 2.78055 8.53894 2.71944 8.21672 2.71944C7.89449 2.71944 7.53894 2.78055 7.15005 2.90278C6.76116 3.025 6.40005 3.22222 6.06672 3.49444C5.73338 3.76666 5.45283 4.12222 5.22505 4.56111C4.99727 5 4.88338 5.53055 4.88338 6.15278C4.88338 6.875 5.16394 7.64722 5.72505 8.46944C6.28616 9.29166 7.11672 10.1472 8.21672 11.0361ZM8.21672 12.7194C6.65005 11.5639 5.4806 10.4417 4.70838 9.35278C3.93616 8.26389 3.55005 7.19722 3.55005 6.15278C3.55005 5.36389 3.69172 4.67222 3.97505 4.07778C4.25838 3.48333 4.62227 2.98611 5.06672 2.58611C5.51116 2.18611 6.01116 1.88611 6.56672 1.68611C7.12227 1.48611 7.67227 1.38611 8.21672 1.38611C8.76116 1.38611 9.31116 1.48611 9.86672 1.68611C10.4223 1.88611 10.9223 2.18611 11.3667 2.58611C11.8112 2.98611 12.175 3.48333 12.4584 4.07778C12.7417 4.67222 12.8834 5.36389 12.8834 6.15278C12.8834 7.19722 12.4973 8.26389 11.725 9.35278C10.9528 10.4417 9.78338 11.5639 8.21672 12.7194ZM8.21672 7.38611C8.58338 7.38611 8.89727 7.25555 9.15838 6.99444C9.41949 6.73333 9.55005 6.41944 9.55005 6.05278C9.55005 5.68611 9.41949 5.37222 9.15838 5.11111C8.89727 4.85 8.58338 4.71944 8.21672 4.71944C7.85005 4.71944 7.53616 4.85 7.27505 5.11111C7.01394 5.37222 6.88338 5.68611 6.88338 6.05278C6.88338 6.41944 7.01394 6.73333 7.27505 6.99444C7.53616 7.25555 7.85005 7.38611 8.21672 7.38611ZM3.55005 14.7194V13.3861H12.8834V14.7194H3.55005Z"
            fill="#004949"
          />
        </g>
      </g>
    </svg>
  )
}

export default LocationIcon
