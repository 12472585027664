const DisableTrashIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <mask
      id="a"
      width={16}
      height={16}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill="#D9D9D9" d="M0 0h16v16H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#BFD8CF"
        d="M4.667 14c-.367 0-.68-.13-.942-.392a1.284 1.284 0 0 1-.392-.941V4h-.666V2.667H6V2h4v.667h3.333V4h-.666v8.667c0 .366-.13.68-.392.941a1.284 1.284 0 0 1-.942.392H4.667Zm6.666-10H4.667v8.667h6.666V4ZM6 11.333h1.333v-6H6v6Zm2.667 0H10v-6H8.667v6Z"
      />
    </g>
  </svg>
)
export default DisableTrashIcon
