import Circle from '../Circle'

const CountMaster = ({
  count = 0,
  title = '',
  Icon = () => {},
  className = '',
  backgroundColor = 'bg-on-bg-Tertiary-hover',
  isFullWidth = false,
}) => {
  return (
    <div
      id="count-master"
      className={`${className} ${
        isFullWidth ? 'count-custom w-100' : 'count-master'
      } rounded shadow-sm p-3 d-flex align-items-center gap-2 bg-white`}
    >
      <Circle className={`${backgroundColor} p-4`}>
        <Icon />
      </Circle>
      <div className="d-flex flex-column mt-3">
        <span className="text-on-bg-primary font-small">{title}</span>
        <span className="fs-2 text-on-bg-primary mb-3 fw-bolder">{count}</span>
      </div>
    </div>
  )
}

export default CountMaster
