import { useQuery } from '@tanstack/react-query'
import CampaingIcon from '../../../icons/CampaingIcon'
import MenuBurgerIcon from '../../../icons/MenuBurgerIcon'
import UserTwoIcon from '../../../icons/UserTwoIcon'
import { TAGS } from '../../../services/Tags'
import { getNewNotificationsList } from '../../../services/Notifications'
import Notifications from '../../../components/Notifications/Notifications'
import { useState } from 'react'
import CampaingTwoIcon from '../../../icons/CampaingTwoIcon'
import { useTranslation } from 'react-i18next'
import { TYPE_OF_USER } from '../../../utils/constants'
import { useNavigate } from 'react-router-dom'

const Profile = ({ username = '', setOpenDrawer = () => {}, typeUser }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [openUserOptions, setOpenUserOptions] = useState(false)

  const findCharacter = username?.includes('@')

  const { data: newNotificationsListData } = useQuery(
    [TAGS.new_notification_list],
    getNewNotificationsList
  )

  const handleViewNotifications = () => {
    setOpenUserOptions((prevState) => !prevState)
  }

  const renderText = {
    [TYPE_OF_USER.talent]: findCharacter
      ? username
      : `@${username || 'No disponible'} `,
    [TYPE_OF_USER.recruiter]: findCharacter
      ? username
      : `@${username || 'No disponible'} `,
    [TYPE_OF_USER.company]: findCharacter
      ? username
      : `@${username || 'No disponible'} `,
    [TYPE_OF_USER.hiring_advisor]: findCharacter
      ? username
      : `@${username || 'No disponible'} `,
    [TYPE_OF_USER.admin]: `@${t('administrator')}`,
  }

  const renderRoute = {
    [TYPE_OF_USER.talent]: '/update-personal-information',
    [TYPE_OF_USER.recruiter]: '/personal-data',
    [TYPE_OF_USER.hiring_advisor]: '/personal-data',
    [TYPE_OF_USER.company]: '/company-personal-information',
    [TYPE_OF_USER.admin]: '/dashboard',
  }

  const handleRedirectProfile = () => {
    navigate(renderRoute[typeUser])
  }

  return (
    <div className="d-flex justify-content-between">
      <div
        className="d-flex d-lg-none cursor-pointer menu-burger"
        onClick={() => setOpenDrawer((prevState) => !prevState)}
      >
        <MenuBurgerIcon />
      </div>
      <div className={`${openUserOptions && 'profile-extra-container'}`}>
        <div id="profile" className="profile-container d-flex gap-2">
          <p className="text-on-bg-primary">{renderText[typeUser]}</p>
          <div onClick={handleRedirectProfile}>
            <UserTwoIcon className="cursor-pointer" />
          </div>
          <div className="position-relative">
            <div className=" cursor-pointer" onClick={handleViewNotifications}>
              {newNotificationsListData?.length > 0 ? (
                <CampaingTwoIcon />
              ) : (
                <CampaingIcon className="cursor-pointer" />
              )}
            </div>
            <Notifications
              typeOfUser={typeUser}
              openUserOptions={openUserOptions}
              setOpenUserOptions={setOpenUserOptions}
              newNotificationsListData={newNotificationsListData}
              handleViewNotifications={handleViewNotifications}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
