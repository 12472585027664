const PlusSmallIcon = ({ fill = '#004949' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <mask
        id="mask0_3514_9368"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="17"
        height="16"
      >
        <rect x="0.5" width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3514_9368)">
        <path
          d="M7.83337 12.6666V8.66665H3.83337V7.33331H7.83337V3.33331H9.16671V7.33331H13.1667V8.66665H9.16671V12.6666H7.83337Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default PlusSmallIcon
