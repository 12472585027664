import { useEffect } from 'react'
import { useState } from 'react'

export const PreloadImageComponent = ({ src, alt, width, height }) => {
  const img = new Image()

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    img.src = src
    img.onload = () => setLoaded(true)
  }, [img])

  if (!loaded) {
    return <div>Cargando imagen...</div>
  }

  return <img src={src} alt={alt} width={width} height={height} />
}
