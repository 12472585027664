import { useNavigate } from 'react-router-dom'
import ConfigIcon from '../../../icons/ConfigIcon'
import LogOutIcon from '../../../icons/LogOutIcon'
import { logOut } from '../../../services/user'
import Divider from '../../Divider'
import SidebarButton from './SidebarButton'
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from '../../../Routes/routes'
import { reloadPage } from '../../../utils'
import CustomModal from '../../Modals/CustomModal'
import LogOutTwoIcon from '../../../icons/LogOutTwoIcon'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import { LOCAL_STORAGE_VALUES } from '../../../localStorage'
import { IS_ADMIN, IS_RECRUITER } from '../../../utils/constants'
import WindowQuestionIcon from '../../../icons/WindowQuestionIcon'

const Footer = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleLogOut = () => {
    CustomModal({
      Image: () => <LogOutTwoIcon />,
      title: t('youre_about_to_leave'),
      description: t('are_you_sure_you_want_to_log_out'),
      textPrimaryButton: t('log_out'),
      textSecondaryButton: t('cancel'),
      handlePrimaryButton: async () => {
        await localStorage.setItem(
          LOCAL_STORAGE_VALUES.skip_linkedin_modal,
          false
        )
        await Swal.close()
        await logOut()
        await reloadPage()
        window.location.href = PUBLIC_ROUTES.login
      },
      handleSecondaryButton: () => Swal.close(),
    })
  }

  const handleGoToConfig = () => {
    navigate(PRIVATE_ROUTES.setting)
  }

  return (
    <div
      id="sidebar-footer"
      className=" d-flex flex-column justify-content-start align-items-start ps-3 mb-2"
    >
      {IS_RECRUITER && (
        <SidebarButton
          Icon={() => <WindowQuestionIcon />}
          title={t('frequent_questions')}
          className="text-white"
          onClick={() => navigate(PRIVATE_ROUTES.frecuent_questions)}
        />
      )}
      {!IS_ADMIN && (
        <SidebarButton
          Icon={() => <ConfigIcon />}
          title={t('setting')}
          className="text-white"
          onClick={handleGoToConfig}
        />
      )}
      <Divider className="mt-1 mb-2 bg-on-bg-brand" />
      <SidebarButton
        Icon={() => <LogOutIcon className="log-out" />}
        title={t('log_out')}
        className="log-out"
        onClick={handleLogOut}
      />
    </div>
  )
}

export default Footer
