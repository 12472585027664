const ArrowDownIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1178_4322"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="17"
      >
        <rect y="0.5" width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1178_4322)">
        <path
          d="M8 10.75L4 6.74998L4.93333 5.81665L8 8.88332L11.0667 5.81665L12 6.74998L8 10.75Z"
          fill="#004949"
        />
      </g>
    </svg>
  )
}

export default ArrowDownIcon
