import React from 'react'
import Skeleton from 'react-loading-skeleton'

const CompanyDetailSkeleton = () => {
  return (
    <div className="mt-3">
      <Skeleton
        containerClassName="d-flex align-items-center gap-2"
        height={94}
        count={3}
      />
      <Skeleton className="mt-2" count={1} height={282} />
      <Skeleton containerClassName="d-flex gap-2 mt-3" count={2} height={502} />
      <Skeleton className="mt-2 mb-4" count={1} height={296} />
    </div>
  )
}

export default CompanyDetailSkeleton
