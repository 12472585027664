const XIcon = ({ fillPath = '#FAFAFA', width = '24', height = '25' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1586_26567"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="25"
      >
        <rect y="0.386475" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1586_26567)">
        <path
          d="M6.4 19.3865L5 17.9865L10.6 12.3865L5 6.78647L6.4 5.38647L12 10.9865L17.6 5.38647L19 6.78647L13.4 12.3865L19 17.9865L17.6 19.3865L12 13.7865L6.4 19.3865Z"
          fill={fillPath}
        />
      </g>
    </svg>
  )
}

export default XIcon
