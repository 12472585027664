const TranslateIconTwo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={15}
    fill="none"
    {...props}
  >
    <path
      fill="#004949"
      d="m7.934 14.167 3.033-8h1.4l3.034 8H14l-.7-2.034h-3.233l-.734 2.034h-1.4Zm2.533-3.2h2.4l-1.166-3.3h-.067l-1.167 3.3Zm-7.8 1.2-.933-.934 3.367-3.366c-.422-.467-.792-.95-1.109-1.45-.316-.5-.591-1.028-.825-1.583h1.4c.2.4.414.76.642 1.083.228.322.503.66.825 1.017a9.272 9.272 0 0 0 1.217-1.642c.322-.561.594-1.159.816-1.792h-7.4V2.167h4.667V.833h1.333v1.334h4.667V3.5H9.401c-.234.789-.55 1.556-.95 2.3-.4.745-.895 1.445-1.484 2.1l1.6 1.633-.5 1.367-2.066-2.066-3.334 3.333Z"
    />
  </svg>
)
export default TranslateIconTwo
