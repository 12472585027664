import { View, Text } from '@react-pdf/renderer'
import ReactDOMServer from 'react-dom/server'
import Html from 'react-pdf-html'
import { PDF_PALET_OF_COLORS } from '../constants/pdfConstants'

const PdfIdiomsAndAboutMe = ({
  languaje_lang_levels = [],
  aboutMe = '',
  // totalIdiomsToShow = 0,
  // totalItems,
}) => {
  const { on_bg_primary, on_bg_secondary, on_bg_tertiary } = PDF_PALET_OF_COLORS

  const element = (
    <div
      style={{
        width: '100%',
        height: 'auto',
        overflow: 'auto',
        fontSize: '10px',
        fontFamily: 'Mulish',
        color: on_bg_secondary,
        textAlign:  aboutMe === '' ? 'center' : 'start'
      }}
      dangerouslySetInnerHTML={{
        __html:
          aboutMe === ''
            ? '¡Pronto podrás visualizar esta información!'
            : aboutMe,
      }}
    ></div>
  )

  const html = ReactDOMServer.renderToStaticMarkup(element)

  // const showText = totalItems?.length > totalIdiomsToShow
  // const restOfIdioms = totalItems?.length - totalIdiomsToShow

  return (
    <View
      style={{
        width: '100%',
        backgroundColor: '#FFFFFF',
        padding: '8px',
        borderRadius: '8px',
        minHeight: '144px',
        marginBottom: '12px',
      }}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingLeft: 8,
          paddingTop: 8,
          paddingBottom: 8,
          paddingRight: 8,
        }}
      >
        <View
          style={{
            width: '65%',
            paddingRight: '16px',
          }}
        >
          <Text
            style={{
              color: on_bg_primary,
              fontWeight: 'bold',
              fontSize: '12px',
              textAlign: 'center',
              marginBottom: '8px',
            }}
          >
            Sobre mí
          </Text>
          <View>
            <Html
              style={{
                width: '100%',
                height: 'auto',
                overflow: 'auto',
                fontSize: '10px',
                fontFamily: 'Mulish',
              }}
            >
              {html}
            </Html>
          </View>
        </View>
        <View
          style={{
            width: '35%',
            paddingLeft: '16px',
            borderLeft: `1px solid ${on_bg_tertiary}`,
          }}
        >
          <Text
            style={{
              color: on_bg_primary,
              fontWeight: 'bold',
              fontSize: '12px',
              textAlign: 'left',
              marginBottom: '8px',
            }}
          >
            Idiomas
          </Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            {languaje_lang_levels?.map(({ id, lang_level, languaje }) => {
              return (
                <View
                  key={id}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '4px',
                  }}
                >
                  <Text style={{ color: on_bg_primary, fontSize: '10px' }}>
                    {languaje?.name}
                  </Text>{' '}
                  -{' '}
                  <Text
                    style={{
                      color: on_bg_primary,
                      fontWeight: 'bold',
                      fontSize: '10px',
                    }}
                  >
                    {lang_level?.name}
                  </Text>
                </View>
              )
            })}
          </View>
        </View>
      </View>
    </View>
  )
}

export default PdfIdiomsAndAboutMe
