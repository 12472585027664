import { Svg, Path } from '@react-pdf/renderer'

const PdfCalendarIcon = () => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={9} height={9} fill="none">
      <Path
        fill="#00CE83"
        d="M1.482 8.856A.839.839 0 0 1 .866 8.6a.839.839 0 0 1-.256-.615V1.887c0-.24.086-.445.256-.616a.839.839 0 0 1 .616-.256h.435v-.87h.871v.87h3.485v-.87h.871v.87h.436c.24 0 .444.086.615.256.17.17.256.376.256.616v6.098c0 .24-.086.444-.256.615a.839.839 0 0 1-.615.256H1.482Zm0-.871H7.58V3.629H1.482v4.356Zm0-5.227H7.58v-.871H1.482v.87ZM4.53 5.37a.421.421 0 0 1-.31-.125.421.421 0 0 1-.126-.31c0-.124.042-.227.125-.31a.421.421 0 0 1 .31-.126c.124 0 .227.042.31.125a.421.421 0 0 1 .126.31.421.421 0 0 1-.125.31.421.421 0 0 1-.31.126Zm-1.743 0a.421.421 0 0 1-.31-.125.421.421 0 0 1-.125-.31c0-.124.041-.227.125-.31a.421.421 0 0 1 .31-.126c.124 0 .227.042.31.125a.421.421 0 0 1 .126.31.421.421 0 0 1-.125.31.421.421 0 0 1-.31.126Zm3.485 0a.421.421 0 0 1-.31-.125.421.421 0 0 1-.126-.31c0-.124.042-.227.126-.31a.421.421 0 0 1 .31-.126c.123 0 .227.042.31.125a.421.421 0 0 1 .125.31.421.421 0 0 1-.125.31.421.421 0 0 1-.31.126ZM4.53 7.113a.421.421 0 0 1-.31-.125.421.421 0 0 1-.126-.31c0-.124.042-.227.125-.31a.421.421 0 0 1 .31-.126c.124 0 .227.042.31.126a.421.421 0 0 1 .126.31.421.421 0 0 1-.125.31.421.421 0 0 1-.31.125Zm-1.743 0a.421.421 0 0 1-.31-.125.421.421 0 0 1-.125-.31c0-.124.041-.227.125-.31a.421.421 0 0 1 .31-.126c.124 0 .227.042.31.126a.421.421 0 0 1 .126.31.421.421 0 0 1-.125.31.421.421 0 0 1-.31.125Zm3.485 0a.421.421 0 0 1-.31-.125.421.421 0 0 1-.126-.31c0-.124.042-.227.126-.31a.421.421 0 0 1 .31-.126c.123 0 .227.042.31.126a.421.421 0 0 1 .125.31.421.421 0 0 1-.125.31.421.421 0 0 1-.31.125Z"
      />
    </Svg>
  )
}

export default PdfCalendarIcon
