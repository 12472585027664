import { token } from '../../localStorage'
import { BASE_URL } from '../constants'

export const listOfChats = () => {
  const URL = `${BASE_URL}user/getmychats`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.chats)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const chatDetail = ({ id = 0 }) => {
  const URL = `${BASE_URL}user/getchatinfo?chat=${id}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.infoChat)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const createChat = async (data) => {
  const rawBody = JSON.stringify(data)

  const URL = `${BASE_URL}user/createchat`

  try {
    const response = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    const res = await response.json()

    return { response, res }
  } catch (error) {
    console.log(error)
  }
}

export const messageHistory = ({ page = 0, id = 0 }) => {
  const URL = `${BASE_URL}user/getmsgschat?page=${page}&chat=${id}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.msgs)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const setStateViewdMsg = (data) => {
  const rawBody = JSON.stringify(data)

  const URL = `${BASE_URL}user/setStateViewdMsg`

  try {
    const response = fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}
