const NotificationIcon = () => {
  return (
    <svg
      width="178"
      height="181"
      viewBox="0 0 178 181"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M127.718 63.0889H61.23C58.091 63.0889 55.5464 65.6335 55.5464 68.7725V110.643C55.5464 113.782 58.091 116.327 61.23 116.327H127.718C130.857 116.327 133.401 113.782 133.401 110.643V68.7725C133.401 65.6335 130.857 63.0889 127.718 63.0889Z"
        fill="#01A76A"
      />
      <path
        d="M53.7294 62.4747C53.5963 62.4741 53.4687 62.4213 53.3742 62.3275L52.4608 61.4192C52.3652 61.3236 52.3115 61.194 52.3115 61.0589C52.3115 60.9237 52.3652 60.7941 52.4608 60.6986C52.5563 60.603 52.6859 60.5493 52.8211 60.5493C52.9562 60.5493 53.0858 60.603 53.1814 60.6986L54.0897 61.612C54.1842 61.7071 54.2373 61.8357 54.2373 61.9698C54.2373 62.1038 54.1842 62.2324 54.0897 62.3275C54.0423 62.3746 53.9861 62.4118 53.9242 62.437C53.8624 62.4623 53.7962 62.4751 53.7294 62.4747Z"
        fill="#01A76A"
      />
      <path
        d="M52.3543 65.6614H51.0705C50.9359 65.6614 50.8068 65.6079 50.7116 65.5128C50.6165 65.4176 50.563 65.2885 50.563 65.1539C50.563 65.0194 50.6165 64.8903 50.7116 64.7951C50.8068 64.6999 50.9359 64.6465 51.0705 64.6465H52.3543C52.4889 64.6465 52.618 64.6999 52.7132 64.7951C52.8083 64.8903 52.8618 65.0194 52.8618 65.1539C52.8618 65.2885 52.8083 65.4176 52.7132 65.5128C52.618 65.6079 52.4889 65.6614 52.3543 65.6614Z"
        fill="#01A76A"
      />
      <path
        d="M56.962 61.1045C56.8333 61.1048 56.7093 61.0562 56.6151 60.9686C56.5209 60.881 56.4635 60.7609 56.4545 60.6325L56.3631 59.3487C56.3583 59.282 56.3667 59.215 56.3878 59.1516C56.409 59.0882 56.4424 59.0295 56.4863 58.9791C56.5301 58.9286 56.5835 58.8873 56.6434 58.8576C56.7032 58.8278 56.7684 58.8102 56.8351 58.8057C56.9689 58.7973 57.1006 58.8423 57.2014 58.9307C57.3022 59.0191 57.3639 59.1438 57.373 59.2776L57.4694 60.5615C57.4742 60.6282 57.4659 60.6951 57.4447 60.7586C57.4236 60.822 57.3901 60.8806 57.3463 60.9311C57.3024 60.9815 57.249 61.0228 57.1892 61.0526C57.1293 61.0823 57.0642 61.1 56.9975 61.1045H56.962Z"
        fill="#01A76A"
      />
      <path
        d="M100.152 110.851L120.568 131.261V110.851H100.152Z"
        fill="#01A76A"
      />
      <path
        d="M105.75 101.91L104.735 97.6014C104.479 96.4689 104.479 95.2934 104.735 94.1608L106.146 88.0256C106.723 85.4093 106.41 82.6752 105.258 80.2567C104.105 77.8381 102.178 75.8735 99.782 74.6742C99.827 74.5613 99.8659 74.4462 99.8987 74.3292C99.982 73.957 99.9912 73.572 99.9257 73.1963C99.8603 72.8205 99.7214 72.4614 99.5172 72.1393C99.3129 71.8172 99.0472 71.5385 98.7352 71.3191C98.4232 71.0997 98.0711 70.9439 97.6989 70.8606C97.3267 70.7773 96.9417 70.7682 96.566 70.8336C96.1903 70.8991 95.8311 71.038 95.509 71.2422C95.1869 71.4465 94.9082 71.7122 94.6889 72.0242C94.4695 72.3362 94.3137 72.6883 94.2304 73.0605C94.2052 73.1791 94.1899 73.2996 94.1847 73.4208C91.5065 73.4836 88.9258 74.4381 86.8513 76.133C84.7767 77.828 83.3268 80.1665 82.7313 82.7784L81.356 88.9187C81.1034 90.0493 80.6005 91.1088 79.8844 92.0193L77.1492 95.4954C76.8816 95.8364 76.7048 96.2396 76.635 96.6673C76.5653 97.0951 76.605 97.5335 76.7503 97.9418C76.8957 98.3501 77.1421 98.715 77.4665 99.0024C77.7909 99.2898 78.1827 99.4904 78.6056 99.5856L102.71 104.985C103.131 105.076 103.569 105.059 103.982 104.936C104.395 104.813 104.771 104.588 105.074 104.282C105.377 103.975 105.598 103.597 105.716 103.182C105.834 102.768 105.846 102.33 105.75 101.91Z"
        fill="white"
      />
      <path
        d="M87.5469 103.239C87.3635 103.199 87.173 103.204 86.9923 103.255C86.8115 103.306 86.6461 103.4 86.5106 103.53C86.3751 103.66 86.2737 103.822 86.2155 104C86.1573 104.179 86.1439 104.369 86.1767 104.554C86.3151 105.369 86.6931 106.125 87.2625 106.725C87.8319 107.324 88.567 107.741 89.3741 107.922C90.1812 108.103 91.0238 108.039 91.7946 107.739C92.5654 107.439 93.2295 106.917 93.7024 106.238C93.8104 106.085 93.8792 105.908 93.9026 105.722C93.926 105.537 93.9034 105.348 93.8367 105.173C93.77 104.998 93.6613 104.842 93.5202 104.719C93.3791 104.596 93.2099 104.51 93.0275 104.467L87.5469 103.239Z"
        fill="white"
      />
      <path
        d="M69.0346 94.6984C68.9089 94.6994 68.7849 94.6688 68.6741 94.6094C68.5633 94.55 68.4691 94.4637 68.4003 94.3584C66.0035 90.7676 65.1313 86.3717 65.9754 82.1378C66.8195 77.9038 69.3109 74.1786 72.9015 71.7814C73.0697 71.6697 73.2755 71.6294 73.4734 71.6694C73.6714 71.7093 73.8453 71.8263 73.957 71.9945C74.0687 72.1628 74.109 72.3685 74.0691 72.5664C74.0291 72.7644 73.9121 72.9384 73.7439 73.0501C70.4934 75.2272 68.2401 78.6055 67.4789 82.4429C66.7176 86.2804 67.5108 90.2631 69.6842 93.516C69.7964 93.6836 69.8376 93.8889 69.7986 94.0869C69.7596 94.2848 69.6436 94.4591 69.4761 94.5716C69.3453 94.6577 69.1913 94.702 69.0346 94.6984Z"
        fill="white"
      />
      <path
        d="M72.2572 92.5365C72.1325 92.5358 72.0099 92.5043 71.9002 92.4451C71.7905 92.3858 71.697 92.3004 71.628 92.1965C69.8037 89.4611 69.1398 86.1134 69.782 82.8888C70.4243 79.6642 72.3202 76.8263 75.0533 74.9986C75.1366 74.943 75.2301 74.9043 75.3283 74.8848C75.4266 74.8652 75.5277 74.8652 75.626 74.8848C75.7242 74.9044 75.8177 74.9431 75.901 74.9987C75.9843 75.0544 76.0558 75.1259 76.1114 75.2092C76.1671 75.2925 76.2057 75.386 76.2253 75.4842C76.2448 75.5825 76.2448 75.6836 76.2252 75.7819C76.2057 75.8801 76.167 75.9736 76.1113 76.0568C76.0556 76.1401 75.9841 76.2116 75.9008 76.2673C73.5018 77.8682 71.8371 80.3566 71.2728 83.185C70.7084 86.0133 71.2907 88.95 72.8916 91.3491C73.0038 91.5167 73.0449 91.722 73.0059 91.9199C72.9669 92.1178 72.851 92.2921 72.6835 92.4046C72.5572 92.4891 72.4091 92.5349 72.2572 92.5365Z"
        fill="white"
      />
      <path
        d="M75.4846 90.3801C75.3602 90.3808 75.2375 90.3505 75.1277 90.2921C75.0179 90.2336 74.9243 90.1488 74.8553 90.0452C74.2336 89.1142 73.8015 88.0698 73.5839 86.9716C73.3662 85.8735 73.3672 84.7432 73.5869 83.6454C73.8065 82.5477 74.2405 81.504 74.8639 80.5741C75.4873 79.6443 76.288 78.8465 77.2201 78.2264C77.3877 78.1223 77.5891 78.0873 77.782 78.1289C77.9748 78.1704 78.144 78.2852 78.2539 78.449C78.3638 78.6129 78.4058 78.813 78.371 79.0071C78.3363 79.2013 78.2275 79.3744 78.0676 79.49C77.3031 80.0003 76.6466 80.6563 76.1357 81.4204C75.6247 82.1845 75.2693 83.0418 75.0898 83.9433C74.9102 84.8448 74.91 85.7729 75.0892 86.6744C75.2684 87.576 75.6235 88.4334 76.1341 89.1978C76.2451 89.3657 76.2854 89.5706 76.2465 89.7682C76.2076 89.9657 76.0925 90.1399 75.9261 90.2533C75.7952 90.3394 75.6412 90.3837 75.4846 90.3801Z"
        fill="white"
      />
      <path
        d="M112.92 105.401C112.75 105.4 112.585 105.343 112.451 105.238C112.317 105.132 112.223 104.985 112.182 104.82C112.142 104.655 112.158 104.48 112.229 104.325C112.299 104.17 112.419 104.043 112.57 103.965C116.047 102.168 118.668 99.0649 119.857 95.3366C121.047 91.6083 120.707 87.5603 118.913 84.0823C118.859 83.993 118.823 83.8934 118.809 83.7898C118.794 83.6862 118.802 83.5807 118.83 83.4799C118.858 83.3792 118.907 83.2854 118.973 83.2043C119.039 83.1233 119.121 83.0567 119.214 83.0088C119.307 82.9609 119.409 82.9327 119.514 82.9259C119.618 82.9191 119.723 82.934 119.821 82.9695C119.919 83.005 120.009 83.0604 120.086 83.1322C120.162 83.2041 120.222 83.2908 120.263 83.3871C121.243 85.2876 121.839 87.3626 122.017 89.4935C122.195 91.6244 121.951 93.7694 121.3 95.8062C120.649 97.8429 119.603 99.7313 118.221 101.364C116.84 102.996 115.151 104.34 113.25 105.32C113.147 105.37 113.034 105.398 112.92 105.401Z"
        fill="white"
      />
      <path
        d="M111.139 101.955C110.969 101.955 110.803 101.898 110.67 101.792C110.536 101.687 110.441 101.54 110.401 101.375C110.361 101.209 110.377 101.035 110.447 100.88C110.518 100.725 110.638 100.598 110.789 100.519C112.058 99.8646 113.187 98.966 114.109 97.8749C115.031 96.7838 115.73 95.5215 116.164 94.1604C116.598 92.7992 116.759 91.3659 116.639 89.9422C116.519 88.5186 116.119 87.1327 115.462 85.8637C115.369 85.6841 115.352 85.4748 115.413 85.2821C115.475 85.0894 115.61 84.929 115.79 84.8361C115.969 84.7433 116.179 84.7256 116.371 84.7869C116.564 84.8483 116.724 84.9838 116.817 85.1634C117.565 86.61 118.021 88.1898 118.158 89.8126C118.295 91.4353 118.11 93.0692 117.616 94.6207C117.121 96.1722 116.325 97.6109 115.274 98.8547C114.222 100.098 112.936 101.123 111.489 101.869C111.381 101.927 111.261 101.956 111.139 101.955Z"
        fill="white"
      />
      <path
        d="M109.358 98.5098C109.188 98.507 109.024 98.448 108.892 98.3421C108.759 98.2362 108.666 98.0893 108.626 97.9245C108.586 97.7597 108.601 97.5862 108.671 97.4313C108.74 97.2764 108.858 97.1488 109.008 97.0686C109.825 96.6481 110.551 96.0707 111.144 95.3692C111.738 94.6678 112.187 93.8562 112.467 92.9809C112.746 92.1056 112.85 91.1838 112.773 90.2681C112.696 89.3525 112.439 88.4611 112.017 87.645C111.924 87.4653 111.906 87.2561 111.968 87.0634C112.029 86.8707 112.165 86.7102 112.344 86.6174C112.524 86.5245 112.733 86.5068 112.926 86.5682C113.119 86.6296 113.279 86.765 113.372 86.9447C114.405 88.9545 114.598 91.2919 113.909 93.444C113.22 95.5961 111.706 97.3869 109.698 98.4235C109.593 98.4778 109.476 98.5073 109.358 98.5098Z"
        fill="white"
      />
      <path
        d="M64.7163 32.7115H60.8393C60.7386 32.7141 60.6422 32.7522 60.5669 32.8191C60.4917 32.8859 60.4426 32.9773 60.4282 33.0769L59.6163 40.8106C59.6105 40.8572 59.6147 40.9045 59.6287 40.9494C59.6427 40.9942 59.666 41.0355 59.6973 41.0706C59.7285 41.1057 59.7669 41.1337 59.8098 41.1527C59.8527 41.1718 59.8992 41.1814 59.9461 41.1811H63.8232C63.9245 41.1783 64.0216 41.1395 64.0969 41.0716C64.1723 41.0037 64.221 40.9112 64.2342 40.8106L65.0461 33.0769C65.052 33.0305 65.0478 32.9835 65.0338 32.9389C65.0198 32.8943 64.9963 32.8533 64.965 32.8186C64.9337 32.7839 64.8952 32.7564 64.8523 32.7379C64.8094 32.7194 64.763 32.7104 64.7163 32.7115Z"
        fill="#01A76A"
      />
      <path
        opacity="0.4"
        d="M64.3661 32.7118H60.8393C60.7386 32.7143 60.6422 32.7524 60.5669 32.8193C60.4917 32.8862 60.4426 32.9775 60.4282 33.0771L59.6163 40.8109C59.6105 40.8575 59.6147 40.9048 59.6287 40.9496C59.6427 40.9944 59.666 41.0358 59.6973 41.0708C59.7285 41.1059 59.7669 41.1339 59.8098 41.153C59.8527 41.172 59.8992 41.1817 59.9461 41.1813H63.4984C63.5992 41.1784 63.6956 41.1394 63.7701 41.0714C63.8446 41.0035 63.8922 40.911 63.9043 40.8109L64.7163 33.0771C64.7235 33.0288 64.7196 32.9795 64.7048 32.9329C64.69 32.8863 64.6648 32.8437 64.6309 32.8085C64.5971 32.7732 64.5557 32.7461 64.5098 32.7294C64.4639 32.7126 64.4147 32.7066 64.3661 32.7118Z"
        fill="white"
      />
      <path
        d="M45.2853 175.715C45.7928 175.715 46.3002 175.639 46.4778 175.405C46.521 175.342 46.5442 175.268 46.5442 175.192C46.5442 175.116 46.521 175.042 46.4778 174.979C46.4039 174.865 46.2897 174.784 46.1581 174.75C45.468 174.563 44.0674 175.385 44.0065 175.42C43.9907 175.43 43.9783 175.445 43.971 175.462C43.9638 175.479 43.962 175.498 43.9659 175.517C43.9697 175.537 43.9794 175.555 43.9939 175.57C44.0084 175.584 44.0269 175.594 44.0471 175.598C44.4558 175.672 44.87 175.711 45.2853 175.715ZM45.9044 174.918C45.9667 174.91 46.0298 174.91 46.0922 174.918C46.1728 174.938 46.2434 174.986 46.2901 175.055C46.3611 175.177 46.3408 175.238 46.2901 175.268C46.1125 175.537 45.0722 175.542 44.3059 175.436C44.7981 175.156 45.3419 174.98 45.9044 174.918Z"
        fill="#01A76A"
      />
      <path
        d="M44.0371 175.598H44.0777C44.5852 175.375 45.5088 174.487 45.4123 174.02C45.4123 173.913 45.3058 173.776 45.0368 173.751C44.9416 173.74 44.8453 173.749 44.7537 173.777C44.6621 173.805 44.5771 173.851 44.504 173.913C43.9965 174.345 43.9407 175.436 43.9356 175.497C43.935 175.514 43.9388 175.532 43.9468 175.548C43.9548 175.564 43.9666 175.578 43.9813 175.588C43.9985 175.596 44.0179 175.6 44.0371 175.598ZM44.9708 173.934H45.0267C45.2043 173.934 45.2195 174.02 45.2195 174.04C45.2804 174.319 44.641 175.02 44.1487 175.324C44.1798 174.858 44.3574 174.414 44.6562 174.055C44.7441 173.98 44.8551 173.937 44.9708 173.934Z"
        fill="#01A76A"
      />
      <path
        d="M43.6207 165.987H39.5103V175.502H43.6207V165.987Z"
        fill="#FF8B7B"
      />
      <path
        d="M9.70215 170.965L13.8075 171.411L16.4819 162.176L12.3765 161.729L9.70215 170.965Z"
        fill="#FF8B7B"
      />
      <path
        d="M15.7156 172.34C16.2231 172.472 16.7306 172.528 16.9589 172.34C17.0147 172.288 17.0526 172.22 17.0672 172.145C17.0818 172.071 17.0723 171.993 17.0401 171.924C17.0192 171.861 16.9861 171.802 16.9425 171.752C16.899 171.701 16.8459 171.66 16.7864 171.63C16.152 171.28 14.5789 171.721 14.5129 171.736C14.4936 171.742 14.4764 171.753 14.4636 171.768C14.4509 171.784 14.4433 171.803 14.4419 171.823C14.4419 171.843 14.4476 171.863 14.4584 171.88C14.4691 171.897 14.4846 171.91 14.5028 171.919C14.8944 172.094 15.3001 172.235 15.7156 172.34ZM16.5174 171.731C16.579 171.746 16.6386 171.768 16.695 171.797C16.7702 171.833 16.8285 171.897 16.8574 171.975C16.898 172.112 16.8574 172.163 16.8574 172.193C16.6138 172.401 15.5938 172.152 14.8682 171.858C15.4047 171.711 15.965 171.669 16.5174 171.736V171.731Z"
        fill="#01A76A"
      />
      <path
        d="M14.5231 171.929H14.5637C15.0712 171.833 16.2383 171.214 16.2586 170.747C16.2586 170.635 16.218 170.483 15.9592 170.391C15.8681 170.359 15.7713 170.344 15.6745 170.35C15.5778 170.355 15.4831 170.379 15.396 170.422C14.7971 170.711 14.4622 171.757 14.447 171.797C14.4385 171.812 14.4341 171.828 14.4341 171.845C14.4341 171.862 14.4385 171.879 14.447 171.894C14.4556 171.905 14.4671 171.915 14.4804 171.921C14.4938 171.927 14.5085 171.93 14.5231 171.929ZM15.8425 170.564H15.9034C16.0709 170.625 16.0658 170.696 16.0658 170.716C16.0658 171 15.2539 171.513 14.6957 171.68C14.8362 171.241 15.1136 170.859 15.4873 170.589C15.5988 170.538 15.7249 170.529 15.8425 170.564Z"
        fill="#01A76A"
      />
      <path
        d="M14.0255 171.031L9.53449 169.742C9.45732 169.719 9.37448 169.724 9.30033 169.755C9.22618 169.787 9.1654 169.843 9.12852 169.915L7.47419 173.198C7.43555 173.281 7.41569 173.372 7.41602 173.463C7.41635 173.555 7.43687 173.645 7.4761 173.728C7.51534 173.811 7.57234 173.884 7.64305 173.943C7.71376 174.001 7.79645 174.043 7.88523 174.066C9.45837 174.487 10.2449 174.619 12.219 175.182C13.4318 175.532 15.9031 176.375 17.5829 176.857C19.2626 177.339 19.8817 175.761 19.2321 175.416C16.3193 173.868 15.3855 172.619 14.6142 171.462C14.4758 171.253 14.267 171.1 14.0255 171.031Z"
        fill="#263238"
      />
      <path
        d="M43.5755 175.024H39.0895C39.0088 175.024 38.9305 175.052 38.869 175.104C38.8074 175.156 38.7668 175.229 38.7546 175.309L38.1 178.937C38.0853 179.028 38.0904 179.12 38.115 179.209C38.1396 179.297 38.1831 179.379 38.2424 179.449C38.3017 179.519 38.3755 179.575 38.4587 179.614C38.5419 179.652 38.6324 179.673 38.7241 179.673C40.343 179.642 42.6773 179.546 44.7122 179.546C47.0922 179.546 48.3913 179.678 51.1824 179.678C52.8671 179.678 53.263 177.973 52.5474 177.815C49.3048 177.115 47.4322 177.039 44.5803 175.324C44.2773 175.138 43.9309 175.035 43.5755 175.024Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M43.6207 165.987H39.5103V170.894H43.6207V165.987Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M12.3764 161.734L16.4818 162.175L14.9949 167.301L10.9961 166.494L12.3764 161.734Z"
        fill="black"
      />
      <path
        d="M36.8616 71.355C36.8616 71.355 34.5932 102.381 32.5634 114.144C30.3254 127.029 17.3039 166.479 17.3039 166.479L9.84424 165.114C9.84424 165.114 19.2475 128.069 20.2878 115.311C21.4195 101.468 22.7795 72.9332 22.7795 72.9332L36.8616 71.355Z"
        fill="#01A76A"
      />
      <path
        opacity="0.3"
        d="M33.0404 84.2445C33.4463 82.5394 34.3801 84.686 35.466 88.4057C34.644 97.6111 33.5732 108.273 32.5481 114.149C32.3198 115.464 31.9747 117.057 31.5332 118.864C31.9239 105.73 31.9392 88.8573 33.0404 84.2445Z"
        fill="black"
      />
      <path
        d="M44.6104 70.5127C44.6104 70.5127 47.5892 101.701 47.6907 114.231C47.7973 127.267 45.1128 169.945 45.1128 169.945H38.1453C38.1453 169.945 36.0546 128.029 35.1868 115.225C34.2379 101.27 30.2441 72.0909 30.2441 72.0909L44.6104 70.5127Z"
        fill="#01A76A"
      />
      <path
        d="M18.8616 163.967C18.892 163.967 17.6893 166.672 17.6893 166.672L9.2959 165.149L9.80336 162.637L18.8616 163.967Z"
        fill="#263238"
      />
      <path
        d="M46.331 167.169C46.3614 167.169 46.062 170.061 46.062 170.061H37.5975L37.2524 167.484L46.331 167.169Z"
        fill="#263238"
      />
      <path
        d="M22.9825 42.7139C22.4243 43.7948 21.8103 44.9315 21.2521 46.053L19.547 49.4327C18.9939 50.5593 18.4509 51.6909 17.9282 52.8226C17.4055 53.9542 16.9133 55.0808 16.4921 56.2073L16.4515 54.4312C16.7481 55.3433 17.1041 56.2349 17.5172 57.1005C17.7151 57.547 17.9688 57.9733 18.187 58.4148C18.4052 58.8563 18.6945 59.2775 18.9533 59.6936C19.4917 60.5538 20.0744 61.3854 20.699 62.1852C21.0136 62.5861 21.3282 62.9972 21.6631 63.3829C21.9981 63.7685 22.3634 64.1694 22.6781 64.4993L22.7796 64.6109C23.0133 64.8676 23.1481 65.1992 23.1597 65.5462C23.1712 65.8933 23.0589 66.2331 22.8427 66.5048C22.6266 66.7765 22.3207 66.9624 21.98 67.0292C21.6392 67.0959 21.2859 67.0392 20.9831 66.8691C20.4586 66.5737 19.9504 66.2501 19.4608 65.8999C18.9533 65.565 18.5067 65.2148 18.05 64.8444C17.1357 64.0944 16.2775 63.2786 15.4822 62.4035C14.6704 61.5142 13.9321 60.5604 13.2748 59.5515C12.5993 58.5237 12.0119 57.4407 11.519 56.3139C11.3018 55.8357 11.264 55.2953 11.4124 54.7915L11.4834 54.553C11.8691 53.2285 12.3004 51.9447 12.7775 50.6861C13.2545 49.4276 13.7619 48.1945 14.2999 46.9817C14.8378 45.7688 15.401 44.5712 15.9948 43.3888C16.5885 42.2064 17.1873 41.0494 17.8724 39.867C18.251 39.1903 18.8813 38.6904 19.6265 38.4758C20.3716 38.2612 21.1713 38.3493 21.8518 38.721C22.5323 39.0927 23.0387 39.7179 23.2609 40.4608C23.483 41.2037 23.4031 42.0042 23.0384 42.6885L22.9825 42.7139Z"
        fill="#FF8B7B"
      />
      <path
        d="M25.8955 40.9074C26.0985 44.7793 21.7038 51.8889 21.7038 51.8889L12.6558 47.3674C13.7553 44.1603 15.457 41.1927 17.6695 38.6238C22.4549 33.336 25.7077 37.3551 25.8955 40.9074Z"
        fill="#263238"
      />
      <path
        d="M19.7651 66.1434L21.5869 69.1882L25.3777 66.7625C24.7156 65.576 23.6787 64.6426 22.4293 64.1085C22.1404 64.0162 21.828 64.0305 21.5487 64.1487C21.2695 64.2669 21.0418 64.4813 20.9069 64.753L19.7651 66.1434Z"
        fill="#FF8B7B"
      />
      <path
        d="M23.1042 70.487L24.8651 70.1572C25.0134 70.1297 25.1547 70.0733 25.281 69.991C25.4073 69.9086 25.516 69.8021 25.6009 69.6776C25.6859 69.553 25.7453 69.4129 25.7758 69.2653C25.8062 69.1177 25.8072 68.9654 25.7786 68.8174L25.3777 66.7876L21.5869 69.2133L21.8406 69.8273C21.9445 70.0649 22.1264 70.2598 22.3562 70.3798C22.586 70.4998 22.85 70.5376 23.1042 70.487Z"
        fill="#FF8B7B"
      />
      <path
        d="M33.7965 21.4509C33.832 21.7807 34.0401 22.0395 34.2532 22.0192C34.4663 21.9989 34.6084 21.7198 34.5678 21.39C34.5272 21.0601 34.3242 20.8013 34.1111 20.8166C33.898 20.8318 33.7559 21.116 33.7965 21.4509Z"
        fill="#263238"
      />
      <path
        d="M34.0093 22.0396C34.5225 23.0252 35.1623 23.9396 35.9123 24.7596C35.4048 25.3533 34.3899 25.1807 34.3899 25.1807L34.0093 22.0396Z"
        fill="#FF5652"
      />
      <path
        d="M32.3757 19.7003C32.4021 19.7042 32.429 19.7022 32.4545 19.6943C32.48 19.6864 32.5033 19.6728 32.5228 19.6546C32.6988 19.4899 32.9125 19.3709 33.1452 19.308C33.3779 19.2452 33.6225 19.2405 33.8575 19.2943C33.8815 19.302 33.9068 19.3046 33.9318 19.3021C33.9569 19.2995 33.9811 19.2918 34.003 19.2794C34.0249 19.267 34.0441 19.2502 34.0592 19.2301C34.0743 19.2099 34.0851 19.1869 34.0909 19.1624C34.1036 19.112 34.0965 19.0586 34.071 19.0133C34.0455 18.968 34.0036 18.9341 33.9539 18.9188C33.6572 18.848 33.3477 18.8508 33.0523 18.9268C32.7569 19.0029 32.4846 19.15 32.259 19.3552C32.2401 19.3732 32.2251 19.3949 32.2148 19.4189C32.2045 19.4429 32.1992 19.4687 32.1992 19.4948C32.1992 19.5209 32.2045 19.5467 32.2148 19.5707C32.2251 19.5947 32.2401 19.6163 32.259 19.6343C32.273 19.6526 32.2906 19.6678 32.3107 19.6791C32.3307 19.6905 32.3528 19.6977 32.3757 19.7003Z"
        fill="#263238"
      />
      <path
        d="M23.5659 25.125C24.5352 28.0632 25.8241 33.4778 23.9871 35.6498C23.9871 35.6498 25.1949 38.6083 30.7567 38.1363C36.8817 37.6289 33.294 34.8632 33.294 34.8632C29.8534 34.3557 29.62 31.8743 29.9041 29.5044L23.5659 25.125Z"
        fill="#FF8B7B"
      />
      <path
        d="M22.6731 36.7206C22.0997 35.8021 21.4602 34.7516 22.5462 34.3609C23.7388 33.9295 30.4373 33.2292 32.8223 33.8179C35.2074 34.4066 34.7203 36.3552 34.7203 36.3552L22.6731 36.7206Z"
        fill="#01A76A"
      />
      <path
        d="M42.2861 37.9587C43.1589 38.7605 44.1028 39.6486 45.0416 40.4605C45.9804 41.2724 46.9294 42.1047 47.8834 42.9065C48.8374 43.7083 49.8118 44.4644 50.7912 45.2002C51.7706 45.936 52.7652 46.6059 53.7598 47.2301L51.73 47.0372C52.5735 46.8122 53.3719 46.4431 54.0897 45.9462C54.9285 45.3599 55.707 44.6919 56.4139 43.9519C57.1756 43.1723 57.8891 42.347 58.5503 41.4805C58.8903 41.039 59.2303 40.5925 59.5652 40.1307C59.9001 39.6689 60.2046 39.1817 60.4837 38.7554L60.5395 38.6742C60.7323 38.3799 61.0244 38.1646 61.3627 38.0677C61.7009 37.9708 62.0627 37.9988 62.3821 38.1464C62.7014 38.2941 62.9571 38.5517 63.1023 38.8722C63.2476 39.1926 63.2727 39.5547 63.1733 39.8922C62.9804 40.5519 62.7775 41.1151 62.544 41.7038C62.3106 42.2925 62.067 42.871 61.793 43.4393C61.2373 44.5987 60.5873 45.7104 59.8494 46.7632C59.0748 47.8796 58.1721 48.9015 57.1598 49.808C56.0394 50.8082 54.7393 51.5865 53.3285 52.1017C53.0257 52.2084 52.7046 52.254 52.3841 52.2357C52.0635 52.2174 51.7498 52.1356 51.461 51.9951L51.2936 51.9089C50.0335 51.2896 48.8102 50.5983 47.6297 49.8384C46.4777 49.0925 45.3613 48.316 44.2855 47.4838C43.2097 46.6516 42.1694 45.8092 41.1545 44.9465C40.1395 44.0838 39.1652 43.1805 38.1756 42.1707C37.6491 41.6214 37.3557 40.8896 37.3569 40.1288C37.3582 39.3679 37.6541 38.6371 38.1825 38.0896C38.7109 37.5422 39.4308 37.2206 40.1912 37.1925C40.9515 37.1643 41.6932 37.4316 42.2607 37.9384L42.2861 37.9587Z"
        fill="#FF8B7B"
      />
      <path
        d="M39.1858 35.325C39.1858 35.325 44.301 40.288 44.8947 70.9844C36.7144 72.9686 28.8437 73.6993 22.7643 72.928C22.475 69.0763 22.0437 55.7097 18.1362 41.6175C17.9684 41.024 17.9257 40.402 18.011 39.7911C18.0963 39.1802 18.3076 38.5937 18.6316 38.0688C18.9556 37.5439 19.3852 37.0921 19.8931 36.7421C20.401 36.3921 20.9761 36.1514 21.5819 36.0354C22.3583 35.8832 23.1753 35.7462 23.9872 35.6396C27.0718 35.1984 30.1793 34.9358 33.2941 34.853C35.2668 34.8691 37.2357 35.0268 39.1858 35.325Z"
        fill="#263238"
      />
      <path
        d="M35.2175 40.6736C35.7249 44.5151 42.322 51.8987 42.322 51.8987L50.1521 43.7793C47.8261 41.3373 45.3115 39.0823 42.6315 37.0351C36.8921 32.747 34.7608 37.1112 35.2175 40.6736Z"
        fill="#263238"
      />
      <path
        d="M62.6661 41.2423L65.2846 38.842L62.1485 35.6196C61.1112 36.503 60.3971 37.7062 60.1187 39.0399C60.088 39.3448 60.1688 39.6505 60.346 39.9005C60.5232 40.1505 60.785 40.3279 61.0828 40.3999L62.6661 41.2423Z"
        fill="#FF8B7B"
      />
      <path
        d="M66.2485 37.0963L65.5736 35.4369C65.5165 35.2945 65.4313 35.1651 65.3232 35.0562C65.2151 34.9473 65.0863 34.8612 64.9444 34.803C64.8024 34.7448 64.6502 34.7157 64.4968 34.7174C64.3434 34.719 64.1919 34.7515 64.0512 34.8127L62.1279 35.6196L65.2641 38.842L65.8121 38.4715C66.032 38.3276 66.1956 38.1121 66.2751 37.8616C66.3546 37.6111 66.3452 37.3408 66.2485 37.0963Z"
        fill="#FF8B7B"
      />
      <path
        d="M22.7845 21.6237C23.5203 25.6834 23.7436 28.0888 26.0272 30.0222C29.4627 32.93 34.2024 30.6667 34.654 26.5207C35.0803 22.7858 33.8066 16.7723 29.6758 15.4631C25.5451 14.1538 22.0487 17.564 22.7845 21.6237Z"
        fill="#FF8B7B"
      />
      <path
        d="M30.7871 23.0851C32.2131 23.4098 33.3244 21.7606 33.4766 21.2937C32.7814 21.3597 31.5483 21.0045 31.3047 20.6898C32.0659 20.7558 32.8017 19.817 32.8931 19.4567C32.0855 19.3008 31.3702 18.8367 30.8987 18.1627C32.1319 18.1373 33.6137 15.9654 32.2587 14.0218C30.9038 12.0782 25.2405 13.7072 24.4438 12.3218C23.2563 13.0119 24.3119 13.9304 24.3119 13.9304C24.3119 13.9304 16.299 15.4528 19.9934 18.5433C17.2835 18.7869 16.9029 21.9889 19.1611 23.6179C21.4193 25.2469 21.536 26.3227 20.6378 26.1907C20.9981 26.8809 22.6068 26.9722 22.6981 26.3024C23.0787 27.7081 24.1748 29.611 26.702 29.3015C29.2292 28.9919 31.7056 25.4549 30.7871 23.0851Z"
        fill="#263238"
      />
      <path
        d="M20.6939 18.0714C18.7858 18.3353 17.9181 17.2595 18.3951 16.3105C16.0303 17.5183 18.6133 20.1876 20.6939 18.0714Z"
        fill="#263238"
      />
      <path
        d="M28.8129 22.4659C29.1413 23.3163 29.7222 24.0459 30.4774 24.5566C31.4923 25.2265 32.4109 24.5566 32.2383 23.5417C32.081 22.6079 31.3655 21.1312 30.31 20.8673C30.0914 20.7956 29.8568 20.7878 29.6339 20.8448C29.411 20.9019 29.2091 21.0214 29.0518 21.1893C28.8945 21.3572 28.7885 21.5666 28.7462 21.7927C28.7039 22.0189 28.727 22.2524 28.8129 22.4659Z"
        fill="#FF8B7B"
      />
      <path
        d="M157.795 165.5C154.04 156.64 152.523 146.216 156.821 146.648C161.231 147.074 160.713 162.785 161.429 164.51C160.053 165.693 157.795 165.5 157.795 165.5Z"
        fill="#01A76A"
      />
      <path
        d="M159.013 148.688C158.089 152.067 157.11 157.289 156.364 161.714C156.79 162.983 157.272 164.251 157.795 165.5C157.795 165.5 160.048 165.693 161.433 164.51C160.865 163.15 161.068 153.082 159.013 148.688Z"
        fill="#263238"
      />
      <path
        d="M159.033 163.876C157.856 145.049 161.063 127.597 169.507 128.384C176.754 129.064 166.189 160.76 166.392 164.414C163.073 165.703 159.033 163.876 159.033 163.876Z"
        fill="#01A76A"
      />
      <path
        d="M164.503 163.754C165.828 150.22 172.968 134.428 176.033 139.705C179.925 146.404 169.943 162.359 169.674 164.982C167.178 165.52 164.503 163.754 164.503 163.754Z"
        fill="#01A76A"
      />
      <path
        d="M163.169 133.413C162.311 133.271 161.834 132.738 161.89 131.982C161.951 131.13 162.651 130.282 163.301 130.272C163.616 130.272 164.143 130.434 164.275 131.607L163.768 131.667C163.707 131.109 163.539 130.779 163.316 130.779C162.971 130.779 162.443 131.409 162.403 132.018C162.367 132.525 162.651 132.809 163.255 132.911L163.169 133.413Z"
        fill="#01A76A"
      />
      <path
        d="M172.283 136.818C171.914 136.788 171.562 136.652 171.268 136.427L171.542 136.006C172.014 136.31 172.379 136.392 172.522 136.219C172.735 135.945 172.587 135.143 172.146 134.722C171.791 134.382 171.375 134.407 170.913 134.813L170.583 134.428C170.702 134.294 170.846 134.185 171.008 134.109C171.17 134.033 171.346 133.99 171.524 133.983C171.703 133.977 171.881 134.006 172.048 134.071C172.215 134.135 172.368 134.232 172.496 134.357C173.115 134.945 173.323 136.021 172.917 136.534C172.842 136.629 172.745 136.704 172.635 136.754C172.525 136.803 172.404 136.825 172.283 136.818Z"
        fill="#01A76A"
      />
      <path
        d="M159.845 144.481C159.538 144.503 159.233 144.412 158.988 144.225C158.744 144.038 158.575 143.768 158.516 143.466C158.313 142.639 158.719 141.619 159.338 141.405C159.627 141.309 160.185 141.299 160.672 142.38L160.205 142.593C159.977 142.085 159.698 141.811 159.5 141.887C159.175 141.999 158.866 142.755 158.993 143.344C159.109 143.851 159.5 144.024 160.079 143.933L160.155 144.44C160.053 144.462 159.949 144.476 159.845 144.481Z"
        fill="#01A76A"
      />
      <path
        d="M154.441 154.991C154.193 154.996 153.95 154.927 153.743 154.792C153.535 154.657 153.374 154.463 153.279 154.234C152.923 153.458 153.132 152.377 153.695 152.052C153.964 151.9 154.512 151.788 155.192 152.763L154.776 153.052C154.451 152.59 154.141 152.382 153.949 152.494C153.649 152.666 153.487 153.468 153.74 154.016C153.949 154.468 154.344 154.59 154.918 154.387L155.085 154.869C154.879 154.947 154.661 154.988 154.441 154.991Z"
        fill="#01A76A"
      />
      <path
        d="M163.433 139.67C163.38 139.67 163.329 139.654 163.286 139.625C162.971 139.45 162.729 139.168 162.606 138.829C162.483 138.49 162.486 138.119 162.616 137.782C162.763 137.445 163.014 137.165 163.333 136.982C163.652 136.799 164.022 136.724 164.387 136.767C164.702 136.823 165.437 137.102 165.488 138.452C165.488 138.52 165.461 138.584 165.414 138.632C165.366 138.679 165.302 138.706 165.234 138.706C165.167 138.706 165.103 138.679 165.055 138.632C165.007 138.584 164.981 138.52 164.981 138.452C164.95 137.747 164.712 137.321 164.296 137.245C164.043 137.224 163.791 137.283 163.573 137.413C163.356 137.543 163.184 137.738 163.083 137.97C162.9 138.402 163.083 138.838 163.59 139.198C163.635 139.229 163.668 139.274 163.685 139.325C163.703 139.376 163.703 139.432 163.687 139.483C163.67 139.535 163.638 139.58 163.594 139.612C163.55 139.644 163.497 139.66 163.443 139.66L163.433 139.67Z"
        fill="white"
      />
      <path
        d="M169.446 153.366C169.388 153.367 169.332 153.347 169.287 153.31C169.242 153.273 169.213 153.221 169.203 153.163C169.195 153.131 169.194 153.098 169.2 153.065C169.206 153.032 169.218 153.001 169.236 152.973C169.254 152.945 169.277 152.921 169.305 152.902C169.332 152.884 169.363 152.871 169.396 152.864C170.086 152.722 170.466 152.412 170.477 151.991C170.454 151.739 170.353 151.5 170.189 151.307C170.024 151.114 169.804 150.977 169.558 150.915C169.101 150.804 168.695 151.052 168.426 151.611C168.412 151.641 168.391 151.667 168.366 151.689C168.341 151.712 168.312 151.728 168.281 151.739C168.249 151.75 168.216 151.755 168.182 151.753C168.149 151.751 168.116 151.742 168.086 151.727C168.056 151.713 168.03 151.692 168.008 151.667C167.985 151.642 167.968 151.613 167.958 151.581C167.947 151.55 167.942 151.516 167.944 151.483C167.946 151.45 167.955 151.417 167.97 151.387C168.094 151.051 168.333 150.769 168.645 150.591C168.957 150.414 169.322 150.353 169.675 150.418C170.033 150.506 170.354 150.707 170.589 150.992C170.825 151.276 170.963 151.628 170.984 151.996C170.984 152.316 170.822 153.087 169.502 153.361L169.446 153.366Z"
        fill="white"
      />
      <path
        d="M176.581 149.088C176.287 149.088 175.896 148.916 175.459 148.261L175.881 147.977C176.195 148.444 176.495 148.657 176.693 148.551C176.997 148.388 177.2 147.586 176.931 147.028C176.738 146.577 176.342 146.445 175.764 146.632L175.607 146.15C176.429 145.881 177.099 146.15 177.398 146.83C177.738 147.612 177.51 148.688 176.936 148.997C176.828 149.058 176.705 149.09 176.581 149.088Z"
        fill="#01A76A"
      />
      <path
        d="M175.069 168.722C175.072 166.014 174.101 163.395 172.334 161.344C172.507 161.318 172.666 161.232 172.781 161.099C172.897 160.967 172.961 160.798 172.963 160.623C172.962 160.425 172.882 160.235 172.741 160.095C172.6 159.955 172.41 159.877 172.212 159.877H155.222C155.037 159.879 154.86 159.951 154.725 160.078C154.59 160.205 154.507 160.377 154.493 160.562C154.479 160.746 154.535 160.929 154.649 161.075C154.763 161.221 154.928 161.318 155.11 161.349C153.999 162.639 153.193 164.164 152.753 165.809C152.312 167.454 152.248 169.177 152.566 170.85C152.883 172.524 153.574 174.104 154.586 175.473C155.599 176.842 156.907 177.966 158.414 178.76C158.25 178.844 158.119 178.982 158.042 179.15C157.966 179.318 157.948 179.507 157.992 179.686C158.036 179.865 158.139 180.025 158.285 180.138C158.43 180.252 158.61 180.313 158.795 180.312H168.634C168.852 180.311 169.06 180.224 169.214 180.07C169.368 179.916 169.455 179.708 169.456 179.49C169.456 179.34 169.414 179.193 169.336 179.064C169.259 178.935 169.148 178.83 169.015 178.76C170.842 177.796 172.372 176.352 173.438 174.583C174.505 172.814 175.069 170.788 175.069 168.722Z"
        fill="#263238"
      />
    </svg>
  )
}

export default NotificationIcon
