export default function BriefcaseIcon({
  className,
  width = '33',
  height = '32',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <mask
        id="mask0_69_879"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="33"
        height="32"
      >
        <rect x="0.6604" width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_69_879)">
        <path
          d="M5.99381 28C5.26048 28 4.6327 27.7389 4.11048 27.2167C3.58826 26.6944 3.32715 26.0667 3.32715 25.3333V10.6667C3.32715 9.93332 3.58826 9.30555 4.11048 8.78332C4.6327 8.2611 5.26048 7.99999 5.99381 7.99999H11.3271V5.33332C11.3271 4.59999 11.5883 3.97221 12.1105 3.44999C12.6327 2.92777 13.2605 2.66666 13.9938 2.66666H19.3271C20.0605 2.66666 20.6883 2.92777 21.2105 3.44999C21.7327 3.97221 21.9938 4.59999 21.9938 5.33332V7.99999H27.3271C28.0605 7.99999 28.6883 8.2611 29.2105 8.78332C29.7327 9.30555 29.9938 9.93332 29.9938 10.6667V25.3333C29.9938 26.0667 29.7327 26.6944 29.2105 27.2167C28.6883 27.7389 28.0605 28 27.3271 28H5.99381ZM5.99381 25.3333H27.3271V10.6667H5.99381V25.3333ZM13.9938 7.99999H19.3271V5.33332H13.9938V7.99999Z"
          fill="#E8FFFF"
        />
      </g>
    </svg>
  )
}
