import Loader from '../../../components/Loader'
import EmptyStateOne from '../../../icons/chat/EmptyStateOne'
import SingleChatCard from './SingleChatCard'
import { useChatStore } from '../../../store'
import { IS_RECRUITER } from '../../../utils/constants'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef } from 'react'

const ListOfChats = ({
  profileUserData = [],
  isLoadingProfileUser,
  setDetailData,
  setStateViewdMsgMutate,
  listOfChatsData,
}) => {
  const { t } = useTranslation()
  const scrollPosition = useRef(0)

  const { userInfo = {} } = profileUserData || []
  const { user = {} } = userInfo || {}
  const { username = '' } = user || {}

  const { id, setId, prevId, setPrevId, currentScroll, setCurrentScroll } =
    useChatStore()

  const isEmpty = listOfChatsData?.length === 0

  const emptyState = (
    <div className="d-flex flex-column align-items-center text-on-bg-primary chat-empty-state-container">
      <span className="text-center chat-empty-state-text-one">
        {t('greeting', { value: username })}
      </span>
      <EmptyStateOne />
      {!IS_RECRUITER && (
        <span className="text-center chat-empty-state-text-two ">
          {t('here_you_will_receive_messages_from_your')} <br />{' '}
          {t('manager_and_you_will_establish_contact_with')} <br />
          {t('companies_you_have_accepted')}
        </span>
      )}
    </div>
  )

  useEffect(() => {
    if (currentScroll !== 0) {
      scrollPosition.current.scrollTop = currentScroll
    }
  }, [id])
  

  return isLoadingProfileUser ? (
    <div className="d-flex justify-content-center h-100 align-items-center">
      <Loader />
    </div>
  ) : (
    <div>
      {isEmpty ? (
        emptyState
      ) : (
        <div
          onScrollCapture={(evt) => {
            setCurrentScroll(evt.target.scrollTop)
          }}
          ref={scrollPosition}
          className="d-flex flex-column mt-2 overflow-auto list-of-chats-container"
        >
          {listOfChatsData?.map((props) => {
            return props?.chat_users?.map((chatProps) => (
              <SingleChatCard
                key={chatProps?.id}
                {...chatProps}
                allProps={chatProps}
                setId={setId}
                setPrevId={setPrevId}
                userId={props?.id}
                prevId={prevId}
                setDetailData={setDetailData}
                unseenMessages={props?.unseen_messages}
                setStateViewdMsgMutate={setStateViewdMsgMutate}
              />
            ))
          })}
        </div>
      )}
    </div>
  )
}

export default ListOfChats
