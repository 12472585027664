import { Text, View } from '@react-pdf/renderer'
import { styles } from '../styles/pdfStyles'
import { PDF_PALET_OF_COLORS } from '../constants/pdfConstants'
import PdfPercentageSkills from './PdfPercentageSkills'

const { black } = PDF_PALET_OF_COLORS

const PdfSoftSkils = ({ renderSkills = [] }) => {
  // const showText = totalSkillsItems?.length > totalSkillsToShow
  // const restOfIdioms = totalSkillsItems?.length - totalSkillsToShow

  return (
    <View id="pdf-general-skills" style={styles.section_one}>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '8px',
            marginTop: '9px',
          }}
        >
          {renderSkills
            ?.slice(8)
            ?.map(({ id, percentage, soft_skill }) => {
              return (
                <View
                  key={id}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Text style={{ fontSize: '9px', color: black }}>
                    {soft_skill?.name}
                  </Text>
                  <PdfPercentageSkills isSoftSkill percentage={percentage?.value} />
                </View>
              )
            })}
        </View>
    </View>
  )
}

export default PdfSoftSkils
