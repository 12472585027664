import { Navigate, useLocation } from 'react-router-dom'
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from '../Routes/routes'
import { token } from '../localStorage'
import { globalBootstrap } from '..'

export const currencyFormat = (number, countryFormat = 'en-US') => {
  let convertPositive = Math.abs(number)
  let toFixedNumber = Number.parseFloat(String(convertPositive)).toFixed(2)
  let numberReturn = parseFloat(toFixedNumber)
  if (Number.isNaN(numberReturn)) numberReturn = 0
  return new Intl.NumberFormat(countryFormat).format(numberReturn)
}

export const formatCurrency = ({ amount, symbol = '$', short_name = '' }) => {
  const cleanedValue = amount?.replace(/[^0-9]/g, '')
  const formattedValue = `${symbol}${cleanedValue.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ','
  )}`
  return `${formattedValue} ${short_name}`
}

export const reloadPage = () => {
  window.location.reload()
}

export const copyClipboard = (url_origin = '', text = '') => {
  navigator.clipboard.writeText(`${url_origin}${text}`)
}

export const findRepeatedElements = (arr) => {
  const countMap = {}
  const repeated = []

  arr.forEach((element) => {
    if (!countMap[element]) {
      countMap[element] = 1
    } else {
      countMap[element]++
      if (countMap[element] === 2) {
        repeated.push(element)
      }
    }
  })

  return repeated
}

export const hasDuplicates = (array) => new Set(array).size < array.length

export const RedirectRoute = ({ Route = () => {}, isPrivate = true }) => {
  const location = useLocation()
  if (isPrivate) {
    return token === undefined ? (
      <Navigate to={PUBLIC_ROUTES.login} state={{ from: location }} />
    ) : (
      <Route />
    )
  } else {
    return token === undefined ? (
      <Route />
    ) : (
      <Navigate to={PRIVATE_ROUTES.dashboard} />
    )
  }
}

export const increasePercentage = ({
  increase_amount = 0,
  total_amount = 0,
}) => {
  const result = (increase_amount / total_amount) * 100
  return result
}

export const handleOpenModal = ({ id = '' }) => {
  const modal = globalBootstrap.Modal.getOrCreateInstance(`#${id}`)
  modal.show()
}

export const handleHideModal = ({ id = '' }) => {
  const modal = globalBootstrap.Modal.getOrCreateInstance(`#${id}`)
  modal.hide()
}

export const removeTags = ({ value }) => {
  if (value === null || value === '') return false
  else value = value.toString()

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return value.replace(/(<([^>]+)>)/gi, '')
}

export const handleRedirect = ({ route }) => {
  window.location.href = route
}

export const ellipsisRender = (text = '', maxLength = 12) => {
  if (text?.length > maxLength) {
    return `${text.slice(0, maxLength)}...`
  }
  return text
}

export const selectCheckValue = ({
  checkValue,
  currentCheckValue,
  setCheckValue = () => {},
  isCustomDefaultValue = false,
  defaultValue = '',
}) => {
  if (currentCheckValue === checkValue) {
    isCustomDefaultValue && setCheckValue(defaultValue)
    return // Deselect if already selected
  } else {
    setCheckValue(checkValue)
  }
}

export const capitalize = ({ text = '' }) => {
  const firstLetter = text?.charAt(0)
  const rest = text?.slice(1)
  return firstLetter.toUpperCase() + rest?.toLowerCase()
}

export const formatDateString = (isSameDate, years, months, days) => {
  const yearString =
    years !== 0 ? `${years} ${Number(years) === 1 ? 'año' : 'años'}` : ''
  const monthString =
    months !== 0 ? `${months} ${Number(months) === 1 ? 'mes' : 'meses'}` : ''
  const dayString =
    days !== 0 ? `${days} ${Number(days) === 1 ? 'día' : 'días'}` : ''

  const result = [yearString, monthString, dayString].filter(Boolean).join(' ')

  return `(${isSameDate ? 'Menos de un mes' : result})`
}
