const Button = ({
  id = '',
  className = 'btn-bg-CTA-primary text-on-bg-primary',
  children,
  isSmall = false,
  ...rest
}) => {
  return (
    <button
      id={id}
      className={`btn ${isSmall ? 'btn-sm' : ''} ${className}`}
      type="submit"
      {...rest}
    >
      {children}
    </button>
  )
}

export default Button
