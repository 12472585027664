/* eslint-disable quotes */
import { Text, View } from '@react-pdf/renderer'
import { styles } from '../styles/pdfStyles'
import { PDF_PALET_OF_COLORS } from '../constants/pdfConstants'
import dayjs from 'dayjs'
import Html from 'react-pdf-html'
import ReactDOMServer from 'react-dom/server'
import { DICTIONARY } from '../../../utils/constants'

const { on_bg_primary, bg_cta_primary, bg_brand } = PDF_PALET_OF_COLORS

const PdfWorkExperince = ({ renderWorkExperience = [] }) => {
  const orderWorkExperience = renderWorkExperience.sort((a, b) => {
    const dateA = new Date(a.date_end || Date.now())
    const dateB = new Date(b.date_end || Date.now())
    return dateB - dateA
  })

  return (
    <View id="pdf-section-two" style={styles.section_Two}>
      <View>
        {orderWorkExperience?.map((props, index) => {
          const { date_end, date_start, id, title, summary, company_sector } =
            props

          const element = (
            <div
              style={{
                width: '100%',
                height: 'auto',
                overflow: 'auto',
                fontSize: '12px',
                fontFamily: 'Mulish',
                color: on_bg_primary,
              }}
              dangerouslySetInnerHTML={{ __html: summary }}
            ></div>
          )

          const html = ReactDOMServer.renderToStaticMarkup(element)

          const dateStart = dayjs(date_start).add(1, 'day')
          const dateEnd =
            date_end === null
              ? dayjs().add(1, 'day')
              : dayjs(date_end).add(1, 'day')

          const years = dateEnd.diff(dateStart, 'year')
          const months = dateEnd
            .subtract(years, 'year')
            .diff(dateStart, 'month')
          const days = dateEnd
            .subtract(years, 'year')
            .subtract(months, 'month')
            .diff(dateStart, 'day')

          return (
            <View key={id}>
              <View style={styles.work_experience_card}>
                <View style={styles.work_experience_card_container}>
                  {index === 0 && (
                    <Text
                      style={{
                        color: on_bg_primary,
                        fontWeight: 'bold',
                        fontSize: '14px',
                        textAlign: 'center',
                      }}
                    >
                      {DICTIONARY.work_experience}
                    </Text>
                  )}
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '9px',
                    }}
                  >
                    <View style={{ display: 'flex', flexDirection: 'column' }}>
                      <Text
                        style={{
                          fontWeight: 'bold',
                          marginBottom: '4px',
                          color: bg_cta_primary,
                          ...styles.font_size_extra_small,
                        }}
                      >
                        {title} | {company_sector?.name}
                      </Text>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginBottom: '7px',
                          gap: 2,
                        }}
                      >
                        {' '}
                        <Text
                          style={{
                            fontWeight: 'normal',
                            fontSize: '10px',
                            color: bg_brand,
                          }}
                        >
                          {date_end === null ? 'inicio' : ''}{' '}
                          {dayjs(date_start).add(1, 'day').format('DD-MM-YYYY')}{' '}
                          {date_end === null ? 'y' : 'hasta'}{' '}
                          {date_end === null
                            ? 'Trabaja aqui actualmente'
                            : dayjs(date_end)
                                .add(1, 'day')
                                .format('DD-MM-YYYY')}
                        </Text>{' '}
                        <Text
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            fontWeight: 'normal',
                            fontSize: '10px',
                            color: bg_brand,
                          }}
                        >
                          ({years !== 0 && <Text>{`${years}`}</Text>}
                          {years !== 0 && (
                            <Text>{Number(years) === 1 ? ` año` : ` años`}</Text>
                          )}
                          {months !== 0 && <Text>{` ${months}`}</Text>}
                          {months !== 0 && (
                            <Text>
                              {Number(months) === 1 ? ` mes` : ` meses`}
                            </Text>
                          )}
                          {days !== 0 && <Text>{` ${days}`}</Text>}
                          {days !== 0 && (
                            <Text>{Number(days) === 1 ? ` dia` : ` días`}</Text>
                          )}
                          )
                        </Text>
                      </View>
                    </View>
                  </View>
                  <Text
                    style={{
                      color: on_bg_primary,
                      fontWeight: 'bold',
                      marginTop: '7px',
                      ...styles.font_size_extra_small,
                    }}
                  >
                    Actividades
                  </Text>
                  <View
                    style={{
                      width: '90%',
                    }}
                  >
                    <Html
                      style={{
                        ...styles.sumary,
                      }}
                    >
                      {html}
                    </Html>
                  </View>
                </View>
              </View>
            </View>
          )
        })}
      </View>
    </View>
  )
}

export default PdfWorkExperince

{
  /* <span className="my-resume-date-text">
                        {date_end === null ? 'inicio' : ''}{' '}
                        {dayjs(date_start).add(1, 'day').format('DD-MM-YYYY')}{' '}
                        {date_end === null ? 'y' : 'hasta'}{' '}
                        {date_end === null
                          ? 'Trabaja aqui actualmente'
                          : dayjs(date_end).add(1, 'day').format('DD-MM-YYYY')}
                      </span>{' '} */
}
