const TrashIcon = ({ width = 17, height = 17, fill = '#EA3D29' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <mask
      id="a"
      width={17}
      height={17}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill="#D9D9D9" d="M.5.226h16v16H.5z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill={fill}
        d="M5.167 14.226c-.367 0-.68-.131-.942-.392a1.284 1.284 0 0 1-.392-.942V4.226h-.666V2.892H6.5v-.666h4v.666h3.333v1.334h-.666v8.666c0 .367-.13.68-.392.942a1.284 1.284 0 0 1-.942.392H5.167Zm6.666-10H5.167v8.666h6.666V4.226ZM6.5 11.559h1.333v-6H6.5v6Zm2.667 0H10.5v-6H9.167v6Z"
      />
    </g>
  </svg>
)
export default TrashIcon
