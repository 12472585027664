import ListOfNotifications from './components/ListOfNotifications'

const Notifications = ({
  openUserOptions = false,
  setOpenUserOptions,
  typeOfUser = 1,
  newNotificationsListData,
  handleViewNotifications = () => {},
}) => {
  return (
    <ListOfNotifications
      typeOfUser={typeOfUser}
      openUserOptions={openUserOptions}
      setOpenUserOptions={setOpenUserOptions}
      newNotificationsListData={newNotificationsListData}
      handleViewNotifications={handleViewNotifications}
    />
  )
}

export default Notifications
