const ChatMessageIcon = () => {
  return (
    <svg
      width="89"
      height="89"
      viewBox="0 0 89 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3_7502)">
        <rect
          x="16.1604"
          y="8.64795"
          width="56"
          height="56"
          rx="28"
          fill="#B5E4E4"
          shapeRendering="crispEdges"
        />
        <mask
          id="mask0_3_7502"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x="32"
          y="24"
          width="25"
          height="25"
        >
          <rect x="32.1604" y="24.6479" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_3_7502)">
          <path
            d="M38.1604 38.6479H50.1604V36.6479H38.1604V38.6479ZM38.1604 35.6479H50.1604V33.6479H38.1604V35.6479ZM38.1604 32.6479H50.1604V30.6479H38.1604V32.6479ZM54.1604 46.6479L50.1604 42.6479H36.1604C35.6104 42.6479 35.1396 42.4521 34.7479 42.0604C34.3562 41.6688 34.1604 41.1979 34.1604 40.6479V28.6479C34.1604 28.0979 34.3562 27.6271 34.7479 27.2354C35.1396 26.8438 35.6104 26.6479 36.1604 26.6479H52.1604C52.7104 26.6479 53.1812 26.8438 53.5729 27.2354C53.9646 27.6271 54.1604 28.0979 54.1604 28.6479V46.6479ZM36.1604 28.6479V40.6479H50.9854L52.1604 41.8229V28.6479H36.1604Z"
            fill="#446659"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_3_7502"
          x="0.1604"
          y="0.647949"
          width="88"
          height="88"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="8" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.168627 0 0 0 0 0.0666667 0 0 0 0 0.34902 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3_7502"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3_7502"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default ChatMessageIcon
