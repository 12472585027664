const PartyIcon = ({ fill = '#157A08', width = '22', height = '21' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22 21"
      fill="none"
    >
      <path
        d="M0 20.6797L5 6.67969L14 15.6797L0 20.6797ZM3.3 17.3797L10.35 14.8797L5.8 10.3297L3.3 17.3797ZM12.55 11.2297L11.5 10.1797L17.1 4.57969C17.6333 4.04635 18.275 3.77969 19.025 3.77969C19.775 3.77969 20.4167 4.04635 20.95 4.57969L21.55 5.17969L20.5 6.22969L19.9 5.62969C19.6667 5.39635 19.375 5.27969 19.025 5.27969C18.675 5.27969 18.3833 5.39635 18.15 5.62969L12.55 11.2297ZM8.55 7.22969L7.5 6.17969L8.1 5.57969C8.33333 5.34635 8.45 5.06302 8.45 4.72969C8.45 4.39635 8.33333 4.11302 8.1 3.87969L7.45 3.22969L8.5 2.17969L9.15 2.82969C9.68333 3.36302 9.95 3.99635 9.95 4.72969C9.95 5.46302 9.68333 6.09635 9.15 6.62969L8.55 7.22969ZM10.55 9.22969L9.5 8.17969L13.1 4.57969C13.3333 4.34635 13.45 4.05469 13.45 3.70469C13.45 3.35469 13.3333 3.06302 13.1 2.82969L11.5 1.22969L12.55 0.179688L14.15 1.77969C14.6833 2.31302 14.95 2.95469 14.95 3.70469C14.95 4.45469 14.6833 5.09635 14.15 5.62969L10.55 9.22969ZM14.55 13.2297L13.5 12.1797L15.1 10.5797C15.6333 10.0464 16.275 9.77969 17.025 9.77969C17.775 9.77969 18.4167 10.0464 18.95 10.5797L20.55 12.1797L19.5 13.2297L17.9 11.6297C17.6667 11.3964 17.375 11.2797 17.025 11.2797C16.675 11.2797 16.3833 11.3964 16.15 11.6297L14.55 13.2297Z"
        fill={fill}
      />
    </svg>
  )
}

export default PartyIcon
