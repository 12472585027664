import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLostConectionStore } from '../../store'
import { reloadPage } from '../../utils'
import Button from '../../components/Button'
import { useTranslation } from 'react-i18next'
import { PreloadImageComponent } from '../../components/PreLoadImage'

const LostInternetConection = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { isLostConection, lostConectionImage } = useLostConectionStore()

  useEffect(() => {
    setInterval(function () {
      if (navigator.onLine && !isLostConection) {
        navigate('/')
        reloadPage()
      }
    }, 2500)
  }, [navigator.onLine, isLostConection])

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center text-on-bg-primary"
      style={{
        height: '100vh',
      }}
    >
      <PreloadImageComponent src={lostConectionImage} alt="lost conection" width={242.32} height={200} />
      <span className="fs-4 fw-bolder">
        {t('oops_there_seems_to_be_a_connection_problem')}
      </span>
      <span className="mb-3">
        {t(
          'please_check_your_internet_connection_or_try_again_later_ff_the_problem_persists_please_contact_support'
        )}
      </span>
      <div className="d-flex align-items-center gap-2">
        <Button onClick={() => reloadPage()}>{t('retry')}</Button>
      </div>
    </div>
  )
}

export default LostInternetConection
