const EnglishIcon = ({ width = '12', height = '12' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7286_232382)">
        <path
          d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
          fill="#F0F0F0"
        />
        <path
          d="M5.73926 5.99992H12.0001C12.0001 5.45837 11.9279 4.93375 11.7934 4.43469H5.73926V5.99992Z"
          fill="#D80027"
        />
        <path
          d="M5.73926 2.86955H11.1195C10.7522 2.2702 10.2826 1.74045 9.73502 1.30432H5.73926V2.86955Z"
          fill="#D80027"
        />
        <path
          d="M6.00053 12C7.41262 12 8.71052 11.512 9.73544 10.6957H2.26562C3.29055 11.512 4.58845 12 6.00053 12Z"
          fill="#D80027"
        />
        <path
          d="M0.880977 9.13041H11.1197C11.4146 8.64926 11.6432 8.12333 11.7936 7.56519H0.207031C0.35743 8.12333 0.586109 8.64926 0.880977 9.13041Z"
          fill="#D80027"
        />
        <path
          d="M2.77931 0.936984H3.32609L2.81749 1.30648L3.01177 1.90434L2.5032 1.53485L1.99462 1.90434L2.16244 1.38785C1.71464 1.76086 1.32216 2.19788 0.998719 2.68481H1.17391L0.850172 2.92001C0.799734 3.00415 0.751359 3.08962 0.705 3.17637L0.859594 3.65217L0.571172 3.44262C0.499477 3.59452 0.433898 3.74984 0.374953 3.90839L0.545273 4.43264H1.17391L0.66532 4.80213L0.859594 5.4L0.351023 5.03051L0.0463828 5.25185C0.0158906 5.49696 0 5.74662 0 6H6C6 2.68631 6 2.29566 6 0C4.81472 0 3.7098 0.343828 2.77931 0.936984ZM3.01177 5.4L2.5032 5.03051L1.99462 5.4L2.1889 4.80213L1.6803 4.43264H2.30895L2.5032 3.83477L2.69745 4.43264H3.32609L2.81749 4.80213L3.01177 5.4ZM2.81749 3.0543L3.01177 3.65217L2.5032 3.28268L1.99462 3.65217L2.1889 3.0543L1.6803 2.68481H2.30895L2.5032 2.08695L2.69745 2.68481H3.32609L2.81749 3.0543ZM5.16394 5.4L4.65537 5.03051L4.1468 5.4L4.34107 4.80213L3.83248 4.43264H4.46112L4.65537 3.83477L4.84962 4.43264H5.47826L4.96966 4.80213L5.16394 5.4ZM4.96966 3.0543L5.16394 3.65217L4.65537 3.28268L4.1468 3.65217L4.34107 3.0543L3.83248 2.68481H4.46112L4.65537 2.08695L4.84962 2.68481H5.47826L4.96966 3.0543ZM4.96966 1.30648L5.16394 1.90434L4.65537 1.53485L4.1468 1.90434L4.34107 1.30648L3.83248 0.936984H4.46112L4.65537 0.339117L4.84962 0.936984H5.47826L4.96966 1.30648Z"
          fill="#0052B4"
        />
      </g>
      <defs>
        <clipPath id="clip0_7286_232382">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default EnglishIcon
