import { Text, View } from '@react-pdf/renderer'
import { styles } from '../styles/pdfStyles'
import { PDF_PALET_OF_COLORS } from '../constants/pdfConstants'
import PdfPercentageSkills from './PdfPercentageSkills'

const { black } = PDF_PALET_OF_COLORS

const PdfGeneralSkills = ({ renderSkills }) => {
  // const showText = totalSkillsItems?.length > totalSkillsToShow
  // const restOfIdioms = totalSkillsItems?.length - totalSkillsToShow

  return (
    <View id="pdf-general-skills" style={styles.section_one}>
      <View
        style={{
          backgroundColor: '#FFFFFF',
          padding: '8px',
          borderRadius: '8px',
          width: '100%',
        }}
      >
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '8px',
            marginTop: '9px',
          }}
        >
          {renderSkills
            ?.slice(8)
            ?.map(({ id, percentage, skills_categori }) => {
              return (
                <View
                  key={id}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Text style={{ fontSize: '9px', color: black }}>
                    {skills_categori?.skill?.name}
                  </Text>
                  <PdfPercentageSkills percentage={percentage?.value} />
                </View>
              )
            })}
        </View>
        {/* <Image src={myChart.getUrl()}></Image> */}
        {/* {showText && (
            <Text
              style={{
                color: on_bg_primary,
                fontSize: '12px',
                marginTop: '20px',
                textAlign: 'center',
              }}
            >
              {restOfIdioms} {DICTIONARY.skills} más en{' '}
              <Link src="https://hutrit.com/" style={{ color: bg_cta_primary }}>
                Hutrit
              </Link>
            </Text>
          )} */}
      </View>
    </View>
  )
}

export default PdfGeneralSkills
