const HeartIconTwo = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1176_8987"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="25"
      >
        <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1176_8987)">
        <path
          d="M12 21.5L10.55 20.2C8.86667 18.6834 7.475 17.375 6.375 16.275C5.275 15.175 4.4 14.1875 3.75 13.3125C3.1 12.4375 2.64583 11.6334 2.3875 10.9C2.12917 10.1667 2 9.41669 2 8.65002C2 7.08336 2.525 5.77502 3.575 4.72502C4.625 3.67502 5.93333 3.15002 7.5 3.15002C8.36667 3.15002 9.19167 3.33336 9.975 3.70002C10.7583 4.06669 11.4333 4.58336 12 5.25002C12.5667 4.58336 13.2417 4.06669 14.025 3.70002C14.8083 3.33336 15.6333 3.15002 16.5 3.15002C18.0667 3.15002 19.375 3.67502 20.425 4.72502C21.475 5.77502 22 7.08336 22 8.65002C22 9.41669 21.8708 10.1667 21.6125 10.9C21.3542 11.6334 20.9 12.4375 20.25 13.3125C19.6 14.1875 18.725 15.175 17.625 16.275C16.525 17.375 15.1333 18.6834 13.45 20.2L12 21.5ZM12 18.8C13.6 17.3667 14.9167 16.1375 15.95 15.1125C16.9833 14.0875 17.8 13.1959 18.4 12.4375C19 11.6792 19.4167 11.0042 19.65 10.4125C19.8833 9.82086 20 9.23336 20 8.65002C20 7.65002 19.6667 6.81669 19 6.15002C18.3333 5.48336 17.5 5.15002 16.5 5.15002C15.7167 5.15002 14.9917 5.37086 14.325 5.81252C13.6583 6.25419 13.2 6.81669 12.95 7.50002H11.05C10.8 6.81669 10.3417 6.25419 9.675 5.81252C9.00833 5.37086 8.28333 5.15002 7.5 5.15002C6.5 5.15002 5.66667 5.48336 5 6.15002C4.33333 6.81669 4 7.65002 4 8.65002C4 9.23336 4.11667 9.82086 4.35 10.4125C4.58333 11.0042 5 11.6792 5.6 12.4375C6.2 13.1959 7.01667 14.0875 8.05 15.1125C9.08333 16.1375 10.4 17.3667 12 18.8Z"
          fill="#004949"
        />
      </g>
    </svg>
  )
}

export default HeartIconTwo
