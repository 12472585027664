const UPDATE_OFFER_MANAGEMENT_ID = 'UPDATE_OFFER_MANAGEMENT_ID'
const UPDATE_OFFER_MANAGEMENT_ID_FROM_MATCH_VIEW =
  'UPDATE_OFFER_MANAGEMENT_ID_FROM_MATCH_VIEW'
const GET_TALENT_ID = 'GET_TALENT_ID'
const UPDATE_TYPE_OF_REGISTER = 'UPDATE_TYPE_OF_REGISTER'
const UPDATE_PREV_ROUTE = 'UPDATE_PREV_ROUTE'
const CHANGE_DASHBOARD_COMPONENT = 'CHANGE_DASHBOARD_COMPONENT'
const CHANGE_DASHBOARD_BY_COMPANY_COMPONENT =
  'CHANGE_DASHBOARD_BY_COMPANY_COMPONENT'
const GET_RECRUITER_ID = 'GET_RECRUITER_ID'
const GET_JOB_OFFER_ID = 'GET_JOB_OFFER_ID'

export {
  CHANGE_DASHBOARD_BY_COMPANY_COMPONENT,
  UPDATE_OFFER_MANAGEMENT_ID,
  GET_TALENT_ID,
  UPDATE_TYPE_OF_REGISTER,
  UPDATE_OFFER_MANAGEMENT_ID_FROM_MATCH_VIEW,
  UPDATE_PREV_ROUTE,
  CHANGE_DASHBOARD_COMPONENT,
  GET_RECRUITER_ID,
  GET_JOB_OFFER_ID,
}
