const WaitResponseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="139"
      height="173"
      viewBox="0 0 139 173"
      fill="none"
    >
      <path
        d="M92.1845 0.0292969H59.5791V1.11777H92.1845V0.0292969Z"
        fill="#01A76A"
      />
      <path
        d="M41.5518 5.75358L40.7549 5.00526C42.2502 3.41403 44.0576 2.14828 46.0642 1.28712C48.0708 0.425952 50.2335 -0.0121238 52.417 0.000255213V1.079C50.3816 1.06758 48.3658 1.47692 46.4961 2.28134C44.6264 3.08576 42.9431 4.26789 41.5518 5.75358Z"
        fill="#01A76A"
      />
      <path
        d="M52.4558 3.81002H114.79C118.302 3.86736 121.665 5.23306 124.223 7.63987C126.781 10.0467 128.349 13.3213 128.619 16.823L130.981 54.1419L131.739 66.2511L118.872 54.1516H55.6434C52.1343 54.0943 48.7729 52.7296 46.217 50.3246C43.661 47.9196 42.0944 44.6474 41.8238 41.1483L40.2883 16.8522C40.1575 15.1801 40.3759 13.499 40.9294 11.9158C41.483 10.3325 42.3597 8.88163 43.5038 7.65523C44.6479 6.42884 46.0346 5.45371 47.5757 4.79176C49.1167 4.12981 50.7786 3.79549 52.4558 3.81002Z"
        fill="#01A76A"
      />
      <path
        d="M55.7499 21.6431H114.653C114.932 21.6451 115.209 21.5894 115.465 21.4795C115.722 21.3695 115.953 21.2076 116.143 21.004C116.334 20.8004 116.481 20.5596 116.574 20.2966C116.667 20.0336 116.705 19.7541 116.685 19.4759C116.637 18.8921 116.373 18.3471 115.946 17.9466C115.518 17.5461 114.957 17.3187 114.372 17.3086H55.4778C55.1988 17.3066 54.9224 17.3623 54.666 17.4723C54.4096 17.5823 54.1787 17.7441 53.9879 17.9477C53.7971 18.1513 53.6505 18.3921 53.5574 18.6551C53.4642 18.9181 53.4265 19.1976 53.4467 19.4759C53.4925 20.0588 53.7538 20.6037 54.1796 21.0044C54.6055 21.4051 55.1653 21.6328 55.7499 21.6431Z"
        fill="#E4F8ED"
      />
      <path
        d="M56.4005 31.6339H115.304C115.583 31.636 115.859 31.5803 116.116 31.4703C116.372 31.3603 116.603 31.1984 116.794 30.9949C116.985 30.7913 117.131 30.5504 117.224 30.2874C117.318 30.0244 117.355 29.745 117.335 29.4667C117.289 28.8821 117.027 28.3358 116.599 27.9349C116.171 27.534 115.609 27.3074 115.022 27.2995H56.109C55.8295 27.2959 55.5524 27.3507 55.2953 27.4601C55.0382 27.5696 54.8068 27.7315 54.6157 27.9354C54.4246 28.1393 54.2781 28.3807 54.1855 28.6444C54.0928 28.908 54.0562 29.1881 54.0778 29.4667C54.124 30.0529 54.388 30.6006 54.818 31.0017C55.2479 31.4029 55.8125 31.6284 56.4005 31.6339Z"
        fill="#E4F8ED"
      />
      <path
        d="M77.0718 41.6245H115.946C116.225 41.6267 116.503 41.5707 116.76 41.4601C117.017 41.3495 117.248 41.1868 117.439 40.9821C117.63 40.7775 117.777 40.5354 117.869 40.2713C117.962 40.0071 117.998 39.7266 117.977 39.4475C117.933 38.8638 117.672 38.3176 117.246 37.9165C116.82 37.5154 116.259 37.2884 115.673 37.2803H76.8094C76.5304 37.2783 76.254 37.334 75.9975 37.444C75.7411 37.5539 75.5103 37.7158 75.3195 37.9194C75.1287 38.123 74.9821 38.3638 74.8889 38.6268C74.7958 38.8898 74.7581 39.1693 74.7782 39.4475C74.8194 40.0313 75.0777 40.5786 75.5022 40.9815C75.9267 41.3844 76.4866 41.6138 77.0718 41.6245Z"
        fill="#E4F8ED"
      />
      <path
        d="M74.5654 66.2606H12.2313C10.5548 66.241 8.89252 66.5703 7.35012 67.2276C5.80772 67.8849 4.41889 68.8558 3.27188 70.0786C2.12487 71.3015 1.24475 72.7496 0.687446 74.3309C0.130137 75.9122 -0.0921766 77.5921 0.034628 79.2639L1.78395 106.631L2.54199 118.731L13.8834 106.631H77.1117C78.7878 106.648 80.4492 106.316 81.9904 105.657C83.5317 104.998 84.9192 104.026 86.065 102.803C87.2109 101.579 88.09 100.131 88.6466 98.5501C89.2032 96.969 89.4253 95.2895 89.2986 93.618L88.3948 79.2639C88.124 75.7631 86.5561 72.4896 83.998 70.0844C81.44 67.6791 78.0762 66.3155 74.5654 66.2606Z"
        fill="#01A76A"
      />
      <path
        opacity="0.5"
        d="M74.5654 66.2606H12.2313C10.5548 66.241 8.89252 66.5703 7.35012 67.2276C5.80772 67.8849 4.41889 68.8558 3.27188 70.0786C2.12487 71.3015 1.24475 72.7496 0.687446 74.3309C0.130137 75.9122 -0.0921766 77.5921 0.034628 79.2639L1.78395 106.631L2.54199 118.731L13.8834 106.631H77.1117C78.7878 106.648 80.4492 106.316 81.9904 105.657C83.5317 104.998 84.9192 104.026 86.065 102.803C87.2109 101.579 88.09 100.131 88.6466 98.5501C89.2032 96.969 89.4253 95.2895 89.2986 93.618L88.3948 79.2639C88.124 75.7631 86.5561 72.4896 83.998 70.0844C81.44 67.6791 78.0762 66.3155 74.5654 66.2606Z"
        fill="#E4F8ED"
      />
      <path
        d="M73.5245 84.0845H14.6113C14.0267 84.0742 13.4668 83.8465 13.041 83.4458C12.6151 83.0451 12.3538 82.5002 12.308 81.9173C12.2879 81.639 12.3256 81.3596 12.4187 81.0966C12.5119 80.8336 12.6585 80.5927 12.8493 80.3891C13.04 80.1855 13.2709 80.0237 13.5273 79.9137C13.7838 79.8037 14.0602 79.748 14.3392 79.7501H73.233C73.8193 79.7579 74.3815 79.9846 74.8094 80.3855C75.2373 80.7864 75.5 81.3327 75.546 81.9173C75.566 82.1947 75.5286 82.4733 75.436 82.7357C75.3435 82.998 75.1978 83.2384 75.008 83.4418C74.8183 83.6453 74.5886 83.8074 74.3333 83.9179C74.0781 84.0285 73.8027 84.0852 73.5245 84.0845Z"
        fill="#E4F8ED"
      />
      <path
        d="M54.0978 94.0942H15.224C14.6384 94.0842 14.0774 93.8568 13.6499 93.4563C13.2225 93.0558 12.9592 92.5108 12.911 91.927C12.8909 91.6488 12.9286 91.3693 13.0218 91.1063C13.1149 90.8433 13.2615 90.6024 13.4523 90.3989C13.6431 90.1953 13.8739 90.0334 14.1304 89.9234C14.3868 89.8135 14.6632 89.7578 14.9422 89.7598H53.816C54.402 89.7657 54.9642 89.9919 55.391 90.3934C55.8178 90.795 56.0778 91.3425 56.1193 91.927C56.1393 92.2045 56.1019 92.4831 56.0093 92.7454C55.9168 93.0078 55.7711 93.2482 55.5813 93.4516C55.3916 93.655 55.1619 93.8171 54.9066 93.9277C54.6513 94.0383 54.376 94.095 54.0978 94.0942Z"
        fill="#E4F8ED"
      />
      <path
        d="M59.7245 118.731H122.049C125.561 118.786 128.926 120.151 131.485 122.558C134.043 124.965 135.61 128.241 135.878 131.744L137.725 160.899L138.493 172.998L125.625 160.899H62.3874C58.8757 160.842 55.5118 159.476 52.954 157.069C50.3962 154.662 48.8286 151.388 48.558 147.886L47.5376 131.705C47.4166 130.036 47.6428 128.361 48.202 126.785C48.7611 125.208 49.6411 123.765 50.7864 122.546C51.9316 121.326 53.3171 120.358 54.8556 119.701C56.394 119.045 58.0519 118.714 59.7245 118.731Z"
        fill="#01A76A"
      />
      <path
        d="M87.5476 138.275C87.6375 139.507 88.1866 140.662 89.0863 141.509C89.986 142.357 91.1709 142.836 92.4068 142.852C92.9971 142.859 93.5824 142.743 94.1255 142.512C94.6687 142.281 95.1578 141.939 95.5618 141.508C95.9657 141.078 96.2757 140.568 96.4721 140.011C96.6685 139.455 96.7469 138.863 96.7024 138.275C96.6081 137.043 96.0577 135.892 95.1589 135.045C94.2602 134.199 93.0777 133.718 91.8431 133.697C91.2524 133.689 90.6664 133.803 90.1226 134.034C89.5787 134.265 89.089 134.607 88.6848 135.038C88.2805 135.468 87.9705 135.979 87.7747 136.536C87.5789 137.094 87.5016 137.686 87.5476 138.275Z"
        fill="#E4F8ED"
      />
      <path
        d="M72.2815 139.81C72.3714 141.043 72.9204 142.197 73.8202 143.045C74.7199 143.892 75.9048 144.371 77.1407 144.388C77.7306 144.395 78.3155 144.279 78.8582 144.048C79.4009 143.816 79.8894 143.474 80.2926 143.044C80.6958 142.613 81.0049 142.103 81.2001 141.546C81.3954 140.99 81.4725 140.398 81.4265 139.81C81.3323 138.579 80.7819 137.427 79.8831 136.581C78.9843 135.734 77.8019 135.253 76.5673 135.233C75.9774 135.226 75.3925 135.341 74.8498 135.573C74.3071 135.804 73.8186 136.146 73.4154 136.577C73.0121 137.007 72.7031 137.517 72.5078 138.074C72.3126 138.631 72.2355 139.222 72.2815 139.81Z"
        fill="#E4F8ED"
      />
      <path
        d="M103.147 142.113C103.241 143.344 103.792 144.496 104.69 145.343C105.589 146.189 106.772 146.67 108.006 146.691C108.596 146.698 109.181 146.582 109.724 146.351C110.266 146.119 110.755 145.777 111.158 145.347C111.561 144.916 111.87 144.406 112.066 143.849C112.261 143.293 112.338 142.701 112.292 142.113C112.202 140.88 111.653 139.726 110.753 138.879C109.854 138.031 108.669 137.552 107.433 137.536C106.843 137.53 106.259 137.647 105.717 137.879C105.175 138.111 104.687 138.453 104.284 138.883C103.881 139.313 103.572 139.823 103.376 140.379C103.181 140.935 103.102 141.525 103.147 142.113Z"
        fill="#E4F8ED"
      />
    </svg>
  )
}

export default WaitResponseIcon
