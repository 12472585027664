const SendMessageIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="mask0_5816_2985"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5816_2985)">
        <path
          d="M2.5 4.5L3.5 3.5L21.5 11.5L3.5 19.5L2.5 18.5L5.5 11.5L2.5 4.5ZM5.6 6.6L7.7 11.5L5.6 16.4L16.6 11.5L5.6 6.6Z"
          fill="#004949"
        />
      </g>
    </svg>
  )
}

export default SendMessageIcon
