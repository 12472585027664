const CampaingIcon = ({ className }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <mask
        id="mask0_1383_2284"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="25"
      >
        <rect x="0.1604" y="0.647949" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1383_2284)">
        <path
          d="M4.1604 19.6479V17.6479H6.1604V10.6479C6.1604 9.26462 6.57707 8.03545 7.4104 6.96045C8.24373 5.88545 9.32707 5.18128 10.6604 4.84795V4.14795C10.6604 3.73128 10.8062 3.37712 11.0979 3.08545C11.3896 2.79378 11.7437 2.64795 12.1604 2.64795C12.5771 2.64795 12.9312 2.79378 13.2229 3.08545C13.5146 3.37712 13.6604 3.73128 13.6604 4.14795V4.84795C14.9937 5.18128 16.0771 5.88545 16.9104 6.96045C17.7437 8.03545 18.1604 9.26462 18.1604 10.6479V17.6479H20.1604V19.6479H4.1604ZM12.1604 22.6479C11.6104 22.6479 11.1396 22.4521 10.7479 22.0604C10.3562 21.6688 10.1604 21.1979 10.1604 20.6479H14.1604C14.1604 21.1979 13.9646 21.6688 13.5729 22.0604C13.1812 22.4521 12.7104 22.6479 12.1604 22.6479ZM8.1604 17.6479H16.1604V10.6479C16.1604 9.54795 15.7687 8.60628 14.9854 7.82295C14.2021 7.03962 13.2604 6.64795 12.1604 6.64795C11.0604 6.64795 10.1187 7.03962 9.3354 7.82295C8.55207 8.60628 8.1604 9.54795 8.1604 10.6479V17.6479Z"
          fill="#004949"
        />
      </g>
    </svg>
  )
}

export default CampaingIcon
