const SearchUserOutlineExtraSmallIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
    >
      <mask
        id="mask0_3514_10113"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="12"
        height="13"
      >
        <rect y="0.5" width="12" height="12" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3514_10113)">
        <path
          d="M5.5 6.5C4.95 6.5 4.47917 6.30417 4.0875 5.9125C3.69583 5.52083 3.5 5.05 3.5 4.5C3.5 3.95 3.69583 3.47917 4.0875 3.0875C4.47917 2.69583 4.95 2.5 5.5 2.5C6.05 2.5 6.52083 2.69583 6.9125 3.0875C7.30417 3.47917 7.5 3.95 7.5 4.5C7.5 5.05 7.30417 5.52083 6.9125 5.9125C6.52083 6.30417 6.05 6.5 5.5 6.5ZM5.5 5.5C5.775 5.5 6.01042 5.40208 6.20625 5.20625C6.40208 5.01042 6.5 4.775 6.5 4.5C6.5 4.225 6.40208 3.98958 6.20625 3.79375C6.01042 3.59792 5.775 3.5 5.5 3.5C5.225 3.5 4.98958 3.59792 4.79375 3.79375C4.59792 3.98958 4.5 4.225 4.5 4.5C4.5 4.775 4.59792 5.01042 4.79375 5.20625C4.98958 5.40208 5.225 5.5 5.5 5.5ZM11.05 12.25L9.45 10.65C9.275 10.75 9.0875 10.8333 8.8875 10.9C8.6875 10.9667 8.475 11 8.25 11C7.625 11 7.09375 10.7812 6.65625 10.3438C6.21875 9.90625 6 9.375 6 8.75C6 8.125 6.21875 7.59375 6.65625 7.15625C7.09375 6.71875 7.625 6.5 8.25 6.5C8.875 6.5 9.40625 6.71875 9.84375 7.15625C10.2812 7.59375 10.5 8.125 10.5 8.75C10.5 8.975 10.4667 9.1875 10.4 9.3875C10.3333 9.5875 10.25 9.775 10.15 9.95L11.75 11.55L11.05 12.25ZM8.25 10C8.6 10 8.89583 9.87917 9.1375 9.6375C9.37917 9.39583 9.5 9.1 9.5 8.75C9.5 8.4 9.37917 8.10417 9.1375 7.8625C8.89583 7.62083 8.6 7.5 8.25 7.5C7.9 7.5 7.60417 7.62083 7.3625 7.8625C7.12083 8.10417 7 8.4 7 8.75C7 9.1 7.12083 9.39583 7.3625 9.6375C7.60417 9.87917 7.9 10 8.25 10ZM1.5 10.5V9.1125C1.5 8.82917 1.57083 8.56667 1.7125 8.325C1.85417 8.08333 2.05 7.9 2.3 7.775C2.725 7.55833 3.20417 7.375 3.7375 7.225C4.27083 7.075 4.8625 7 5.5125 7C5.4125 7.15 5.32708 7.31042 5.25625 7.48125C5.18542 7.65208 5.12917 7.82917 5.0875 8.0125C4.5875 8.05417 4.14167 8.13958 3.75 8.26875C3.35833 8.39792 3.02917 8.53333 2.7625 8.675C2.67917 8.71667 2.61458 8.77708 2.56875 8.85625C2.52292 8.93542 2.5 9.02083 2.5 9.1125V9.5H5.0875C5.12917 9.68333 5.18542 9.85833 5.25625 10.025C5.32708 10.1917 5.4125 10.35 5.5125 10.5H1.5Z"
          fill="#6B8282"
        />
      </g>
    </svg>
  )
}

export default SearchUserOutlineExtraSmallIcon
