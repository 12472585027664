import { Link } from 'react-router-dom'
import { PUBLIC_ROUTES } from '../../../Routes/routes'
import XIcon from '../../../icons/XIcon'
import HutritIcon from '../../../icons/HutritIcon'
import { LINKEDIN_HUTRIT_URL } from '../../../utils/constants'
import LinkedinIcon from '../../../icons/LinkedinIcon'
import { useTranslation } from 'react-i18next'
import Translate from './Translate'

const NavbarDrawer = ({ setOpenDrawer = () => {} }) => {
  const { t } = useTranslation()

  return (
    <div className="navbar-drawer-container d-lg-none">
      <div className="d-flex justify-content-between px-5 pt-5 mb-5 pb-5">
        <HutritIcon />
        <div
          className="cursor-pointer"
          onClick={() => setOpenDrawer((prevState) => !prevState)}
        >
          <XIcon />
        </div>
      </div>
      <div className="d-flex flex-column justify-content-center gap-5">
        <div className="d-flex flex-column justify-content-center align-items-center gap-4 pb-5">
          <Link
            className="btn text-white"
            to={PUBLIC_ROUTES.login}
            onClick={() => setOpenDrawer((prevState) => !prevState)}
          >
            {t('log_in')}
          </Link>
          <Link
            className="btn text-white"
            to={PUBLIC_ROUTES.register}
            onClick={() => setOpenDrawer((prevState) => !prevState)}
          >
            {t('sign_up')}
          </Link>
          <Link className="btn text-white" to={PUBLIC_ROUTES.contact_us}>
            {t('contact_us')}
          </Link>
          <Translate />
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center pt-5">
          <span className="text-white mt-4">{t('follow_us_on')}</span>
          <div className="d-flex justify-content-center align-items-center gap-3">
            <a target="_blank" rel="noreferrer" href={LINKEDIN_HUTRIT_URL}>
              <LinkedinIcon />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavbarDrawer
