import { idiom } from '../localStorage'

export const TYPE_OF_USER_SERVICE = Object.freeze({
  recruiter: 'recruiter',
  company: 'company',
})

export const GRANT_TYPE = 'authorization_code'

export const LINKEDIN_RESPONSE_TYPE = 'code'

export const REDIRECT_URI = 'https://hutrit.com/login/'

export const REDIRECT_URI_CONTACT_FORM = 'https://hutrit.com/contact-us'

export const LINKEDIN_SCOPE = 'r_liteprofile%20r_emailaddress'

export const LINKEDIN_CLIENT_SECRET_ID = 'BGn5UqhrmIcRCwbS'

export const LINKEDIN_CLIENT_ID = '86jjyl1j8z1zr6'

export const LINKEDIN_OAUTH_URL =
  'https://www.linkedin.com/oauth/v2/authorization'

export const LINKEDIN_ACCES_TOKEN_URL =
  'https://www.linkedin.com/oauth/v2/accessToken'

export const GOOGLE_CLIENT_ID =
  '91193398945-a2f4vlk6p3lh2fh2gi989rbrqgoo64pu.apps.googleusercontent.com'

export const URL = `${LINKEDIN_OAUTH_URL}?client_id=${LINKEDIN_CLIENT_ID}&response_type=${LINKEDIN_RESPONSE_TYPE}&redirect_uri=${REDIRECT_URI}&scope=${LINKEDIN_SCOPE}`

export const BASE_URL = `${process.env.REACT_APP_BASE_URL_PROD}`

export const SOCKET_BASE_URL = `${process.env.REACT_APP_SOCKET_BASE_URL_PROD}`

export const WHATSAPP_API_URL =
  'https://api.whatsapp.com/send?phone=34640296736'

export const CALENDLY_API_URL = 'https://calendly.com/hutrit/30min'

export const BLOGGER_BASE_URL = `${process.env.REACT_APP_BLOGGER_BASE_URL}`
export const BLOGGER_API_KEY = 'AIzaSyCT8_OCa4sUIyJPhuiclcOdIEG9IipZVjw'
export const BLOGGER_ID = '5820104871593246817'

export const GOOGLE_ANALYTICS_COOKIES_POLICY = `https://support.google.com/analytics/answer/4597324?hl=${
  idiom ?? 'es'
}`
