const ArrowRigthIcon = ({ width = '16', height = '16' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <mask
        id="mask0_3038_877"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3038_877)">
        <path
          d="M6.26666 12L5.33333 11.0667L8.39999 8L5.33333 4.93333L6.26666 4L10.2667 8L6.26666 12Z"
          fill="#004949"
        />
      </g>
    </svg>
  )
}

export default ArrowRigthIcon
