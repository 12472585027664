import React, { useState } from 'react'

const Tooltip = ({
  className,
  text,
  position = 'bottom',
  children,
  style = {},
  textContainerClassName = '',
  isTooltipContainer = true,
  isTooltipCustomTwo = false,
  isJSX = false,
  RenderJSX = () => <></>,
}) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const handleMouseEnter = () => {
    setShowTooltip(true)
  }

  const handleMouseLeave = () => {
    setShowTooltip(false)
  }

  return (
    <div
      className={`${isTooltipContainer ? 'tooltip-container' : ''} ${className}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showTooltip && (
        <div
          className={`${isTooltipCustomTwo ? 'tooltip-custom-two' : `tooltip-custom-${position} tooltip-custom`}   text-center ${textContainerClassName}`}
          style={style}
        >
          {isJSX ? <RenderJSX /> : <span>{text}</span>}
        </div>
      )}
    </div>
  )
}

export default Tooltip
