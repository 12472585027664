import { IS_HIRING_ADVISOR } from '../../utils/constants'
import { BASE_URL } from '../constants'

const token = JSON.parse(localStorage.getItem('token'))

export const addFavorite = (data) => {
  const rawBody = JSON.stringify(data)

  const URL = `${BASE_URL}v2/addfavorites`

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getMyFavoritesTalents = ({ page = 0 }) => {
  const URL = `${BASE_URL}v2/getmyfavorites?page=${page}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res.favorites)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const deleteFavorite = (idOffer) => {
  const URL = `${BASE_URL}/removeFavorite`
  const rawBody = JSON.stringify({ idFavorite: idOffer })

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getFavoritesCandidatesByJobOffer = ({
  id,
  idCompany,
  page = 0,
}) => {
  const idCompanyAdapter = '&idCompanyAdapter='
  const emptyField = ''

  const validateRole = IS_HIRING_ADVISOR ? idCompanyAdapter : emptyField
  const renderId = id || 0
  const renderIdCompany = IS_HIRING_ADVISOR ? idCompany : emptyField

  const URL = `${BASE_URL}v2/getFavoritesOneOffer?idOffer=${renderId}${validateRole}${renderIdCompany}&page=${page}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.favorites)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const addMultipliesFavorites = (data) => {
  const rawBody = JSON.stringify(data)

  const URL = `${BASE_URL}user/v2/addOffersFavorite`

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const removeFavorite = (data) => {
  const rawBody = JSON.stringify(data)

  const URL = `${BASE_URL}user/v2/removeOffersFavorite`

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}
