const MagnifyingGlass = ({ fill = '#01A76A', bgFill = '#CFF2DF' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
    >
      <circle cx="24.7168" cy="24.0527" r="24" fill={bgFill} />
      <mask
        id="mask0_2451_49422"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="33"
        height="33"
      >
        <rect x="8.57666" y="8.05273" width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2451_49422)">
        <path
          d="M34.71 36.0527L26.31 27.6527C25.6433 28.1861 24.8767 28.6083 24.01 28.9194C23.1433 29.2305 22.2211 29.3861 21.2433 29.3861C18.8211 29.3861 16.7711 28.5472 15.0933 26.8694C13.4155 25.1916 12.5767 23.1416 12.5767 20.7194C12.5767 18.2972 13.4155 16.2472 15.0933 14.5694C16.7711 12.8916 18.8211 12.0527 21.2433 12.0527C23.6655 12.0527 25.7155 12.8916 27.3933 14.5694C29.0711 16.2472 29.91 18.2972 29.91 20.7194C29.91 21.6972 29.7544 22.6194 29.4433 23.4861C29.1322 24.3527 28.71 25.1194 28.1767 25.7861L36.5767 34.1861L34.71 36.0527ZM21.2433 26.7194C22.91 26.7194 24.3267 26.1361 25.4933 24.9694C26.66 23.8027 27.2433 22.3861 27.2433 20.7194C27.2433 19.0527 26.66 17.6361 25.4933 16.4694C24.3267 15.3027 22.91 14.7194 21.2433 14.7194C19.5767 14.7194 18.16 15.3027 16.9933 16.4694C15.8267 17.6361 15.2433 19.0527 15.2433 20.7194C15.2433 22.3861 15.8267 23.8027 16.9933 24.9694C18.16 26.1361 19.5767 26.7194 21.2433 26.7194ZM18.5767 24.7194L19.5767 21.4527L16.91 19.3194H20.1767L21.2433 16.0527L22.31 19.3194H25.5767L22.91 21.4527L23.91 24.7194L21.2433 22.6527L18.5767 24.7194Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default MagnifyingGlass
