const SearchIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="list/search">
        <mask
          id="mask0_8324_10476"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="17"
          height="16"
        >
          <rect
            id="Bounding box"
            x="0.84375"
            width="16"
            height="16"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask0_8324_10476)">
          <path
            id="icon"
            d="M13.9104 14L9.71042 9.8C9.37708 10.0667 8.99375 10.2778 8.56042 10.4333C8.12708 10.5889 7.66597 10.6667 7.17708 10.6667C5.96597 10.6667 4.94097 10.2472 4.10208 9.40833C3.26319 8.56944 2.84375 7.54444 2.84375 6.33333C2.84375 5.12222 3.26319 4.09722 4.10208 3.25833C4.94097 2.41944 5.96597 2 7.17708 2C8.38819 2 9.41319 2.41944 10.2521 3.25833C11.091 4.09722 11.5104 5.12222 11.5104 6.33333C11.5104 6.82222 11.4326 7.28333 11.2771 7.71667C11.1215 8.15 10.9104 8.53333 10.6438 8.86667L14.8438 13.0667L13.9104 14ZM7.17708 9.33333C8.01042 9.33333 8.71875 9.04167 9.30208 8.45833C9.88542 7.875 10.1771 7.16667 10.1771 6.33333C10.1771 5.5 9.88542 4.79167 9.30208 4.20833C8.71875 3.625 8.01042 3.33333 7.17708 3.33333C6.34375 3.33333 5.63542 3.625 5.05208 4.20833C4.46875 4.79167 4.17708 5.5 4.17708 6.33333C4.17708 7.16667 4.46875 7.875 5.05208 8.45833C5.63542 9.04167 6.34375 9.33333 7.17708 9.33333Z"
            fill="#004949"
          />
        </g>
      </g>
    </svg>
  )
}

export default SearchIcon
