// import { IS_TALENT } from '../../utils/constants'
// import ChatGptRobot from './components/ChatGptRobot'
import Footer from './components/Footer'
import Header from './components/Header'
import Menu from './components/Menu'

const Sidebar = ({ isAnimation = false, userData }) => {
  return (
    <section
      id="sidebar"
      className={`${
        isAnimation && 'sidebar-animation'
      } bg-bg-brand new-sidebar d-flex flex-column justify-content-between overflow-auto`}
    >
      <div className="p-3">
        {' '}
        <div className="mb-5">
          <Header />
        </div>
        <Menu userData={userData} />
        {/* {IS_TALENT && <ChatGptRobot />} */}
      </div>

      <Footer />
    </section>
  )
}

export default Sidebar
