export const PDF_PALET_OF_COLORS = {
  bg_page: '#E4E4E4',
  bg_primary: '#FAFAFA',
  on_bg_primary: '#004949',
  bg_cta_primary: '#00CF83',
  bg_tertiary: '#e4f8ed',
  on_bg_tertiary: '#B5C9C9',
  bg_brand_hover: '#74a4a4',
  on_bg_secondary: '#6B8282',
  bg_brand: '#004949',
  black: '#000000',
  white: '#ffffff',
  cta_primary_pressed: '#17AF78',
  test: '#bdf2d5',
}
