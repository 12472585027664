const HouseAndBuildingIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="list/home_work">
        <mask
          id="mask0_9174_6763"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="17"
          height="17"
        >
          <rect
            id="Bounding box"
            x="0.880859"
            y="0.626953"
            width="16"
            height="16"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask0_9174_6763)">
          <path
            id="icon"
            d="M12.2143 6.62695H13.5476V5.29362H12.2143V6.62695ZM12.2143 9.29362H13.5476V7.96029H12.2143V9.29362ZM12.2143 11.9603H13.5476V10.627H12.2143V11.9603ZM12.2143 14.627V13.2936H14.8809V3.96029H8.88094V4.89362L7.54761 3.92695V2.62695H16.2143V14.627H12.2143ZM1.54761 14.627V7.96029L6.21427 4.62695L10.8809 7.96029V14.627H6.88094V11.2936H5.54761V14.627H1.54761ZM2.88094 13.2936H4.21427V9.96029H8.21427V13.2936H9.54761V8.62695L6.21427 6.26029L2.88094 8.62695V13.2936Z"
            fill="#E4F8ED"
          />
        </g>
      </g>
    </svg>
  )
}

export default HouseAndBuildingIcon
