const HutritIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={105}
    height={29}
    fill="none"
    {...props}
  >
    <path
      fill="#FAFAFA"
      d="M22.385 28.623h-6.253v-8.186c-3.236 1.415-6.433 1.423-9.673.007v8.16H.179V8.258h6.26v.592c0 2.059.01 4.117-.009 6.176 0 .39.098.6.475.76 2.887 1.23 6.744 1.133 9.226-.256v-7.27h6.254v20.364ZM40.782 8.241h6.242v20.366H40.79V25.91c-1.116 1.383-2.398 2.228-3.944 2.654-3.714 1.02-7.384-.432-9.084-3.605a9.187 9.187 0 0 1-1.061-4.297c-.037-3.91-.012-7.82-.012-11.728v-.667h6.233v.62c0 3.418-.005 6.837 0 10.255 0 1.047.139 2.073.774 2.957.937 1.299 2.668 1.83 4.36 1.346 1.544-.44 2.564-1.715 2.66-3.419.06-1.11.055-2.226.057-3.339.008-2.607 0-5.214 0-7.82l.01-.626ZM52.514 13.435h-2.46V8.24h2.444V3.264h6.25v4.943h4.117v5.202h-4.098v7.798c0 1.656.428 2.09 2.063 2.09h2.061v5.352c-2.18-.073-4.374.236-6.493-.52-2.283-.81-3.495-2.487-3.76-4.842-.124-1.124-.11-2.258-.124-3.391-.012-2.13 0-4.262 0-6.46ZM104.16 28.65c-2-.074-4.003.185-5.966-.36-2.534-.7-4.041-2.444-4.3-5.08-.104-1.067-.113-2.147-.113-3.221-.02-2.154-.01-4.307-.015-6.46 0-.015-.022-.029-.073-.091h-2.392v-5.18h2.437V3.271h6.303v4.934h4.072v5.213h-4.052v8.047c0 1.35.487 1.825 1.855 1.828h2.244v5.357ZM72.634 28.623h-6.243V8.256h6.246v3.268c1.647-2.233 3.657-3.513 6.375-3.532v6.672c-.776 0-1.527-.011-2.277 0-.32.011-.639.049-.952.113-1.999.391-2.991 1.492-3.095 3.532-.064 1.336-.05 2.678-.056 4.018-.008 1.888 0 3.776 0 5.667l.002.629ZM88.257 8.266v20.341h-6.234V8.266h6.234ZM82.017 6.39V.154h6.211v6.24l-6.21-.006ZM19.265 6.394a3.14 3.14 0 1 1 2.922-1.947 3.132 3.132 0 0 1-2.922 1.947ZM3.312 6.395A3.14 3.14 0 1 1 3.29.114a3.14 3.14 0 0 1 .022 6.28Z"
    />
  </svg>
)
export default HutritIcon
