const Xicon = ({ width = '13', height = '13', fill = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <mask
      id="a"
      width={12}
      height={13}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill="#D9D9D9" d="M0 .5h12v12H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill={fill}
        d="m3.2 10-.7-.7 2.8-2.8-2.8-2.8.7-.7L6 5.8 8.8 3l.7.7-2.8 2.8 2.8 2.8-.7.7L6 7.2 3.2 10Z"
      />
    </g>
  </svg>
)
export default Xicon
