const LogOutIcon = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
    >
      <g id="list/logout">
        <mask
          id="mask0_8245_7085"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="17"
          height="17"
        >
          <rect
            id="Bounding box"
            x="0.568359"
            y="0.0419922"
            width="16"
            height="16"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask0_8245_7085)">
          <path
            id="icon"
            d="M3.90169 14.042C3.53503 14.042 3.22114 13.9114 2.96003 13.6503C2.69891 13.3892 2.56836 13.0753 2.56836 12.7087V3.37533C2.56836 3.00866 2.69891 2.69477 2.96003 2.43366C3.22114 2.17255 3.53503 2.04199 3.90169 2.04199H8.56836V3.37533H3.90169V12.7087H8.56836V14.042H3.90169ZM11.235 11.3753L10.3184 10.4087L12.0184 8.70866H6.56836V7.37533H12.0184L10.3184 5.67533L11.235 4.70866L14.5684 8.04199L11.235 11.3753Z"
            fill="#74A4A4"
          />
        </g>
      </g>
    </svg>
  )
}

export default LogOutIcon
