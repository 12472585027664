const Circle = ({
  className = '',
  classNameText = 'text-white',
  children,
  width = '34px',
  height = '34px',
  renderIcon = false,
}) => {
  return (
    <div
      id="circle"
      style={{ width, height }}
      className={`${className} rounded rounded-pill d-flex justify-content-center align-items-center`}
    >
      {renderIcon ? (
        children
      ) : (
        <span className={classNameText}>{children}</span>
      )}
    </div>
  )
}

export default Circle
