import Skeleton from 'react-loading-skeleton'

const ProfileSkeleton = () => {
  return (
    <div className="profile-container d-flex align-items-center gap-1">
      <Skeleton
        style={{
          width: '100px',
          height: '24px',
        }}
      />
      <Skeleton circle width={25} height={25}/>
      <Skeleton circle width={25} height={25}/>
    </div>
  )
}

export default ProfileSkeleton
