import { createContext, useReducer } from 'react'
import { initialState } from './initialState'
import GlobalReducer from './GlobalReducer'

const GlobalContext = createContext()

const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(GlobalReducer, initialState)

  return (
    <GlobalContext.Provider value={[state, dispatch]}>
      {children}
    </GlobalContext.Provider>
  )
}

export { GlobalContext }
export default GlobalStateProvider
