const LogOutTwoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <circle cx="24" cy="24" r="24" fill="#FEEFC6" />
      <mask
        id="mask0_3936_34908"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="32"
        height="32"
      >
        <rect x="8" y="8" width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3936_34908)">
        <path
          d="M12 36V33.3333H14.6667V14.6667C14.6667 13.9333 14.9278 13.3056 15.45 12.7833C15.9722 12.2611 16.6 12 17.3333 12H30.6667C31.4 12 32.0278 12.2611 32.55 12.7833C33.0722 13.3056 33.3333 13.9333 33.3333 14.6667V33.3333H36V36H12ZM17.3333 33.3333H30.6667V14.6667H17.3333V33.3333ZM21.3333 25.3333C21.7111 25.3333 22.0278 25.2056 22.2833 24.95C22.5389 24.6944 22.6667 24.3778 22.6667 24C22.6667 23.6222 22.5389 23.3056 22.2833 23.05C22.0278 22.7944 21.7111 22.6667 21.3333 22.6667C20.9556 22.6667 20.6389 22.7944 20.3833 23.05C20.1278 23.3056 20 23.6222 20 24C20 24.3778 20.1278 24.6944 20.3833 24.95C20.6389 25.2056 20.9556 25.3333 21.3333 25.3333Z"
          fill="#D9650C"
        />
      </g>
    </svg>
  )
}

export default LogOutTwoIcon
