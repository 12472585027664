const OfflineIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <circle
        cx="6"
        cy="6"
        r="5.64706"
        fill="#B5C9C9"
        stroke="#004949"
        strokeWidth="0.705882"
      />
    </svg>
  )
}

export default OfflineIcon
