import { BASE_URL } from '../constants'
const token = JSON.parse(localStorage.getItem('token'))

export const getNewNotificationsList = () => {
  const URL = `${BASE_URL}user/getMyNotifications`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res.notifications || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const hideNewNotificationsList = (data) => {
  const URL = `${BASE_URL}user/setViewNotifications`

  const rawBody = JSON.stringify(data)

  try {
    const response = fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getRecruiterNotifications = () => {
  const URL = `${BASE_URL}/notificationsRecruiter`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res.offer)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getAllNotifications = ({ page }) => {
  const URL = `${BASE_URL}user/getAllMyNotifications?page=${page}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res || [])

    return response
  } catch (error) {
    console.log(error)
  }
}
