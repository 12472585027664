import { BASE_URL } from '../constants'
import { token } from '../../localStorage/index'

export const getProfileUser = () => {
  const URL = `${BASE_URL}profileuser`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.profileUser)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getTalentDetail = (id) => {
  const PUBLIC_URL = 'getprofileTalentnottoken/'
  const PRIVATE_URL = 'getprofileTalent/'

  const URL = `${BASE_URL}${
    token?.token === undefined ? PUBLIC_URL : PRIVATE_URL
  }${id}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res.talent)
    return response
  } catch (error) {
    console.log(error)
  }
}

export const changeUsernameFree = async (data) => {
  const URL = `${BASE_URL}user/changeUsernameFree`

  const rawBody = JSON.stringify(data)

  try {
    const res = await fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })
    const getData = await res.json()

    return { res, getData }
  } catch (error) {
    console.log(error)
  }
}
