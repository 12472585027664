import ArrowDownSmallIcon from '../icons/ArrowDownSmallIcon'
import Button from './Button'

const ButtonSelect = ({
  text = '',
  textClassName = '',
  className = '',
  arrowFill = '#004949',
  ...rest
}) => {
  return (
    <div className="d-flex align-items-center position-relative border-1">
      <Button
        className={`btn-white border border-on-bg-Tertiary text-on-bg-secondary d-flex align-items-center justify-content-between ${className}`}
        {...rest}
      >
        <span className={`${textClassName} me-2`}>{text}</span>
        <ArrowDownSmallIcon fill={arrowFill} />
      </Button>
    </div>
  )
}

export default ButtonSelect
