import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import Header from './components/Header'
import ListOfChats from './components/ListOfChats'
import { TAGS } from '../../services/Tags'
import { getProfileUser } from '../../services/MyProfile'
import { IS_COMPANY, IS_RECRUITER, IS_TALENT } from '../../utils/constants'
import ChatDetail from './components/ChatDetail'
import { useChatStore } from '../../store'
import {
  listOfChats as listOfChatsService,
  setStateViewdMsg,
} from '../../services/Chat'

const Chat = () => {
  const queryClient = useQueryClient()
  const { id, detailData, setDetailData } = useChatStore()

  const { data: profileUserData = {}, isLoading: isLoadingProfileUser } =
    useQuery([TAGS.profile_data], getProfileUser, {
      enabled: IS_TALENT || IS_COMPANY || IS_RECRUITER,
    })

  const { data: listOfChatsData } = useQuery(
    [TAGS.list_of_chats],
    listOfChatsService
  )

  const { mutate: setStateViewdMsgMutate } = useMutation(
    [TAGS.state_view_msg],
    setStateViewdMsg,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(TAGS.state_view_msg)
      },
    }
  )
  return (
    <div className="position-fixed chat-container bg-bg-tertiary">
      <Header detailData={detailData} />
      {id ? (
        <ChatDetail
          detailData={detailData}
          setStateViewdMsgMutate={setStateViewdMsgMutate}
        />
      ) : (
        <ListOfChats
          profileUserData={profileUserData}
          isLoadingProfileUser={isLoadingProfileUser}
          setDetailData={setDetailData}
          setStateViewdMsgMutate={setStateViewdMsgMutate}
          listOfChatsData={listOfChatsData}
        />
      )}
    </div>
  )
}

export default Chat
