import dayjs from 'dayjs'
import HutritIcon from '../icons/HutritIcon'
import { Link, useNavigate } from 'react-router-dom'
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from '../Routes/routes'
import { useTranslation } from 'react-i18next'

import LinkedinIcon from '../icons/LinkedinIcon'
import { LINKEDIN_HUTRIT_URL } from '../utils/constants'

const Footer = () => {
  const { t } = useTranslation()
  const today = dayjs().year()
  const navigate = useNavigate()

  const handleGoToTermsAndConditions = () => {
    navigate(PUBLIC_ROUTES.terms_and_conditions)
  }

  return (
    <footer
      className={`${
        window.location.pathname === PRIVATE_ROUTES.talents ? 'd-none' : ''
      } footer bg-bg-brand-pressed z-index-footer`}
    >
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="mb-5 mt-5">
          <HutritIcon />
        </div>
        <div className="d-flex justify-content-center align-items-center gap-1 mb-4">
          <Link className="btn text-white" to={PUBLIC_ROUTES.login}>
            {t('log_in')}
          </Link>
          <Link className="btn text-white" to={PUBLIC_ROUTES.register}>
            {t('sign_up')}
          </Link>
          <Link className="btn text-white" to={PUBLIC_ROUTES.contact_us}>
            {t('contact_us')}
          </Link>
        </div>
        <div className="mb-5 d-flex flex-column justify-content-center align-items-center">
          <span className="text-white mt-4">{t('follow_us_on')}</span>
          <div className="d-flex justify-content-center align-items-center gap-3">
            <a target="_blank" rel="noreferrer" href={LINKEDIN_HUTRIT_URL}>
              <LinkedinIcon />
            </a>
          </div>
        </div>
        <div className="mb-4 mt-4 d-flex justify-content-center align-items-center gap-3 gap-lg-2 text-white w-100">
          <p className="font-extra-small">
            &copy;Copyright <span id="copyright"> {today.toString()}</span>{' '}
            Hutrit
          </p>

          <p className="font-extra-small">{t('privacy')}</p>
          <p
            className="font-extra-small text-decoration-underline cursor-pointer"
            onClick={handleGoToTermsAndConditions}
          >
            {t('terms_and_conditions')}
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
