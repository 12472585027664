const ProcessIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <mask
        id="mask0_5114_6046"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="25"
      >
        <rect y="0.772949" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5114_6046)">
        <path
          d="M15 21.7729V18.7729H11V8.77295H9V11.7729H2V3.77295H9V6.77295H15V3.77295H22V11.7729H15V8.77295H13V16.7729H15V13.7729H22V21.7729H15ZM17 9.77295H20V5.77295H17V9.77295ZM17 19.7729H20V15.7729H17V19.7729ZM4 9.77295H7V5.77295H4V9.77295Z"
          fill="#E4F8ED"
        />
      </g>
    </svg>
  )
}

export default ProcessIcon
