import { useQuery, useQueryClient } from '@tanstack/react-query'
import { chatDetail, messageHistory } from '../../../services/Chat'
import { TAGS } from '../../../services/Tags'
import { useChatStore } from '../../../store'
import Loader from '../../../components/Loader'
import { useEffect, useState } from 'react'
import SendMessage from './SendMessage'
import { useForm } from 'react-hook-form'
import { SOCKET, SOCKET_VALUES } from '../../../socket'
import {
  IS_COMPANY,
  IS_RECRUITER,
  IS_TALENT,
  TYPE_PROFILE,
} from '../../../utils/constants'
import { getProfileUser } from '../../../services/MyProfile'
import ProfileIcon from '../../../icons/chat/ProfileIcon'
import DateCounter from './DateCounter'

import InfiniteScroll from 'react-infinite-scroll-component'

import WaitResponseIcon from '../../../icons/chat/WaitResponseIcon'
import WaitResponseIconTwo from '../../../icons/chat/WaitResponseIconTwo'
import { useTranslation } from 'react-i18next'

const initialValues = {
  msg: '',
}

const ChatDetail = ({ setStateViewdMsgMutate }) => {
  const { t } = useTranslation()

  const {
    id,
    setDetailData,
    currentMessageHistoryData,
    setCurrentMessageHistoryData,
    detailData,
  } = useChatStore()

  const queryClient = useQueryClient()
  const [page, setPage] = useState(0)
  const [hasMore, setHasMore] = useState(true)

  const { register, handleSubmit, reset, watch } = useForm({
    defaultValues: initialValues,
  })

  const { msg } = watch()

  const { user = {} } = detailData?.chat_users?.[0] || []
  const { names = '', type_profile = '' } = user || {}

  const {
    data: chatDetailData,
    isInitialLoading: isLoadingChatDetail,
    isSuccess,
  } = useQuery([TAGS.chat_detail, id], () => chatDetail({ id }), {
    enabled: Boolean(id),
  })

  const { data: profileUserData, isInitialLoading: isLoadingProfileUserData } =
    useQuery([TAGS.profile_user], getProfileUser, {
      enabled: IS_TALENT || IS_COMPANY || IS_RECRUITER,
    })

  const { data: messageHistoryData, isSuccess: isSuccessMessageHistory } =
    useQuery(
      [TAGS.message_history, page, id],
      () => messageHistory({ page, id }),
      {
        enabled: Boolean(id),
      }
    )

  const showElements =
    page === 0 ? messageHistoryData : currentMessageHistoryData

  const waitResponseForRecruiter =
    type_profile === TYPE_PROFILE?.recruiter &&
    IS_TALENT &&
    showElements?.length === 0
  const waitResponseForCompany =
    type_profile !== TYPE_PROFILE?.recruiter &&
    IS_TALENT &&
    showElements?.length === 0

  const handleRestorePage = () => setPage(0)

  const onSubmit = async () => {
    handleRestorePage()
    SOCKET.emit(SOCKET_VALUES.sendMessage, {
      msg,
      idChat: chatDetailData?.id,
    })
    queryClient.invalidateQueries(TAGS.message_history)
    reset()
  }

  const handleShowMoreMessage = () => {
    if (!hasMore) {
      return
    }

    if (hasMore) {
      setPage((prevPage) => prevPage + 1)
    }
  }

  useEffect(() => {
    setDetailData(chatDetailData)
  }, [isSuccess, id])

  useEffect(() => {
    if (page === 0) {
      setCurrentMessageHistoryData(messageHistoryData)
    }

    if (isSuccessMessageHistory && page !== 0) {
      setCurrentMessageHistoryData([
        ...currentMessageHistoryData,
        ...messageHistoryData,
      ])
    }
  }, [isSuccessMessageHistory, page])

  useEffect(() => {
    if (messageHistoryData?.length === 0 && page > 0) {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
  }, [messageHistoryData])

  useEffect(() => {
    id !== null &&
      setStateViewdMsgMutate({
        idChat: id,
      })
  }, [])

  return (
    <div
      className={`${
        isLoadingChatDetail ? '' : 'h-100'
      } w-100  d-flex flex-column justify-content-end p-2`}
    >
      {isLoadingChatDetail || isLoadingProfileUserData ? (
        <div className="d-flex justify-content-center align-items-start">
          <Loader />
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          {waitResponseForCompany && (
            <div className="text-on-bg-primary text-center d-flex flex-column justify-content-center align-items-center mb-5 pb-2 px-3">
              <WaitResponseIcon />
              <div className="d-flex flex-column align-items-center">
                <span className="fw-bolder">
                  {t('inn_this_space_you_can_communicate')} <br /> {t('with')}{' '}
                  {type_profile === TYPE_PROFILE?.recruiter
                    ? `${t('he')}/${t('recruiter')}`
                    : t('the_company')}
                  {names}
                </span>
                <span>
                  {t(
                    'this_space_was_created_so_that_a_manager_or_a_company_can_establish_a_conversation_with_you'
                  )}
                </span>
              </div>
            </div>
          )}

          {waitResponseForRecruiter && (
            <div className="text-on-bg-primary text-center d-flex flex-column justify-content-center align-items-center mb-5 pb-2 px-3">
              <WaitResponseIconTwo />
            </div>
          )}

          {!waitResponseForCompany && !waitResponseForRecruiter && (
            <div
              id="scrollableDiv"
              style={{
                height: '415px',
                display: 'flex',
                flexDirection: 'column-reverse',
                overflow: 'auto',
              }}
            >
              <InfiniteScroll
                className="gap-1 p-2"
                dataLength={showElements?.length || 100000}
                next={handleShowMoreMessage}
                style={{
                  display: 'flex',
                  flexDirection: 'column-reverse',
                  overflow: 'hidden',
                }}
                inverse={true}
                hasMore={hasMore}
                loader={showElements?.length > 10 ? <>{t('charging')}</> : ''}
                endMessage=""
                scrollableTarget="scrollableDiv"
              >
                {showElements?.map(({ msg, id, user, send_date }) => {
                  const itsMe =
                    user?.username === profileUserData?.userInfo?.user?.username

                  return (
                    <div
                      key={id}
                      className={`${
                        itsMe ? 'justify-content-end' : 'justify-content-start'
                      } text-on-bg-primary d-flex align-items-start gap-1 font-small`}
                    >
                      {itsMe ? (
                        <>
                          <span
                            key={id}
                            className="text-on-bg-primary border rounded bg-on-bg-Tertiary-hover p-2"
                            style={{ width: 'auto' }}
                          >
                            {msg}
                            <DateCounter date={send_date} />
                          </span>
                          <span className="text-on-bg-primary font-extra-small">
                            {t('you')}
                          </span>
                        </>
                      ) : (
                        <>
                          <div>
                            <ProfileIcon />
                          </div>
                          <span
                            key={id}
                            className="text-on-bg-primary border rounded bg-on-bg-Tertiary-hover p-2"
                            style={{ width: 'auto' }}
                          >
                            {msg}
                            <DateCounter date={send_date} />
                          </span>
                        </>
                      )}
                    </div>
                  )
                })}
              </InfiniteScroll>
            </div>
          )}
          <SendMessage
            register={register}
            onSubmit={onSubmit}
            msg={msg}
            waitResponse={waitResponseForCompany}
          />
        </form>
      )}
    </div>
  )
}

export default ChatDetail
