import { BASE_URL } from '../constants'

const token = JSON.parse(localStorage.getItem('token'))

export const updateCompanyData = (data) => {
  const rawBody = JSON.stringify(data)

  const URL = `${BASE_URL}company/register-all-info`

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const setCompanyData = (data) => {
  const rawBody = JSON.stringify(data)

  const URL = `${BASE_URL}company/profileUpdate`

  try {
    const response = fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const uploadIcon = (data) => {
  const URL = `${BASE_URL}/addImgUser`

  const formData = new FormData()
  formData.append('img', data)

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        authorization: token?.token,
      },
      body: formData,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getCompanySectors = () => {
  const URL = `${BASE_URL}/getCompanysectors`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((res) => res.sectors)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getCompanyInformation = (companyId) => {
  const URL = `${BASE_URL}/getinfocompany/${companyId}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.company || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const sendOffersFromMatch = async (data) => {
  const rawBody = JSON.stringify(data)

  const URL = `${BASE_URL}/sendoffermatch`

  try {
    const response = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    }).then()
    const res = await response.json()
    return {
      response,
      res,
    }
  } catch (error) {
    console.log(error)
  }
}

export const getListOfCandidates = ({ id = 0, status = 1 }) => {
  const URL = `${BASE_URL}user/getOffersSentFilterStatus?page=0&idStatus=${status}&idOffer=${id}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.offerTalents || [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const requestAcces = async (data) => {
  const rawBody = JSON.stringify(data)

  const URL = `${BASE_URL}quickCompanies/create`

  try {
    const response = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    }).then()
    const res = await response.json()
    return {
      response,
      res,
    }
  } catch (error) {
    console.log(error)
  }
}

export const getListOfOrderCompanies = ({
  page = 0,
  searchText = '',
  companiesAuthorized = false,
}) => {
  const URL = `${BASE_URL}master/listQuickCompanies?page=${page}&searchByText=${searchText}&companiesAuthorized=${companiesAuthorized}`
  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.companies ?? [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getAccountantsQuickCompanies = () => {
  const URL = `${BASE_URL}master/accountantsQuickCompanies`
  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.accountants ?? [])

    return response
  } catch (error) {
    console.log(error)
  }
}

export const updatePersonalCompanyData = (data) => {
  const rawBody = JSON.stringify(data)

  const URL = `${BASE_URL}user/profileUpdate`

  try {
    const response = fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const resetCompanyData = (data) => {
  const rawBody = JSON.stringify({
    password: data.password,
    username: data.username,
  })

  const URL = `${BASE_URL}quickCompanies/setUser`

  try {
    const response = fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        token_auth: data?.token_auth,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}
