import {
  CHANGE_DASHBOARD_BY_COMPANY_COMPONENT,
  CHANGE_DASHBOARD_COMPONENT,
  GET_JOB_OFFER_ID,
  GET_RECRUITER_ID,
  GET_TALENT_ID,
  UPDATE_OFFER_MANAGEMENT_ID,
  UPDATE_OFFER_MANAGEMENT_ID_FROM_MATCH_VIEW,
  UPDATE_PREV_ROUTE,
  UPDATE_TYPE_OF_REGISTER,
} from './actionTypes'

const GlobalReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_OFFER_MANAGEMENT_ID: {
      const { offerId, isEdit, showDetail, showMatch } = action.payload
      return { offerId, isEdit, showDetail, showMatch }
    }
    case GET_TALENT_ID: {
      const { talentId } = action.payload
      return { talentId }
    }
    case GET_RECRUITER_ID: {
      const { recruiterId } = action.payload
      return { recruiterId }
    }
    case GET_JOB_OFFER_ID: {
      const { jobOfferId } = action.payload
      return { jobOfferId }
    }
    case UPDATE_TYPE_OF_REGISTER: {
      const { typeOfRegister } = action.payload
      return { typeOfRegister }
    }
    case UPDATE_OFFER_MANAGEMENT_ID_FROM_MATCH_VIEW: {
      const { idOffer, idFavoriteOffer } = action.payload
      return { idOffer, idFavoriteOffer }
    }
    case UPDATE_PREV_ROUTE: {
      const { prevRoute, offerId } = action.payload
      return { prevRoute, offerId }
    }
    case CHANGE_DASHBOARD_COMPONENT: {
      const { label } = action.payload
      return { label }
    }
    case CHANGE_DASHBOARD_BY_COMPANY_COMPONENT: {
      const { renderComponentInCompany } = action.payload
      return { renderComponentInCompany }
    }
    default:
      return
  }
}

export default GlobalReducer
