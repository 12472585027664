import Swal from 'sweetalert2'

const Toast = ({
  title = 'Success',
  icon = 'success',
  timer = 5000,
  ...rest
}) => {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
    ...rest,
  })
  return Toast.fire({
    icon,
    title,
  })
}

export default Toast
