import { useTranslation } from 'react-i18next'

const ITEMS_PER_PAGE = 15

const Pagination = ({
  totalItems = 0,
  page = 0,
  setPage = () => {},
  itemsPerPage = ITEMS_PER_PAGE,
  isGlobalState = false,
  className = '',
}) => {
  const { t } = useTranslation()
  const totalPages = Math.ceil(totalItems / itemsPerPage)

  const items = Array.from({ length: totalPages }, (_, index) => index)

  const handleNextPage = () => {
    isGlobalState ? setPage(page + 1) : setPage((prevPage) => prevPage + 1)
  }

  const handlePrevPage = () => {
    isGlobalState ? setPage(page - 1) : setPage((prevPage) => prevPage - 1)
  }

  const handleSelectItem = ({ items = 0 }) => {
    setPage(items)
  }

  return (
    <nav aria-label="Page navigation example">
      <ul className={`pagination ${className}`}>
        <li className="page-item">
          <button
            type="button"
            disabled={page === 0}
            className="page-link text-on-bg-primary"
            onClick={handlePrevPage}
          >
            {t('former')}
          </button>
        </li>
        {items?.map((items, index) => (
          <li key={index} className="page-item ">
            <button
              className={`${
                items === page
                  ? 'text-white bg-on-bg-primary'
                  : 'text-on-bg-primary'
              } page-link`}
              onClick={() => handleSelectItem({ items })}
            >
              {items + 1}
            </button>
          </li>
        ))}
        <li className="page-item">
          <button
            type="button"
            className="page-link text-on-bg-primary"
            onClick={handleNextPage}
            disabled={page === items?.length - 1}
          >
            {t('next')}
          </button>
        </li>
      </ul>
    </nav>
  )
}

export default Pagination
