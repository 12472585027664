const ArrowLeftIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="mask0_5879_6794"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5879_6794)">
        <path
          d="M14.2998 18L8.2998 12L14.2998 6L15.6998 7.4L11.0998 12L15.6998 16.6L14.2998 18Z"
          fill="#004949"
        />
      </g>
    </svg>
  )
}

export default ArrowLeftIcon
