import Button from './Button'

const EmptyState = ({
  onClick = () => {},
  src = '',
  buttonText = '',
  imgWidth = 0,
  imgHeight = 0,
  title = '',
  description = '',
  showButton = true,
}) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center text-on-bg-primary gap-2 mt-3">
      <img src={src} width={imgWidth} height={imgHeight} />
      <span className="fw-bolder fs-4">{title}</span>
      <span className="w-75 text-center">{description}</span>
      {showButton && <Button onClick={onClick}>{buttonText}</Button>}
    </div>
  )
}

export default EmptyState
