const Europe = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="_Flags/european union">
        <path
          id="Vector"
          d="M8.49992 14.6668C12.1818 14.6668 15.1666 11.6821 15.1666 8.00016C15.1666 4.31826 12.1818 1.3335 8.49992 1.3335C4.81802 1.3335 1.83325 4.31826 1.83325 8.00016C1.83325 11.6821 4.81802 14.6668 8.49992 14.6668Z"
          fill="#0052B4"
        />
        <g id="Group">
          <path
            id="Vector_2"
            d="M8.50002 3.94189L8.71588 4.60619H9.41431L8.84924 5.01674L9.06509 5.68104L8.50002 5.27046L7.93491 5.68104L8.15077 5.01674L7.58569 4.60619H8.28416L8.50002 3.94189Z"
            fill="#FFDA44"
          />
          <path
            id="Vector_3"
            d="M5.6306 5.13038L6.253 5.44747L6.74688 4.95361L6.63758 5.64346L7.25995 5.96057L6.57008 6.06984L6.46081 6.75973L6.1437 6.13736L5.45386 6.24666L5.94774 5.75278L5.6306 5.13038Z"
            fill="#FFDA44"
          />
          <path
            id="Vector_4"
            d="M4.44214 7.99977L5.10644 7.78391V7.08545L5.51696 7.65055L6.18128 7.43469L5.77068 7.99977L6.18128 8.56485L5.51696 8.34902L5.10644 8.9141V8.21563L4.44214 7.99977Z"
            fill="#FFDA44"
          />
          <path
            id="Vector_5"
            d="M5.6306 10.8691L5.94771 10.2467L5.45386 9.75279L6.14373 9.86212L6.46078 9.23975L6.57008 9.92962L7.25993 10.0389L6.63763 10.356L6.74688 11.0458L6.253 10.552L5.6306 10.8691Z"
            fill="#FFDA44"
          />
          <path
            id="Vector_6"
            d="M8.50002 12.0579L8.28413 11.3936H7.58569L8.1508 10.9831L7.93491 10.3188L8.50002 10.7294L9.06509 10.3188L8.84924 10.9831L9.41431 11.3936H8.71585L8.50002 12.0579Z"
            fill="#FFDA44"
          />
          <path
            id="Vector_7"
            d="M11.3695 10.8691L10.7472 10.552L10.2533 11.0459L10.3626 10.356L9.74023 10.0389L10.4301 9.92962L10.5393 9.23975L10.8564 9.86212L11.5463 9.75279L11.0524 10.2467L11.3695 10.8691Z"
            fill="#FFDA44"
          />
          <path
            id="Vector_8"
            d="M12.5579 7.99977L11.8936 8.21563V8.9141L11.4831 8.34899L10.8188 8.56485L11.2294 7.99977L10.8188 7.43469L11.4831 7.65055L11.8936 7.08545V7.78394L12.5579 7.99977Z"
            fill="#FFDA44"
          />
          <path
            id="Vector_9"
            d="M11.3695 5.13036L11.0524 5.75275L11.5463 6.24663L10.8564 6.13731L10.5393 6.75968L10.4301 6.06981L9.74023 5.96051L10.3626 5.64343L10.2533 4.95361L10.7472 5.44747L11.3695 5.13036Z"
            fill="#FFDA44"
          />
        </g>
      </g>
    </svg>
  )
}

export default Europe
