const LinkedinInformationIcon = () => {
  return (
    <svg
      width="237"
      height="154"
      viewBox="0 0 237 154"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M220.37 94.4297C220.37 94.4297 219.493 100.718 218.113 107.828C217.311 111.989 211.231 114.34 207.165 115.497C212.76 115.684 216.714 119.919 218.934 124.342C221.153 128.764 221.843 138.822 221.843 138.822C221.843 138.822 222.907 126.562 226.32 121.636C228.427 118.595 233.258 116.206 236.634 114.751C225.574 114.321 221.638 103.853 220.37 94.4297Z"
        fill="#77C4C3"
      />
      <path
        d="M202.205 0.845215C202.205 0.845215 201.552 5.56622 200.508 10.8844C199.911 14.0006 195.342 15.7733 192.302 16.6503C196.498 16.781 199.464 19.9532 201.123 23.2747C202.783 26.5962 203.306 34.1349 203.306 34.1349C203.306 34.1349 204.108 24.9354 206.663 21.2407C208.248 18.9642 211.867 17.1728 214.403 16.0905C206.103 15.7547 203.157 7.9174 202.205 0.845215Z"
        fill="#77C4C3"
      />
      <path
        d="M11.9466 23.2964C11.9466 23.2964 11.1633 28.8944 9.951 35.0336C9.24226 38.7656 3.92664 40.7249 0.364258 41.7513C5.26952 41.9192 8.71999 45.6326 10.6784 49.5139C12.6367 53.3952 13.2336 62.2028 13.2336 62.2028C13.2336 62.2028 14.1661 51.4359 17.1503 47.144C19.0155 44.4756 23.2493 42.3857 26.1962 41.0982C16.4976 40.7063 13.1403 31.5442 11.9466 23.2964Z"
        fill="#77C4C3"
      />
      <path
        d="M112.148 10.165C111.272 10.165 110.416 10.4246 109.688 10.9111C108.96 11.3976 108.393 12.0891 108.058 12.8981C107.722 13.7071 107.635 14.5973 107.806 15.4561C107.976 16.3149 108.398 17.1038 109.017 17.723C109.636 18.3422 110.425 18.7638 111.284 18.9347C112.143 19.1055 113.033 19.0178 113.842 18.6827C114.651 18.3476 115.343 17.7802 115.829 17.0521C116.316 16.324 116.575 15.468 116.575 14.5924C116.575 13.4181 116.109 12.292 115.279 11.4617C114.448 10.6314 113.322 10.165 112.148 10.165ZM112.148 14.1641C111.894 14.1641 111.645 14.0887 111.434 13.9475C111.223 13.8063 111.058 13.6057 110.961 13.3709C110.864 13.1361 110.838 12.8777 110.888 12.6285C110.937 12.3792 111.06 12.1503 111.239 11.9706C111.419 11.7909 111.648 11.6685 111.897 11.6189C112.146 11.5694 112.405 11.5948 112.64 11.6921C112.874 11.7893 113.075 11.954 113.216 12.1653C113.357 12.3766 113.433 12.625 113.433 12.8792C113.433 13.0479 113.4 13.215 113.335 13.3709C113.27 13.5268 113.176 13.6684 113.056 13.7877C112.937 13.907 112.796 14.0017 112.64 14.0663C112.484 14.1308 112.317 14.1641 112.148 14.1641Z"
        fill="#01A76A"
      />
      <path
        opacity="0.6"
        d="M112.148 10.165C111.272 10.165 110.416 10.4246 109.688 10.9111C108.96 11.3976 108.393 12.0891 108.058 12.8981C107.722 13.7071 107.635 14.5973 107.806 15.4561C107.976 16.3149 108.398 17.1038 109.017 17.723C109.636 18.3422 110.425 18.7638 111.284 18.9347C112.143 19.1055 113.033 19.0178 113.842 18.6827C114.651 18.3476 115.343 17.7802 115.829 17.0521C116.316 16.324 116.575 15.468 116.575 14.5924C116.575 13.4181 116.109 12.292 115.279 11.4617C114.448 10.6314 113.322 10.165 112.148 10.165ZM112.148 14.1641C111.894 14.1641 111.645 14.0887 111.434 13.9475C111.223 13.8063 111.058 13.6057 110.961 13.3709C110.864 13.1361 110.838 12.8777 110.888 12.6285C110.937 12.3792 111.06 12.1503 111.239 11.9706C111.419 11.7909 111.648 11.6685 111.897 11.6189C112.146 11.5694 112.405 11.5948 112.64 11.6921C112.874 11.7893 113.075 11.954 113.216 12.1653C113.357 12.3766 113.433 12.625 113.433 12.8792C113.433 13.0479 113.4 13.215 113.335 13.3709C113.27 13.5268 113.176 13.6684 113.056 13.7877C112.937 13.907 112.796 14.0017 112.64 14.0663C112.484 14.1308 112.317 14.1641 112.148 14.1641Z"
        fill="white"
      />
      <path
        d="M155.955 15.9783H68.336C66.3453 15.9783 64.7316 17.5921 64.7316 19.5828V150.152C64.7316 152.143 66.3453 153.757 68.336 153.757H155.955C157.946 153.757 159.559 152.143 159.559 150.152V19.5828C159.559 17.5921 157.946 15.9783 155.955 15.9783Z"
        fill="#01A76A"
      />
      <path
        opacity="0.8"
        d="M154.54 23.9861H69.7509V147.202H154.54V23.9861Z"
        fill="white"
      />
      <path
        d="M134.699 14.8041H89.5971C89.0841 14.8041 88.6683 15.2199 88.6683 15.7329V21.5173C88.6683 22.0303 89.0841 22.4461 89.5971 22.4461H134.699C135.212 22.4461 135.627 22.0303 135.627 21.5173V15.7329C135.627 15.2199 135.212 14.8041 134.699 14.8041Z"
        fill="#01A76A"
      />
      <path
        opacity="0.4"
        d="M134.699 14.8041H89.5971C89.0841 14.8041 88.6683 15.2199 88.6683 15.7329V21.5173C88.6683 22.0303 89.0841 22.4461 89.5971 22.4461H134.699C135.212 22.4461 135.627 22.0303 135.627 21.5173V15.7329C135.627 15.2199 135.212 14.8041 134.699 14.8041Z"
        fill="white"
      />
      <path
        d="M129.506 29.5444H115.916C115.639 29.5492 115.367 29.4782 115.127 29.3391C114.888 29.2 114.691 28.998 114.559 28.7551C114.326 28.3195 113.979 27.9553 113.555 27.7013C113.131 27.4474 112.647 27.3132 112.153 27.3132C111.659 27.3132 111.174 27.4474 110.75 27.7013C110.326 27.9553 109.979 28.3195 109.747 28.7551C109.613 28.9977 109.417 29.1994 109.178 29.3384C108.939 29.4775 108.666 29.5487 108.389 29.5444H94.7848C94.4019 29.5444 94.0347 29.3923 93.764 29.1215C93.4932 28.8508 93.3411 28.4836 93.3411 28.1007V19.9918C93.3411 19.6089 93.4932 19.2417 93.764 18.9709C94.0347 18.7002 94.4019 18.5481 94.7848 18.5481H129.506C129.889 18.5481 130.256 18.7002 130.527 18.9709C130.798 19.2417 130.95 19.6089 130.95 19.9918V28.0766C130.953 28.2682 130.918 28.4585 130.847 28.6365C130.776 28.8144 130.67 28.9764 130.536 29.113C130.401 29.2497 130.241 29.3582 130.064 29.4322C129.887 29.5063 129.698 29.5444 129.506 29.5444ZM112.148 26.3441C112.818 26.3428 113.475 26.5245 114.05 26.8697C114.624 27.2149 115.093 27.7105 115.406 28.3028C115.458 28.3906 115.533 28.4628 115.623 28.5119C115.713 28.561 115.814 28.5852 115.916 28.5819H129.506C129.634 28.5819 129.756 28.5312 129.846 28.4409C129.937 28.3507 129.987 28.2283 129.987 28.1007V19.9918C129.987 19.8642 129.937 19.7418 129.846 19.6515C129.756 19.5613 129.634 19.5106 129.506 19.5106H94.7848C94.6572 19.5106 94.5348 19.5613 94.4446 19.6515C94.3543 19.7418 94.3036 19.8642 94.3036 19.9918V28.0766C94.3036 28.2042 94.3543 28.3266 94.4446 28.4169C94.5348 28.5071 94.6572 28.5578 94.7848 28.5578H108.385C108.486 28.5607 108.586 28.5364 108.675 28.4873C108.764 28.4382 108.838 28.3662 108.89 28.2787C109.207 27.6912 109.677 27.2009 110.251 26.8601C110.825 26.5193 111.48 26.341 112.148 26.3441Z"
        fill="#263238"
      />
      <g opacity="0.2">
        <path
          d="M129.506 29.5444H115.916C115.639 29.5492 115.367 29.4782 115.127 29.3391C114.888 29.2 114.691 28.998 114.559 28.7551C114.326 28.3195 113.979 27.9553 113.555 27.7013C113.131 27.4474 112.647 27.3132 112.153 27.3132C111.659 27.3132 111.174 27.4474 110.75 27.7013C110.326 27.9553 109.979 28.3195 109.747 28.7551C109.613 28.9977 109.417 29.1994 109.178 29.3384C108.939 29.4775 108.666 29.5487 108.389 29.5444H94.7848C94.4019 29.5444 94.0347 29.3923 93.764 29.1215C93.4932 28.8508 93.3411 28.4836 93.3411 28.1007V19.9918C93.3411 19.6089 93.4932 19.2417 93.764 18.9709C94.0347 18.7002 94.4019 18.5481 94.7848 18.5481H129.506C129.889 18.5481 130.256 18.7002 130.527 18.9709C130.798 19.2417 130.95 19.6089 130.95 19.9918V28.0766C130.953 28.2682 130.918 28.4585 130.847 28.6365C130.776 28.8144 130.67 28.9764 130.536 29.113C130.401 29.2497 130.241 29.3582 130.064 29.4322C129.887 29.5063 129.698 29.5444 129.506 29.5444ZM112.148 26.3441C112.818 26.3428 113.475 26.5245 114.05 26.8697C114.624 27.2149 115.093 27.7105 115.406 28.3028C115.458 28.3906 115.533 28.4628 115.623 28.5119C115.713 28.561 115.814 28.5852 115.916 28.5819H129.506C129.634 28.5819 129.756 28.5312 129.846 28.4409C129.937 28.3507 129.987 28.2283 129.987 28.1007V19.9918C129.987 19.8642 129.937 19.7418 129.846 19.6515C129.756 19.5613 129.634 19.5106 129.506 19.5106H94.7848C94.6572 19.5106 94.5348 19.5613 94.4446 19.6515C94.3543 19.7418 94.3036 19.8642 94.3036 19.9918V28.0766C94.3036 28.2042 94.3543 28.3266 94.4446 28.4169C94.5348 28.5071 94.6572 28.5578 94.7848 28.5578H108.385C108.486 28.5607 108.586 28.5364 108.675 28.4873C108.764 28.4382 108.838 28.3662 108.89 28.2787C109.207 27.6912 109.677 27.2009 110.251 26.8601C110.825 26.5193 111.48 26.341 112.148 26.3441Z"
          fill="white"
        />
      </g>
      <path
        d="M127.446 17.1381H96.8638C96.5555 17.1381 96.3055 17.388 96.3055 17.6964V19.8619C96.3055 20.1702 96.5555 20.4202 96.8638 20.4202H127.446C127.755 20.4202 128.005 20.1702 128.005 19.8619V17.6964C128.005 17.388 127.755 17.1381 127.446 17.1381Z"
        fill="#01A76A"
      />
      <path
        opacity="0.6"
        d="M127.432 17.1381H96.8494C96.5411 17.1381 96.2911 17.388 96.2911 17.6964V19.8619C96.2911 20.1702 96.5411 20.4202 96.8494 20.4202H127.432C127.74 20.4202 127.99 20.1702 127.99 19.8619V17.6964C127.99 17.388 127.74 17.1381 127.432 17.1381Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M120.021 91.2823H111.455V99.8484H120.021V91.2823Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M119.02 103.294H112.456C112.19 103.294 111.936 103.189 111.748 103.001C111.56 102.813 111.455 102.559 111.455 102.293C111.456 102.028 111.562 101.775 111.75 101.588C111.937 101.402 112.191 101.297 112.456 101.297H119.02C119.285 101.297 119.539 101.402 119.726 101.588C119.914 101.775 120.02 102.028 120.021 102.293C120.021 102.559 119.916 102.813 119.728 103.001C119.54 103.189 119.285 103.294 119.02 103.294Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M146.585 91.2823H124.554C124.049 91.2823 123.64 91.6917 123.64 92.1967V92.2015C123.64 92.7065 124.049 93.1159 124.554 93.1159H146.585C147.09 93.1159 147.5 92.7065 147.5 92.2015V92.1967C147.5 91.6917 147.09 91.2823 146.585 91.2823Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M141.773 96.0947H124.554C124.049 96.0947 123.64 96.5041 123.64 97.0091V97.0139C123.64 97.5189 124.049 97.9282 124.554 97.9282H141.773C142.278 97.9282 142.687 97.5189 142.687 97.0139V97.0091C142.687 96.5041 142.278 96.0947 141.773 96.0947Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M146.585 100.907H124.554C124.049 100.907 123.64 101.316 123.64 101.821V101.826C123.64 102.331 124.049 102.741 124.554 102.741H146.585C147.09 102.741 147.5 102.331 147.5 101.826V101.821C147.5 101.316 147.09 100.907 146.585 100.907Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M134.073 105.719H124.554C124.049 105.719 123.64 106.129 123.64 106.634V106.639C123.64 107.144 124.049 107.553 124.554 107.553H134.073C134.578 107.553 134.987 107.144 134.987 106.639V106.634C134.987 106.129 134.578 105.719 134.073 105.719Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M137.466 36.6764H86.5893C86.1494 36.6751 85.7278 36.4998 85.4168 36.1887C85.1057 35.8776 84.9303 35.456 84.9291 35.0161C84.9291 34.5758 85.104 34.1535 85.4154 33.8421C85.7267 33.5308 86.149 33.3558 86.5893 33.3558H137.466C137.906 33.3558 138.328 33.5308 138.64 33.8421C138.951 34.1535 139.126 34.5758 139.126 35.0161C139.125 35.456 138.949 35.8776 138.638 36.1887C138.327 36.4998 137.906 36.6751 137.466 36.6764Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M145.95 46.5032H112.369C112.126 46.5019 111.894 46.4045 111.722 46.2323C111.551 46.06 111.455 45.827 111.455 45.584C111.455 45.3415 111.551 45.109 111.723 44.9375C111.894 44.766 112.127 44.6697 112.369 44.6697H145.95C146.193 44.6697 146.425 44.766 146.597 44.9375C146.768 45.109 146.864 45.3415 146.864 45.584C146.864 45.827 146.768 46.06 146.597 46.2323C146.426 46.4045 146.193 46.5019 145.95 46.5032Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M141.138 51.3156H112.369C112.126 51.3143 111.894 51.2169 111.722 51.0447C111.551 50.8724 111.455 50.6394 111.455 50.3964C111.455 50.1539 111.551 49.9213 111.723 49.7499C111.894 49.5784 112.127 49.4821 112.369 49.4821H141.138C141.38 49.4821 141.613 49.5784 141.784 49.7499C141.956 49.9213 142.052 50.1539 142.052 50.3964C142.052 50.6394 141.956 50.8724 141.785 51.0447C141.613 51.2169 141.381 51.3143 141.138 51.3156Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M145.95 56.1279H112.369C112.126 56.1267 111.894 56.0293 111.722 55.857C111.551 55.6848 111.455 55.4517 111.455 55.2088C111.455 54.9663 111.551 54.7337 111.723 54.5622C111.894 54.3908 112.127 54.2944 112.369 54.2944H145.95C146.193 54.2944 146.425 54.3908 146.597 54.5622C146.768 54.7337 146.864 54.9663 146.864 55.2088C146.864 55.4517 146.768 55.6848 146.597 55.857C146.426 56.0293 146.193 56.1267 145.95 56.1279Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M136.325 60.9403H112.369C112.126 60.9391 111.894 60.8417 111.722 60.6694C111.551 60.4972 111.455 60.2641 111.455 60.0212C111.455 59.7787 111.551 59.5461 111.723 59.3746C111.894 59.2031 112.127 59.1068 112.369 59.1068H136.325C136.568 59.1068 136.8 59.2031 136.972 59.3746C137.143 59.5461 137.24 59.7787 137.24 60.0212C137.24 60.2641 137.143 60.4972 136.972 60.6694C136.801 60.8417 136.568 60.9391 136.325 60.9403Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M113.856 66.6767L114.386 67.745L115.569 67.9183L114.713 68.7508L114.915 69.9299L113.856 69.3716L112.802 69.9299L113.004 68.7508L112.148 67.9183L113.327 67.745L113.856 66.6767Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M119.833 66.6767L120.363 67.745L121.542 67.9183L120.685 68.7508L120.887 69.9299L119.833 69.3716L118.774 69.9299L118.977 68.7508L118.12 67.9183L119.304 67.745L119.833 66.6767Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M125.805 66.6767L126.335 67.745L127.519 67.9183L126.662 68.7508L126.864 69.9299L125.805 69.3716L124.747 69.9299L124.949 68.7508L124.097 67.9183L125.276 67.745L125.805 66.6767Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M131.782 66.6767L132.307 67.745L133.491 67.9183L132.634 68.7508L132.836 69.9299L131.782 69.3716L130.724 69.9299L130.926 68.7508L130.069 67.9183L131.253 67.745L131.782 66.6767Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M137.755 66.6767L138.284 67.745L139.463 67.9183L138.611 68.7508L138.813 69.9299L137.755 69.3716L136.696 69.9299L136.898 68.7508L136.046 67.9183L137.225 67.745L137.755 66.6767Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M113.856 72.8076L114.386 73.876L115.569 74.0492L114.713 74.8817L114.915 76.0608L113.856 75.5025L112.802 76.0608L113.004 74.8817L112.148 74.0492L113.327 73.876L113.856 72.8076Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M119.833 72.8076L120.363 73.876L121.542 74.0492L120.685 74.8817L120.887 76.0608L119.833 75.5025L118.774 76.0608L118.977 74.8817L118.12 74.0492L119.304 73.876L119.833 72.8076Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M125.805 72.8076L126.335 73.876L127.519 74.0492L126.662 74.8817L126.864 76.0608L125.805 75.5025L124.747 76.0608L124.949 74.8817L124.097 74.0492L125.276 73.876L125.805 72.8076Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M131.782 72.8076L132.307 73.876L133.491 74.0492L132.634 74.8817L132.836 76.0608L131.782 75.5025L130.724 76.0608L130.926 74.8817L130.069 74.0492L131.253 73.876L131.782 72.8076Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M137.755 72.8076L138.284 73.876L139.463 74.0492L138.611 74.8817L138.813 76.0608L137.755 75.5025L136.696 76.0608L136.898 74.8817L136.046 74.0492L137.225 73.876L137.755 72.8076Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M113.856 78.9386L114.386 80.0069L115.569 80.1802L114.713 81.0127L114.915 82.1918L113.856 81.6335L112.802 82.1918L113.004 81.0127L112.148 80.1802L113.327 80.0069L113.856 78.9386Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M119.833 78.9386L120.363 80.0069L121.542 80.1802L120.685 81.0127L120.887 82.1918L119.833 81.6335L118.774 82.1918L118.977 81.0127L118.12 80.1802L119.304 80.0069L119.833 78.9386Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M125.805 78.9386L126.335 80.0069L127.519 80.1802L126.662 81.0127L126.864 82.1918L125.805 81.6335L124.747 82.1918L124.949 81.0127L124.097 80.1802L125.276 80.0069L125.805 78.9386Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M131.782 78.9386L132.307 80.0069L133.491 80.1802L132.634 81.0127L132.836 82.1918L131.782 81.6335L130.724 82.1918L130.926 81.0127L130.069 80.1802L131.253 80.0069L131.782 78.9386Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M137.755 78.9386L138.284 80.0069L139.463 80.1802L138.611 81.0127L138.813 82.1918L137.755 81.6335L136.696 82.1918L136.898 81.0127L136.046 80.1802L137.225 80.0069L137.755 78.9386Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M120.021 115.344H111.455V123.91H120.021V115.344Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M119.02 127.356H112.456C112.19 127.356 111.936 127.25 111.748 127.063C111.56 126.875 111.455 126.62 111.455 126.355C111.456 126.09 111.562 125.837 111.75 125.65C111.937 125.464 112.191 125.359 112.456 125.359H119.02C119.285 125.359 119.539 125.464 119.726 125.65C119.914 125.837 120.02 126.09 120.021 126.355C120.021 126.62 119.916 126.875 119.728 127.063C119.54 127.25 119.285 127.356 119.02 127.356Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M146.585 115.344H124.554C124.049 115.344 123.64 115.754 123.64 116.259V116.263C123.64 116.768 124.049 117.178 124.554 117.178H146.585C147.09 117.178 147.5 116.768 147.5 116.263V116.259C147.5 115.754 147.09 115.344 146.585 115.344Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M141.773 120.157H124.554C124.049 120.157 123.64 120.566 123.64 121.071V121.076C123.64 121.581 124.049 121.99 124.554 121.99H141.773C142.278 121.99 142.687 121.581 142.687 121.076V121.071C142.687 120.566 142.278 120.157 141.773 120.157Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M146.585 124.969H124.554C124.049 124.969 123.64 125.378 123.64 125.883V125.888C123.64 126.393 124.049 126.802 124.554 126.802H146.585C147.09 126.802 147.5 126.393 147.5 125.888V125.883C147.5 125.378 147.09 124.969 146.585 124.969Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M134.073 129.781H124.554C124.049 129.781 123.64 130.191 123.64 130.696V130.7C123.64 131.205 124.049 131.615 124.554 131.615H134.073C134.578 131.615 134.987 131.205 134.987 130.7V130.696C134.987 130.191 134.578 129.781 134.073 129.781Z"
        fill="black"
      />
      <path
        d="M105.988 44.6697H75.1022V85.6807H105.988V44.6697Z"
        fill="white"
      />
      <path
        d="M104.544 46.1134H76.546V84.237H104.544V46.1134Z"
        fill="#01A76A"
      />
      <path
        opacity="0.4"
        d="M104.544 46.1134H76.546V84.237H104.544V46.1134Z"
        fill="white"
      />
      <path
        d="M104.544 75.7191V84.237H102.369C101.767 83.6636 101.104 83.1585 100.391 82.7307C100.048 82.5238 99.6789 82.362 99.294 82.2495C96.0264 81.364 100.738 72.6248 100.738 72.6248C101.825 72.9712 103.178 74.2032 104.544 75.7191Z"
        fill="#01A76A"
      />
      <path
        opacity="0.6"
        d="M104.544 75.7191V84.237H102.369C101.767 83.6636 101.104 83.1585 100.391 82.7307C100.048 82.5238 99.6789 82.362 99.294 82.2495C96.0264 81.364 100.738 72.6248 100.738 72.6248C101.825 72.9712 103.178 74.2032 104.544 75.7191Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M104.544 84.2082V84.237H102.369C101.767 83.6636 101.104 83.1585 100.391 82.7308L101.036 78.7028C101.665 79.2247 102.223 79.8265 102.696 80.493C103.519 81.6174 104.144 82.8738 104.544 84.2082Z"
        fill="black"
      />
      <path
        d="M98.5481 72.2735C98.4807 72.62 98.4133 72.9713 98.3364 73.3178C98.2594 73.6642 98.1535 74.1407 98.0524 74.5497C97.0033 78.7702 95.2709 82.4661 92.7877 84.237H84.2987C81.8299 82.981 81.3583 80.8684 81.4401 78.6547C81.493 77.158 81.801 75.6325 81.9213 74.3235C81.9595 73.872 81.9595 73.418 81.9213 72.9665C81.8588 72.2831 81.7289 71.4602 81.57 70.5603C80.8337 66.3687 79.3949 60.3195 80.1263 57.2156C80.9974 53.4427 83.75 52.4032 83.75 52.4032C83.75 52.4032 84.6836 52.3406 86.1177 52.3502C87.1765 52.3502 88.5239 52.4032 89.9339 52.5379H90.035C91.6165 52.6803 93.183 52.9572 94.7174 53.3657L95.0688 53.4667C95.3719 53.5582 95.6751 53.6544 95.9687 53.7603C96.1612 53.8276 96.3488 53.8998 96.5365 53.9768C96.7799 54.0801 96.9995 54.2325 97.1814 54.4244C99.3614 56.6621 99.9004 64.8672 98.5481 72.2735Z"
        fill="#263238"
      />
      <path
        d="M96.012 54.4436C99.0678 52.9999 100.386 62.9663 99.294 70.1608"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M102.927 84.237H80.9637C80.8723 83.8231 80.7808 83.4093 80.6846 83.0002C80.1422 80.6051 79.4232 78.2535 78.5334 75.9645C79.4333 75.4297 80.3729 74.9647 81.3439 74.5738C81.5267 74.4919 81.7144 74.415 81.9165 74.338C82.6769 74.0348 83.5624 73.722 84.5537 73.4236L84.8713 73.3322C85.6173 73.1156 86.4209 72.9135 87.2775 72.7354C89.0653 72.3601 90.8808 72.1316 92.7059 72.0521C94.0951 71.9866 95.487 72.0091 96.8734 72.1194C97.4172 72.1579 97.9706 72.2109 98.5337 72.2831C99.2411 72.3697 99.9774 72.4804 100.704 72.6199C100.786 72.7499 102.124 74.8769 102.677 80.4978C102.812 81.6046 102.894 82.8462 102.927 84.237Z"
        fill="#01A76A"
      />
      <path
        d="M84.6789 77.7115C84.6789 77.7115 82.1668 71.5227 90.011 70.8249C97.8552 70.1271 99.4192 75.0165 99.4192 75.0165L95.7762 73.7557L93.8513 82.6345C92.6146 79.2485 90.166 76.4415 86.9792 74.7567L84.6789 77.7115Z"
        fill="#01A76A"
      />
      <path
        opacity="0.6"
        d="M84.6789 77.7115C84.6789 77.7115 82.1668 71.5227 90.011 70.8249C97.8552 70.1271 99.4192 75.0165 99.4192 75.0165L95.7762 73.7557L93.8513 82.6345C92.6146 79.2485 90.166 76.4415 86.9792 74.7567L84.6789 77.7115Z"
        fill="white"
      />
      <path
        d="M93.524 78.7028C91.5038 76.6076 89.21 74.7946 86.7048 73.313C88.5817 71.7249 88.3892 69.2224 88.0138 67.6488C87.8945 67.1548 87.7335 66.6718 87.5326 66.2051L92.7444 67.4659C92.5835 68.1918 92.4757 68.9284 92.4219 69.67C92.3769 70.2159 92.3979 70.7653 92.4845 71.3062C92.7588 72.8991 93.6539 72.9665 93.6539 72.9665C94.5057 74.7567 93.524 78.7028 93.524 78.7028Z"
        fill="#E4897B"
      />
      <path
        opacity="0.2"
        d="M92.4412 69.6651C92.3962 70.2111 92.4172 70.7604 92.5038 71.3013C90.4296 70.9164 88.933 69.1598 88.0138 67.6584C87.8946 67.1644 87.7336 66.6814 87.5326 66.2147L92.7444 67.4755C92.5907 68.1971 92.4894 68.9289 92.4412 69.6651Z"
        fill="black"
      />
      <path
        d="M90.7906 52.5139C96.4644 52.5139 96.9408 55.2569 97.268 59.9827C97.6723 65.8923 96.3537 70.0886 90.6221 68.8086C82.8405 67.0809 83.1726 52.5139 90.7906 52.5139Z"
        fill="#E4897B"
      />
      <path
        d="M85.8579 60.5072C85.8579 60.5072 88.009 56.7439 87.6914 53.8324C87.6914 53.8324 90.9253 50.4975 95.2805 53.7939C95.2805 53.7939 97.1044 56.9316 97.2055 59.3667C97.2055 59.3667 98.1102 51.1856 90.2275 50.9787C82.3448 50.7718 82.3641 62.3118 86.4257 65.7575C86.4257 65.7575 84.5249 62.8605 85.8579 60.5072Z"
        fill="#263238"
      />
      <path
        d="M86.6038 61.4745C86.5596 60.9444 86.3077 60.4532 85.903 60.108C85.4983 59.7627 84.9735 59.5914 84.4431 59.6314C82.9994 59.7854 82.3353 62.7017 85.8868 63.4091C86.3776 63.5101 86.6664 63.0145 86.6038 61.4745Z"
        fill="#E4897B"
      />
      <path
        d="M87.6914 53.8325C87.6914 53.8325 92.9321 51.5081 96.4018 54.5784C96.4018 54.5784 97.7637 50.536 93.1149 49.1548C88.4662 47.7737 83.7404 52.4224 83.7404 52.4224L87.6914 53.8325Z"
        fill="#263238"
      />
      <path
        d="M87.6914 53.8324C87.6914 53.8324 84.6308 56.8498 85.0735 62.8604C85.5162 68.8711 84.9339 72.6729 83.7405 76.4361C82.547 80.1994 85.6654 81.3207 85.6654 81.3207C85.6654 81.3207 82.1043 82.1966 81.3728 78.3852C80.8915 75.8827 82.0369 70.464 81.4594 64.0106C80.8819 57.5572 83.187 52.9903 87.6914 53.8324Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M81.9791 84.237H80.9637C80.8722 83.8231 80.7808 83.4093 80.6846 83.0002L81.4497 82.3313C81.6659 82.9548 81.8427 83.5913 81.9791 84.237Z"
        fill="black"
      />
      <path
        d="M81.4546 84.237H76.546V77.4997C77.153 76.9248 77.8174 76.4136 78.5287 75.9742C79.2347 76.2725 79.8517 76.7483 80.3197 77.3554C80.7877 77.9625 81.0907 78.6803 81.1995 79.4391C81.3412 80.3955 81.4232 81.3599 81.445 82.3265C81.469 83.0147 81.4642 83.674 81.4546 84.237Z"
        fill="#01A76A"
      />
      <path
        opacity="0.6"
        d="M81.4546 84.237H76.546V77.4997C77.153 76.9248 77.8174 76.4136 78.5287 75.9742C79.2347 76.2725 79.8517 76.7483 80.3197 77.3554C80.7877 77.9625 81.0907 78.6803 81.1995 79.4391C81.3412 80.3955 81.4232 81.3599 81.445 82.3265C81.469 83.0147 81.4642 83.674 81.4546 84.237Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M105.988 90.4594H75.1022V131.413H105.988V90.4594Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M100.795 96.807H79.7317C79.4888 96.8057 79.2562 96.7083 79.0849 96.5361C78.9136 96.3638 78.8174 96.1308 78.8174 95.8878C78.8174 95.6453 78.9137 95.4127 79.0852 95.2413C79.2567 95.0698 79.4892 94.9735 79.7317 94.9735H100.795C100.916 94.9728 101.035 94.996 101.147 95.0417C101.258 95.0873 101.36 95.1546 101.445 95.2396C101.531 95.3245 101.598 95.4255 101.645 95.5368C101.691 95.648 101.715 95.7673 101.715 95.8878C101.715 96.0085 101.691 96.128 101.645 96.2396C101.598 96.3511 101.531 96.4524 101.445 96.5377C101.36 96.6231 101.259 96.6908 101.147 96.737C101.036 96.7832 100.916 96.807 100.795 96.807Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M100.8 100.094H79.7317C79.2267 100.094 78.8174 100.503 78.8174 101.008V101.013C78.8174 101.518 79.2267 101.927 79.7317 101.927H100.8C101.305 101.927 101.715 101.518 101.715 101.013V101.008C101.715 100.503 101.305 100.094 100.8 100.094Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M91.1707 107.053H79.7317C79.4888 107.051 79.2562 106.954 79.0849 106.782C78.9136 106.609 78.8174 106.376 78.8174 106.133C78.8174 105.891 78.9137 105.658 79.0852 105.487C79.2567 105.315 79.4892 105.219 79.7317 105.219H91.1707C91.2912 105.218 91.4106 105.242 91.5221 105.287C91.6336 105.333 91.735 105.4 91.8204 105.485C91.9058 105.57 91.9736 105.671 92.0199 105.782C92.0661 105.894 92.0899 106.013 92.0899 106.133C92.0899 106.377 91.9931 106.611 91.8207 106.783C91.6483 106.956 91.4145 107.053 91.1707 107.053Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M100.795 112.173H79.7317C79.4892 112.173 79.2567 112.077 79.0852 111.905C78.9137 111.734 78.8174 111.501 78.8174 111.259C78.8174 111.016 78.9136 110.783 79.0849 110.61C79.2562 110.438 79.4888 110.341 79.7317 110.339H100.795C101.039 110.339 101.273 110.436 101.445 110.609C101.618 110.781 101.715 111.015 101.715 111.259C101.715 111.379 101.691 111.498 101.645 111.61C101.598 111.721 101.531 111.822 101.445 111.907C101.36 111.992 101.258 112.059 101.147 112.105C101.035 112.15 100.916 112.174 100.795 112.173Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M100.795 117.293H79.7317C79.4892 117.293 79.2567 117.197 79.0852 117.025C78.9137 116.854 78.8174 116.621 78.8174 116.379C78.8174 116.136 78.9137 115.904 79.0852 115.732C79.2567 115.561 79.4892 115.464 79.7317 115.464H100.795C100.916 115.464 101.035 115.487 101.147 115.533C101.258 115.578 101.36 115.646 101.445 115.731C101.531 115.816 101.598 115.917 101.645 116.028C101.691 116.139 101.715 116.258 101.715 116.379C101.715 116.499 101.691 116.619 101.645 116.73C101.598 116.841 101.531 116.942 101.445 117.027C101.36 117.112 101.258 117.179 101.147 117.225C101.035 117.271 100.916 117.294 100.795 117.293Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M86.3584 122.399H79.7317C79.4892 122.399 79.2567 122.303 79.0852 122.131C78.9137 121.96 78.8174 121.727 78.8174 121.485C78.8168 121.364 78.8399 121.245 78.8856 121.133C78.9313 121.022 78.9985 120.921 79.0835 120.835C79.1685 120.75 79.2695 120.682 79.3807 120.636C79.492 120.589 79.6113 120.566 79.7317 120.566H86.3584C86.4793 120.565 86.5991 120.588 86.7109 120.634C86.8227 120.68 86.9243 120.748 87.0098 120.833C87.0952 120.919 87.1629 121.02 87.2089 121.132C87.2548 121.244 87.2782 121.364 87.2775 121.485C87.2776 121.605 87.2537 121.725 87.2075 121.836C87.1612 121.947 87.0935 122.048 87.008 122.133C86.9226 122.218 86.8213 122.285 86.7098 122.331C86.5983 122.377 86.4789 122.4 86.3584 122.399Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M95.9831 127.539H79.7317C79.4892 127.539 79.2567 127.442 79.0852 127.271C78.9137 127.1 78.8174 126.867 78.8174 126.624C78.8174 126.382 78.9137 126.149 79.0852 125.978C79.2567 125.806 79.4892 125.71 79.7317 125.71H95.9831C96.1036 125.709 96.223 125.733 96.3345 125.778C96.446 125.824 96.5474 125.891 96.6328 125.976C96.7182 126.061 96.786 126.162 96.8322 126.273C96.8785 126.385 96.9023 126.504 96.9023 126.624C96.9023 126.745 96.8785 126.864 96.8322 126.975C96.786 127.087 96.7182 127.188 96.6328 127.273C96.5474 127.358 96.446 127.425 96.3345 127.471C96.223 127.516 96.1036 127.539 95.9831 127.539Z"
        fill="white"
      />
      <g clipPath="url(#clip0_8017_75516)">
        <path
          d="M174.475 24.7324H143.663C136.155 24.7324 130.069 30.8186 130.069 38.3262V69.1387C130.069 76.6463 136.155 82.7324 143.663 82.7324H174.475C181.983 82.7324 188.069 76.6463 188.069 69.1387V38.3262C188.069 30.8186 181.983 24.7324 174.475 24.7324Z"
          fill="white"
        />
        <path
          d="M174.475 24.7324H143.663C136.155 24.7324 130.069 30.8186 130.069 38.3262V69.1387C130.069 76.6463 136.155 82.7324 143.663 82.7324H174.475C181.983 82.7324 188.069 76.6463 188.069 69.1387V38.3262C188.069 30.8186 181.983 24.7324 174.475 24.7324Z"
          fill="#0A66C2"
        />
        <path
          d="M171.919 74.0517H178.55C178.79 74.0517 179.021 73.9562 179.191 73.7863C179.361 73.6164 179.456 73.386 179.456 73.1457L179.46 59.1346C179.46 51.8114 177.882 46.1825 169.324 46.1825C166.071 46.0615 163.003 47.7385 161.347 50.5388C161.339 50.5524 161.327 50.563 161.312 50.5689C161.298 50.5749 161.281 50.5758 161.266 50.5717C161.251 50.5676 161.237 50.5586 161.228 50.546C161.218 50.5335 161.213 50.5181 161.212 50.5023V47.7648C161.212 47.5244 161.117 47.2939 160.947 47.1239C160.777 46.954 160.547 46.8585 160.306 46.8585H154.013C153.773 46.8585 153.542 46.954 153.372 47.1239C153.202 47.2939 153.107 47.5244 153.107 47.7648V73.1443C153.107 73.3846 153.202 73.6152 153.372 73.7851C153.542 73.9551 153.773 74.0505 154.013 74.0505H160.644C160.884 74.0505 161.115 73.9551 161.285 73.7851C161.455 73.6152 161.55 73.3846 161.55 73.1443V60.5989C161.55 57.0516 162.223 53.6162 166.621 53.6162C170.956 53.6162 171.012 57.6753 171.012 60.8286V73.1454C171.012 73.3858 171.108 73.6163 171.278 73.7862C171.448 73.9562 171.678 74.0517 171.919 74.0517ZM138.679 38.2419C138.679 40.9298 140.891 43.1415 143.58 43.1415C146.267 43.1413 148.479 40.9282 148.479 38.2408C148.478 35.5533 146.266 33.3418 143.579 33.3418C140.89 33.3418 138.679 35.554 138.679 38.2419ZM140.255 74.0517H146.895C147.135 74.0517 147.366 73.9562 147.536 73.7862C147.706 73.6163 147.801 73.3858 147.801 73.1454V47.7648C147.801 47.5244 147.706 47.2939 147.536 47.1239C147.366 46.954 147.135 46.8585 146.895 46.8585H140.255C140.015 46.8585 139.784 46.954 139.614 47.1239C139.444 47.2939 139.349 47.5244 139.349 47.7648V73.1454C139.349 73.3858 139.444 73.6163 139.614 73.7862C139.784 73.9562 140.015 74.0517 140.255 74.0517Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_8017_75516">
          <rect
            width="58"
            height="58"
            fill="white"
            transform="translate(130.069 24.7324)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LinkedinInformationIcon
