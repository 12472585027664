import { useTranslation } from 'react-i18next'
import not_found from '../../assets/not_found.png'
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center text-on-bg-primary"
      style={{
        height: '100vh',
      }}
    >
      <img src={not_found} width={254} height={200} />
      <span className="fs-4 fw-bolder">
        {t('sorry_we_couldn_t_find_the_page_you_were_looking_for')}
      </span>
      <span className="mb-3">
        {t(
          'please_go_back_to_the_beginning_or_contact_support_if_you_think_this_is_an_error'
        )}
      </span>
      <div className="d-flex align-items-center gap-2">
        <Button onClick={() => navigate('/')}>{t('back_to_top')}</Button>
      </div>
    </div>
  )
}

export default NotFound
