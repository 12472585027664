import { View } from '@react-pdf/renderer'
import { PDF_PALET_OF_COLORS } from '../constants/pdfConstants'

const { bg_cta_primary, on_bg_tertiary } = PDF_PALET_OF_COLORS

const PdfPercentageSkills = ({ percentage, isSoftSkill = false }) => {
  const bars = [1, 2, 3, 4]

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      {bars.map((_, index) => (
        <View
          key={index}
          style={
            index < percentage / 25
              ? {
                  width: '5px',
                  height: '10px',
                  backgroundColor: isSoftSkill ? '#407BFF' : bg_cta_primary ,
                  borderRadius: '2px',
                  marginRight: '5px',
                }
              : {
                  width: '5px',
                  height: '10px',
                  backgroundColor: on_bg_tertiary,
                  borderRadius: '2px',
                  marginRight: '5px',
                }
          }
        />
      ))}
    </View>
  )
}

export default PdfPercentageSkills
