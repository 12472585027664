import { useCallback, useEffect, useState } from 'react'

import { useMutation } from '@tanstack/react-query'

import { PRIVATE_ROUTES } from '../../Routes/routes'

import { listOfTalents } from '../../services/talents'

import ListOfCandidates from './ListOfTalents'

import DashboardWrapper from '../Dashboard/components/DashboardWrapper'
import { useForm } from 'react-hook-form'
import Wrapper from '../../components/Wrapper/Wrapper'
import InnerBanner from '../../components/InnerBanner'
import useGoToTop from '../../hooks/useGoToTop'
import useResize from '../../hooks/useResize'
import { OUT_OF_THE_APP, VIEWPORT_LG } from '../../utils/constants'
import Filters from './components/Filters'
import { userInfo } from '../../localStorage'
import { CANDIDATES_TAGS } from '../../services/Tags/candidates'
import FiltersModal from './components/FiltersModal'
import BannerIcon from '../../icons/landing/BannerIcon'

import {
  useListOfTalentsStore,
  useSearchTalentsFiltersStore,
} from '../../store'
import { useTranslation } from 'react-i18next'

const initialValues = {
  talentCategory: '',
  talentLangLevel: [],
  talentSkills: '',
  yearsExperience: [],
}

const Talents = () => {
  const { t } = useTranslation()
  const {
    data: globalData,
    page: globalPage,
    setData,
    setPage: setGlobalPage,
  } = useListOfTalentsStore()
  const { payload = {} } = userInfo
  const { typeUser = 0 } = payload

  const {
    selectedCategories,
    selectedSkills,
    selectedLanguages,
    selectedExperience,
  } = useSearchTalentsFiltersStore()

  const [hasMore, setHasMore] = useState(true)
  const [selectedButton, setSelectedButton] = useState(2)
  const [scroll, setScroll] = useState(false)

  const { handleSubmit, getValues } = useForm(initialValues)
  const values = getValues()

  const viewportSize = useResize()

  const handleNavigation = useCallback(() => {
    setScroll(!window.scrollY <= 0)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleNavigation)
    return () => {
      window.removeEventListener('scroll', handleNavigation)
    }
  }, [handleNavigation])

  const { mutate, isLoading: listOfTalentIsLoading } = useMutation(
    [CANDIDATES_TAGS.list_Of_Candidates_two],
    listOfTalents,
    {
      onSuccess: (data) => {
        if (data?.length === 0 && globalPage > 0) {
          setHasMore(false)
        } else {
          setHasMore(true)
        }

        if (hasMore && data?.length > 0) {
          setData([...globalData, ...data])
          return
        }

        if (globalPage <= 0) {
          setData([...globalData, ...data])
          return
        }
      },
    }
  )

  const handleGetMoreElements = () => {
    if (!hasMore) {
      return
    }

    if (hasMore) {
      if (window.location.pathname === PRIVATE_ROUTES.talents) {
        setGlobalPage(globalPage + 1)
      }
    }
  }

  if (window.location.pathname === PRIVATE_ROUTES.talents) {
    useGoToTop()
  }

  return (
    <Wrapper
      activeWrapper={
        window.location.pathname === PRIVATE_ROUTES.talents && typeUser === 0
      }
    >
      <DashboardWrapper
        activeWrapper={
          window.location.pathname === PRIVATE_ROUTES.talents && typeUser !== 0
        }
        dashboardWrapperClassName="w-100"
      >
        {/* TODO: refactor this component */}

        <FiltersModal
          initialValues={initialValues}
          selectedButton={selectedButton}
          setSelectedButton={setSelectedButton}
          candidateMutate={mutate}
          handleSubmit={handleSubmit}
          page={globalPage}
          setGlobalPage={setGlobalPage}
          values={values}
          globalData={globalData}
        />
        <section
          id="list-of-talents"
          className={`${
            viewportSize[0] > VIEWPORT_LG
              ? 'd-flex flex-column align-items-center w-100'
              : ''
          } ${
            window.location.pathname === PRIVATE_ROUTES.talents
              ? viewportSize[0] > VIEWPORT_LG
                ? 'pt-5 mt-5'
                : 'p-2'
              : 'pt-0'
          }
          
          ${
            OUT_OF_THE_APP
              ? 'justify-content-center'
              : viewportSize[0] > VIEWPORT_LG
              ? 'p-5'
              : ''
          }
          `}
        >
          <div
            className={`${
              window.location.pathname === PRIVATE_ROUTES.talents &&
              viewportSize[0] > VIEWPORT_LG
                ? 'bg-white'
                : ''
            } 
             ${
               typeUser === 0
                 ? viewportSize[0] <= VIEWPORT_LG
                   ? ''
                   : 'w-75'
                 : 'w-100'
             }`}
          >
            {window.location.pathname === PRIVATE_ROUTES.talents &&
              typeUser === 0 && (
                <InnerBanner
                  title={t('home')}
                  text={t('candidates')}
                  className={`d-flex ${
                    scroll ? 'mt-5 pt-3' : ''
                  } pb-3 ms-5 ps-1`}
                  beforeRoute={-1}
                />
              )}
            {window.location.pathname === PRIVATE_ROUTES.talents &&
              OUT_OF_THE_APP && (
                <div className="mb-5 d-flex justify-content-center align-items-center">
                  <BannerIcon
                    width={`${viewportSize[0] <= VIEWPORT_LG ? '450' : '1040'}`}
                    height={`${viewportSize[0] <= VIEWPORT_LG ? '100' : '288'}`}
                  />
                </div>
              )}

            {typeUser !== 0 && (
              <h1 className="text-on-bg-primary fw-bolder mb-3">
                {t('talents')}
              </h1>
            )}

            <Filters
              setSelectedButton={setSelectedButton}
              selectedCategories={selectedCategories}
              selectedSkills={selectedSkills}
              selectedLanguages={selectedLanguages}
              selectedExperience={selectedExperience}
            />
          </div>
          <div
            className={`${viewportSize[0] <= VIEWPORT_LG ? 'p-1' : 'mt-5'} ${
              OUT_OF_THE_APP
                ? viewportSize[0] <= VIEWPORT_LG
                  ? ''
                  : 'w-50'
                : ''
            }`}
          >
            <div>
              <ListOfCandidates
                data={globalData}
                isLoading={listOfTalentIsLoading}
                handleGetMoreElements={handleGetMoreElements}
                hasMore={hasMore}
                mutate={mutate}
                listOfTalentsPage={globalPage}
                page={globalPage}
                selectedButton={selectedButton}
              />
            </div>
          </div>
        </section>
      </DashboardWrapper>
    </Wrapper>
  )
}

export default Talents
