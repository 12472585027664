const SpainIcon = ({ width = '12', height = '12' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7286_232403)">
        <path
          d="M0 6.00004C0 6.73397 0.132023 7.437 0.373195 8.08699L6 8.60873L11.6268 8.08699C11.868 7.437 12 6.73397 12 6.00004C12 5.26612 11.868 4.56309 11.6268 3.9131L6 3.39136L0.373195 3.9131C0.132023 4.56309 0 5.26612 0 6.00004H0Z"
          fill="#FFDA44"
        />
        <path
          d="M11.6267 3.91305C10.7789 1.62827 8.57964 0 5.99985 0C3.42006 0 1.2208 1.62827 0.373047 3.91305H11.6267Z"
          fill="#D80027"
        />
        <path
          d="M0.373047 8.08691C1.2208 10.3717 3.42006 12 5.99985 12C8.57964 12 10.7789 10.3717 11.6267 8.08691H0.373047Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_7286_232403">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SpainIcon
