const WindowQuestionIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="list/quiz">
        <mask
          id="mask0_8245_7444"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="17"
          height="17"
        >
          <rect
            id="Bounding box"
            x="0.568359"
            y="0.0419922"
            width="16"
            height="16"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask0_8245_7444)">
          <path
            id="quiz"
            d="M9.90161 10.0419C10.0905 10.0419 10.2544 9.97247 10.3933 9.83358C10.5322 9.69469 10.6016 9.5308 10.6016 9.34191C10.6016 9.15302 10.5322 8.98913 10.3933 8.85024C10.2544 8.71136 10.0905 8.64191 9.90161 8.64191C9.71272 8.64191 9.54883 8.71136 9.40994 8.85024C9.27106 8.98913 9.20161 9.15302 9.20161 9.34191C9.20161 9.5308 9.27106 9.69469 9.40994 9.83358C9.54883 9.97247 9.71272 10.0419 9.90161 10.0419ZM9.40161 7.90858H10.4016C10.4016 7.58636 10.4349 7.35024 10.5016 7.20024C10.5683 7.05024 10.7238 6.85302 10.9683 6.60858C11.3016 6.27524 11.5238 6.0058 11.6349 5.80024C11.7461 5.59469 11.8016 5.35302 11.8016 5.07524C11.8016 4.57524 11.6266 4.16691 11.2766 3.85024C10.9266 3.53358 10.4683 3.37524 9.90161 3.37524C9.44606 3.37524 9.04883 3.50302 8.70995 3.75858C8.37106 4.01413 8.13495 4.35302 8.00161 4.77524L8.90161 5.14191C9.00161 4.86413 9.13772 4.6558 9.30995 4.51691C9.48217 4.37802 9.67939 4.30858 9.90161 4.30858C10.1683 4.30858 10.3849 4.38358 10.5516 4.53358C10.7183 4.68358 10.8016 4.88636 10.8016 5.14191C10.8016 5.29747 10.7572 5.44469 10.6683 5.58358C10.5794 5.72247 10.4238 5.89747 10.2016 6.10858C9.83494 6.4308 9.60994 6.68358 9.52661 6.86691C9.44328 7.05024 9.40161 7.39747 9.40161 7.90858ZM5.90161 12.0419C5.53494 12.0419 5.22106 11.9114 4.95994 11.6502C4.69883 11.3891 4.56828 11.0752 4.56828 10.7086V2.70858C4.56828 2.34191 4.69883 2.02802 4.95994 1.76691C5.22106 1.5058 5.53494 1.37524 5.90161 1.37524H13.9016C14.2683 1.37524 14.5822 1.5058 14.8433 1.76691C15.1044 2.02802 15.2349 2.34191 15.2349 2.70858V10.7086C15.2349 11.0752 15.1044 11.3891 14.8433 11.6502C14.5822 11.9114 14.2683 12.0419 13.9016 12.0419H5.90161ZM5.90161 10.7086H13.9016V2.70858H5.90161V10.7086ZM3.23494 14.7086C2.86828 14.7086 2.55439 14.578 2.29328 14.3169C2.03217 14.0558 1.90161 13.7419 1.90161 13.3752V4.04191H3.23494V13.3752H12.5683V14.7086H3.23494Z"
            fill="#E4F8ED"
          />
        </g>
      </g>
    </svg>
  )
}

export default WindowQuestionIcon
