import dayjs from 'dayjs'

const DateCounter = ({ date }) => {
  const currentDateTime = dayjs(date)
  const formattedTime = currentDateTime.format('h:mm A')

  return (
    <div className="text-end">
      <span className="font-extra-small ">{formattedTime}</span>
    </div>
  )
}

export default DateCounter
