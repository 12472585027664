import { useTranslation } from 'react-i18next'
import { useListOfTalentsBySuperSearchStore } from '../store'
import ButtonSelect from './ButtonSelect'
import SearchInput from './SearchInput'
import { useQuery } from '@tanstack/react-query'
import {
  getCategories,
  getListOfSkillsCategories,
  superSearchListOfTalents,
} from '../services/talents'
import { TAGS } from '../services/Tags'
import { useEffect, useState } from 'react'
import Xicon from '../icons/filters/Xicon'
import Button from './Button'
import { useNavigate } from 'react-router-dom'
import useResize from '../hooks/useResize'
import { VIEWPORT_LG } from '../utils/constants'

const SearchTalents = ({ isSuperSearch = false }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const viewportSize = useResize()

  const {
    selectedCategories,
    setSelectedCategories,

    currentCategories,
    setCurrentCategories,

    filteredCategories,
    setFilteredCategories,

    selectedSkills,
    setSelectedSkills,

    currentSkills,
    setCurrentSkills,

    filteredSkills,
    setFilteredSkills,

    setPage: setGlobalPage,
    setData,
    setDataWithoutBanners,

    actionButton,
    setActionButton,
    id,
    setId,

    firstSearch,
    setFirstSearch,
  } = useListOfTalentsBySuperSearchStore()

  const getOnlyCategoriesIds = selectedCategories?.map(({ id }) => id)
  const getOnlySkillsIds = selectedSkills?.map(({ id }) => id)

  const [searchTermCategory, setSearchTermCategory] = useState('')
  const [searchTermSkill, setSearchTermSkill] = useState('')

  const { data: categoriesData = [], isSuccess: isSuccessCategories } =
    useQuery([TAGS.categories], getCategories)

  const { data: skillsData = [], isSuccess: isSuccessSkills } = useQuery(
    [TAGS.skills_categories],
    getListOfSkillsCategories
  )

  const transformSkillsData = skillsData?.map(({ id, skill }) => {
    return {
      id,
      name: skill.name,
    }
  })

  const filterValue = ({ data, term }) => {
    return data?.filter(({ name }) =>
      name.toLowerCase().includes(term.toLowerCase())
    )
  }

  const handleDataChange = ({
    data = [],
    serviceData = [],
    searchTerm = '',
    id = null,
    setSelectedData = () => {},
    setCurrentData = () => {},
    setFilteredData = () => {},
  }) => {
    const updatedData = data?.map((value) => {
      if (value.id === id) {
        return { ...value, checked: !value.checked }
      }
      return value
    })

    const filteredData = filterValue({
      data: serviceData,
      term: searchTerm,
    })

    const selectedData = updatedData?.filter((value) => value.checked)
    setSelectedData(selectedData)
    setCurrentData(updatedData)
    setFilteredData(filteredData)
  }

  const handleDeleteData = ({
    data = [],
    id = null,
    setSelectedData = () => {},
    setCurrentData = () => {},
  }) => {
    const updatedData = data?.map((value) => {
      if (value.id === id) {
        return { ...value, checked: false }
      }
      return value
    })

    const selectedData = updatedData?.filter((value) => value.checked)
    setSelectedData(selectedData)
    setCurrentData(updatedData)
  }

  const getFirstTalentPositionId = async () => {
    if (id) {
      await setId(null)
    }

    const data = await superSearchListOfTalents({
      filters: {
        yearsExperience: '',
        talentSkills: getOnlySkillsIds ?? '',
        talentCategory:
          getOnlyCategoriesIds?.length === 0 ? '' : getOnlyCategoriesIds,
        talentLangLevel: '',
      },
      page: 0,
    })

    if (data?.info?.length === 0) return
    await setId(data?.info?.[0]?.id)
  }

  useEffect(() => {
    if (
      (isSuccessCategories && currentCategories?.length === 0) ||
      currentCategories?.length === 0
    ) {
      setFilteredCategories(
        filteredCategories?.length === 0 ? categoriesData : filteredCategories
      )
      setCurrentCategories(
        currentCategories?.length === 0 ? categoriesData : currentCategories
      )
    }
  }, [isSuccessCategories])

  useEffect(() => {
    if (
      (isSuccessSkills && currentSkills?.length === 0) ||
      filteredSkills?.length === 0
    ) {
      setCurrentSkills(
        selectedSkills?.length === 0 ? transformSkillsData : currentSkills
      )
      setFilteredSkills(
        filteredSkills?.length === 0 ? transformSkillsData : filteredSkills
      )
    }
  }, [isSuccessSkills])

  return (
    <div
      className={`${
        !isSuperSearch
          ? 'border border-bg-CTA-primary border-4 p-3 super-search-container shadow'
          : ''
      }   d-flex flex-column flex-lg-row align-items-center gap-2 `}
      style={{
        borderRadius: '16px',
      }}
    >
      <div className="dropdown position-relative super-search-field">
        <ButtonSelect
          textClassName="font-small"
          text={t('search_by_category')}
          role="button"
          data-bs-toggle="collapse"
          href="#filter-by-category-collapse"
          className={`${
            !isSuperSearch
              ? 'p-2 super-search-category-input'
              : 'bg-on-bg-primary border border-on-bg-Tertiary text-white'
          }`}
          arrowFill={!isSuperSearch ? '#004949' : '#ffffff'}
        />
        <div
          id="filter-by-category-collapse"
          className="collapse position-absolute font-small rounded bg-white shadow-sm p-2 text-on-bg-primary"
          style={{ zIndex: 100 }}
        >
          <SearchInput
            className="form-control-sm mb-2"
            onChange={(e) => {
              const term = e.target.value
              setSearchTermCategory(term)

              const categoriesFiltered = filterValue({
                data: currentCategories ?? [],
                term,
              })

              setFilteredCategories(categoriesFiltered)
            }}
            value={searchTermCategory}
            placeholder={t('search')}
          />

          <div className="overflow-auto mt-3" style={{ height: '150px' }}>
            {filteredCategories?.map(({ id, name }) => (
              <div
                key={id}
                className="custom-control custom-checkbox form-check p-0"
                id={id}
                value={searchTermCategory}
              >
                <input
                  className="me-2 border border-2 border-on-bg-primary mycheck"
                  type="checkbox"
                  value={id}
                  key={id}
                  checked={selectedCategories.some(
                    (selectedCategory) => selectedCategory?.id === id
                  )}
                  onChange={() =>
                    handleDataChange({
                      data: currentCategories ?? [],
                      serviceData: categoriesData,
                      searchTerm: searchTermCategory ?? '',
                      id,
                      setSelectedData: setSelectedCategories,
                      setCurrentData: setCurrentCategories,
                      setFilteredData: setFilteredCategories,
                    })
                  }
                />
                <label className="custom-control-label">{name}</label>
              </div>
            ))}
          </div>
          <div>
            <div className="d-flex flex-wrap mt-3 gap-1">
              {selectedCategories?.map(({ id, name }) => (
                <span
                  key={id}
                  className="d-flex align-items-center text-on-bg-CTA-primary-selected bg-bg-CTA-primary-selected badge rounded rounded-pill cursor-pointer"
                >
                  {name}
                  <div
                    onClick={() =>
                      handleDeleteData({
                        id,
                        data: currentCategories ?? [],
                        setCurrentData: setCurrentCategories,
                        setSelectedData: setSelectedCategories,
                      })
                    }
                  >
                    <Xicon className="cursor-pointer" fill="#004949" />
                  </div>
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="dropdown position-relative super-search-field">
        <ButtonSelect
          textClassName="font-small"
          className={`${
            !isSuperSearch
              ? 'p-2 super-search-skills-input'
              : 'bg-on-bg-primary border border-on-bg-Tertiary text-white w-100'
          }`}
          arrowFill={!isSuperSearch ? '#004949' : '#ffffff'}
          text={t('technical_skills')}
          role="button"
          data-bs-toggle="collapse"
          href="#filter-by-skills-collapse"
        />
        <div
          id="filter-by-skills-collapse"
          className="collapse position-absolute font-small rounded bg-white shadow-sm p-2 text-on-bg-primary"
          style={{ zIndex: 100 }}
        >
          <SearchInput
            className="form-control-sm mb-2"
            onChange={(e) => {
              const term = e.target.value
              setSearchTermSkill(term)

              const skillsFiltered = filterValue({
                data: currentSkills ?? [],
                term,
              })

              setFilteredSkills(skillsFiltered)
            }}
            value={searchTermSkill}
            placeholder={t('search')}
          />

          <div className="overflow-auto mt-3" style={{ height: '150px' }}>
            {filteredSkills?.map(({ id, name }) => (
              <div
                key={id}
                className="custom-control custom-checkbox form-check p-0 d-flex"
                id={id}
                value={searchTermSkill}
              >
                <input
                  className="me-2 border border-2 border-on-bg-primary mycheck"
                  type="checkbox"
                  value={id}
                  key={id}
                  checked={selectedSkills.some(
                    (selectedSkill) => selectedSkill?.id === id
                  )}
                  onChange={() =>
                    handleDataChange({
                      data: currentSkills ?? [],
                      serviceData: transformSkillsData ?? [],
                      searchTerm: searchTermSkill ?? '',
                      id,
                      setSelectedData: setSelectedSkills,
                      setCurrentData: setCurrentSkills,
                      setFilteredData: setFilteredSkills,
                    })
                  }
                />
                <label className="custom-control-label font-small-2 inline-text">
                  {name}
                </label>
              </div>
            ))}
          </div>
          <div>
            <div className="d-flex flex-wrap mt-3 gap-1">
              {selectedSkills?.map(({ id, name }) => (
                <span
                  key={id}
                  className="d-flex align-items-center text-on-bg-CTA-primary-selected bg-bg-CTA-primary-selected badge rounded rounded-pill cursor-pointer"
                >
                  {name}
                  <div
                    onClick={() =>
                      handleDeleteData({
                        id,
                        data: currentSkills ?? [],
                        setCurrentData: setCurrentSkills,
                        setSelectedData: setSelectedSkills,
                      })
                    }
                  >
                    <Xicon className="cursor-pointer" fill="#004949" />
                  </div>
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Button
        className={`${
          !isSuperSearch ? 'px-5 py-2 text-white' : 'text-on-bg-primary'
        } btn-bg-CTA-primary super-search-field`}
        type="submit"
        disabled={
          selectedCategories?.length === 0 && selectedSkills?.length === 0
        }
        onClick={() => {
          if (
            selectedCategories?.length === 0 &&
            selectedSkills?.length === 0
          ) {
            return
          }

          if (firstSearch === false) {
            setFirstSearch(true)
          }

          if (viewportSize[0] > VIEWPORT_LG) {
            getFirstTalentPositionId()
          }

          setActionButton(actionButton + 1)
          setGlobalPage(0)
          setData([])
          setDataWithoutBanners([])
          navigate('/super-search')
        }}
      >
        {t('see_talents')}
      </Button>
    </div>
  )
}

export default SearchTalents
