export const initialState = {
  showDetail: false,
  isEdit: false,
  talentId: null,
  recruiterId: null,
  jobOfferId: null,
  offerId: null,
  showMatch: false,
  idOffer: 0,
  idFavoriteOffer: 0,
  prevRoute: false,
  label: 'dashboard',
  renderComponentInCompany: 'dashboard',
}
