const ArrowRigthIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="mask0_5639_7891"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5639_7891)">
        <path
          d="M9.69999 18L8.29999 16.6L12.9 12L8.29999 7.4L9.69999 6L15.7 12L9.69999 18Z"
          fill="#004949"
        />
      </g>
    </svg>
  )
}

export default ArrowRigthIcon
