const StarTwoIcon = ({ fill = '#01A76A', bgFill = '#CFF2DF' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
    >
      <circle cx="24.7168" cy="24.0527" r="24" fill={bgFill} />
      <mask
        id="mask0_2451_49445"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="33"
        height="33"
      >
        <rect x="8.7168" y="8.05273" width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2451_49445)">
        <path
          d="M20.5169 31.8194L24.7169 29.2861L28.9169 31.8527L27.8169 27.0527L31.5169 23.8527L26.6502 23.4194L24.7169 18.8861L22.7835 23.3861L17.9169 23.8194L21.6169 27.0527L20.5169 31.8194ZM16.4835 37.3861L18.6502 28.0194L11.3835 21.7194L20.9835 20.8861L24.7169 12.0527L28.4502 20.8861L38.0502 21.7194L30.7835 28.0194L32.9502 37.3861L24.7169 32.4194L16.4835 37.3861Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default StarTwoIcon
