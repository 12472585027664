const MenuBurgerTwoIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1522_26006"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="25"
      >
        <rect y="0.386475" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1522_26006)">
        <path
          d="M3 18.3865V16.3865H21V18.3865H3ZM3 13.3865V11.3865H21V13.3865H3ZM3 8.38647V6.38647H21V8.38647H3Z"
          fill="#FAFAFA"
        />
      </g>
    </svg>
  )
}

export default MenuBurgerTwoIcon
