import { token } from '../../localStorage'
import { BASE_URL } from '../constants'

export const listOfTalents = ({ filters, page = 0 }) => {
  const validation =
    token === undefined ? 'outside/getcandidates' : '/getcandidates'

  const rawBody = JSON.stringify({
    talentCategory: filters.talentCategory || '',
    talentLangLevel: filters.talentLangLevel || '',
    talentSkills: filters.talentSkills || [],
    yearsExperience: filters.yearsExperience || '',
    countries: [],
    modalities: [],
  })

  const URL = `${BASE_URL}${validation}/${page}`

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })
      .then((response) => response.json())
      .then((res) => res.info)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const superSearchListOfTalents = ({ filters, page = 0 }) => {
  const validation =
    token === undefined ? 'outside/getcandidates' : '/getcandidates'

  const rawBody = JSON.stringify({
    talentCategory: filters.talentCategory || '',
    talentLangLevel: filters.talentLangLevel || '',
    talentSkills: filters.talentSkills || [],
    yearsExperience: filters.yearsExperience || '',
    countries: [],
    modalities: [],
  })

  const URL = `${BASE_URL}${validation}/${page}`

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })
      .then((response) => response.json())
      .then((res) => res)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getCategories = () => {
  const URL = `${BASE_URL}getcategories`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res.categories)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const getListOfSkillsCategories = (param = 0) => {
  const URL = `${BASE_URL}/getskillscategories/${param || 0}`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res.skillsCategories)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const listOfSoftSkills = () => {
  const URL = `${BASE_URL}getsoftskills`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.softSkills)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const updateStatusProfileTalent = (data) => {
  const rawBody = JSON.stringify(data)

  const URL = `${BASE_URL}setvisibleuser`

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const saveTalentInfo = (data) => {
  const rawBody = JSON.stringify(data)

  const URL = `${BASE_URL}user/register-all-info`

  try {
    const response = fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const updateTalentInfo = (data) => {
  const rawBody = JSON.stringify(data)

  const URL = `${BASE_URL}user/updateAllInfo`

  try {
    const response = fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const changeUsername = async (data) => {
  const rawBody = JSON.stringify(data)

  const URL = `${BASE_URL}user/changeUsername`

  try {
    const res = await fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })
    const getData = await res.json()

    return { res, getData }
  } catch (error) {
    console.log(error)
  }
}

export const updateSendFeedbackModal = async () => {
  const URL = `${BASE_URL}user/modalSeenFromProfileExample`

  try {
    const res = await fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
    const getData = await res.json()

    return { res, getData }
  } catch (error) {
    console.log(error)
  }
}
