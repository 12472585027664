const CampaingTwoIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1721_5864"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1721_5864)">
        <circle cx="16.6011" cy="7.6886" r="4" fill="#EA3D29" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2723 2.69582C12.0658 3.50182 11.1678 4.73355 10.7932 6.17624C10.1943 6.36212 9.65495 6.69504 9.175 7.17499C8.39167 7.95832 8 8.89999 8 9.99999V17H16V13.6589C16.1977 13.6785 16.3981 13.6886 16.601 13.6886C17.0827 13.6886 17.5511 13.6318 18 13.5246V17H20V19H4V17H6V9.99999C6 8.61666 6.41667 7.38749 7.25 6.31249C8.08333 5.23749 9.16667 4.53332 10.5 4.19999V3.49999C10.5 3.08332 10.6458 2.72916 10.9375 2.43749C11.2292 2.14582 11.5833 1.99999 12 1.99999C12.4167 1.99999 12.7708 2.14582 13.0625 2.43749C13.1438 2.51875 13.2137 2.60486 13.2723 2.69582ZM10.5875 21.4125C10.9792 21.8042 11.45 22 12 22C12.55 22 13.0208 21.8042 13.4125 21.4125C13.8042 21.0208 14 20.55 14 20H10C10 20.55 10.1958 21.0208 10.5875 21.4125Z"
          fill="#004949"
        />
      </g>
    </svg>
  )
}

export default CampaingTwoIcon
