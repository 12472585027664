import { DICTIONARY } from '../../utils/constants'

export const LIST_OF_DASHBOARD_OPTIONS = {
  dashboard: 'Dashboard',
  my_resume: 'Mi resumen',
  personal_data: 'Datos personales',
  professional_data: 'Datos profesionales',
  change_password: 'Cambio de contraseña',
  close_session: 'Cerrar sesión',
  invite_talent: 'Invitar al talento',
  incomplete_profiles: 'Perfiles incompletos',
  complete_profiles: 'Perfiles completados',
  processes: 'Procesos',
  work_offers: 'Ofertas de trabajo',
  my_favorites: 'Mis favoritos',
  offer_management: 'Gestión de ofertas',
  Data_of_your_company: 'Datos de tu empresa',
  my_network: 'Mi red',
  work_experience: 'Experiencia laboral',
  search_talent: 'Buscar talentos',
  all_offers: 'Todas las ofertas',
  offers_sent: 'Ofertas enviadas',
  see_all_talents: 'Ver todos los talentos',
  recruiters: 'Reclutadores',
  talents: 'Talentos',
  companies: 'Empresas',
  change_email: 'Cambiar correo electrónico',
  send_emails: 'Enviar correos',
  list_Of_Skills: 'Habilidades',
  list_Of_Sectors: 'Sectores',
  list_Of_Categories: 'Categorias',
  list_of_city: 'Ciudades',
  list_of_idioms: 'Idiomas',
  list_of_fast_offers: 'Creadas',
  list_of_order_companies: 'Compañias rapidas',
  list_of_validate_fast_offers: 'Ofertas validadas',
  offers: 'Ofertas',
  list_of_states: 'Estados',
  list_of_hiring_advisors: 'HA',
  my_companies: 'Mis compañias',
  support: DICTIONARY.support,
  quick_offer: 'Ofertas rápidas',
  tm_ranking: 'Ranking TM',
}

export const LIST_OF_HEADER_DASHBOARD_LABELS = {
  profile_visits: 'Visitas a tu perfil',
  saved_to_favorites: 'Guardado en favoritos',
  offers_received: 'Ofertas recibidas',
  my_profiles: 'Mis perfiles',
  my_favorites: 'En favoritos',
  incomplete: 'Incompletos',
  hired: 'Contratados',
  offers: 'Ofertas',
  accepted: 'Aceptados',
  validated: 'Validados',
  default: 'No disponible',
  registered_companies: 'Empresas registradas',
  registered_recruiters: 'Talent Managers registrados',
  registered_talents: 'Talentos registrados',
}
