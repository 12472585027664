import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { SkeletonTheme } from 'react-loading-skeleton'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import './i18n'

import GlobalStateProvider from './store/GlobalStateProvider'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'
import 'react-loading-skeleton/dist/skeleton.css'
import './global.css'
import './index.css'
import 'swiper/css';

import { GoogleOAuthProvider } from '@react-oauth/google'
import { GOOGLE_CLIENT_ID } from './services/constants'
import AppRouter from './Routes/AppRouter'
import { SKELETON_THEME } from './utils/constants'

export const globalBootstrap = bootstrap

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <QueryClientProvider client={queryClient}>
    <GlobalStateProvider>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <SkeletonTheme
          baseColor={SKELETON_THEME.baseColor}
          highlightColor={SKELETON_THEME.highlightColor}
        >
          <AppRouter />
        </SkeletonTheme>
      </GoogleOAuthProvider>
    </GlobalStateProvider>
  </QueryClientProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
