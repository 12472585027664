import Tooltip from '../../Tooltip'

const SidebarButton = ({
  Icon = () => {},
  IconTwo = () => {},
  title = '',
  onClick = () => {},
  className = '',
  titleClassName = '',
  hoverTitle = '',
  tooltipClassName = '',
  tooltipPosition = 'top',
  tooltipText = '',
  isTooltip = false,
  isTooltipContainer = false,
  isTooltipCustomTwo = true,
  isJSX = false,
  RenderJSX = () => <></>,
  ...rest
}) => {
  return (
    <>
      {isTooltip ? (
        <Tooltip
          className={tooltipClassName}
          position={tooltipPosition}
          style={{
            width: '300px',
          }}
          text={tooltipText}
          isTooltipContainer={isTooltipContainer}
          isTooltipCustomTwo={isTooltipCustomTwo}
          isJSX={isJSX}
          textContainerClassName='font-small-2'
          RenderJSX={RenderJSX}
        >
          <div
            {...rest}
            className={`sidebar-button p-1 ${className}`}
            title={hoverTitle}
            onClick={() => onClick()}
          >
            <div className="inline-text d-flex justify-content-start align-items-center gap-2">
              <Icon />{' '}
              <span className={`font-small ${titleClassName}`}>{title}</span>{' '}
              <IconTwo />
            </div>
          </div>
        </Tooltip>
      ) : (
        <div
          {...rest}
          className={`sidebar-button p-1 ${className}`}
          title={hoverTitle}
          onClick={() => onClick()}
        >
          <div className="inline-text d-flex justify-content-start align-items-center gap-2">
            <Icon />{' '}
            <span className={`font-small ${titleClassName}`}>{title}</span>{' '}
            <IconTwo />
          </div>
        </div>
      )}
    </>
  )
}

export default SidebarButton

// height: 40px;
//   width: 224px;
//   left: 0px;
//   top: 8px;
//   border-radius: 24px;
//   padding: 0px 16px 0px 32px;
