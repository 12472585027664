const MenuBurgerIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={className}
    >
      <mask
        id="mask0_5442_10138"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="24"
      >
        <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5442_10138)">
        <path
          d="M3.5 18V16H21.5V18H3.5ZM3.5 13V11H21.5V13H3.5ZM3.5 8V6H21.5V8H3.5Z"
          fill="#004949"
        />
      </g>
    </svg>
  )
}

export default MenuBurgerIcon
