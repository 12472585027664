import UserTwoIcon from '../../../icons/UserTwoIcon'
import ArrowRigthIcon from '../../../icons/chat/ArrowRigthIcon'
import OfflineIcon from '../../../icons/chat/OfflineIcon'
import OnlineIcon from '../../../icons/chat/OnlineIcon'
import { TYPE_OF_CHAT_USER } from '../../../utils/constants'

const SingleChatCard = ({
  user = {},
  userId,
  setId,
  setPrevId,
  allProps,
  setDetailData,
  unseenMessages,
  setStateViewdMsgMutate,
  prevId,
}) => {
  const { username = '', typeProfile = '', connected } = user || {}

  const NOT_NEW_MESSAGE = unseenMessages === '0'

  return (
    <div
      className={`${
        prevId === userId ? 'bg-on-bg-Tertiary-hover' : 'single-chat-container'
      } cursor-pointer mb-2 p-2`}
      onClick={() => {
        setId(userId)
        setPrevId(userId)
        setDetailData(allProps)
        setStateViewdMsgMutate({
          idChat: userId,
        })
      }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center gap-2">
          <div className="position-relative">
            <UserTwoIcon width="36" height="36" />
            <div className="position-absolute offline-icon">
              {connected ? <OnlineIcon /> : <OfflineIcon />}
            </div>
          </div>
          <div className="d-flex flex-column font-extra-small">
            <span className="fw-bolder text-on-bg-primary font-normal">
              {username}
            </span>
            <span>{TYPE_OF_CHAT_USER[typeProfile] || ''}</span>
          </div>
        </div>
        <ArrowRigthIcon />
      </div>
      {!NOT_NEW_MESSAGE && (
        <div className="d-flex align-items-center justify-content-end pe-3">
          <span className="badge bg-secondary rounded-pill fw-light  bg-bg-CTA-primary">
            {unseenMessages}
          </span>
        </div>
      )}
    </div>
  )
}

export default SingleChatCard
