const LinkedinIcon = ({ className }) => {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M31.6667 5.92725C32.5507 5.92725 33.3986 6.27844 34.0237 6.90356C34.6488 7.52868 35 8.37652 35 9.26058V32.5939C35 33.478 34.6488 34.3258 34.0237 34.9509C33.3986 35.5761 32.5507 35.9272 31.6667 35.9272H8.33333C7.44928 35.9272 6.60143 35.5761 5.97631 34.9509C5.35119 34.3258 5 33.478 5 32.5939V9.26058C5 8.37652 5.35119 7.52868 5.97631 6.90356C6.60143 6.27844 7.44928 5.92725 8.33333 5.92725H31.6667ZM30.8333 31.7606V22.9272C30.8333 21.4862 30.2609 20.1042 29.2419 19.0853C28.223 18.0664 26.841 17.4939 25.4 17.4939C23.9833 17.4939 22.3333 18.3606 21.5333 19.6606V17.8106H16.8833V31.7606H21.5333V23.5439C21.5333 22.2606 22.5667 21.2106 23.85 21.2106C24.4688 21.2106 25.0623 21.4564 25.4999 21.894C25.9375 22.3316 26.1833 22.9251 26.1833 23.5439V31.7606H30.8333ZM11.4667 15.1939C12.2093 15.1939 12.9215 14.8989 13.4466 14.3738C13.9717 13.8487 14.2667 13.1365 14.2667 12.3939C14.2667 10.8439 13.0167 9.57725 11.4667 9.57725C10.7196 9.57725 10.0032 9.874 9.47498 10.4022C8.94675 10.9305 8.65 11.6469 8.65 12.3939C8.65 13.9439 9.91667 15.1939 11.4667 15.1939ZM13.7833 31.7606V17.8106H9.16667V31.7606H13.7833Z"
        fill="white"
      />
    </svg>
  )
}

export default LinkedinIcon
