import { StyleSheet } from '@react-pdf/renderer'
import { PDF_PALET_OF_COLORS } from '../constants/pdfConstants'

const {
  on_bg_primary,
  // bg_cta_primary,
  bg_tertiary,
  on_bg_tertiary,
  cta_primary_pressed,
  test,
} = PDF_PALET_OF_COLORS

export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#F5F5F5',
    fontFamily: 'Mulish',
    paddingTop: '28px',
    paddingLeft: '24px',
    paddingRight: '28px',
    paddingBottom: '',
    width: '100%'
  },
  header: {
    padding: '0px 16px 0px 16px',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${cta_primary_pressed}`,
    borderRadius: '8px',
    backgroundColor: test,
    width: '100%'
  },
  header_container: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    fontSize: '24px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'ultrabold',
    textAlign: 'start',
    color: on_bg_primary,
    marginTop: '4px',
  },
  pdf_general_skills: {
    width: '50%',
    backgroundColor: bg_tertiary,
    height: '89.8vh',
  },
  section_one: {
    width: '100%',
  },
  section_Two: {
    width: '100%',
    flexGrow: 1,
  },
  progressBar_container: {
    width: '100%',
    height: 9,
    backgroundColor: on_bg_tertiary,
    borderRadius: '20px',
  },
  progressBar_title: {
    display: 'flex',
    flexDirection: 'row',
  },
  sumary: {
    width: '100%',
    height: 'auto',
    overflow: 'auto',
    fontSize: '12px',
    fontFamily: 'Mulish',
    color: on_bg_primary,
  },
  font_size_extra_small: {
    fontSize: '12px',
  },
  work_experience_card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: '#FFFFFF',
    padding: '8px',
    marginTop: '12px',
    borderRadius: '8px',
  },
  work_experience_card_container: {},
})
