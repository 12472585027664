const ArrowUpIcon = ({ width = '16', height = '16', fill = '#FAFAFA' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_7286_12524"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_7286_12524)">
        <path
          d="M4.93333 10.4667L4 9.53333L8 5.53333L12 9.53333L11.0667 10.4667L8 7.39999L4.93333 10.4667Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default ArrowUpIcon
