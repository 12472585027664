const RegisterFastIconFour = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={251}
    height={211}
    fill="none"
    {...props}
  >
    <path
      fill="#E0E0E0"
      d="M142.858 17.957a4.764 4.764 0 0 0-.675 1.635l-.915.085v1.77l.915.085c.12.575.34 1.13.675 1.635l-.585.705 1.25 1.25.705-.585c.505.33 1.06.555 1.635.675l.085.915h1.77l.085-.915a4.764 4.764 0 0 0 1.635-.675l.705.585 1.25-1.25-.585-.705c.33-.505.555-1.06.675-1.635l.915-.085v-1.77l-.915-.085a4.764 4.764 0 0 0-.675-1.635l.585-.705-1.25-1.25-.705.585a4.764 4.764 0 0 0-1.635-.675l-.085-.915h-1.77l-.085.915c-.575.12-1.13.345-1.635.675l-.705-.585-1.25 1.25.585.705Zm5.67.915a2.393 2.393 0 1 1-3.384 3.385 2.393 2.393 0 0 1 3.384-3.385ZM215.668 44.337a4.764 4.764 0 0 0-.675 1.635l-.915.085v1.77l.915.085c.12.575.34 1.13.675 1.635l-.585.705 1.25 1.25.705-.585c.505.33 1.06.555 1.635.675l.085.915h1.77l.085-.915a4.764 4.764 0 0 0 1.635-.675l.705.585 1.25-1.25-.585-.705c.33-.505.555-1.06.675-1.635l.915-.085v-1.77l-.915-.085a4.764 4.764 0 0 0-.675-1.635l.585-.705-1.25-1.25-.705.585a4.764 4.764 0 0 0-1.635-.675l-.085-.915h-1.77l-.085.915c-.575.12-1.13.345-1.635.675l-.705-.585-1.25 1.25.585.705Zm5.67.915a2.393 2.393 0 1 1-3.384 3.385 2.393 2.393 0 0 1 3.384-3.385ZM32.453 43.322a4.765 4.765 0 0 0-.675 1.636l-.915.084v1.77l.915.086c.12.574.345 1.13.675 1.634l-.585.706 1.25 1.25.705-.585c.505.33 1.06.555 1.635.675l.085.914h1.77l.085-.914a4.764 4.764 0 0 0 1.635-.675l.705.584 1.25-1.25-.585-.705c.33-.504.555-1.06.675-1.634l.915-.086v-1.77l-.915-.084a4.765 4.765 0 0 0-.675-1.636l.585-.705-1.25-1.25-.705.586a4.764 4.764 0 0 0-1.635-.675l-.085-.916h-1.77l-.085.916c-.575.12-1.13.345-1.635.675l-.705-.586-1.25 1.25.585.706Zm5.67.915a2.393 2.393 0 1 1-3.384 3.386 2.393 2.393 0 0 1 3.384-3.386Z"
    />
    <path
      fill="#E0E0E0"
      d="M222.909 140.353h-90.375a2.934 2.934 0 0 1-2.93-2.93V5.783a2.934 2.934 0 0 1 2.93-2.93h90.375a2.934 2.934 0 0 1 2.93 2.93v131.64a2.934 2.934 0 0 1-2.93 2.93ZM132.534 2.978a2.81 2.81 0 0 0-2.805 2.805v131.64a2.81 2.81 0 0 0 2.805 2.805h90.375a2.81 2.81 0 0 0 2.805-2.805V5.783a2.81 2.81 0 0 0-2.805-2.805h-90.375ZM118.693 140.353h-90.37a2.934 2.934 0 0 1-2.93-2.93V5.783a2.934 2.934 0 0 1 2.93-2.93h90.37a2.934 2.934 0 0 1 2.93 2.93v131.64a2.934 2.934 0 0 1-2.93 2.93ZM28.323 2.978a2.81 2.81 0 0 0-2.805 2.805v131.64a2.81 2.81 0 0 0 2.805 2.805h90.37a2.81 2.81 0 0 0 2.805-2.805V5.783a2.81 2.81 0 0 0-2.805-2.805h-90.37ZM250.948 170.888h-250v.125h250v-.125ZM34.813 178.018h-8.03v.125h8.03v-.125ZM112.888 176.663H86.123v.125h26.765v-.125ZM53.438 182.663H35.313v.125h18.125v-.125ZM211.718 176.598h-21.595v.125h21.595v-.125ZM219.338 176.598h-3.165v.125h3.165v-.125ZM185.998 179.713h-26.945v.125h26.945v-.125Z"
    />
    <path
      fill="#E0E0E0"
      d="M211.263 125.973a5.89 5.89 0 0 0-.835 2.02l-1.125.105v2.185l1.13.104c.145.71.425 1.396.835 2.021l-.72.869 1.545 1.546.87-.72a5.89 5.89 0 0 0 2.02.835l.105 1.13h2.185l.105-1.13a5.812 5.812 0 0 0 2.02-.835l.87.72 1.545-1.546-.72-.869c.41-.625.685-1.311.835-2.021l1.125-.104v-2.185l-1.125-.105a5.793 5.793 0 0 0-.835-2.02l.72-.87-1.545-1.546-.87.72a5.887 5.887 0 0 0-2.02-.834l-.105-1.131h-2.185l-.105 1.131a5.791 5.791 0 0 0-2.02.834l-.87-.72-1.545 1.546.72.87h-.005Zm7 1.125a2.953 2.953 0 0 1 0 4.179 2.954 2.954 0 0 1-4.18 0 2.953 2.953 0 0 1 0-4.179 2.954 2.954 0 0 1 4.18 0ZM36.198 123.737a4.764 4.764 0 0 0-.675 1.635l-.915.085v1.77l.915.085c.12.575.345 1.13.675 1.635l-.585.705 1.25 1.25.705-.585c.505.33 1.06.555 1.635.675l.085.915h1.77l.085-.915a4.764 4.764 0 0 0 1.635-.675l.705.585 1.25-1.25-.585-.705c.33-.505.555-1.06.675-1.635l.915-.085v-1.77l-.915-.085a4.764 4.764 0 0 0-.675-1.635l.585-.705-1.25-1.25-.705.585a4.764 4.764 0 0 0-1.635-.675l-.085-.915h-1.77l-.085.915c-.575.12-1.13.34-1.635.675l-.705-.585-1.25 1.25.585.705Zm5.67.915a2.393 2.393 0 1 1-3.383 3.385 2.393 2.393 0 0 1 3.383-3.385ZM31.368 9.158c-9.49 4.375-15.985 10.505-14.515 13.694 1.47 3.19 10.355 2.23 19.84-2.145s15.985-10.51 14.515-13.695c-1.47-3.184-10.355-2.23-19.84 2.146Zm4.68 10.144c-8.3 3.826-16 4.816-17.2 2.215-1.2-2.604 4.555-7.81 12.85-11.634 8.295-3.825 15.995-4.82 17.2-2.22 1.205 2.604-4.555 7.814-12.85 11.64Z"
    />
    <path
      fill="#E6E6E6"
      d="M45.092 17.777c2-5.599-.919-11.758-6.518-13.758-5.599-1.999-11.759.92-13.758 6.519-2 5.599.92 11.758 6.518 13.758 5.6 1.999 11.76-.92 13.758-6.518Z"
    />
    <g fill="#FAFAFA" opacity={0.2}>
      <path d="M25.683 8.732c.145-.28.31-.545.5-.795 3.25-.965 9.51-2.46 12.36-3.91.43.145.85.325 1.255.535-3.555.815-10.925 3.425-14.115 4.175v-.005ZM24.848 10.457c2.465-.8 5.02-1.305 7.61-1.505 1.74 0 6.895-2.025 8.715-3.575.145.095.28.2.41.315-4.465 1.88-9.905 6.53-12.415 7.875a27.314 27.314 0 0 1-4.855 2.07 10.204 10.204 0 0 1-.075-2.47c.09-.93.295-1.84.615-2.72l-.005.01Z" />
    </g>
    <path
      fill="#E0E0E0"
      d="M24.449 16.448c.785-.186 1.56-.41 2.32-.675 2.945-1 8.26-5.296 12.965-7.27 1.765-.735 2.78-1.16 3.37-1.39.18.215.355.43.52.675a36.213 36.213 0 0 1-4.18 2.09c-2.995 1.255-6.19 2.955-8.035 4.49-1.41 1.165-4.97 2.7-6.63 3.264a8.17 8.17 0 0 1-.33-1.184ZM25.048 18.348c2.71-.67 5.46-1.18 8.235-1.515 4.665-.54 6.435-2.61 8.345-4.58.95-.93 2.035-1.706 3.225-2.3.175.4.325.81.44 1.225a32.405 32.405 0 0 1-7.05 4.47c-4.42 2.07-10.555 3.075-12.855 3.404a4.603 4.603 0 0 1-.335-.71l-.005.006ZM27.483 21.887c4.046-.52 7.98-1.7 11.646-3.485 3-1.475 5.14-2.645 6.56-3.455.005.066.005.136 0 .2-.035.316-.07.62-.13.926-3.37 1.8-11.17 5.514-17.98 5.895-.046-.006-.076-.046-.1-.08h.005Z"
    />
    <path
      fill="#F0F0F0"
      d="M27.133 6.767a10.794 10.794 0 0 1 8.815-3.32c.255 0 .52.06.77.1-2.295 1.065-6.55 2.805-9.585 3.215v.005Z"
    />
    <path
      fill="#E0E0E0"
      d="M30.323 23.867c2.7-.64 9.59-2.375 12.525-3.98.545-.31 1.105-.58 1.685-.814a7.8 7.8 0 0 1-.585 1.01c-2.1.915-6.15 2.595-8.435 2.97-2.025.325-3.83.674-4.915.934a1.307 1.307 0 0 1-.27-.12h-.005ZM39.428 5.417s-3.945 2.44-5.72 2.74a21.54 21.54 0 0 1-3.26.305s5.705-2.925 8.975-3.04l.005-.005ZM47.703 6.628c.52.19.95.57 1.195 1.065 1.2 2.604-4.555 7.815-12.85 11.64s-16 4.815-17.2 2.215c-.2-.505-.2-1.07 0-1.58l-2.03.934a2.76 2.76 0 0 0 .045 1.98c1.47 3.19 10.355 2.23 19.84-2.145 9.485-4.374 15.985-10.51 14.515-13.694a2.727 2.727 0 0 0-1.475-1.32l-2.035.905h-.005ZM114.459 40.332a2.86 2.86 0 1 0 0-5.72 2.86 2.86 0 0 0 0 5.72ZM151.584 48.492a1.945 1.945 0 1 0 0-3.89 1.945 1.945 0 0 0 0 3.89ZM202.094 88.052a1.945 1.945 0 1 0 0-3.89 1.945 1.945 0 0 0 0 3.89ZM165.449 134.062a1.08 1.08 0 1 0 0-2.16 1.08 1.08 0 0 0 0 2.16ZM135.484 34.197a1.08 1.08 0 1 0 0-2.16 1.08 1.08 0 0 0 0 2.16ZM206.644 114.273a1.306 1.306 0 0 1-2.61 0 1.306 1.306 0 0 1 2.61 0ZM134.179 137.237a1.305 1.305 0 1 0 0-2.61 1.305 1.305 0 0 0 0 2.61ZM49.344 109.547a1.306 1.306 0 0 1-2.61 0 1.306 1.306 0 0 1 2.61 0ZM93.764 141.213a1.305 1.305 0 1 0-.001-2.611 1.305 1.305 0 0 0 0 2.611Z"
    />
    <path
      fill="#E0E0E0"
      d="m135.483 136.193-.075-.02-87.825-26.21.275-.295 66.655-71.96 90.805 76.205-.43.14-69.4 22.14h-.005Zm-86.985-26.485 86.98 25.96 68.82-21.955-89.745-75.315-66.055 71.315v-.005Z"
    />
    <path
      fill="#E0E0E0"
      d="m134.098 136.392-.105-.125-57.45-65.69 75.215-24.325.11.135 53.37 67.55-.345.11-70.785 22.34-.01.005Zm-56.685-65.57 56.845 64.995 70.13-22.135-52.81-66.845-74.16 23.98-.005.005Z"
    />
    <path
      fill="#E0E0E0"
      d="m93.009 140.357-.03-.245c-3.83-32.345-11.19-93.864-11.62-95.17l-.345-.55.645.215 102.2 29.066-49.555 62.669-41.3 4.015h.005Zm-11.105-95.16c1.105 6.63 9.69 78.955 11.545 94.615l40.6-3.945 48.955-61.92-101.1-28.75Z"
    />
    <path
      fill="#E0E0E0"
      d="m165.954 133.192-.135-.01-76.51-6.295-.065-.125c-5.31-10.375-22.605-44.305-22.24-45.345l.035-.105.105-.045 84.555-35.01.115.09 50.485 39.71-.15.195-36.2 46.935.005.005Zm-76.325-6.785 76.095 6.265 35.885-46.525-49.98-39.315-84.11 34.825c.635 2.37 13.425 27.775 22.115 44.75h-.005Z"
    />
    <path
      fill="#E0E0E0"
      d="m146.679 141.987-.29-.09-77.52-23.815 66.635-85.71 19.405 31-.01.085-8.22 78.53Zm-76.97-24.17 76.54 23.515 8.15-77.845-18.935-30.25-65.75 84.58h-.005ZM127.993 153.977a8.533 8.533 0 0 0-1.22 2.95l-1.65.155v3.19l1.65.155a8.493 8.493 0 0 0 1.22 2.95l-1.055 1.27 2.255 2.255 1.27-1.055a8.533 8.533 0 0 0 2.95 1.22l.155 1.65h3.19l.155-1.65a8.493 8.493 0 0 0 2.95-1.22l1.27 1.055 2.26-2.255-1.055-1.27a8.533 8.533 0 0 0 1.22-2.95l1.65-.155v-3.19l-1.65-.155a8.493 8.493 0 0 0-1.22-2.95l1.055-1.27-2.255-2.255-1.27 1.055a8.533 8.533 0 0 0-2.95-1.22l-.155-1.65h-3.195l-.155 1.65a8.493 8.493 0 0 0-2.95 1.22l-1.27-1.055-2.255 2.26 1.055 1.27v-.005Zm10.235 1.65a4.315 4.315 0 0 1 0 6.11 4.315 4.315 0 0 1-6.11 0 4.32 4.32 0 0 1 0-6.11 4.315 4.315 0 0 1 6.11 0Z"
    />
    <path
      fill="#F5F5F5"
      d="M227.658 203.387c0 1.695-46.76 3.065-104.435 3.065-57.674 0-104.434-1.375-104.434-3.065s46.76-3.064 104.434-3.064c57.675 0 104.435 1.374 104.435 3.064Z"
    />
    <path
      fill="url(#a)"
      d="M99.703 195.507c-1.605-2.015-4.35-3.06-6.895-2.62.055-2.92-1.195-5.84-3.34-7.82-2.145-1.98-5.155-2.99-8.06-2.705-.1-4.68-4.685-8.725-9.33-8.34v-42.665H47.133v42.665c-4.645-.385-9.23 3.66-9.33 8.34-2.905-.285-5.915.725-8.06 2.705-2.145 1.98-3.39 4.9-3.34 7.82-2.54-.44-5.285.6-6.895 2.62-1.605 2.015-2.01 4.925-1.01 7.305h82.22c1-2.38.595-5.285-1.01-7.305h-.005Z"
    />
    <path
      fill="#fff"
      d="M99.703 195.507c-1.605-2.015-4.35-3.06-6.895-2.62.055-2.92-1.195-5.84-3.34-7.82-2.145-1.98-5.155-2.99-8.06-2.705-.1-4.68-4.685-8.725-9.33-8.34v-42.665H47.133v42.665c-4.645-.385-9.23 3.66-9.33 8.34-2.905-.285-5.915.725-8.06 2.705-2.145 1.98-3.39 4.9-3.34 7.82-2.54-.44-5.285.6-6.895 2.62-1.605 2.015-2.01 4.925-1.01 7.305h82.22c1-2.38.595-5.285-1.01-7.305h-.005Z"
      opacity={0.8}
    />
    <path
      fill="url(#b)"
      d="M36.969 64.157s-12.13 6.115-15.78 21.43c-2.66 11.17-1.855 32.25 2.94 33.945 4.795 1.695 5.33-11.36 5.37-16 .04-4.64 4.39-10.304 8.265-10.644 0 0 .76-24.496-.795-28.73Z"
    />
    <path
      fill="#000"
      d="M38.783 67.198c-2.87 1.095-5.224 3.19-7.135 5.54a36.168 36.168 0 0 0-4.974 8.46 45.517 45.517 0 0 0-3.05 10.11 69.033 69.033 0 0 0-.85 11.074c-.066 4.535-.175 9.26 1.595 13.535.07.17.35.095.28-.075-1.53-3.725-1.626-7.795-1.596-11.765.03-3.745.09-7.51.62-11.224.95-6.6 3.356-13.275 7.27-18.706 2.03-2.815 4.635-5.415 7.93-6.67.175-.07.1-.345-.075-.28h-.015Z"
      opacity={0.2}
    />
    <path
      fill="url(#c)"
      d="M78.518 64.157s12.13 6.115 15.78 21.43c2.66 11.17 1.855 32.25-2.94 33.945-4.795 1.69-5.33-11.36-5.37-16-.04-4.64-4.39-10.304-8.26-10.644 0 0-.76-24.496.795-28.73h-.005Z"
    />
    <path
      fill="#000"
      d="M76.628 67.473c2.885 1.1 5.24 3.24 7.14 5.63a36.205 36.205 0 0 1 4.85 8.38 45.272 45.272 0 0 1 2.995 10.055c.635 3.64.76 7.335.8 11.025.06 4.455.155 9.08-1.57 13.28-.07.17.21.245.28.075 1.55-3.775 1.63-7.905 1.595-11.93-.03-3.795-.1-7.605-.65-11.365a45.317 45.317 0 0 0-2.855-10.44 37.992 37.992 0 0 0-4.67-8.526c-2.025-2.74-4.605-5.22-7.835-6.454-.175-.07-.25.21-.075.28l-.005-.01Z"
      opacity={0.2}
    />
    <path
      fill="url(#d)"
      d="M74.349 110.707h-33.51s-6.135-23.58-3.73-50.02a65.244 65.244 0 0 1 5.265-20.3c.04-.1.085-.2.13-.294.7-1.596 1.44-3.1 2.2-4.53a71.99 71.99 0 0 1 11.04-15.51 2.656 2.656 0 0 1 3.99 0 72.012 72.012 0 0 1 13.52 20.674l.125.286a65.489 65.489 0 0 1 4.995 19.675c2.4 26.454-4.015 50.019-4.015 50.019h-.01Z"
    />
    <path
      fill="#fff"
      d="M74.349 110.707h-33.51s-6.135-23.58-3.73-50.02a65.244 65.244 0 0 1 5.265-20.3c.04-.1.085-.2.13-.294.7-1.596 1.44-3.1 2.2-4.53a71.99 71.99 0 0 1 11.04-15.51 2.656 2.656 0 0 1 3.99 0 72.012 72.012 0 0 1 13.52 20.674l.125.286a65.489 65.489 0 0 1 4.995 19.675c2.4 26.454-4.015 50.019-4.015 50.019h-.01Z"
      opacity={0.5}
    />
    <path fill="url(#e)" d="M70.644 107.348h-25.13v.385h25.13v-.385Z" />
    <path
      fill="#2E353A"
      d="M75.114 119.683h-33.88c-2.416-3.475.14-8.975.14-8.975h33.05s2.54 4.49.684 8.975h.005Z"
    />
    <path
      fill="#2E353A"
      d="M66.553 122.833h-16.94c-1.195-1.74.07-4.49.07-4.49h16.525s1.27 2.24.345 4.49Z"
    />
    <path
      fill="url(#f)"
      d="M66.564 122.833h-16.89c-3.42 1.815-11.925 6.15-10.735 13.49h38.8c1.19-7.34-7.75-11.68-11.17-13.49h-.005Z"
    />
    <path fill="#2E353A" d="M78.903 134.772H37.478v3.195h41.425v-3.195Z" />
    <path
      fill="url(#g)"
      d="M71.049 36.102c-3.056 2.186-7.73 4.636-13.316 4.636a19.28 19.28 0 0 1-13.04-5.185c.32-.605.64-1.19.965-1.765l.155-.265a71.161 71.161 0 0 1 9.92-13.476 2.656 2.656 0 0 1 3.99 0 71.16 71.16 0 0 1 9.94 13.515l.156.276c.42.734.825 1.495 1.23 2.27v-.005Z"
    />
    <path
      fill="#000"
      d="M69.823 33.843c-.98.704-2.02 1.32-3.11 1.844a20.587 20.587 0 0 1-12.34 1.77 20.974 20.974 0 0 1-8.715-3.66l.155-.264a20.317 20.317 0 0 0 3.35 1.974 20.208 20.208 0 0 0 12.28 1.596 20.624 20.624 0 0 0 8.225-3.53l.155.27Z"
      opacity={0.2}
    />
    <path
      fill="url(#h)"
      d="M73.793 64.907c-1.535-15.51-2.735-21.72-2.875-22.4.58-.314 1.12-.634 1.595-.944.29-.18.57-.366.84-.546l-.124-.285c-.285.19-.576.4-.88.575-3.19 2.015-8.32 4.42-14.496 4.42-4.864 0-9.62-1.49-13.61-4.274a23.767 23.767 0 0 1-1.765-1.35c-.044.094-.09.194-.13.294a24.467 24.467 0 0 0 3.035 2.166c-.17.86-1.355 7.12-2.864 22.34-1.195 11.89-.105 23.72 1.005 31.55.66 4.805 1.565 9.575 2.72 14.285.014.065.075.11.14.11h.035c.075-.02.12-.1.105-.175a140.803 140.803 0 0 1-2.715-14.26c-1.11-7.81-2.18-19.615-1.005-31.475 1.46-14.74 2.614-21.06 2.84-22.23a23.9 23.9 0 0 0 12.224 3.35 27.087 27.087 0 0 0 12.765-3.376c.2 1.015 1.37 7.3 2.856 22.28 1.174 11.86.105 23.66-1.005 31.475a138.343 138.343 0 0 1-2.715 14.261c-.02.075.03.155.105.175h.035a.144.144 0 0 0 .14-.11c1.154-4.71 2.06-9.48 2.72-14.285 1.124-7.846 2.2-19.675 1.02-31.56l.004-.01Z"
    />
    <path
      fill="url(#i)"
      d="M57.873 81.373c-6.13 0-11.105-4.966-11.105-11.1 0-6.13 4.965-11.105 11.1-11.105 6.13 0 11.105 4.964 11.105 11.1v.01c-.005 6.124-4.97 11.094-11.1 11.1v-.005Zm0-21.91c-5.97 0-10.81 4.84-10.81 10.81 0 5.97 4.84 10.81 10.81 10.81 5.97 0 10.81-4.84 10.81-10.81-.005-5.97-4.845-10.805-10.81-10.81Z"
    />
    <path
      fill="url(#j)"
      d="M66.279 70.272a8.404 8.404 0 0 1-8.405 8.405h-.195a8.311 8.311 0 0 1-4.04-1.14 8.517 8.517 0 0 1-2.45-2.16 8.355 8.355 0 0 1-1.72-5.1c0-.325.02-.655.055-.975a8.423 8.423 0 0 1 7.37-7.37 8.408 8.408 0 0 1 9.38 8.15v.19h.005Z"
    />
    <path
      fill="#fff"
      d="m62.969 63.602-11.78 11.78a8.355 8.355 0 0 1-1.72-5.1c0-.325.02-.655.055-.975l7.37-7.37c.325-.035.655-.055.98-.055 1.84 0 3.63.6 5.095 1.72ZM66.274 70.078l-8.6 8.6a8.311 8.311 0 0 1-4.04-1.14l11.495-11.49a8.313 8.313 0 0 1 1.145 4.034v-.004Z"
      opacity={0.1}
    />
    <path fill="#E0E0E0" d="M200.193 168.088h-24.32v34.76h24.32v-34.76Z" />
    <path
      fill="#000"
      d="M200.188 168.088h-7.915v34.825h7.915v-34.825Z"
      opacity={0.2}
    />
    <path fill="#E0E0E0" d="M165.578 178.142h-21.805v24.77h21.805v-24.77Z" />
    <path
      fill="#000"
      d="M165.583 178.142h-7.1v24.77h7.1v-24.77Z"
      opacity={0.2}
    />
    <path fill="#E0E0E0" d="M233.223 147.922h-23.77v54.99h23.77v-54.99Z" />
    <path
      fill="#000"
      d="M233.223 147.922h-7.74v54.99h7.74v-54.99Z"
      opacity={0.2}
    />
    <path
      fill="url(#k)"
      d="M215.423 16.983h-50.6a2.47 2.47 0 0 0-2.47 2.47v41.76a2.47 2.47 0 0 0 2.47 2.47h50.6a2.47 2.47 0 0 0 2.47-2.47v-41.76a2.47 2.47 0 0 0-2.47-2.47Z"
    />
    <path
      fill="#fff"
      d="M215.423 16.983h-50.6a2.47 2.47 0 0 0-2.47 2.47v41.76a2.47 2.47 0 0 0 2.47 2.47h50.6a2.47 2.47 0 0 0 2.47-2.47v-41.76a2.47 2.47 0 0 0-2.47-2.47Z"
      opacity={0.5}
    />
    <path
      fill="url(#l)"
      d="M164.668 16.983h50.955a2.277 2.277 0 0 1 2.275 2.274v5.87h-55.54v-5.83a2.316 2.316 0 0 1 2.315-2.314h-.005Z"
    />
    <path
      fill="#fff"
      d="M214.758 21.648a1.78 1.78 0 1 1-3.561-.002 1.78 1.78 0 0 1 3.561.002ZM209.703 21.648a1.78 1.78 0 1 1-3.561-.002 1.78 1.78 0 0 1 3.561.002ZM204.653 21.648a1.78 1.78 0 1 1-3.561-.002 1.78 1.78 0 0 1 3.561.002Z"
      opacity={0.5}
    />
    <path
      fill="#fff"
      d="M203.338 31.933h-28.485v1.66h28.485v-1.66Z"
      opacity={0.6}
    />
    <path
      fill="#263238"
      d="M195.683 35.523h-20.385v1.66h20.385v-1.66Z"
      opacity={0.6}
    />
    <path fill="url(#m)" d="M211.038 31.933h-5.605v1.66h5.605v-1.66Z" />
    <path fill="url(#n)" d="M172.878 31.933h-5.605v1.66h5.605v-1.66Z" />
    <path fill="url(#o)" d="M203.338 39.503h-28.485v1.66h28.485v-1.66Z" />
    <path
      fill="#fff"
      d="M211.038 39.503h-5.605v1.66h5.605v-1.66Z"
      opacity={0.6}
    />
    <path fill="url(#p)" d="M172.878 39.503h-5.605v1.66h5.605v-1.66Z" />
    <path fill="url(#q)" d="M203.338 55.288h-28.485v1.66h28.485v-1.66Z" />
    <path
      fill="#fff"
      d="M211.038 55.288h-5.605v1.66h5.605v-1.66Z"
      opacity={0.6}
    />
    <path fill="url(#r)" d="M203.338 43.498h-28.485v1.66h28.485v-1.66Z" />
    <path
      fill="#263238"
      d="M211.038 43.498h-5.605v1.66h5.605v-1.66ZM172.878 43.498h-5.605v1.66h5.605v-1.66Z"
      opacity={0.6}
    />
    <path fill="url(#s)" d="M211.453 35.587h-13.54v1.66h13.54v-1.66Z" />
    <path
      fill="#fff"
      d="M195.238 46.977h-20.385v1.66h20.385v-1.66Z"
      opacity={0.6}
    />
    <path fill="url(#t)" d="M211.008 47.043h-13.54v1.66h13.54v-1.66Z" />
    <path
      fill="#263238"
      d="M185.043 50.583h-10.19v1.66h10.19v-1.66Z"
      opacity={0.6}
    />
    <path
      fill="url(#u)"
      d="M83.518 49.502v73.575c0 1.27 1.03 2.295 2.295 2.295h101.01V49.508c0-.68-.55-1.23-1.23-1.23H84.743c-.68 0-1.225.55-1.225 1.224Z"
    />
    <path
      fill="#fff"
      d="M184.588 48.278H85.753c-1.234 0-2.235 1-2.235 2.235v72.629c0 1.235 1 2.235 2.235 2.235h98.835c1.234 0 2.235-1 2.235-2.235v-72.63a2.235 2.235 0 0 0-2.235-2.234Z"
      opacity={0.9}
    />
    <path
      fill="url(#v)"
      d="M84.793 48.278h100.99c.575 0 1.04.465 1.04 1.04v5.15H83.518v-4.91c0-.706.57-1.276 1.275-1.276v-.004Z"
    />
    <path
      fill="#fff"
      d="M185.253 51.112a1.318 1.318 0 0 1-1.545 1.55 1.309 1.309 0 0 1-1.03-1.03 1.318 1.318 0 0 1 1.545-1.55c.515.1.935.52 1.03 1.03ZM181.523 51.112a1.318 1.318 0 0 1-1.545 1.55 1.309 1.309 0 0 1-1.03-1.03 1.318 1.318 0 0 1 1.545-1.55c.515.1.935.52 1.03 1.03ZM177.788 51.112a1.318 1.318 0 0 1-1.545 1.55 1.309 1.309 0 0 1-1.03-1.03 1.318 1.318 0 0 1 1.545-1.55c.515.1.935.52 1.03 1.035v-.005Z"
      opacity={0.5}
    />
    <path
      fill="url(#w)"
      d="M179.228 92.507a58.96 58.96 0 0 0-4.555-9.375c-.99-1.655-2.23-3.39-4.195-3.96-2.425-.705-5.045.67-6.665 2.47-1.625 1.795-2.615 4.005-4.175 5.85-1.56 1.845-4.035 3.36-6.52 2.87-2.515-.495-4.545-2.925-7.07-2.465-2.49.455-3.73 3.54-6.26 3.74-1.855.145-3.335-1.35-4.355-2.775-1.82-2.535-3.275-5.325-5.53-7.55-2.255-2.225-5.615-3.845-8.84-3.14-2.205.48-3.985 1.955-5.455 3.54-2.385 2.57-4.225 5.56-5.39 8.76-.49 1.355-1.085 2.955-2.585 3.345-1.38.355-2.775-.565-3.62-1.625-.845-1.06-1.42-2.325-2.51-3.175-2.27-1.77-5.965-.975-8.055.975s-2.975 4.695-3.79 7.32v11.465h89.61l-.03-16.265-.01-.005Z"
    />
    <path
      fill="#fff"
      d="M179.228 92.507a58.96 58.96 0 0 0-4.555-9.375c-.99-1.655-2.23-3.39-4.195-3.96-2.425-.705-5.045.67-6.665 2.47-1.625 1.795-2.615 4.005-4.175 5.85-1.56 1.845-4.035 3.36-6.52 2.87-2.515-.495-4.545-2.925-7.07-2.465-2.49.455-3.73 3.54-6.26 3.74-1.855.145-3.335-1.35-4.355-2.775-1.82-2.535-3.275-5.325-5.53-7.55-2.255-2.225-5.615-3.845-8.84-3.14-2.205.48-3.985 1.955-5.455 3.54-2.385 2.57-4.225 5.56-5.39 8.76-.49 1.355-1.085 2.955-2.585 3.345-1.38.355-2.775-.565-3.62-1.625-.845-1.06-1.42-2.325-2.51-3.175-2.27-1.77-5.965-.975-8.055.975s-2.975 4.695-3.79 7.32v11.465h89.61l-.03-16.265-.01-.005Z"
      opacity={0.3}
    />
    <path
      fill="url(#x)"
      d="M179.408 92.457c-.86-2.25-1.85-4.45-2.975-6.58-.98-1.855-1.99-3.895-3.49-5.395-1.33-1.33-3.1-1.945-4.96-1.565-1.975.4-3.64 1.71-4.87 3.26-1.405 1.775-2.38 3.875-3.93 5.54-1.55 1.665-3.805 2.94-6.105 2.445-1.89-.405-3.36-1.815-5.205-2.335-1.65-.465-3.055.15-4.305 1.215-1.33 1.135-2.695 2.77-4.655 2.35-2.19-.465-3.51-2.845-4.635-4.565-2.325-3.545-4.78-7.27-9.045-8.6-1.92-.6-3.96-.545-5.8.295-1.93.88-3.485 2.435-4.83 4.035a26.174 26.174 0 0 0-3.6 5.555c-.445.93-.8 1.885-1.17 2.84-.34.89-.775 1.89-1.62 2.41-1.115.68-2.415.225-3.34-.555-.83-.7-1.35-1.665-1.975-2.535-1.05-1.465-2.455-2.365-4.285-2.425-1.695-.055-3.435.545-4.78 1.57-1.585 1.215-2.605 3.01-3.335 4.835-.395.985-.71 1.995-1.025 3.005-.07.23.29.33.36.1 1.2-3.865 2.89-8.19 7.36-9.025 2.135-.4 4.035.295 5.325 2.05 1.07 1.455 1.99 3.26 3.94 3.645 1.725.335 2.87-.945 3.5-2.385.38-.875.67-1.785 1.055-2.66.45-1.015.965-1.995 1.545-2.945 1.165-1.915 2.58-3.73 4.23-5.25 1.65-1.52 3.66-2.63 5.955-2.595 4.715.07 7.95 3.875 10.335 7.49 1.64 2.49 4.185 7.565 7.97 5.8 1.475-.685 2.42-2.115 3.805-2.93 1.855-1.09 3.66-.165 5.35.775 1.69.94 3.22 1.59 5.095 1.18 1.875-.41 3.55-1.715 4.745-3.22 1.4-1.765 2.38-3.835 3.9-5.51 1.395-1.535 3.455-2.76 5.595-2.585 2.14.175 3.695 1.965 4.785 3.73 1.225 1.98 2.305 4.055 3.275 6.17.52 1.14 1.005 2.3 1.45 3.47.085.225.45.125.36-.1v-.005Z"
    />
    <path
      fill="#000"
      d="M89.438 67.282v41.49c0 .1.085.19.19.19h86c1.15 0 2.34.095 3.485 0h.15c.24 0 .24-.375 0-.375H90.968c-.365 0-.835-.08-1.195 0-.045.01-.1 0-.15 0l.19.19v-41.49c0-.24-.375-.24-.375 0v-.005Z"
      opacity={0.3}
    />
    <path
      fill="#000"
      d="M106.148 66.417v42.355c0 .24.375.24.375 0V66.417c0-.24-.375-.24-.375 0ZM120.718 66.417v42.355c0 .24.375.24.375 0V66.417c0-.24-.375-.24-.375 0ZM135.288 66.417v42.355c0 .24.375.24.375 0V66.417c0-.24-.375-.24-.375 0ZM149.858 66.417v42.355c0 .24.375.24.375 0V66.417c0-.24-.375-.24-.375 0ZM164.423 66.417v42.355c0 .24.375.24.375 0V66.417c0-.24-.375-.24-.375 0ZM179.073 66.417v42.355c0 .24.375.24.375 0V66.417c0-.24-.375-.24-.375 0Z"
      opacity={0.3}
    />
    <path
      fill="#000"
      d="M89.413 99.647h89.85c.24 0 .24-.375 0-.375h-89.85c-.24 0-.24.375 0 .375ZM89.413 89.153h89.85c.24 0 .24-.376 0-.376h-89.85c-.24 0-.24.376 0 .376ZM89.413 78.657h89.85c.24 0 .24-.374 0-.374h-89.85c-.24 0-.24.374 0 .374ZM89.413 68.157h89.85c.24 0 .24-.374 0-.374h-89.85c-.24 0-.24.374 0 .374Z"
      opacity={0.3}
    />
    <path
      fill="url(#y)"
      d="M95.103 116.547a1.415 1.415 0 1 0 0-2.83 1.415 1.415 0 0 0 0 2.83Z"
    />
    <path
      fill="#263238"
      d="M98.373 114.587h25.795c.24 0 .24-.375 0-.375H98.373c-.24 0-.24.375 0 .375ZM98.373 116.307h14.205c.24 0 .24-.375 0-.375H98.373c-.24 0-.24.375 0 .375Z"
    />
    <path
      fill="url(#z)"
      d="M135.543 116.547a1.415 1.415 0 1 0 0-2.83 1.415 1.415 0 0 0 0 2.83Z"
    />
    <path
      fill="#263238"
      d="M138.813 114.587h25.795c.24 0 .24-.375 0-.375h-25.795c-.24 0-.24.375 0 .375ZM138.813 116.307h14.205c.24 0 .24-.375 0-.375h-14.205c-.24 0-.24.375 0 .375Z"
    />
    <path
      fill="#E9B376"
      d="m183.862 191.177 1.615 9.32-3.83 1.545-3.185-9.78 5.4-1.085Z"
    />
    <path
      fill="#263238"
      d="M184.287 199.732c-.26.7-.88 1.04-1.545.91l-.445-.63c-.385-.54-.92-.735-1.505-.34-.29.195-.535.75-.505 1.435.05 1.235-.44 2.025-.44 2.025s-2.095 2.815-2.945 3.635c-.505.49-1.17 1.175-1.27 2.035-.03.245-.03.52 0 .825.03.25.27.725.275.735l7.1-3.61s.51-.79.825-.935c.56-.26 1.045.095 1.045.095l2.59-1.295s-.155-.425-.375-1.04l-.335-.93c-.52-1.435-1.12-3.27-1.27-3.675-.265-.71-1.2.75-1.2.75v.01Z"
    />
    <path
      fill="url(#A)"
      d="M178.682 204.592a.11.11 0 0 1 .115-.07c.225.02.44.1.63.235.1.075-.005.245-.11.17-.17-.125-.36-.19-.555-.21-.065-.005-.105-.065-.08-.125Z"
    />
    <path
      fill="url(#B)"
      d="M179.082 204.017a.11.11 0 0 1 .115-.07c.225.02.44.1.63.235.1.075-.005.245-.11.17-.17-.125-.36-.19-.555-.21-.065-.005-.105-.065-.08-.125Z"
    />
    <path
      fill="url(#C)"
      d="M179.507 203.457a.11.11 0 0 1 .115-.07c.225.02.44.1.63.235.1.075-.005.245-.11.17-.17-.125-.36-.19-.555-.21-.065-.005-.105-.065-.08-.125Z"
    />
    <path
      fill="#fff"
      d="M175.623 209.253c0 .125.01.259.025.399.03.25.27.725.275.735l7.1-3.61s.51-.79.825-.935c.56-.26 1.045.096 1.045.096l2.59-1.295s-.155-.425-.375-1.04l-.055-.155-11.43 5.805Z"
      opacity={0.2}
    />
    <path
      fill="url(#D)"
      d="m179.717 203.588-.115-.025c-.19-.04-.835-.19-1.065-.365-.24-.18-.395-.43-.425-.69-.02-.145.015-.335.165-.42.145-.085.31-.03.43.035.23.12.44.325.61.6.15.24.255.505.36.76l.045.11-.005-.005Zm-1.28-1.421a.176.176 0 0 0-.1.026c-.095.055-.115.195-.1.3.025.225.165.445.375.605.15.115.545.235.905.315a3.66 3.66 0 0 0-.305-.63c-.16-.255-.35-.445-.56-.555a.496.496 0 0 0-.21-.061h-.005Z"
    />
    <path
      fill="url(#E)"
      d="m179.592 203.612-.01-.12c-.02-.275-.04-.56-.005-.84.035-.32.13-.595.29-.805.11-.15.235-.225.375-.215.17.015.28.17.33.31.085.245.055.54-.08.805-.135.26-.645.67-.8.795l-.095.075-.005-.005Zm.625-1.855c-.11 0-.2.105-.25.165-.145.195-.23.445-.265.745a4.09 4.09 0 0 0 0 .7c.285-.23.59-.51.675-.68.12-.235.15-.495.075-.71-.035-.1-.115-.215-.22-.225h-.015v.005Z"
    />
    <path
      fill="#E9B376"
      d="m171.553 189.182 1.615 9.321-3.83 1.545-3.18-9.775 5.395-1.091Z"
    />
    <path
      fill="#263238"
      d="M171.983 197.738c-.26.7-.88 1.04-1.545.91l-.445-.63c-.385-.54-.92-.735-1.505-.34-.29.195-.535.75-.51 1.435.05 1.235-.44 2.025-.44 2.025s-2.095 2.815-2.945 3.635c-.505.49-1.17 1.175-1.27 2.035-.03.245-.03.52 0 .825.03.25.27.725.275.735l7.1-3.61s.51-.79.825-.935c.56-.26 1.045.095 1.045.095l2.59-1.295s-.155-.425-.375-1.04l-.335-.93c-.52-1.435-1.12-3.27-1.27-3.675-.265-.71-1.2.75-1.2.75l.005.01Z"
    />
    <path
      fill="url(#F)"
      d="M166.378 202.598a.11.11 0 0 1 .115-.07c.225.02.44.1.63.235.1.075-.005.245-.11.17-.17-.125-.36-.19-.555-.21-.065-.005-.105-.065-.08-.125Z"
    />
    <path
      fill="url(#G)"
      d="M166.772 202.023a.11.11 0 0 1 .115-.07c.225.02.44.1.63.235.1.075-.005.245-.11.17-.17-.125-.36-.19-.555-.21-.065-.005-.105-.065-.08-.125Z"
    />
    <path
      fill="url(#H)"
      d="M167.197 201.463a.11.11 0 0 1 .115-.07c.225.02.44.1.63.235.1.075-.005.245-.11.17-.17-.125-.36-.19-.555-.21-.065-.005-.105-.065-.08-.125Z"
    />
    <path
      fill="#fff"
      d="M163.312 207.263c0 .125.01.26.025.4.03.25.27.725.275.735l7.1-3.61s.51-.79.825-.935c.56-.26 1.045.095 1.045.095l2.59-1.295s-.155-.425-.375-1.04l-.055-.155-11.43 5.805Z"
      opacity={0.2}
    />
    <path
      fill="url(#I)"
      d="m167.407 201.598-.115-.025c-.19-.04-.835-.19-1.065-.365-.24-.18-.395-.43-.425-.69-.02-.145.015-.335.165-.42.145-.085.31-.03.43.035.23.12.44.325.61.6.15.24.255.505.36.76l.045.11-.005-.005Zm-1.28-1.42a.176.176 0 0 0-.1.025c-.095.055-.115.195-.1.3.025.225.165.445.375.605.15.115.545.235.905.315a3.66 3.66 0 0 0-.305-.63c-.16-.255-.35-.445-.56-.555a.496.496 0 0 0-.21-.06h-.005Z"
    />
    <path
      fill="url(#J)"
      d="m167.282 201.617-.01-.12c-.02-.275-.04-.56-.005-.84.035-.325.13-.595.29-.805.11-.15.25-.225.37-.215.17.015.28.17.33.31.085.245.055.54-.08.805-.135.26-.645.67-.8.795l-.095.075v-.005Zm.625-1.855c-.11 0-.2.105-.25.165-.145.195-.23.445-.265.745a4.09 4.09 0 0 0 0 .7c.285-.23.59-.51.675-.68.12-.235.15-.495.075-.71-.035-.1-.11-.215-.22-.225h-.015v.005Z"
    />
    <path
      fill="#263238"
      d="M202.438 149.208s-9.235 9.03-14.035 13.515c-4.8 4.485-19.905 3.82-19.905 3.82l5.7 29.09-7.83.945s-7.385-21.585-10.25-33.13c-.415-1.675.36-4.2 1.115-5.265 3.165-4.455 26.655-5.21 26.655-5.21l.515-3.135 18.03-.635.005.005Z"
    />
    <path
      fill="#000"
      d="M169.643 172.402s-4.135-7.285-5.02-10.2c-.88-2.915-.61-4.004 1.56-4.884 2.17-.88 17.54-2.726 17.54-2.726l.245-1.269 3.17 3.46s-18.175 1.214-17.97 4.199c.205 2.985.475 11.42.475 11.42Z"
      opacity={0.2}
    />
    <path
      fill="#263238"
      d="M202.438 149.208s1.9 11.22-1.975 14.09c-3.7 2.745-19.335 4.38-19.335 4.38l4.8 29.25-7.665 1.345s-7.455-22.3-10.325-33.84c-.415-1.675-.06-3.83.7-4.895 3.165-4.455 26.445-5.81 26.445-5.81l7.355-4.52Z"
    />
    <path
      fill="#000"
      d="M181.127 167.682s-1.225-1.78-2.15-2.51c0 0 2.65.88 3.155 2.45l-1.005.06Z"
      opacity={0.2}
    />
    <path
      fill="#E9B376"
      d="m165.628 149.468 1.68 2.5s10.565-3.57 11.81-4.77c1.245-1.195 2.795-11.96 2.795-11.96v-7.63l.125-5.525s-4.38 1.065-4.73 4.585c-.105 1.04-1.98 16.535-1.98 16.535l-9.705 6.27.005-.005Z"
    />
    <path
      fill="#263238"
      d="M169.083 155.723h15.395c0 .595-.66 1.065-1.465 1.065h-25.075c-.8-.005-1.44-.48-1.44-1.065h12.585Z"
    />
    <path
      fill="#263238"
      d="M157.707 156.728h14.065c.245 0 .44-.16.435-.355a.425.425 0 0 0-.02-.11l-6.255-14.345c-.16-.41-.63-.69-1.165-.69h-14.07c-.24 0-.43.155-.43.345 0 .04.01.08.025.115l6.255 14.345c.16.41.63.685 1.165.69l-.005.005Z"
    />
    <path
      fill="url(#K)"
      d="M150.693 141.228h14.07c.535 0 1.005.28 1.165.69l6.02 13.8h12.53c0 .595-.66 1.065-1.465 1.065h-25.075c-.255 0-.49-.055-.695-.14-.325-.11-.59-.33-.7-.61l-6.255-14.345a.322.322 0 0 1-.025-.12c0-.19.195-.345.43-.345v.005Z"
    />
    <path
      fill="#fff"
      d="M159.142 148.862c.305.69.92 1.16 1.38 1.065.46-.095.58-.725.285-1.415-.295-.69-.92-1.15-1.375-1.055-.46.095-.595.7-.29 1.405Z"
    />
    <path
      fill="#000"
      d="m165.928 141.918 6.02 13.8h-.65l-6.53-14.495c.535 0 1.005.28 1.165.69l-.005.005Z"
      opacity={0.3}
    />
    <path
      fill="#fff"
      d="M150.693 141.228h14.07c.535 0 1.005.28 1.165.69l6.02 13.8h12.53c0 .595-.66 1.065-1.465 1.065h-25.075c-.255 0-.49-.055-.695-.14-.325-.11-.59-.33-.7-.61l-6.255-14.345a.322.322 0 0 1-.025-.12c0-.19.195-.345.43-.345v.005Z"
      opacity={0.5}
    />
    <path
      fill="url(#L)"
      d="M182.738 153.023s-2.445-2.94-1.66-3.51l.285-27.225.68-.205 5.53-1.265 5.715-1.23 3 .625 5.28 5.825s.745 14.205.785 14.85c.05.79.43 11.085.43 11.085-9.045 1.985-20.19 1.76-20.045 1.06v-.01Z"
    />
    <path
      fill="#E9B376"
      d="m185.693 121.288 2.305 3.45 4.235-5.22s-1.13-.875-2.08-1.69c-.755-.645-2.8-3.23-2.8-3.23l-.56.49-3.56 3.07s2.54 1.45 2.48 1.99c.1.47.21.95-.055 1.135"
    />
    <path
      fill="#000"
      d="M185.712 120.158c.1.525.155.635-.02 1.135 2.665-.56 1.39-6.18 1.39-6.18h-.29l-3.56 3.07s2.56 1.46 2.48 1.975Z"
      opacity={0.2}
    />
    <path
      fill="#263238"
      d="M176.358 109.613c-.365 1.52.105 4.555 2.11 3.415 2.005-1.135-1.485-6.025-2.11-3.415Z"
    />
    <path
      fill="#E9B376"
      d="M187.173 110.883c.495 3.805.85 5.939-.685 8.214-2.305 3.425-7.17 2.626-8.795-.964-1.46-3.23-2.025-8.91 1.38-11.08 3.355-2.14 7.605.025 8.1 3.83Z"
    />
    <path
      fill="#263238"
      d="M186.948 105.768c-.915-1.97-3.815-2.6-5.06-1.26-.185.2 1.56.445 1.36.415l.705 3.03c.215-.09.7.085 1.06.735 0 0 .215 4.085 2.17 5.44 3.705-2.66 2.725-8.025-.235-8.36Z"
    />
    <path
      fill="#E9B376"
      d="M189.263 113.733a3.61 3.61 0 0 1-1.37 2.415c-1.015.775-2.005-.02-2.125-1.225-.11-1.085.285-2.795 1.5-3.095 1.195-.295 2.125.735 1.995 1.905Z"
    />
    <path
      fill="#263238"
      d="M182.003 113.003c.075.385-.07.735-.325.78-.25.045-.515-.23-.59-.615-.075-.385.07-.735.32-.78.25-.045.515.23.59.615h.005ZM178.243 113.808c.075.385-.07.735-.32.78-.25.045-.515-.23-.59-.615-.075-.385.07-.735.32-.78.25-.045.515.23.59.615Z"
    />
    <path
      fill="#D58745"
      d="M179.428 113.358s-.85 2.145-1.09 3c.63.38 1.51-.02 1.51-.02l-.42-2.98Z"
    />
    <path
      fill="#263238"
      d="M182.443 110.782a.187.187 0 0 1-.185.015c-.745-.345-1.325 0-1.33 0a.187.187 0 0 1-.255-.06.18.18 0 0 1 .06-.25c.03-.02.755-.45 1.68-.02.09.045.13.15.09.245a.166.166 0 0 1-.06.07ZM176.533 112.963a.193.193 0 0 1-.1-.04.183.183 0 0 1-.025-.26c.645-.78 1.48-.685 1.52-.685.1.01.175.105.16.205-.01.1-.1.17-.2.16-.035-.005-.68-.07-1.195.555a.18.18 0 0 1-.16.065ZM186.808 106.023a.123.123 0 0 1-.08-.03.122.122 0 0 1-.015-.175c.475-.57 1.08-1.01 1.625-1.18a.122.122 0 0 1 .155.085.122.122 0 0 1-.085.155c-.49.15-1.065.57-1.505 1.1a.123.123 0 0 1-.095.045Z"
    />
    <path
      fill="#263238"
      d="M186.863 105.963s-.025 0-.04-.005a.123.123 0 0 1-.08-.155c.22-.68.18-1.445-.115-2.1-.03-.065 0-.135.065-.165s.135 0 .165.065c.32.71.365 1.54.125 2.28-.015.055-.065.085-.12.085v-.005Z"
    />
    <path
      fill="#000"
      d="M186.702 114.437a.125.125 0 0 1-.125-.115c-.025-.355.04-.71.19-1.03.105-.215.295-.51.61-.655.315-.145.69-.09.91.13.05.05.05.13 0 .175a.12.12 0 0 1-.175 0c-.145-.145-.41-.18-.63-.08-.25.115-.405.355-.49.535-.135.28-.19.59-.165.9a.124.124 0 0 1-.115.135h-.01v.005Z"
      opacity={0.2}
    />
    <path
      fill="#263238"
      d="M180.793 117.238a2.793 2.793 0 0 0 1.915-1.7.091.091 0 0 0-.06-.12h-.005a.085.085 0 0 0-.115.055 2.633 2.633 0 0 1-1.78 1.58c-.045.02-.07.07-.05.115.02.05.07.07.115.055l-.025.02.005-.005Z"
    />
    <path
      fill="url(#M)"
      d="M184.708 121.473c.075-.11 1.09-.836 1.09-.836l2.03 1.601 2.915-4.04 2.545 1.385-5.385 6.77-3.195-4.886v.006Z"
    />
    <path
      fill="#000"
      d="M187.918 153.448c-.05 0-.1-.035-.12-.085-1.185-3.455-1.03-7.345-.88-11.105.055-1.355.11-2.75.095-4.085-.04-4.375.17-9.23.665-15.275.005-.07.065-.125.135-.115.07.005.12.065.115.135-.495 6.035-.705 10.885-.665 15.25.01 1.335-.045 2.74-.095 4.095-.15 3.74-.3 7.605.865 11.015a.127.127 0 0 1-.08.16c-.015 0-.025.005-.04.005l.005.005Z"
      opacity={0.1}
    />
    <path
      fill="#000"
      d="m196.068 139.038-2.56.245.875-10.895.635 9.31 7.18.3-6.13 1.04ZM184.708 121.473c.055.085 1.235 2.425 1.735 3.415.085.165.325.15.385-.02l.905-2.445-1.115 1.9-1.91-2.845v-.005ZM187.917 122.278c.075.075 2.075 2.22 2.075 2.22l2.935-4.915-2.51 5.6a.355.355 0 0 1-.625.045l-1.875-2.955v.005Z"
      opacity={0.2}
    />
    <path
      fill="#E9B376"
      d="M196.088 133.218s-.015 7.86-.035 7.94c-.015.085-13.7 5.8-13.7 5.8 0 .205 2.035 2.58 2.035 2.58s14.925-1.96 16.61-4.505c1.44-2.17 1.665-11.92 1.665-11.92l-6.575.1v.005Z"
    />
    <path
      fill="url(#N)"
      d="M196.288 120.208c5.45.46 6.79 4.52 6.69 10.7-.095 5.95.05 7.27.05 7.27l-8.175-.18s-.135-10.62-.135-11.645c0-1.025 1.57-6.15 1.57-6.15v.005Z"
    />
    <path
      fill="url(#O)"
      d="m182.188 140.553-7.49-1.885s2.055-10.66 2.405-12.445c.4-2.025 2.85-3.56 4.94-4.14l1.76 6.85-1.615 11.62Z"
    />
    <path
      fill="#000"
      d="M181.078 140.273c-.08-.02-1.21-10.875-1.21-10.875l-.62 10.42 1.83.46v-.005Z"
      opacity={0.2}
    />
    <path
      fill="#E9B376"
      d="M175.173 154.513c.07-.7 1.385-3.77 2.06-4.79s5.12-2.765 5.12-2.765l2.61 1.885s0 1.09-.925 1.88c-.53.455-2.325.915-2.325.915s.15.555.15 1.175c0 .69-.135 1.535-.705 1.51-.565-.025-.775-1.85-.8-2.115.025.265.185 2.135-.575 2.2-.73.065-.85-1.885-.85-1.885l-.06 1.37c-.025.465-.49.865-.92.35-.5-.6-.54-1.63-.54-1.63s-.395.605-.985 1.92c-.425.945-1.315.565-1.255-.025v.005Z"
    />
    <path
      fill="#000"
      d="M180.383 152.613a.126.126 0 0 1-.125-.12c-.015-.35-.035-.715.015-1.075.06-.415.2-.76.42-1.025a.122.122 0 0 1 .175-.015c.055.045.06.125.015.175-.19.225-.315.535-.365.9-.045.335-.03.69-.015 1.03 0 .07-.05.125-.12.13ZM178.898 152.862a.126.126 0 0 1-.125-.12c-.015-.35-.035-.715.015-1.075.06-.42.2-.765.42-1.025.045-.05.125-.06.175-.015.055.045.06.125.015.175-.19.225-.31.53-.365.9-.045.335-.03.69-.015 1.025 0 .07-.05.125-.12.13h-.005l.005.005ZM177.423 152.733a.126.126 0 0 1-.125-.12c-.015-.35-.035-.715.015-1.075.06-.415.2-.76.42-1.025a.122.122 0 0 1 .175-.015c.055.045.06.125.015.175-.19.225-.315.535-.365.9-.045.335-.03.69-.015 1.03 0 .07-.05.125-.12.13Z"
      opacity={0.1}
    />
    <path
      fill="#263238"
      d="M186.128 104.418c-.345-.435-.895-.66-1.445-.75a4.857 4.857 0 0 0-2.225.15c-.825.255-1.58.735-2.43.865-.105.015-.225.02-.3-.05-.055-.05-.07-.125-.095-.195a1.195 1.195 0 0 0-1.1-.805 1.19 1.19 0 0 0-1.105.8c-.465-.73-1.58-.815-2.315-.36-.74.455-1.165.965-1.41 1.795-.085.29-.12.925-.015 1.4.215.95.735 1.53 1.565 2.035 1.12.68 2.445 1.15 3.735.915 1.72-.315 3.025-1.795 4.725-2.21.64-.155 1.405-.115 1.85.37l1.385-2.61c0-.44-.425-.855-.825-1.355l.005.005Z"
    />
    <path
      fill="#B97964"
      d="m101.518 199.852 3.535-.16 1.31-9.195-5.385.185.54 9.17Z"
    />
    <path
      fill="#263238"
      d="M105.483 198.083h-1.255a.886.886 0 0 0-.715.375c-.725.99-1.27.615-2.06-.315-.5-.06-.635.515-.675.67l-.94 3.42a.54.54 0 0 0 .525.685h9.96c1.585 0 1.915-1.595 1.26-1.745-2.935-.65-5.015-1.51-5.92-2.365-.17-.15 0-.26-.18-.725Z"
    />
    <path
      fill="url(#P)"
      d="M107.943 199.222a.526.526 0 0 0 .215-.41.228.228 0 0 0-.105-.22c-.395-.28-1.965.455-2.14.54-.02.005-.035.025-.04.04-.015.035.005.075.04.09.385.09.78.14 1.18.15.3.03.595-.04.855-.195l-.005.005Zm.01-.515c.03.02.05.055.04.095a.413.413 0 0 1-.155.3c-.355.265-1.22.15-1.685.065.655-.285 1.58-.61 1.8-.46Z"
    />
    <path
      fill="url(#Q)"
      d="M105.963 199.282c.47-.195 1.46-.975 1.41-1.385-.01-.085-.07-.19-.285-.22-.86-.1-1.23 1.45-1.23 1.515-.01.025 0 .055.02.075h.05l.035.01v.005Zm1.095-1.475c.16.015.16.075.16.095.045.25-.675.895-1.185 1.165.105-.36.425-1.265.97-1.265h.055v.005Z"
    />
    <path
      fill="url(#R)"
      d="M105.018 200.032s-.045-.005-.065-.02a.123.123 0 0 1-.04-.17c.255-.4.685-.68 1.155-.745.065-.005.13.04.14.105.01.065-.04.13-.105.14-.4.055-.765.29-.98.63a.123.123 0 0 1-.105.06Z"
    />
    <path
      fill="url(#S)"
      d="M105.803 200.623s-.045-.005-.065-.02a.123.123 0 0 1-.04-.17c.255-.4.685-.68 1.155-.745.065-.005.13.04.14.105.01.065-.04.13-.105.14-.4.055-.765.29-.98.63a.123.123 0 0 1-.105.06Z"
    />
    <path
      fill="url(#T)"
      d="M106.773 201.117s-.045-.005-.065-.02a.123.123 0 0 1-.04-.17c.255-.4.685-.68 1.155-.745.065-.01.13.04.14.105.01.07-.04.13-.105.14-.4.055-.765.29-.98.63a.123.123 0 0 1-.105.06Z"
    />
    <path
      fill="#000"
      d="m105.648 195.512-.17 1.18-4.15-.07-.065-1.11h4.385Z"
      opacity={0.3}
    />
    <path
      fill="#B97964"
      d="m116.248 198.942 3.835-.485.845-8.67-5.265-.145.585 9.3Z"
    />
    <path
      fill="#263238"
      d="M120.277 197.732h-1.255a.886.886 0 0 0-.715.375c-.725.99-1.27.615-2.06-.315-.5-.06-.635.515-.675.67l-.94 3.42a.54.54 0 0 0 .525.685h9.96c1.585 0 1.915-1.595 1.26-1.745-2.935-.65-5.015-1.51-5.92-2.365-.17-.15 0-.26-.18-.725Z"
    />
    <path
      fill="url(#U)"
      d="M123.003 198.702c.13-.095.21-.25.215-.41a.228.228 0 0 0-.105-.22c-.395-.28-1.965.455-2.14.54-.02.005-.035.025-.04.04-.015.035.005.075.04.09.385.09.78.14 1.18.15.3.03.595-.04.855-.195l-.005.005Zm.01-.515c.03.02.05.055.04.095a.413.413 0 0 1-.155.3c-.355.265-1.22.15-1.685.065.655-.285 1.58-.61 1.8-.46Z"
    />
    <path
      fill="url(#V)"
      d="M121.028 198.762c.47-.195 1.46-.975 1.41-1.385-.01-.085-.07-.19-.285-.215-.86-.1-1.23 1.45-1.23 1.515-.01.025 0 .055.02.075h.05l.035.01Zm1.095-1.475c.16.015.16.075.16.095.045.25-.675.895-1.185 1.165.105-.36.425-1.265.97-1.265h.055v.005Z"
    />
    <path
      fill="url(#W)"
      d="M120.083 199.518s-.045-.005-.065-.02a.123.123 0 0 1-.04-.17c.255-.4.685-.68 1.155-.745.065-.005.13.04.14.105.01.065-.04.13-.105.14-.4.055-.765.29-.98.63a.123.123 0 0 1-.105.06Z"
    />
    <path
      fill="url(#X)"
      d="M120.862 200.103s-.045-.005-.065-.02a.123.123 0 0 1-.04-.17c.255-.4.685-.681 1.155-.746.065-.005.13.041.14.106.01.07-.04.13-.105.14-.4.055-.765.289-.98.629a.124.124 0 0 1-.105.061Z"
    />
    <path
      fill="url(#Y)"
      d="M121.837 200.597s-.045-.005-.065-.02a.123.123 0 0 1-.04-.17 1.64 1.64 0 0 1 1.155-.745c.065-.005.13.04.14.105.01.065-.04.13-.105.14-.4.055-.765.29-.98.63a.123.123 0 0 1-.105.06Z"
    />
    <path
      fill="#000"
      d="m120.418 194.992-.115 1.185-4.23-.025-.075-1.16h4.42Z"
      opacity={0.3}
    />
    <path
      fill="#263238"
      d="M99.313 99.702c-1.01-5.255 2.265-4.285 1.15-10.975-1.23-7.37 4.025-8.435 4.025-8.435s.62-3.67 4.05-3.48c3.43.19 12.02 2.01 10.295 11.12-1.005 5.31.76 5.7 1.66 8.095 1.275 3.385-2.79 6.425-.135 8.74 0 0-6.805 1.995-16.735 1.33 0 0-3.695-3.19-4.31-6.395Z"
    />
    <path
      fill="url(#Z)"
      d="M117.808 101.677s4.67 11.685 5.41 11.755c.74.07 10.215-3.62 10.215-3.62l1.475 1.665s-4.96 6.635-12.815 8.74c-1.41.38-3.19-1.22-4.89-3.405-.335-.425-.66-.875-.98-1.335a68.384 68.384 0 0 1-4.69-8.105l6.28-5.695h-.005Z"
    />
    <path
      fill="#fff"
      d="M117.808 101.677s4.67 11.685 5.41 11.755c.74.07 10.215-3.62 10.215-3.62l1.475 1.665s-4.96 6.635-12.815 8.74c-1.41.38-3.19-1.22-4.89-3.405-.335-.425-.66-.875-.98-1.335a68.384 68.384 0 0 1-4.69-8.105l6.28-5.695h-.005Z"
      opacity={0.6}
    />
    <path
      fill="#000"
      d="M117.808 103.222c.365 1.62 1.225 4.045 1.9 5.77.22.56.315 1.16.215 1.755-.29 1.7-1.76 2.245-2.25 3.6a8.818 8.818 0 0 0-.47 2.465c-.335-.425-.66-.875-.98-1.335l-.725-2.865 2.315-9.39h-.005Z"
      opacity={0.2}
    />
    <path
      fill="#263238"
      d="M121.643 114.732a.836.836 0 0 1-.575-1.03l3.165-13.32c.19-.63.755-1.075 1.415-1.1l10.15-.21a.836.836 0 0 1 .905 1.055l-3.165 13.32a1.553 1.553 0 0 1-1.415 1.105l-10.15.21a.822.822 0 0 1-.33-.025v-.005Z"
    />
    <path
      fill="#fff"
      d="M121.643 114.732a.836.836 0 0 1-.575-1.03l3.165-13.32c.19-.63.755-1.075 1.415-1.1l10.15-.21a.836.836 0 0 1 .905 1.055l-3.165 13.32a1.553 1.553 0 0 1-1.415 1.105l-10.15.21a.822.822 0 0 1-.33-.025v-.005Z"
      opacity={0.2}
    />
    <path
      fill="#263238"
      d="M122.688 114.707a.833.833 0 0 1-.575-1.025l3.165-13.32c.19-.63.755-1.07 1.41-1.105l10.155-.21a.836.836 0 0 1 .905 1.055l-3.17 13.32c-.19.63-.755 1.07-1.41 1.105l-10.155.21c-.11.015-.22 0-.325-.025v-.005Z"
    />
    <path
      fill="#fff"
      d="M122.688 114.707a.833.833 0 0 1-.575-1.025l3.165-13.32c.19-.63.755-1.07 1.41-1.105l10.155-.21a.836.836 0 0 1 .905 1.055l-3.17 13.32c-.19.63-.755 1.07-1.41 1.105l-10.155.21c-.11.015-.22 0-.325-.025v-.005Z"
      opacity={0.3}
    />
    <path
      fill="#fff"
      d="M131.848 106.857a1.06 1.06 0 0 0-1.155-.955 1.928 1.928 0 0 0-1.75 1.41c-.04.13-.05.27-.04.405.06.58.575 1 1.155.94a1.929 1.929 0 0 0 1.755-1.405c.035-.13.05-.265.035-.395Z"
      opacity={0.2}
    />
    <path
      fill="#B97964"
      d="M135.278 107.492c-.2-.015-1.05.405-1.45.125-.4-.28-.025-.51-.025-.51.73-.18 1.535-1.095 1.98-.83.445.26 1.18.59 1.075 1.005-.12.385-.27.76-.44 1.13.115.185.13.415.04.615-.09.23-.295.4-.535.445.14.14.21.335.2.535-.035.315-.865.73-.865.73s.16.495-.03.685a1.34 1.34 0 0 1-1.165.265c-.485-.115-.82-.285-.785-.775.035-.49.91-.49.91-.49s-1.65-.5-1.3-.81c.35-.31 1.665-.275 1.665-.275s-2.23-.44-1.875-.9 2.575-.335 2.575-.335.225-.6.025-.615v.005Z"
    />
    <path
      fill="#263238"
      d="m108.108 126.323 9.855.04s3.445 17.335 3.24 36.77c.4 11.41.08 32.39.08 32.39h-6.41s-4.085-17.041-2.45-30.541c-.595-2.33-1.39-6.359-1.855-8.414a146.378 146.378 0 0 1-2.95-16.825c-1.125-10.61.485-13.42.485-13.42h.005Z"
    />
    <path
      fill="#000"
      d="M108.983 133.127s3.555 15.96 1.59 23.435a146.378 146.378 0 0 1-2.95-16.825l1.36-6.615v.005Z"
      opacity={0.2}
    />
    <path
      fill="#263238"
      d="M99.823 121.782c-.86 3.85-4.23 8.555-4.915 13.48-1.165 8.415 3.895 18.88 5.215 28.75-2 14.6 1.065 32.305.66 31.92h6.435c.195-1.3 1.515-21.08 2.17-32.8.555-10.26 1.44-36.055 1.44-36.055l-11.01-5.295h.005Z"
    />
    <path
      fill="url(#aa)"
      d="M99.583 119.452c-.01-.69-1.345-5.645-1.38-6.375a81.922 81.922 0 0 0-1.18-10.535 4.345 4.345 0 0 1 3.98-5.095c.69-.05 1.415-.075 2.165-.095.75-.02 1.465-.02 2.155-.02 1.89-.035 3.78.055 5.655.265l.415.055c.705.09 1.42.21 2.11.325a5.88 5.88 0 0 1 4.88 6.245c-.335 3.965 2.655 6.385-1.17 9.06-.495 3.4.24 10.665 1.665 15.675.505 1.78-13.91 1.545-22-1.13l2.7-8.37"
    />
    <path
      fill="#fff"
      d="M99.583 119.452c-.01-.69-1.345-5.645-1.38-6.375a81.922 81.922 0 0 0-1.18-10.535 4.345 4.345 0 0 1 3.98-5.095c.69-.05 1.415-.075 2.165-.095.75-.02 1.465-.02 2.155-.02 1.89-.035 3.78.055 5.655.265l.415.055c.705.09 1.42.21 2.11.325a5.88 5.88 0 0 1 4.88 6.245c-.335 3.965 2.655 6.385-1.17 9.06-.495 3.4.24 10.665 1.665 15.675.505 1.78-13.91 1.545-22-1.13l2.7-8.37"
      opacity={0.6}
    />
    <path
      fill="#B97964"
      d="M104.613 97.333s1.505 1.16 3.445 2.83c.405.35.85.65 1.32.9 1.27.675 2.43 1.06 2.74.45.07-.135.125-.28.165-.435.265-1.025.155-2.465-.67-3.125-.935-.745-1.385-1.38-1.215-2.385.09-.57.295-1.16.535-1.735l-1.08-.65-3.845-3.88c.44 2.38.635 6.63-1.39 8.035l-.005-.005Z"
    />
    <path
      fill="#000"
      d="M110.358 95.782c-1.06-.63-2.22-1.715-2.08-2.96.06-.525.175-1.02.3-1.34l2.15 2.846c-.175.46-.24.76-.37 1.454Z"
      opacity={0.2}
    />
    <path
      fill="#B97964"
      d="M105.253 84.923c.36 3.95.43 5.63 2.47 7.7 3.07 3.115 7.79 2.07 8.6-1.85.73-3.525.015-9.395-3.865-11.06-3.825-1.635-7.57 1.255-7.21 5.205l.005.005Z"
    />
    <path
      fill="#263238"
      d="M110.373 86.208c.02.42.245.76.505.765.26.005.455-.33.435-.745-.02-.42-.245-.76-.505-.765-.26-.005-.455.33-.435.745ZM114.273 86.198c.02.405.245.74.505.745.26.005.455-.32.435-.725-.02-.405-.245-.74-.505-.745-.26-.005-.455.32-.435.725ZM109.548 84.753c.05.005.1-.01.14-.045.61-.565 1.295-.365 1.3-.365.1.03.205-.03.23-.13a.21.21 0 0 0-.145-.245c-.035-.01-.895-.26-1.65.44-.075.07-.075.195 0 .275.035.04.08.065.13.07h-.005ZM115.583 84.213a.151.151 0 0 0 .09-.085c.035-.09-.01-.206-.1-.255-.905-.51-1.575-.12-1.6-.106-.08.046-.1.16-.045.25.055.09.165.126.245.08.025-.014.55-.304 1.27.1.045.025.095.03.14.016Z"
    />
    <path
      fill="#A24E3F"
      d="M113.213 86.003s.62 1.48 1.315 2.4c.12.16.045.395-.145.465-.49.18-1.06.015-1.06.015l-.115-2.885.005.005Z"
    />
    <path
      fill="#263238"
      d="m110.663 85.477.905-.215s-.495.675-.905.215ZM114.613 85.483l.905-.216s-.495.675-.905.216Z"
    />
    <path
      fill="#263238"
      d="M106.628 86.287s2.04-3.07 1.935-5.555c0 0 3.9-1.014 7.015 3.14.495.66.79 1.766 1.235 2.72 0 0 .745-7.52-5.93-8.135-6.665-.614-7.97 8.75-4.72 11.906 0 0-.795-2.15.465-4.07v-.006Z"
    />
    <path
      fill="#B97964"
      d="M104.058 88.142c.465.81 1.225 1.32 1.92 1.496 1.045.26 1.585-1.42 1.23-2.47-.32-.946-1.35-1.57-2.37-1.36-1.005.204-1.315 1.41-.785 2.334h.005Z"
    />
    <path
      fill="#000"
      d="M106.273 88.058a.125.125 0 0 1-.125-.116 1.333 1.333 0 0 0-.445-.9 1.344 1.344 0 0 0-.95-.325c-.065.01-.13-.05-.135-.114 0-.07.05-.13.115-.135.41-.026.82.114 1.13.385.31.27.5.66.53 1.07 0 .07-.05.13-.115.135h-.01.005Z"
      opacity={0.2}
    />
    <path
      fill="#263238"
      d="M112.063 90.207c-.98 0-1.33-.91-1.33-.92a.124.124 0 0 1 .075-.16.124.124 0 0 1 .16.076c.015.04.365.94 1.415.72.065-.016.135.03.145.094.015.066-.03.136-.095.145-.13.025-.25.04-.365.04l-.005.005Z"
    />
    <path
      fill="#000"
      d="m98.688 122.232-.875 2.72s9.81-1.725 14.73-6.12c3.5-3.13 2.955-4.45 2.955-4.45l-3.965.385-12.84 7.47-.005-.005ZM102.618 108.237l-.535 8.87c-1.48.94-2.46-.365-2.685-1.715v-2.555l3.22-4.605v.005Z"
      opacity={0.2}
    />
    <path
      fill="url(#ab)"
      d="M103.148 106.892s-3.715 9.2-3.045 9.625c.67.425 11.365-2.5 11.365-2.5l1.685 2.42c-.36 1.59-18.815 11.605-20.32 3.23-.915-5.09 4.62-19.885 4.62-19.885 4.55-1.78 5.695 7.115 5.695 7.115v-.005Z"
    />
    <path
      fill="#fff"
      d="M103.148 106.892s-3.715 9.2-3.045 9.625c.67.425 11.365-2.5 11.365-2.5l1.685 2.42c-.36 1.59-18.815 11.605-20.32 3.23-.915-5.09 4.62-19.885 4.62-19.885 4.55-1.78 5.695 7.115 5.695 7.115v-.005Z"
      opacity={0.6}
    />
    <path
      fill="#B97964"
      d="M113.313 116.672c1.265.1 1.31-1.805 1.965-1.88.385-.035.755-.13 1.11-.285 0 0-.415 1.145 0 1.055.415-.09 1.75-.16 2.08-1.25.33-1.09-.86-3.42-1.13-3.745-.27-.325-1.695-.225-1.695-.225s1.53-.395 2.37-.51c.84-.115 1-1.075-.175-.865-.48.085-4.285.115-4.695.505-.41.39-1.94 4.235-1.94 4.235-.565.325.87 3.55 2.105 2.96l.005.005Z"
    />
    <g fill="#000" opacity={0.3}>
      <path d="M115.633 110.223c-.39-.025-.78 0-1.165.075-.06.015-.09.075-.075.135.015.05.06.085.115.08.365-.075.735-.1 1.105-.075.135 0 .16-.205.025-.21l-.005-.005ZM117.593 111.378c.12.06.245-.11.125-.175-.71-.38-1.46-.165-2.18.075-.13.04-.035.235.09.195.635-.21 1.33-.415 1.965-.095ZM118.153 112.318a2.12 2.12 0 0 0-2.015-.085c-.05.03-.065.1-.035.15.025.04.075.06.12.045a1.923 1.923 0 0 1 1.805.065c.115.07.24-.105.12-.175h.005ZM117.128 113.912c.055-.11.1-.23.13-.35.03-.135-.17-.21-.205-.075-.09.41-.345.765-.705.98-.12.065-.03.26.085.195h-.01c.225-.125.415-.305.56-.515.015 0 .025.005.045 0a.576.576 0 0 1 .63.165c.09.105.255-.035.16-.135a.794.794 0 0 0-.69-.265Z" />
    </g>
    <defs>
      <linearGradient
        id="a"
        x1={63.92}
        x2={96.95}
        y1={174.23}
        y2={174.23}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={29.763}
        x2={37.004}
        y1={97.473}
        y2={97.473}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={87.617}
        x2={94.856}
        y1={97.472}
        y2={97.472}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={59.923}
        x2={76.719}
        y1={74.085}
        y2={74.085}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={59.38}
        x2={69.343}
        y1={107.579}
        y2={107.579}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="f"
        x1={60.356}
        x2={75.827}
        y1={130.927}
        y2={130.927}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="g"
        x1={59.235}
        x2={69.684}
        y1={32.1}
        y2={32.1}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="h"
        x1={59.822}
        x2={72.629}
        y1={82.564}
        y2={82.564}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="i"
        x1={59.02}
        x2={67.824}
        y1={72.493}
        y2={72.493}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="j"
        x1={58.744}
        x2={65.409}
        y1={71.957}
        y2={71.957}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="k"
        x1={192.998}
        x2={215.019}
        y1={45.002}
        y2={45.002}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="l"
        x1={193.003}
        x2={215.024}
        y1={21.869}
        y2={21.869}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="m"
        x1={208.526}
        x2={210.748}
        y1={32.928}
        y2={32.928}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="n"
        x1={170.366}
        x2={172.588}
        y1={32.928}
        y2={32.928}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="o"
        x1={190.57}
        x2={201.864}
        y1={40.499}
        y2={40.499}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="p"
        x1={170.366}
        x2={172.588}
        y1={40.499}
        y2={40.499}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="q"
        x1={190.57}
        x2={201.864}
        y1={56.284}
        y2={56.284}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="r"
        x1={190.57}
        x2={201.864}
        y1={44.494}
        y2={44.494}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="s"
        x1={205.384}
        x2={210.752}
        y1={36.584}
        y2={36.584}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="t"
        x1={204.939}
        x2={210.307}
        y1={48.039}
        y2={48.039}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="u"
        x1={140.517}
        x2={181.476}
        y1={94.534}
        y2={94.534}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="v"
        x1={140.517}
        x2={181.476}
        y1={51.992}
        y2={51.992}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="w"
        x1={139.101}
        x2={174.63}
        y1={96.469}
        y2={96.469}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="x"
        x1={139.099}
        x2={174.766}
        y1={89.624}
        y2={89.624}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="y"
        x1={95.249}
        x2={96.371}
        y1={115.415}
        y2={115.415}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="z"
        x1={135.689}
        x2={136.811}
        y1={115.415}
        y2={115.415}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="A"
        x1={179.112}
        x2={179.426}
        y1={204.776}
        y2={204.776}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="B"
        x1={179.512}
        x2={179.826}
        y1={204.201}
        y2={204.201}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="C"
        x1={179.937}
        x2={180.251}
        y1={203.641}
        y2={203.641}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="D"
        x1={178.998}
        x2={179.638}
        y1={202.973}
        y2={202.973}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="E"
        x1={180.144}
        x2={180.565}
        y1={202.823}
        y2={202.823}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="F"
        x1={166.807}
        x2={167.122}
        y1={202.782}
        y2={202.782}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="G"
        x1={167.202}
        x2={167.517}
        y1={202.207}
        y2={202.207}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="H"
        x1={167.627}
        x2={167.942}
        y1={201.647}
        y2={201.647}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="I"
        x1={166.688}
        x2={167.329}
        y1={200.983}
        y2={200.983}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="J"
        x1={167.831}
        x2={168.25}
        y1={200.828}
        y2={200.828}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="K"
        x1={169.141}
        x2={182.707}
        y1={150.559}
        y2={150.559}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="L"
        x1={192.984}
        x2={201.651}
        y1={139.956}
        y2={139.956}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="M"
        x1={189.442}
        x2={192.844}
        y1={123.091}
        y2={123.091}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="N"
        x1={199.303}
        x2={202.597}
        y1={130.988}
        y2={130.988}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="O"
        x1={179.722}
        x2={183.331}
        y1={133.165}
        y2={133.165}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="P"
        x1={107.133}
        x2={108.041}
        y1={199.063}
        y2={199.063}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="Q"
        x1={106.693}
        x2={107.296}
        y1={198.639}
        y2={198.639}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="R"
        x1={105.62}
        x2={106.141}
        y1={199.658}
        y2={199.658}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="S"
        x1={106.405}
        x2={106.927}
        y1={200.248}
        y2={200.248}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="T"
        x1={107.375}
        x2={107.896}
        y1={200.743}
        y2={200.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="U"
        x1={122.192}
        x2={123.1}
        y1={198.543}
        y2={198.543}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="V"
        x1={121.758}
        x2={122.361}
        y1={198.121}
        y2={198.121}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="W"
        x1={120.685}
        x2={121.206}
        y1={199.143}
        y2={199.143}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="X"
        x1={121.464}
        x2={121.986}
        y1={199.728}
        y2={199.728}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="Y"
        x1={122.439}
        x2={122.961}
        y1={200.223}
        y2={200.223}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="Z"
        x1={124.43}
        x2={133.698}
        y1={112.835}
        y2={112.835}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="aa"
        x1={109.184}
        x2={118.027}
        y1={116.992}
        y2={116.992}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="ab"
        x1={103.999}
        x2={112.096}
        y1={113.6}
        y2={113.6}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
    </defs>
  </svg>
)
export default RegisterFastIconFour
