const ArrowDownSmallIcon = ({
  fill = '#004949',
  width = '16',
  height = '16',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <mask
        id="mask0_6932_522"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_6932_522)">
        <path
          d="M8 10.4667L4 6.46666L4.93333 5.53333L8 8.59999L11.0667 5.53333L12 6.46666L8 10.4667Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default ArrowDownSmallIcon
