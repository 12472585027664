const IconOne = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={320}
    height={228}
    fill="none"
    {...props}
  >
    <path
      fill="#EBEBEB"
      d="M320 205.536H0v.16h320v-.16ZM287.921 211.629h-58.176v.16h58.176v-.16ZM211.969 209.894h-15.93v.16h15.93v-.16ZM248.385 217.575h-24.697v.16h24.697v-.16ZM48.052 216.499h-14.49v.16h14.49v-.16ZM70.971 216.499h-17.21v.16h17.21v-.16ZM144.082 213.798h-29.978v.16h29.978v-.16Z"
    />
    <path fill="#F0F0F0" d="M272.754 201.9h12v-66.572h-12V201.9Z" />
    <path fill="#F0F0F0" d="M150.286 205.536h132.179v-3.642H150.286v3.642Z" />
    <path fill="#F5F5F5" d="M272.772 135.322H147.984v66.572h124.788v-66.572Z" />
    <path fill="#F0F0F0" d="M274.027 135.322h12.262v-3.175h-12.262v3.175Z" />
    <path fill="#FAFAFA" d="M274.025 132.148H146.479v3.174h127.546v-3.174Z" />
    <path
      fill="#E0E0E0"
      d="M268.474 159.346H152.333V141.99h116.141v17.356Zm-115.36-.787h114.56v-15.744h-114.56v15.744ZM268.474 195.231H152.333v-31.072h116.141v31.072Zm-115.36-.832h114.56v-29.44h-114.56v29.44Z"
    />
    <path
      fill="#E0E0E0"
      d="M169.015 144.973h82.739c.587 0 1.062-.476 1.062-1.063v-.46c0-.587-.475-1.063-1.062-1.063h-82.739c-.587 0-1.063.476-1.063 1.063v.46c0 .587.476 1.063 1.063 1.063ZM169.015 167.148h82.739c.587 0 1.062-.475 1.062-1.062v-.461c0-.587-.475-1.062-1.062-1.062h-82.739c-.587 0-1.063.475-1.063 1.062v.461c0 .587.476 1.062 1.063 1.062Z"
    />
    <path fill="#E6E6E6" d="M36.546 205.536h4.985V120.8h-4.985v84.736Z" />
    <path fill="#F0F0F0" d="M34.967 205.536h3.463V120.8h-3.463v84.736Z" />
    <path fill="#F0F0F0" d="M93.224 120.8H33.91v6.124h59.315V120.8Z" />
    <path fill="#E6E6E6" d="M95.438 205.536h4.985v-78.605h-4.986v78.605Z" />
    <path fill="#F0F0F0" d="M93.89 205.536h3.463v-78.605H93.89v78.605Z" />
    <path fill="#E6E6E6" d="M127.001 205.536h4.986v-78.605h-4.986v78.605Z" />
    <path fill="#F0F0F0" d="M125.423 205.536h3.462v-78.605h-3.462v78.605Z" />
    <path fill="#E6E6E6" d="M68.11 205.536h4.985v-78.605h-4.986v78.605Z" />
    <path fill="#F0F0F0" d="M66.531 205.536h3.462v-78.605h-3.462v78.605Z" />
    <path fill="#E6E6E6" d="M93.216 126.924h38.771v-6.125H93.216v6.125Z" />
    <path
      fill="#EBEBEB"
      d="M88.186 73.421h-47.93l4.244-37.1h39.443l4.243 37.1Z"
    />
    <path fill="#F5F5F5" d="M66.832 73.42h-5.216v47.379h5.216v-47.38Z" />
    <path
      fill="#F0F0F0"
      d="M74.875 120.799H53.589a2.265 2.265 0 0 1 2.265-2.265H72.59a2.27 2.27 0 0 1 1.615.656 2.273 2.273 0 0 1 .67 1.609Z"
    />
    <path fill="#E0E0E0" d="m66.832 77.522-5.216-2.49V73.42h5.216v4.102Z" />
    <path
      fill="#E6E6E6"
      d="M86.659 120.665h22.509l2.784-30.573h-22.51l-2.783 30.573Z"
    />
    <path
      fill="#F0F0F0"
      d="M85.497 120.665h22.509l2.784-30.573H88.281l-2.784 30.573Z"
    />
    <path
      fill="#fff"
      d="m104.917 117.279 2.17-23.801H91.349l-2.17 23.801h15.738Z"
    />
    <path
      fill="#E6E6E6"
      d="M271.863 98.74h-45.689l-8.89 31.661h45.69l8.889-31.661Z"
    />
    <path
      fill="#E6E6E6"
      d="m262.961 130.4-.179 1.747h-45.703l.186-1.747h45.696Z"
    />
    <path
      fill="#F0F0F0"
      d="M270.476 98.74H224.78l-8.889 31.661h45.696l8.889-31.661Z"
    />
    <path
      fill="#fff"
      d="m261.045 128.333 7.354-27.418c.102-.39-.096-.729-.429-.729h-42.01a.797.797 0 0 0-.691.64l-7.347 27.398c-.109.39.089.73.422.73h42.01a.79.79 0 0 0 .691-.621Z"
    />
    <path
      fill="#F0F0F0"
      d="m249.672 130.4-.186 1.747h-50.317l.186-1.747h50.317Z"
    />
    <path
      fill="url(#a)"
      d="M134.677 5.6c2.987-2.658 6.855-4.4 11.603-5.223 4.749-.823 9.769-.3 15.06 1.568 5.273 1.87 10.097 5.244 14.47 10.125 4.066 4.54 6.814 9.295 8.243 14.266a26.166 26.166 0 0 1 .378 14.163 22.461 22.461 0 0 1-6.848 11.18 19.426 19.426 0 0 1-8.442 4.64c-2.82.703-5.732.97-8.633.794-2.786-.179-7.621-.655-14.503-1.427a45.833 45.833 0 0 0-4.851-.269c-1.11-.007-2.216.12-3.296.378-.856.218-1.688.523-2.483.909-.768.377-2.067 1.075-3.911 2.105-3.788 2.317-7.082 1.912-9.881-1.216a7.685 7.685 0 0 1-2.08-5.536c.072-2.06 1.156-4.03 3.251-5.907a22.305 22.305 0 0 1 15.891-6.195c3.364.112 6.72.394 10.055.845 3.545.428 6.163.697 7.852.787a17.96 17.96 0 0 0 4.999-.416 9.6 9.6 0 0 0 4.384-2.272 11.149 11.149 0 0 0 3.84-8.467c.051-3.414-1.229-6.586-3.84-9.517-3.085-3.443-6.223-5.199-9.415-5.267-3.191-.069-6.99.849-11.398 2.752-4.484 2.103-8.058 1.677-10.72-1.28a7.978 7.978 0 0 1-2.118-6.093 7.674 7.674 0 0 1 2.393-5.427ZM103.74 74.52a9.906 9.906 0 0 1-2.618-6.662c-.038-2.526 1.052-4.785 3.27-6.778a9.005 9.005 0 0 1 6.829-2.361 9.36 9.36 0 0 1 6.554 3.264 9.246 9.246 0 0 1 1.508 10.34 9.231 9.231 0 0 1-2.244 2.914c-2.189 1.959-4.536 2.795-7.04 2.509a9.597 9.597 0 0 1-6.259-3.226Z"
    />
    <path
      fill="url(#b)"
      d="M191.897 48.512a12.162 12.162 0 0 1 4.295-5.287 15.911 15.911 0 0 1 7.59-2.867 18.942 18.942 0 0 1 9.363 1.37 19.682 19.682 0 0 1 7.373 4.832 14.037 14.037 0 0 1 3.584 6.688 12.062 12.062 0 0 1-.576 7.04 10.39 10.39 0 0 1-2.931 4.25 15.294 15.294 0 0 1-3.942 2.457c-1.374.584-3.804 1.519-7.29 2.803a22.4 22.4 0 0 0-2.387 1.037 7.195 7.195 0 0 0-1.485.973c-.36.308-.686.653-.973 1.03a79.789 79.789 0 0 0-1.363 1.92c-1.28 2.022-2.955 2.62-5.024 1.792a4.137 4.137 0 0 1-2.33-2.15 4.49 4.49 0 0 1 .141-3.61 11.957 11.957 0 0 1 6.119-6.784 53.86 53.86 0 0 1 5.011-2.035c1.798-.64 3.117-1.146 3.949-1.504a9.663 9.663 0 0 0 2.291-1.402 5.185 5.185 0 0 0 1.555-2.144 5.952 5.952 0 0 0-.198-4.973 7.328 7.328 0 0 0-4.116-3.596c-2.304-.91-4.224-.997-5.76-.263a14.86 14.86 0 0 0-4.787 4.045c-1.647 2.086-3.464 2.726-5.453 1.92a4.297 4.297 0 0 1-2.476-2.413 4.056 4.056 0 0 1-.18-3.13Zm1.748 40.448a5.319 5.319 0 0 1-2.855-2.56 4.693 4.693 0 0 1-.077-4 4.8 4.8 0 0 1 2.695-2.784 4.996 4.996 0 0 1 3.923 0 4.957 4.957 0 0 1 3.208 4.587c.006.663-.12 1.32-.373 1.934a4.703 4.703 0 0 1-2.777 2.893 5.12 5.12 0 0 1-3.744-.07Z"
      opacity={0.5}
    />
    <path
      fill="url(#c)"
      d="M234.957 44.741a5.708 5.708 0 0 1-.89-3.046 7.337 7.337 0 0 1 .909-3.66 8.731 8.731 0 0 1 3.002-3.2 9.135 9.135 0 0 1 3.801-1.55 6.554 6.554 0 0 1 3.514.397 5.623 5.623 0 0 1 2.56 2.08c.47.665.765 1.438.858 2.247.073.72.038 1.447-.103 2.156-.141.687-.426 1.865-.857 3.533-.098.393-.174.79-.231 1.19-.074.494-.035.997.115 1.473.071.204.199.55.391 1.036a1.743 1.743 0 0 1-.123 1.95 1.754 1.754 0 0 1-.517.45 1.921 1.921 0 0 1-1.434.32 2.077 2.077 0 0 1-1.35-1.004 5.829 5.829 0 0 1-.935-2.125 5.774 5.774 0 0 1-.044-2.01c.138-.828.319-1.648.544-2.457.23-.864.384-1.498.467-1.92a4.82 4.82 0 0 0 .07-1.28 2.441 2.441 0 0 0-.416-1.165 2.77 2.77 0 0 0-1.958-1.235 3.39 3.39 0 0 0-2.483.64 3.332 3.332 0 0 0-1.626 2.137 6.942 6.942 0 0 0 .288 2.906c.365 1.184.128 2.048-.698 2.605a1.997 1.997 0 0 1-1.574.313 1.92 1.92 0 0 1-1.28-.78Zm15.968 10.023a2.502 2.502 0 0 1-1.734.416 2.17 2.17 0 0 1-1.562-1.043 2.243 2.243 0 0 1-.358-1.767 2.347 2.347 0 0 1 1.03-1.51 2.279 2.279 0 0 1 1.76-.365 2.25 2.25 0 0 1 1.491 1.005 2.204 2.204 0 0 1 .378 1.83 2.413 2.413 0 0 1-1.005 1.434Z"
      opacity={0.1}
    />
    <path
      fill="#FFC3BD"
      d="m118.912 222.957-4.839-1.254 2.887-11.066 4.838 1.254-2.886 11.066Z"
    />
    <path
      fill="#263238"
      d="m114.003 220.422 5.402 1.709a.338.338 0 0 1 .215.161.34.34 0 0 1 .034.268l-1.139 4.192a.908.908 0 0 1-1.126.557c-1.869-.64-2.733-1.012-5.12-1.767-1.466-.461-4.998-1.504-7.04-2.144-2.042-.64-1.28-2.56-.39-2.438 4.108.429 6.495.083 8.204-.512.309-.114.646-.123.96-.026Z"
    />
    <path
      fill="#000"
      d="m121.79 211.897-4.839-1.255-1.484 5.703 4.838 1.254 1.485-5.702Z"
      opacity={0.2}
    />
    <path
      fill="#01A76A"
      d="M133.994 98.738s-8.858 42.093-11.213 55.872c-2.56 15.091-8.186 58.298-8.186 58.298l8.224 2.989s12.871-45.818 14.823-60.327c4.089-14.182 14.451-56.832 14.451-56.832h-18.099Z"
    />
    <path
      fill="#000"
      d="M145.348 112.479c-2.874 6.899-3.917 19.462-3.418 26.976a3073.843 3073.843 0 0 0 6.765-26.938c-.634-2.182-1.504-4.473-3.347-.038Z"
      opacity={0.3}
    />
    <path
      fill="#263238"
      d="m122.632 216.87-8.819-2.547.333-3.731 10.496 2.95-2.01 3.328Z"
    />
    <path
      fill="url(#d)"
      d="M112.022 220.691c.436.125.884.205 1.337.237a.103.103 0 0 0 .067-.01.104.104 0 0 0 .048-.047.105.105 0 0 0 .019-.061.103.103 0 0 0-.019-.061c-.121-.154-1.177-1.478-1.836-1.44a.453.453 0 0 0-.372.224.64.64 0 0 0-.057.64c.198.266.487.451.813.518Zm1.088 0c-.922-.108-1.543-.332-1.696-.64a.394.394 0 0 1 .051-.409.233.233 0 0 1 .075-.081.214.214 0 0 1 .104-.034c.346-.013 1.024.633 1.466 1.152v.012Z"
    />
    <path
      fill="url(#e)"
      d="M113.351 220.928h.058a.118.118 0 0 0 .089-.064c0-.057.589-1.356.256-1.958a.63.63 0 0 0-.422-.301.46.46 0 0 0-.519.199c-.288.473.103 1.664.487 2.086.012.018.03.031.051.038Zm0-2.086a.342.342 0 0 1 .205.173c.116.528.05 1.081-.186 1.568-.301-.474-.531-1.357-.339-1.664 0-.058.096-.122.262-.09l.058.013Z"
    />
    <path
      fill="#FFC3BD"
      d="M140.849 62.688a69.965 69.965 0 0 1-1.28 2.906 76.768 76.768 0 0 1-1.395 2.803 78.491 78.491 0 0 1-3.123 5.453 50.14 50.14 0 0 1-8.064 10.092l-.116.116a2.703 2.703 0 0 1-.537.377 4.482 4.482 0 0 1-1.722.519 7.265 7.265 0 0 1-1.037 0 10.75 10.75 0 0 1-1.536-.212 26.273 26.273 0 0 1-2.508-.678c-1.575-.5-3.04-1.107-4.48-1.722a62.736 62.736 0 0 1-4.256-2.003c-1.396-.723-2.765-1.446-4.122-2.266a1.549 1.549 0 0 1 1.165-2.828c2.969.716 5.933 1.516 8.851 2.227 1.453.365 2.906.685 4.301.954.657.133 1.322.229 1.99.287.252.015.504.015.755 0h.109a1.244 1.244 0 0 0-.64.199l-.64.493a42.517 42.517 0 0 0 3.437-4.34c1.075-1.555 2.093-3.2 3.072-4.85a96.336 96.336 0 0 0 2.81-5.12 179.27 179.27 0 0 0 2.56-5.191l.038-.083a3.503 3.503 0 0 1 6.4 2.848l-.032.019Z"
    />
    <path
      fill="#E0E0E0"
      d="M139.842 55.917c-4.122.116-6.951 4.59-9.652 10.164a23.808 23.808 0 0 0 8.608 8.64s10.829-19.079 1.044-18.804Z"
    />
    <path
      fill="#FFC3BD"
      d="m106.455 78.098-3.712-.947 2.892-3.718s3.2.505 4.416 3.2l-1.318.94a2.813 2.813 0 0 1-2.278.525ZM101.843 73.35l.717-.64a1.655 1.655 0 0 1 2.131-.045l.947.761-2.893 3.719-1.184-1.844a1.507 1.507 0 0 1 .282-1.952ZM162.228 222.381h-5.062l-1.21-11.277h5.062l1.21 11.277Z"
    />
    <path
      fill="#263238"
      d="M156.698 221.817h5.69a.361.361 0 0 1 .378.333l.275 4.327a.885.885 0 0 1-.268.616.887.887 0 0 1-.622.254c-1.977-.038-2.925-.147-5.427-.147-1.536 0-5.76.153-7.904.153s-2.067-2.022-1.165-2.201c4.039-.826 6.739-1.959 8.173-3.034.249-.193.555-.299.87-.301Z"
    />
    <path
      fill="#000"
      d="M161.018 211.104h-5.062l.627 5.818h5.063l-.628-5.818Z"
      opacity={0.2}
    />
    <path
      fill="#01A76A"
      d="M143.869 98.738s1.12 42.317 1.92 56.263c.896 15.27 8.48 60.512 8.48 60.512h9.28s-2.035-45.818-3.571-60.8c.64-16.41 1.062-55.987 1.062-55.987l-17.171.012Z"
    />
    <path
      fill="#263238"
      d="M163.931 215.513H153.41l-.499-3.385 11.302.192-.282 3.193Z"
    />
    <path
      fill="url(#f)"
      d="M154.919 222.663c.455-.01.908-.068 1.351-.173a.104.104 0 0 0 .056-.031.107.107 0 0 0 .027-.059.104.104 0 0 0-.003-.065.107.107 0 0 0-.042-.05c-.16-.102-1.594-1.049-2.214-.813a.434.434 0 0 0-.276.32.605.605 0 0 0 .148.64c.28.189.618.27.953.231Zm1.024-.333c-.909.173-1.574.141-1.811-.083a.406.406 0 0 1-.083-.403.21.21 0 0 1 .128-.16c.333-.128 1.177.281 1.766.646Z"
    />
    <path
      fill="url(#g)"
      d="M156.244 222.496h.058a.134.134 0 0 0 .064-.096c0-.057.115-1.459-.397-1.92a.584.584 0 0 0-.499-.153.446.446 0 0 0-.429.339c-.115.531.64 1.549 1.145 1.837l.058-.007Zm-.717-1.977a.345.345 0 0 1 .256.102c.28.465.398 1.01.333 1.549-.442-.358-.947-1.133-.87-1.478 0-.071.057-.147.23-.167l.051-.006Z"
    />
    <path
      fill="#E0E0E0"
      d="M136.101 59.174a4.855 4.855 0 0 1 3.738-3.258 31.003 31.003 0 0 1 3.795-.64 104.51 104.51 0 0 1 11.52 0c2.01.09 4.058.327 5.69.557h.057a3.111 3.111 0 0 1 2.707 3.61 147.856 147.856 0 0 0-2.502 19.757c-.397 9.638.141 17.203-.051 19.526H133.97c-1.645-23.507.48-34.195 2.131-39.552Z"
    />
    <path
      fill="#263238"
      d="M157.01 40.934a3.366 3.366 0 0 1-.506 5.76c1.094 1.811-1.818 5.011-4.749 3.002-2.931-2.01 1.21-10.47 5.255-8.762ZM144.898 38.99a2.326 2.326 0 0 1-2.029-1.075 2.32 2.32 0 0 1-.116-2.292c-1.363-.531-1.12-3.508 1.338-3.635 2.458-.128 3.725 6.252.807 7.001Z"
    />
    <path
      fill="#FFC3BD"
      d="M144.073 57.791a1.436 1.436 0 0 1-.105-2.005c.165-.187.375-.327.61-.407 2.471-.91 2.919-2.701 2.72-4.653a13.646 13.646 0 0 0-.268-1.632l4.192-3.347 3.084-2.458c-.768 3.2-1.625 8.774 0 11.597a1.448 1.448 0 0 1-.294 1.869c-1.062.94-3.238 2.156-6.822 1.964-1.645-.083-2.56-.46-3.117-.928Z"
    />
    <path
      fill="#263238"
      d="M134.735 59.289a5.64 5.64 0 0 1 4.103-3.725 34.682 34.682 0 0 1 3.667-.384.779.779 0 0 1 .871.596.775.775 0 0 1-.007.383c-2.144 7.962-5.203 23.891-4.256 43.622a.9.9 0 0 1-.922.935h-5.376a.91.91 0 0 1-.631-.239.912.912 0 0 1-.297-.606c-1.619-24.109 1.242-36.122 2.848-40.582ZM160.701 55.354a50.852 50.852 0 0 0-5.191-.474 1.338 1.338 0 0 0-1.395 1.011c-1.99 8.768-3.763 28.25-3.84 43.923a.923.923 0 0 0 .28.637.898.898 0 0 0 .648.253h9.6a.91.91 0 0 0 .928-.87c.064-3.156-.371-10.548 0-19.725a137.552 137.552 0 0 1 2.458-19.469c.333-1.798-1.587-5.018-3.488-5.286Z"
    />
    <path
      fill="#FFC3BD"
      d="M164.071 58.137a123.408 123.408 0 0 1 5.689 9.46c.871 1.631 1.728 3.289 2.503 5.03.384.87.761 1.76 1.113 2.7.388 1.018.688 2.067.896 3.137a7.62 7.62 0 0 1 .103 2.368 10.29 10.29 0 0 1-.493 1.875c-.365.991-.813 1.95-1.338 2.867a43.93 43.93 0 0 1-3.142 4.787 77.908 77.908 0 0 1-7.245 8.359 1.743 1.743 0 0 1-2.312.136 1.74 1.74 0 0 1-.402-2.28v-.039c1.863-3.001 3.744-6.06 5.447-9.094.845-1.51 1.651-3.047 2.297-4.531.647-1.485 1.12-3.11.788-3.156a16.677 16.677 0 0 0-.743-1.996c-.32-.73-.685-1.485-1.069-2.234a105.199 105.199 0 0 0-2.496-4.538 310.506 310.506 0 0 0-5.472-9.017v-.039a3.498 3.498 0 0 1 5.876-3.795Z"
    />
    <path
      fill="#E0E0E0"
      d="M160.107 55.27c4.122-.076 6.304 3.412 9.005 8.96-3.059 5.665-8.896 8.116-8.896 8.116s-8.211-16.934-.109-17.075Z"
    />
    <path
      fill="#FFC3BD"
      d="m157.982 96.41-2.375.755 4.397 3.341s3.597-1.869 2.47-3.565l-.729-.326a5.33 5.33 0 0 0-3.763-.205ZM154.271 100.095l2.669 2.682a.879.879 0 0 0 .655.246.87.87 0 0 0 .625-.317l1.773-2.201-4.397-3.34-1.37 1.874a.778.778 0 0 0 .045 1.056Z"
    />
    <path
      fill="#000"
      d="M147.028 49.12c.122.538.212 1.083.269 1.632 1.587.403 3.84-1.415 4.051-2.957a9.854 9.854 0 0 0-.128-2.023l-4.192 3.348Z"
      opacity={0.2}
    />
    <path
      fill="#FFC3BD"
      d="M156.941 38.745c-1.081 4.724-1.465 7.54-4.371 9.645a6.66 6.66 0 0 1-10.566-4.755c-.371-4.429 1.459-11.411 6.63-12.653 5.171-1.241 9.389 3.034 8.307 7.763Z"
    />
    <path
      fill="#263238"
      d="M154.764 42.88c-1.37.07-2.829-4.762.192-7.091-.48.262-1.792-.218-1.562-1.12-.889.37-4.78.21-3.84-1.37-1.977.512-6.291-.346-6.752-2.234-.46-1.888 1.767-1.952 2.112-1.702-.979-1.37-.96-3.38 1.639-4.109 2.598-.73 6.515 3.027 6.515 3.027-.41-.992 1.03-1.792 2.522-.94 1.491.85 2.188 3.27 2.188 3.27 0-.73 3.482.25 1.831 3.309 1.709.64 2.489 1.549 2.381 3.13-.109 1.58-1.191 1.67-1.191 1.67a2.742 2.742 0 0 1-.941 3.251c-1.772 1.427-3.321-.25-5.094.909Z"
    />
    <path
      fill="#FFC3BD"
      d="M157.458 43.244a4.608 4.608 0 0 1-2.516 1.824c-1.318.352-1.766-.825-1.228-2.054.486-1.107 1.753-2.63 2.995-2.438 1.241.192 1.491 1.568.749 2.668Z"
    />
    <path
      fill="#263238"
      d="M149.132 38.554c-.064.384-.333.672-.596.64-.262-.032-.428-.378-.364-.768s.332-.64.595-.64c.262 0 .397.377.365.768ZM144.431 37.702c-.064.39-.326.679-.589.64-.262-.038-.428-.377-.364-.768.064-.39.326-.64.588-.64.263 0 .429.384.365.768Z"
    />
    <path
      fill="#ED847E"
      d="M146.29 38.572a14.36 14.36 0 0 1-2.477 3.053 2.402 2.402 0 0 0 1.875.64l.602-3.693Z"
    />
    <path
      fill="#263238"
      d="M146.523 44.172a3.52 3.52 0 0 0 2.617-.768.122.122 0 0 0 0-.166.126.126 0 0 0-.166 0 3.38 3.38 0 0 1-2.874.64.113.113 0 0 0-.089.015.111.111 0 0 0-.052.075.114.114 0 0 0 .015.087c.009.012.02.023.033.031a.109.109 0 0 0 .042.016c.156.035.314.059.474.07ZM149.318 35.891a.234.234 0 0 0-.173.22c0 .032.006.062.018.09.013.028.03.053.053.074a2.315 2.315 0 0 0 2.009.755.226.226 0 0 0 .157-.087.231.231 0 0 0 .042-.175.244.244 0 0 0-.098-.153.246.246 0 0 0-.178-.04 1.849 1.849 0 0 1-1.568-.64.252.252 0 0 0-.262-.044ZM143.122 34.732a.28.28 0 0 0 .224 0 2.088 2.088 0 0 1 1.767-.044.243.243 0 0 0 .326-.084.242.242 0 0 0-.096-.32 2.558 2.558 0 0 0-2.214 0 .223.223 0 0 0-.12.131.22.22 0 0 0 .017.177.255.255 0 0 0 .096.14Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={147.528}
        x2={180.875}
        y1={46.681}
        y2={46.681}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={209.106}
        x2={222.617}
        y1={69.71}
        y2={69.71}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={243.956}
        x2={251.062}
        y1={46.401}
        y2={46.401}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={112.442}
        x2={113.372}
        y1={220.281}
        y2={220.281}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={113.344}
        x2={113.797}
        y1={219.992}
        y2={219.992}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="f"
        x1={155.203}
        x2={156.224}
        y1={222.177}
        y2={222.177}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
      <linearGradient
        id="g"
        x1={155.773}
        x2={156.307}
        y1={221.629}
        y2={221.629}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CF83" />
        <stop offset={1} stopColor="#00FF5E" />
      </linearGradient>
    </defs>
  </svg>
)
export default IconOne
