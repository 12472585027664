import TalentCard from './TalentCard'
import { userInfo } from '../../localStorage'
import {
  DICTIONARY,
  OUT_OF_THE_APP,
  TYPE_OF_USER,
  VIEWPORT_LG,
} from '../../utils/constants'
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from '../../Routes/routes'
import { Link, useNavigate } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import Button from '../../components/Button'
import IconOne from '../../icons/listOfTalents/IconOne'
import Loader from '../../components/Loader'
import useResize from '../../hooks/useResize'
import { useTranslation } from 'react-i18next'

const ListOfTalents = ({
  data = [],
  isLoading,
  showStatus,
  handleGetMoreElements,
  hasMore,
  mutate,
  listOfTalentsPage,
  page,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const totalElementsToShow = 4
  const elementsToShow = data.slice(0, totalElementsToShow)
  const viewportSize = useResize()

  const listOfTalents =
    window.location.pathname === PRIVATE_ROUTES.talents ? data : elementsToShow

  return (
    <div className="mb-5">
      {data.length === 0 && page === 0 && isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Loader />
        </div>
      ) : data.length === 0 ? (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <IconOne className="mb-3" />
          <span
            className={`${
              viewportSize[0] <= VIEWPORT_LG ? '' : 'w-50'
            } text-center text-on-bg-secondary mb-3`}
          >
            No encontramos ningún resultado. Prueba con otros filtros o crea tu
            oferta para encontrar tu candidato ideal
          </span>
          <Button
            className="btn-bg-CTA-primary text-on-bg-primary"
            onClick={() =>
              OUT_OF_THE_APP
                ? navigate(PUBLIC_ROUTES.register_by_company)
                : navigate(PUBLIC_ROUTES.create_job_offer)
            }
          >
            Crea tu oferta
          </Button>
        </div>
      ) : (
        <InfiniteScroll
          dataLength={data?.length}
          next={handleGetMoreElements}
          hasMore={hasMore}
          loader={
            window.location.pathname === PRIVATE_ROUTES.talents ? (
              <>Cargando más talentos...</>
            ) : (
              <></>
            )
          }
          endMessage={
            window.location.pathname === PRIVATE_ROUTES.talents && (
              <div className="d-flex flex-column text-center text-on-bg-secondary">
                <div className="d-flex flex-column mb-3">
                  <span>
                    {DICTIONARY.do_not_you_find_what_you_are_looking_for}
                  </span>
                  <span>
                    {DICTIONARY.let_our_team_find_your_perfect_candidate}
                  </span>
                </div>
                <div className="d-flex justify-content-center align-items-center gap-3">
                  <Button
                    onClick={() =>
                      navigate(
                        OUT_OF_THE_APP
                          ? PUBLIC_ROUTES.register_by_company
                          : PRIVATE_ROUTES.create_job_offer
                      )
                    }
                    className="btn-bg-CTA-primary text-on-bg-primary"
                  >
                    {OUT_OF_THE_APP
                      ? DICTIONARY.sign_up
                      : DICTIONARY.create_your_offer}
                  </Button>
                </div>
              </div>
            )
          }
        >
          {listOfTalents?.map((props, index) => {
            return (
              <div key={index}>
                <TalentCard
                  showStatus={showStatus}
                  mutate={mutate}
                  listOfTalentsPage={listOfTalentsPage}
                  data={data}
                  index={index}
                  {...props}
                />
              </div>
            )
          })}
        </InfiniteScroll>
      )}
      {userInfo?.payload?.typeUser !== TYPE_OF_USER.recruiter && !isLoading ? (
        data?.length >= 3 &&
        window.location.pathname !== PRIVATE_ROUTES.talents ? (
          <Link
            to={PRIVATE_ROUTES.talents}
            className="btn border border-on-bg-primary text-on-bg-primary bg-white mb-4 w-100"
          >
            {t('review_all')}
          </Link>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </div>
  )
}

export default ListOfTalents
