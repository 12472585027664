import { io } from 'socket.io-client'
import { SOCKET_BASE_URL } from '../services/constants'

export const SOCKET_VALUES = {
  base_url: SOCKET_BASE_URL,
  joinChannel: 'join:channel',
  sendMessage: 'send:message',
  privateMessage: 'private:message',
}

export const config = {
  transports: ['websocket', 'polling', 'flashsocket'],
  forceNew: true,
}

export const SOCKET = io(SOCKET_VALUES.base_url, config)

export const emit = (value, callback) => {
  SOCKET.emit(value, callback)
}

export const on = (value, callback) => {
  SOCKET.on(value, callback)
}

export const off = (value, callback) => {
  SOCKET.off(value, callback)
}
